import React, { useState } from "react";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import lodash, { omit } from "lodash";
import { BsUpload } from "react-icons/bs";
import axios from "axios";
import ImageUploading from "react-images-uploading";
import "react-toastify/dist/ReactToastify.css";

// mui imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// component imports
import MountCondition from "../MountCondition";
import config from "../../../../config/config";
import useStyles from "./styles";
import uploadDocumentEvents from "../../../../api/uploadDocumentEvents";
import importAuctionAPI from "../../../../api/adminAPI/auctionImportAPI";

const MAX_NUMBER = 5;

const BulkUploadDialog = ({
  show = false,
  title = "",
  toggleAuctionBulkUploadDialog,
  setIsLoading,
  setCreateType,
}) => {
  const classes = useStyles();
  const fileType = ["application/pdf"];

  // states
  const [file, setFile] = useState();
  const [pdfFile, setPdfFile] = useState(null);
  const [images, setImages] = useState("");
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [uploadRules, setUploadRules] = useState({
    manualStartDate: moment().utc().toISOString(),
    manualEndDate: moment().utc().toISOString(),
    timeInterval: null,
    importType: "EXCEL",
  });
  const [eventInfo, setEventInfo] = useState({
    auctionTitle: "",
    referenceNumber: "",
    description: "",
    eventType: "",
    testProject: "",
    streetOne: "",
    streetTwo: "",
    country: "",
    province: "",
    city: "",
    postalCode: "",
    eventImage: "",
    auctionCatalogue: "",
  });
  const [eventUploadInfo, setEventUploadInfo] = useState({
    document: "",
  });
  const [eventUploadInfoCatalogue, setEventUploadInfoCatalogue] = useState({
    document: "",
  });
  const [imagesUploadedCatalogue, setImagesUploadedCatalogue] = useState([]);
  const [selectedOption, setSelectedOption] = useState("EXCEL");
  const [selectedTimeInterval, setSelectedTimeInterval] = useState("");
  const [pdfFileError, setPdfFileError] = useState("");
  const [errors, setErrors] = useState({});

  // methods
  const onChange = (imageList, addUpdateIndex, y) => {
    setImages(imageList);
    imageUpload(imageList);
  };
  const imageUpload = async (images) => {
    try {
      setIsLoading(true);
      images.map(async (image, index) => {
        eventUploadInfo.document = image["file"];
        const { data } = await uploadDocumentEvents(eventUploadInfo);
        setImagesUploaded(data);
        eventInfo.eventImage = data.documentId;

        if (data) {
          setIsLoading(false);
          toast("Image Uploaded Successfully!");
        }
      });
    } catch (error) {
      if (error.response) {
        error.response.data.error.map((errors, index) => {
          toast(errors);
          setIsLoading(false);
        });
      } else if (error.request) {
        toast("Something went wrong!");
      } else {
        console.log("Error", error.message.code);
      }
    }
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const closeAuctionBulkUploadDialog = () => {
    setIsLoading(false);
    setCreateType(null);
    setFile(null);
    setImages("");
    setUploadRules({
      manualStartDate: moment().utc().toISOString(),
      manualEndDate: moment().utc().toISOString(),
      timeInterval: null,
      importType: "EXCEL",
    });

    return toggleAuctionBulkUploadDialog(false);
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const validation = {
        size: 5 * 1024 * 1024,
        extensions: ["xlsx", "xls", "csv"],
      };
      const extension = (fileName) => {
        return fileName.substr(fileName.lastIndexOf(".") + 1);
      };

      if (lodash.isEmpty(event.target.file.files)) {
        return toast.error("Please upload the auction file first.");
      }
      if (file && file.size > validation.size) {
        return toast.error("File size exceeds the maximum limit of 5MB.");
      }
      if (!validation.extensions.includes(extension(file.name))) {
        return toast.error("Invalid file type");
      }

      const payload = {
        file: file,
        importType: uploadRules.importType,
        image: imagesUploaded.documentId,
        catalogue: imagesUploadedCatalogue.documentId,
      };
      setIsLoading(true);

      if (uploadRules.importType === "MANUAL") {
        payload.manualStartDate = uploadRules.manualStartDate;
        payload.manualEndDate = uploadRules.manualEndDate;

        if (uploadRules.timeInterval) {
          payload.timeInterval = uploadRules.timeInterval;
        }
      }

      const { data } = await importAuctionAPI(payload);
      console.log("DAATA: ", data);

      if (data) {
        toast.success("Auction created Successfully!");
      }
      return closeAuctionBulkUploadDialog();
    } catch (error) {
      toast.error(
        axios.isAxiosError(error)
          ? error.response.data.error
          : "Something went wrong."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    setUploadRules({ ...uploadRules, importType: event.target.value });
  };

  const handleTimeChange = (event) => {
    setSelectedTimeInterval(event.target.value);
    setUploadRules({ ...uploadRules, timeInterval: event.target.value });
  };

  const validate = (name, value) => {
    //A function to validate each input values
    switch (name) {
      case "manualStartDate":
        if (value === null) {
          // we will set the error publishDateTime

          setErrors({
            ...errors,
            manualStartDate: "Start Date Time Can Not Be Empty.",
          });
        } else {
          // set the error publishDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "manualStartDate");
          setErrors(newObj);
        }
        break;

      case "manualEndDate":
        if (value === null) {
          // we will set the error bidStartDateTime

          setErrors({
            ...errors,
            manualEndDate: "End Start Date Time Can Not Be Empty.",
          });
        } else {
          // set the error bidStartDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "manualEndDate");
          setErrors(newObj);
        }
        break;

      case "timeInterval":
        if (value === null) {
          // we will set the error bidStartDateTime

          setErrors({
            ...errors,
            manualEndDate: "Time Interval Can Not Be Empty.",
          });
        } else {
          // set the error bidStartDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "timeInterval");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  const dateHandler = (name, date) => {
    validate(name, date);
    if (lodash.isEmpty(date)) {
      return true;
    }

    if (date.$d == "Invalid Date") {
      return true;
    }

    const dateUTC = moment.utc(date.$d).toISOString();

    setUploadRules((prevUploadRules) => ({
      ...prevUploadRules,
      [name]: dateUTC,
    }));
  };

  const handleDownload = () => {
    window.location.href = `${config.api.base_url}events/download-auction-sample`;
  };

  const handlePdfFileChange = async (e) => {
    let selectedFile = e.target.files[0];
    setIsLoading(true);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = async (e) => {
          setPdfFile(e.target.result);
          setPdfFileError("");
          eventUploadInfoCatalogue.document = selectedFile;
          const { data } = await uploadDocumentEvents(eventUploadInfoCatalogue);
          setImagesUploadedCatalogue(data);
          eventInfo.auctionCatalogue = data.documentId;

          if (data) {
            setIsLoading(false);
            toast("Catalogue Uploaded Successfully!");
          }
        };
      } else {
        setPdfFile(null);
        setPdfFileError("Please select valid pdf file");
        setIsLoading(false);
      }
    } else {
      console.log("select your file");
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        closeAuctionBulkUploadDialog();
      }}
      className="editListModal"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          height: "70vh",
          overflow: "auto",
        }}
      >
        <div>
          <div className={classes.flexRow}>
            <div
              style={{
                flex: 1,
                marginRight: "1rem",
                maxWidth: "15rem",
              }}
            >
              <Form.Label>Select Time Type</Form.Label>
              <Form.Select
                value={uploadRules.importType}
                onChange={handleDropdownChange}
                className="editDropdown"
              >
                <option value="EXCEL">Excel</option>
                <option value="MANUAL">Manual</option>
              </Form.Select>
            </div>
            <MountCondition condition={uploadRules.importType === "MANUAL"}>
              <MountCondition.True>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-4 dateSelector">
                      <Form.Label>Select Start Date and Time</Form.Label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          className="datePicker newDatePicker"
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              className="form-control form-control-sm p-0 m-0"
                            />
                          )}
                          value={uploadRules.manualStartDate}
                          onChange={(date) => {
                            dateHandler("manualStartDate", date);
                          }}
                          minDate={moment().toDate()}
                          style={{ padding: "7px 14px !important" }}
                        />
                      </LocalizationProvider>
                    </div>

                    <div className="col-md-4 dateSelector">
                      <Form.Label>Select End Date and Time</Form.Label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          className="datePicker newDatePicker"
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              className="form-control form-control-sm p-0 m-0"
                            />
                          )}
                          value={uploadRules.manualEndDate}
                          onChange={(date) => {
                            dateHandler("manualEndDate", date);
                          }}
                          minDate={moment().toDate()}
                          style={{ padding: "7px 14px !important" }}
                        />
                      </LocalizationProvider>
                    </div>

                    <div className="col-md-4">
                      <Form.Label>Select Interval</Form.Label>
                      <Form.Select
                        onChange={handleTimeChange}
                        className="editDropdown"
                      >
                        <option value="">Select</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="60">60</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </MountCondition.True>
            </MountCondition>
          </div>
          <div className={classes.flexRow}>
            <div className={classes.uploader}>
              {/* Event Images */}
              <div className="row">
                <div className="col-md-6">
                  Event Image (JPG,JPEG,PNG)
                  <p className={classes.imageSize}>Dimension: 260px X 200px</p>
                  <ImageUploading
                    value={images}
                    onChange={onChange}
                    maxNumber={MAX_NUMBER}
                    // multiple={true}
                    dataURLKey="data_url"
                    acceptType={["jpg", "jpeg", "png"]}
                  >
                    {({ imageList, onImageUpload, dragProps, errors }) => (
                      <>
                        {errors && (
                          <div>
                            {errors.maxNumber && (
                              <span className={classes.errorText}>
                                Exceeded allowed capacity. Atmost
                                {MAX_NUMBER} images can be uploaded
                              </span>
                            )}
                            {errors.acceptType && (
                              <span className={classes.errorText}>
                                Your selected file type is not allow
                              </span>
                            )}
                          </div>
                        )}
                        <div className="upload__image-wrapper">
                          <div className="col-md-12">
                            <Box sx={{ display: "flex" }}>
                              <Box sx={{ width: "100%" }}>
                                <Button
                                  variant="contained"
                                  component="label"
                                  className={classes.upload}
                                  sx={{
                                    display: "block",
                                    textAlign: "right",
                                  }}
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <BsUpload className={classes.uploadIcon} />
                                </Button>
                              </Box>
                            </Box>
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              {imageList.map((image, index) => (
                                <div className="col-md-6">
                                  <div key={index} className="image-item">
                                    <img
                                      src={image["data_url"]}
                                      alt=""
                                      width="200"
                                      className={classes.imagesStyle}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </ImageUploading>
                </div>

                {/* catalogue Pdf */}
                <div className="col-md-6">
                  Catalogue (PDF)
                  <p className={classes.imageSize} style={{ display: "none" }}>
                    Dimension: 260px X 200px
                  </p>
                  <input
                    type="file"
                    accept=".pdf"
                    style={{ marginTop: "30px" }}
                    className="form-control"
                    required
                    onChange={handlePdfFileChange}
                  />
                  {pdfFileError && (
                    <div className="error-msg">{pdfFileError}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <form onSubmit={handleSubmit} className="modalInsideForm mt-4">
          <div className="bg-secondary new-background">
            <input
              type="file"
              name="file"
              onChange={handleChange}
              accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>
          <div className="modalUpload mt-3">
            <button
              type="button"
              value="Download"
              className="editUploadButton mx-3"
              onClick={handleDownload}
            >
              Download sample file
            </button>
            <button type="submit" className="editUploadButton">
              Create auction
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default BulkUploadDialog;
