import * as React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "react-bootstrap";
import EditRules from "./EditRules";
import useStyles from "../../../Create/Event/event.style";
import EditItem from "./EditItem";
import EditBuyer from "./EditBuyer";
import EditViewBuyerLots from "./EditViewBuyerLots";
import getEventDetail from "../../../../../api/getEventDetail";
import colorSchemes from "../../../../../constants/colorSchemes";

function EditTabEvent(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="EditTabEvent hello"
      hidden={value !== index}
      id={`simple-EditTabEvent-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

EditTabEvent.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-EditTabEvent-${index}`,
  };
}

export default function BasicTabs(props, eventId) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const eventEditId = props.eventEditId;

  const [eventDetail, setEventDetail] = useState({
    auctionTitle: "",
    EventNo: "",
  });

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  const getEventData = async () => {
    try {
      const payload = { auctionId: eventEditId };
      const data = await getEventDetail(payload);
      setEventDetail(data.data);
    } catch (error) {
      // console.clear();
      console.log(error);
    }
  };
  React.useEffect(() => {
    getEventData();
    return () => {
      return false;
    };
  }, []);

  return (
    <div className={classes.event}>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <Typography
              className={`${classes.textHead},${classes.kuldeep}`}
              sx={{
                marginTop: "1em",
                fontSize: "20px",
                fontWeight: "600",
                marginBottom: "1em",
                color: colorSchemes.primaryColor,
              }}
            >
              Event No: {eventDetail.eventNumber}
            </Typography>
          </div>
          <div className="col-md-4">
            <Typography
              className={classes.textHead}
              sx={{
                textAlign: "center",
                padding: "1em 0em",
                fontSize: "20px",
                fontWeight: "600",
                color: colorSchemes.primaryColor,
              }}
            >
              Auction Name : {eventDetail.auctionTitle}
            </Typography>
          </div>
          <div className="col-md-4">
            <Typography
              className={classes.textHead}
              sx={{
                textAlign: "center",
                padding: "1em 0em",
                fontSize: "20px",
                fontWeight: "600",
                color: colorSchemes.primaryColor,
              }}
            >
              Owner Of lots : {eventDetail.ownerOfLots}
            </Typography>
          </div>
        </div>
      </div>
      <div className="section-event">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Rules" className={classes.tab} />
              <Tab label="Items" className={classes.tab} {...a11yProps(1)} />
              <Tab label="Buyer" className={classes.tab} {...a11yProps(2)} />
              <Tab
                label="View Lots & Buyer"
                className={classes.tab}
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
          <EditTabEvent value={value} index={0}>
            <EditRules
              onBack={props.onBack}
              method={handleChange}
              eventEditId={eventEditId}
            />
          </EditTabEvent>
          <EditTabEvent value={value} index={1}>
            <EditItem
              method={handleChange}
              eventDetail={eventDetail}
              eventEditId={eventEditId}
            />
          </EditTabEvent>
          <EditTabEvent value={value} index={2}>
            <EditBuyer method={handleChange} eventEditId={eventEditId} />
          </EditTabEvent>
          <EditTabEvent value={value} index={3}>
            <EditViewBuyerLots
              method={handleChange}
              eventEditId={eventEditId}
            />
          </EditTabEvent>
        </Box>
      </div>
    </div>
  );
}
