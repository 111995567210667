import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Form from "react-bootstrap/Form";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { Button } from "@mui/material";
import useStyles from "./auctionCard.style";
import Product from "../../../../assets/common/product.png";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AuctionCard from "../../Home/AuctionCard";
import getEventsListAPI from "../../../../api/getEventsListAPI";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import lodash from "lodash";

function AuctionCards(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [changeIcon, setChangeIcon] = useState(false);
  const [Events, updateEvents] = useState({ apiState: "loading", list: [] });

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState();
  const [pageCount, setPageCount] = React.useState();
  const [TotalEvents, setTotalEvents] = React.useState();
  const [lotStatus, setLotStatus] = React.useState();

  const handleChange = (event, value) => {
    getEventsList(value, lotStatus);
    setPage(value);
  };

  const lotStatusChange = (event) => {

    setLotStatus(event.target.value);
    getEventsList(page, event.target.value);
  };

  const getEventsList = async (value, lotStatus) => {
    try {
      const payload = {
        pageSize: pageSize,
        page: value,
        lotStatus: lotStatus,
        sellerId: props.auctionIds,
      };

      if (!lodash.isEmpty(props.countryId)) {
        payload.countryId = props.countryId;
      }

      if (!lodash.isEmpty(props.stateId)) {
        payload.stateId = props.stateId;
      }

      const { data } = await getEventsListAPI(payload);

      if (data) {
        updateEvents({ apiState: "success", list: data.data, meta: data.meta });
        setPageCount(data.meta.totalPages);
        setTotalEvents(data.meta.totalEvents);
        setPageSize(data.meta.pageSize);
      } else throw new Error(data);
    } catch (error) {
      console.clear();
      console.log(error);
    }
    return true;
  };
  React.useEffect(() => {
    getEventsList(page, lotStatus);
    window.scrollTo(0, 0)
    return () => null;
  }, [props]);

  return (
    <div className={classes.auctioncard}>
      <div className="container">
        <div className={classes.auctionHead}>
          <Box>
            <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
              Auction
            </Typography>
            <Typography>We found {TotalEvents} item</Typography>
          </Box>
          <Box>
            <Form.Select
              aria-label="Default select example"
              className={classes.input}
              required
              onChange={lotStatusChange}
              value={lotStatus}
            >
              <option>Sort By</option>
              <option className={classes.liveD} value="live">
                Live
              </option>
              <option className={classes.UpcomingD} value="upcoming">
                Upcoming
              </option>
              <option className={classes.endedD} value="ended">
                Ended
              </option>
            </Form.Select>
          </Box>
        </div>
        <div className={classes.cardAuction}>
          <div className="row">
            {Events.list.map((event, index) => {
              return (
                <div className="col-md-4 mob-card-responsive" key={index}>
                  <div className={classes.AuctionCards}>
                    <div className={classes.AuctionCard}>
                      <p className={classes.firstText}>
                        {event.status === 'live' && <span className={classes.liveButton}>Live Now</span>}
                        {event.status === 'ended' && <span className={classes.ended}>Ended</span>}
                        {event.status === 'upcoming' && <span className={classes.Upcoming}>Upcoming</span>}
                      </p>
                      <img
                        onClick={() => {
                          navigate(`/auctions/${event.eventId}`)
                          // props.setViewCard(true);
                          // props.setEventId(event.eventId);
                        }}
                        className={classes.productImg}
                        alt="product"
                        src={event.eventImageUrl}
                      />
                      <Box
                        className={classes.auctionContent}
                        onClick={() => {
                          navigate(`/auctions/${event.eventId}`)
                          // props.setViewCard(true);
                          // props.setEventId(event.eventId);
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            paddingTop: "10px",
                          }}
                        >
                          Event - {event.auctionTitle}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            paddingTop: "10px",
                          }}
                        >
                          Ref. No. : {event.referenceNumber}
                        </Typography>
                        <span className={classes.name}>
                          Auctioner Name {event.auctioneerName}
                        </span>
                        <br />
                        <span className={classes.compName}>
                          Company Name {event.companyName}...
                        </span>
                      </Box>
                      <Box className={classes.auctionContent}>
                        <span className={classes.location}>
                          Location:- {event.city}, {event.country}
                        </span>
                      </Box>
                      <Box className={classes.auctionContent}>
                        <span className={classes.lotsnumber}>
                          {event.totalLots} Lots
                        </span>
                      </Box>
                    </div>
                    {/* 
                    <div className={classes.status}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            marginRight: "1em",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Ends in
                        </Typography>
                      </Box>
                    </div> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Pagination
          count={pageCount}
          onChange={handleChange}
          variant="outlined"
          shape="rounded"
          className="pagination"
          sx={{ justifyContent: "center" }}
        />
      </div>
    </div>
  );
}

export default React.memo(AuctionCards);
