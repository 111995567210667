import React, { useState } from "react";

function Loader() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="loaderBg">
      <div className="loader"></div>
    </div>
  );
}

export default Loader;
