import config from '../../config/config'
import axios from 'axios'
import { getToken } from '../../common'; 

export const sellerListAPI = async () => {    

    return await axios({
        method  : 'get',
        url     : `${config.api.base_url}dashboard/sellers`,
        //params    : params,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": getToken(),
        }
    })
}

export default sellerListAPI