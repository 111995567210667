import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "0px !important",
    boxShadow: "0px 1px 6px 0px rgb(221, 219, 219) !important",
    marginRight: "0rem!important",
    marginBottom: "2em",
  },
  lotNumber: {
    fontSize: "18px",
    color: "#3f4043",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "bold",
    padding: " 10px 10px 10px 10px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px !important",
      fontWeight: "bold !important",
    },
  },
  ellipsis: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px !important",
      marginLeft: "5px !important",
      fontWeight: "bold !important",
    },
  },

  longDescription: {
    color: "gray",
    fontSize: "small",
    padding: " 5px 20px 0px 1rem",
    flex: 1,
    overflow: "hidden",
    maxHeight: "32px",
    textOverflow: "ellipsis",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: { maxHeight: "30px" },
  },

  shortDescription: {
    fontSize: "22px !important",
    fontWeight: "bold !important",
    padding: "10px 20px 0px 1rem",
    color: "#000 !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      backgroundColor: "transparent",
    },
  },

  leadBidText: {
    color: "#7e8184",
    fontSize: "small !important",
    fontWeight: "bold !important",
    fontFamily: "Poppins, sans-serif !important",
  },
  leadBidBox: {
    backgroundColor: "#e4e4e4",
    border: "1px solid #b4b5ba",
    padding: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leadBidContainer: {
    padding: "0px 0px 5px 20px !important",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: "5px 5px 5px 0px !important",
    },
  },

  userRankBox: {
    border: "1px solid rgb(219, 28, 28)",
    backgroundColor: "rgb(250, 188, 188)",
    textAlign: "center",
    padding: "0.4rem",
  },
  userRankContainer: {
    padding: "0px 0px 5px 20px !important",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: "5px 5px 5px 0px !important",
    },
  },

  amountFourthTextStyle: {
    color: "#7e8184",
    fontSize: "small !important",
    fontWeight: "bold !important",
    paddingTop: "3px",
  },
  amountFourthContainer: {
    padding: "10px 0px 0px 0px !important",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: "10px 5px 5px 0px !important",
    },
  },

  amountSecondValueLive: {
    color: "#db1c1c",
    fontWeight: "bold !important",
    fontFamily: "Poppins,sans-serif!important",
  },
  amountSecondValueUpcoming: {
    color: "#199e2b",
    fontWeight: "bold !important",
    fontFamily: "Poppins,sans-serif!important",
  },
  amountSecondHeading: {
    color: "#7E8184",
    fontSize: "14px !important",
    fontWeight: "normal !important",
    [theme.breakpoints.down("sm")]: { fontWeight: "bold !important" },
  },
  amountSecondContainer: {
    padding: "10px 0px 10px 20px !important",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: "10px 5px 5px 0px !important",
    },
  },

  amountThirdContainerBox: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  amountThirdTextStyle: {
    fontWeight: "bold !important",
    color: "#7e8184",
    fontSize: "16px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      textAlign: "left",
    },
  },
  amountThirdContainer: {
    padding: "10px 0px 5px 20px !important",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: "10px 5px 5px 0px !important",
    },
  },

  auctionBidErrorBox: {
    color: "white",
    borderRadius: "0px",
    padding: "10px 2px 5px 8px !important",
    backgroundColor: "#D22B2B",
    padding: "0.55rem !important",
    textAlign: "center",
  },
  auctionBidButton: {
    width: "100%",
    color: "white !important",
    padding: "0.55rem !important",
    backgroundColor: "rgb(45, 85, 235) !important",
    borderRadius: "0px !important",
  },
  auctionBidContainer: {
    padding: "10px 8px 5px 8px !important",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: "5px 5px 5px 0px !important",
    },
  },

  auctionRankBox: {
    width: "100%",
    textAlign: "center",
    borderRadius: "0px",
    color: "#7e8184",
    fontWeight: "bold",
    backgroundColor: "#ffc400",
    padding: "0.50rem",
    border: "1px solid #d3a90f",
  },
  auctionRankContainer: {
    padding: "10px 2px 5px 8px !important",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: "5px 5px 5px 0px !important",
    },
  },

  auctionAmountField: {
    width: "100%",
    padding: "0.60rem !important",
    border: "1px solid #a0a0a0 !important",
  },
  auctionAmountContainer: {
    padding: "10px 10px 5px 20px !important",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: "5px 5px 5px 0px !important",
    },
  },

  auctionDialogCancelButton: {
    width: "100%",
    color: "white !important",
    padding: "0.55rem !important",
    backgroundColor: "#DB1C1C !important",
    borderRadius: "0px !important",
  },
  auctionDialogCancelContainer: {
    padding: "10px 8px 5px 8px !important",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: "5px 5px 5px 0px !important",
    },
  },

  auctionDialogAmountData: {
    fontSize: "14px",
    border: "1px solid #a0a0a0",
    width: "100%",
    textAlign: "center",
    color: "black !important",
    padding: "0.60rem !important",
    borderRadius: "0px !important",
  },
  auctionDialogAmountContainer: {
    padding: "10px 8px 5px 20px !important",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: "5px 5px 5px 0px !important",
    },
  },
  auctionDetails: {
    display: "flex",
    width: "100%",
    marginBottom: "1em",
    borderBottom: "1px solid #ccc",
    paddingBottom: "10px",
    margin: "10px 0px 8px 20px !important",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      margin: "0px 9px 10px 0px !important",
    },
  },
  mainDetail: {
    padding: "1em 1em 0em 1em",
  },
  carouselImage: {
    width: "100%",
    height: "11.06em !important",
    objectFit: "cover !important",
  },
}));

export default useStyles;
