const CountdownRenderer = (
  finalizing,
  { days, hours, minutes, seconds, completed }
) => {
  if (finalizing) {
    return (
      <span style={{ color: "green", fontSize: ".8rem" }}> Finalizing... </span>
    );
  }
  if (completed) {
    return (
      <span style={{ color: "red", fontSize: ".8rem" }}> Deal Expired </span>
    );
  } else {
    const timeParts = [];

    if (days > 0) {
      timeParts.push(`${days}d,`);
    }

    if (hours > 0) {
      timeParts.push(`${hours}h`);
    }

    if (minutes > 0) {
      timeParts.push(`${minutes}m`);
    }

    if (seconds > 0 || timeParts.length === 0) {
      timeParts.push(`${seconds}s`);
    }

    return (
      <span
        style={{
          fontSize: ".8rem",
        }}
      >
        {timeParts.join(" ")}
      </span>
    );
  }
};

export default CountdownRenderer;
