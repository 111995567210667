import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dashSearch: { position: "relative", margin: "1em" },
  searchIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    color: "#999999",
  },
  tableBuyer: {
    minWidth: "100% !important",
    borderRadius: "0px !important",
    border: "1px solid #989898 !important",
    boxShadow: "none !important",
  },
  buyerRow: { backgroundColor: "#2D55EB", color: "#fff !important" },
  eye: { fontSize: "20px" },
  lots: {
    color: "#2D55EB",
    fontSize: "18px",
    marginLeft: "4px",
    marginBottom: "4px",
  },
  viewSelect: { height: "38px" },
  dashSearch: { position: "relative" },
  searchIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    color: "#999999",
    input: {
      backgroundColor: "#fff !important",
      width: "30em",
      border: "none",
      borderRadius: "10px !important",
      border: "1px solid #fff !important",
    },
  },
  minus: { color: "red" },
}));

export default useStyles;
