import { makeStyles } from "@mui/styles";
import { textAlign } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  Dashboard: {
    padding: "2em 0em",
    [theme.breakpoints.down("md")]: {
      padding: "5em 0em",
    },
  },
  section: { backgroundColor: "#e7e7e9" },
  avatar: {
    width: "3em !important",
    height: "3em !important",
    borderRadius: "10px !important",
  },
  name: { fontWeight: "600 !important" },
  number: { color: "#7E8184" },
  pentagon: {
    position: "relative",
    width: "90px",
    height: "24px",
    background: "#FFC400",
    clipPath: "polygon( 50% 0, 60% 50%, 50% 100%, 0% 100%, 0 0%)",
    padding: "2px 5px",
    marginLeft: "10px",
  },
  //   Buttons: {
  //     float: "right",
  //     margin: "auto",
  //     [theme.breakpoints.down("md")]: {
  //       float: "none  !important",
  //       textAlign: "center !important",
  //       marginTop:'1em !important'
  //     },
  //     [theme.breakpoints.down("tablet")]: {
  //       float: "right",
  //       margin: "auto",
  //     },
  //   },
  dashboards: {
    padding: "2em",
    // borderBottom: "1px solid #F6F7FB",
  },
  dashboardCard: { backgroundColor: "#fff", borderRadius: "12px" },
  dashboardCards: {
    padding: "1em 2em",
    [theme.breakpoints.down("sm")]: {
      padding: "1em 0.5em",
    },
  },
  headText: { fontSize: "18px !important", fontWeight: "600 !important" },
  viewAll: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    color: "#7E8184",
  },
  live: {
    color: "#07AD00",
    display: "flex",
    marginBottom: "2em !important",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "15px !important",
    },
  },
  Upcoming: {
    color: "#FFC400",
    display: "flex",
    marginBottom: "2em !important",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "15px !important",
    },
  },
  Past: {
    color: "#EB2D6F",
    display: "flex",
    marginBottom: "2em !important",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "15px !important",
    },
  },
  my: {
    color: "#0050FF",
    display: "flex",
    marginBottom: "2em !important",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "15px !important",
    },
  },
  dot: {
    width: "6px",
    height: "6px",
    color: "#07AD00",
    backgroundColor: "#07AD00",
    borderRadius: "100px",
    margin: "auto 6px auto 0px",
  },
  dot1: {
    width: "6px",
    height: "6px",
    color: "#FFC400",
    backgroundColor: "#FFC400",
    borderRadius: "100px",
    margin: "auto 6px auto 0px",
  },
  dot2: {
    width: "6px",
    height: "6px",
    color: "#EB2D6F",
    backgroundColor: "#EB2D6F",
    borderRadius: "100px",
    margin: "auto 6px auto 0px",
  },
  dot3: {
    width: "6px",
    height: "6px",
    color: "#0050FF",
    backgroundColor: "#0050FF",
    borderRadius: "100px",
    margin: "auto 6px auto 0px",
  },
  auctionCard: {
    padding: "1em",
    borderRadius: "9px",
    marginTop: "2em",
  },
  icon: {
    backgroundColor: "#fff",
    borderRadius: "100px",
    width: "1.6em",
    height: "1.6em",
    textAlign: "center",
    padding: "0px",
  },
  svg: { fontSize: "14px !important" },
  sellerSearch: {
    marginTop: "2em",
  },
  dashSearch: { position: "relative" },
  searchIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    color: "#999999",
  },
  viewBtn: {
    padding: "5px 2px",
    cursor: "pointer",
  },
  topDetail: { marginBottom: "4em" },
  SideLine: {
    borderRight: "1px solid #000",
    [theme.breakpoints.down("sm")]: {
      borderRight: "1px solid #fff !important",
    },
  },
  detailCard: { boxShadow: "0px 0px 6px #00000029", padding: "10px" },
  marginremove: { marginBottom: "0px !important" },
  BuyEventHeading: { display: "flex", justifyContent: "space-between" },
  DataTable: { opacity: 1 },
  ellipsisText: {
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%", // Set the maximum width as needed
  },
  customTooltip: {
    backgroundColor: "black", // Set the background color to black
    color: "white", // Set the text color to white
  },
}));
export default useStyles;
