import { green, purple } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  auction: { display: "flex" },
  auctionTab: {
    margin: "2.5em 1.2em",
    [theme.breakpoints.down("sm")]: { margin: "2.5em 0.3em" },
  },
  auctionCard: {
    padding: "1em",
    boxShadow: "0px 1px 6px 0px rgb(221, 219, 219) ",
    backgroundColor: "#fff",
    marginRight: "0rem !important",
    marginBottom: "2em",
  },
  auctionImage: { padding: "0 !important" },
  productimg: {
    width: "100%",
    height: "11.06em !important",
    objectFit: "cover !important",
  },
  auctionCardContent: { width: "100% !important", padding: "0 !important" },
  auctionDetails: {
    borderBottom: "1px solid #ccc",
    paddingBottom: "0.5rem",
    // display: "flex",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0",
    },
    [theme.breakpoints.down("tablet")]: {
      paddingBottom: "0",
    },
  },
  auctionNumber: { textAlign: "center" },
  auctionName: { borderLeft: "1px solid #b4b5ba", paddingLeft: "1rem" },
  lot: { display: "block", fontWeight: "500", color: "#3f4043" },

  eventName: {
    display: "block",
    fontSize: "22px",
    fontWeight: "bold",
    color: "#3f4043",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "16px",
    },
  },
  eventDetail: {
    marginBottom: "0em",
    fontWeight: "500",
    fontSize: "small",
    color: "#7e8184",
    height: "20px",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
  },
  bidders: {
    textAlign: "center",
    alignitem: "center",
    marginTop: "0.5rem",
    marginBottom: "0.4rem",
  },
  bidAmount: {
    border: "1px solid #b4b5ba",
    backgroundColor: "#e4e4e4",
    display: "flex",
    justifyContent: "spaceAround",
    padding: "0.7rem 0.5rem",
    width: "100%",
  },
  bidderText: {
    fontWeight: "bold",
    marginBottom: "0",
    textAlign: "center",
    color: "#7e8184",
    fontSize: "small",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "10px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px !important",
    },
  },
  amount: {
    fontWeight: "800",
    fontSize: "medium",
    color: "#7e8184 !important",
    // color: "#000 !important",
    textAlign: "end",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "9px !important",
      paddingleft: "4px !important",
    },
  },
  rankColumn: {
    // border: "1px solid #db1c1c",
    // backgroundColor: "#fabcbc",
    padding: "0.7rem",
  },
  biddingOptions: {
    display: "flex",
    marginTop: "0.2rem",
    height: "3em",
  },
  biddingEndTime: {
    marginBottom: "0",
    fontSize: "14px",
    color: "#7E8184",
    backgroundColor: green,
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px !important",
    },
  },
  biddingColumn: { width: "100%" },
  biddingEnd: { fontWeight: "bold", color: "#7e8184", fontSize: "small" },
  biddingTime: {
    fontWeight: "900",
    color: "#db1c1c",
    marginBottom: "0em !important",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "14px",
    },
  },

  bidLive: {
    color: "#db1c1c",
    fontWeight: 600,
    [theme.breakpoints.down("tablet")]: {
      fontSize: "12px",
    },
  },
  bidUpcoming: {
    color: "#199e2b",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down("tablet")]: {
      fontSize: "13px",
    },
  },
  bidEndTime: {
    color: "#199e2b",
  },
  biddingDate: {
    fontWeight: "bold",
    color: "#7e8184",
    fontSize: "16px",
    float: "right",
    // paddingTop: "24px",
    // marginLeft: "-9em",
    [theme.breakpoints.down("tablet")]: {
      marginLeft: "0em !important",
      fontSize: "13px",
      marginTop: "5px",
    },
  },
  otherPhase: {
    alignitem: "center",
    justifyContent: "spaceBetween",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
    },
  },
  priceSection: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
    },
  },
  priceOptions: { marginTop: "1rem" },
  priceType: {
    display: "flex",
    justifyContent: "space-between !important",
    fontWeight: "bold",
    color: "#7e8184",
    fontSize: "small",
    marginTop: "0.3rem !important",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
  },
  otherThreeButtons: { padding: "0 0.7rem" },
  bidButton: {
    display: "flex",
    alignitem: "center",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: "0.5rem",
  },
  firstButton: {
    fontWeight: "bold",
    width: "100%",
    height: "3em",
    display: "flex",
    border: "1px solid #a0a0a0",
    marginTop: "8px",
    [theme.breakpoints.down("sm")]: {
      height: "2em",
    },
    [theme.breakpoints.down("tablet")]: {
      height: "2em",
    },
  },
  cadAmount: {
    padding: "0.1rem 0.7rem",
    display: "flex",
    backgroundColor: "#f2f2f24d",
    height: "3.3em",
    borderRight: "1px solid #a0a0a0",
    fontSize: "14px",
    alignitem: "center !important",
    // border: "1px solid #a0a0a0",
    [theme.breakpoints.down("tablet")]: {
      padding: "8px !important",
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
    },
  },
  input: {
    borderRadius: "0px",
    color: "#3F4043",
    backgroundColor: "#F2F2F24D",
    height: "2.9em",
    border: "none",
    // marginTop:'8px'
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.down("tablet")]: {
      fontSize: "10px",
    },
  },
  secondButton: { fontWeight: "bold", padding: "0.5rem", width: "100%" },
  bid: {
    padding: "0.64rem",
    display: "block",
    color: "#fff",
    border: "1px solid #999999  ",
    // backgroundColor: "#2d55eb",
    [theme.breakpoints.down("tablet")]: {
      padding: "8px !important",
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
    },
  },
  thirdButton: {
    fontWeight: "bold",
    padding: "0.5rem",
    width: "100%",
    paddingRight: "0",
  },
  upperAuction: {
    padding: "1rem",
    boxShadow: "0px 1px 6px 0px rgb(221, 219, 219)",
    backgroundColor: "#fff",
    margin: "2.5em 1.2em",
    [theme.breakpoints.down("sm")]: { margin: "3.5em 1em !important" },
  },
  firstText: {
    // margin: "4px 0em 1em",
    marginBottom: "0 !important",
    color: "#3F4043",
    fontWeight: "bold",
    fontSize: "small !important",
  },
  liveButton: {
    border: "1px solid #07AD00",
    padding: "0.1rem 1rem",
    fontWeight: "bold !important",
    color: "#07AD00",
    backgroundColor: "#E3FFD9",
    marginRight: "1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "block",
      marginTop: "10px",
    },
  },
  Upcoming: {
    border: "1px solid #0c5ea5",
    padding: "0.1rem 1rem",
    fontWeight: "bold !important",
    color: "#0c5ea5",
    backgroundColor: "mintcream",
    marginRight: "1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "block",
    },
  },
  ended: {
    border: "1px solid #EB2D6F",
    padding: "0.1rem 1rem",
    fontWeight: "bold !important",
    color: "#EB2D6F",
    backgroundColor: "antiquewhite",
    marginRight: "1em",
    marginBottom: "1em",
    marginTop: "1em",
  },
  auctionHeading: {
    fontSize: "24px !important",
    fontWeight: "bold !important",
    color: "#0d1d58",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "14px !important",
    },
  },
  auctionLink: {
    fontWeight: "bold",
    fontSize: "small",
    color: "#2d55eb",
    [theme.breakpoints.down("tablet")]: {
      marginBottom: "10px",
      textOverflow: "ellipsis",
      width: "100%",
      overflow: "hidden",
    },
  },
  location: {
    fontWeight: "bold",
    color: "#7e8184",
    fontSize: "small !important",
    marginBottom: "0em",
  },
  lastBidText: {
    padding: "0.64rem",
    display: "block",
    backgroundColor: "#ffc400",
    border: "1px solid #ffc400",
    color: "#0d1d58",
    [theme.breakpoints.down("tablet")]: {
      padding: "8px !important",
      fontSize: "14px",
    },
  },
  highBid: { display: "flex", justifyContent: "space-between", width: "100%" },
}));
export default useStyles;
