import * as React from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import colorSchemes from "../../../constants/colorSchemes";
import routes from "../../../constants/routes";
import useStyles from "./Verification.styles";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import verificationAPI from "../../../api/verificationAPI";
import forgotPasswordAPI from "../../../api/forgotPasswordAPI";
import { setToken, removeToken, removeUserData, getToken, setUserData,getUserData } from "../../../common";
import LogoutIcon from "@mui/icons-material/Logout";
import { isEmpty } from "lodash";
import Loader from '../../../common/Loader';
import OtpInput from 'react-otp-input';

export default function Verification() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const email = location.state.data;
  console.clear()
  console.log(email)
  const [otp, setOtp] = useState('');

  const userData = getUserData() ?? {};
  //---------------------------- login-Process ----------------------------------

  // const handleChange = (event) => {
  //   setverifyInfo({ ...verify, [event.target.name]: event.target.value });
  // };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true)
      const payload = {email:email,otp:otp}

      const { data } = await verificationAPI(payload);      

      if(data.status === 'success'){
        toast(data.message); 
        setTimeout(() => {
          setIsLoading(false)  
          navigate(routes.resetpassword, { state: { email: email, otp:otp} });
        }, 1500);
      }else{
        toast("OTP Verified!"); 
        setIsLoading(false)  
      }

    } catch (error) {
      if (error.response) {
        //console.log(error.response.data.error);
        toast(error.response.data.error);
        setIsLoading(false) 
      } else if (error.request) {
        toast("Something Went Wrong!");
        setIsLoading(false) 
      } else {
        //console.log('Error', error.message.code);
        setIsLoading(false) 
      }
      
    }

   //setUserInfo({ email: "", firstname: "", lastname: "", phonenumber: "" , password: "", confirmpassword: ""});
  };
 

  const resendOTP = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true)
      const payload = {email:email}
      const { data } = await forgotPasswordAPI(payload);      

      if(data){
        toast("OTP Sent Again on your registered email id!"); 
        setIsLoading(false)   
      }

    } catch (error) {
      if (error.response) {
        //console.log(error.response.data.error);
        toast(error.response.data.error);
        setIsLoading(false) 
      } else if (error.request) {
        toast("Something Went Wrong!");
        setIsLoading(false) 
      } else {
        //console.log('Error', error.message.code);
        setIsLoading(false) 
      }
      
    }

   //setUserInfo({ email: "", firstname: "", lastname: "", phonenumber: "" , password: "", confirmpassword: ""});
  };

  return (
    <>
    {isLoading === true && <Loader isLoading={isLoading} /> }
    <Box className={classes.signin}>
      {isEmpty(userData) ? 
        <Card
          sx={{ maxWidth: 500, marginTop: "9em", padding: "1em 2em" }}
          className={classes.signcard}
        >
        
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "600",
                color: colorSchemes.primaryColor,
              }}
              variant="h5"
            >
              OTP Verification
            </Typography>
          </Box>
          <form
            layout="vertical"
            onSubmit={handleSubmit}
          >
            <Box className={classes.forms}>             
              <Form.Group className="mb-3 otp" controlId="formBasicEmail">
                {/* <Form.Label className={classes.label}>E-mail</Form.Label> */}
                {/* <Form.Control
                  className={classes.input}
                  type="text"
                  name="otp"
                  value={verify.otp}
                  onChange={handleChange}
                /> */}
                <OtpInput                  
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
              </Form.Group>
            </Box>                   
            <Box>
              <Button
                sx={{
                  backgroundColor: colorSchemes.primaryColor,
                  color: "#fff",
                  borderRadius: "0em",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#2D55EB !important",
                  },
                }}
                // onClick={() => {
                //   setToken(loginInfo.email);
                //   navigate(routes.home);
                // }}
                fullWidth
                type="submit"
              >
                Verify
              </Button>
            </Box>
          </form>
          <Typography className={classes.forgot}
          sx={{
            textAlign: "center",
          }}>
           Dont Recieve the Email?<Link onClick={resendOTP} >Resend OTP</Link>{" "}
          </Typography>
          <Typography className={classes.forgot}
          sx={{
            textAlign: "center",
          }}>
           <Link to="/forgot">Back to forgot password</Link>{" "}
          </Typography>
        </Card>
      : <Card
          sx={{ maxWidth: 500, marginTop: "9em", padding: "1em 2em" }}
          className={classes.signcard}
        >
          <center>
          <h3>Your Already Logged in!</h3>
          <Button
              sx={{
                backgroundColor: colorSchemes.primaryColor,
                color: "#fff",
                borderRadius: "0em",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#2D55EB !important",
                },
              }}
              onClick={() => {
                removeToken();
                removeUserData();
                navigate(routes.signin);
              }}
              fullWidth
              type="submit"
            >
              <LogoutIcon
                  sx={{
                    color: "white",
                    fontSize: "16px",
                    marginTop: "-2px",
                    marginRight: "5px",
                  }}
                />
              Log Out
            </Button>
          </center>
      </Card>}
    </Box>
    </>
  );
}
