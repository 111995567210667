import config from "../config/config";
import axios from "axios";
import { getToken } from "../common";
export const getAllBuyersAPI = async (params) => {
  return await axios({
    method: "get",
    url: `${config.api.base_url}events/${params.auctionId}/all-buyers`,
    params: {
      email: params.email,
    },
    // params    : params,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: getToken(),
    },
  });
};

export default getAllBuyersAPI;
