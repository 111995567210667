import React, { useEffect, useState } from "react";
import lodash from "lodash";
import { getToken } from "common";
import { useParams } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import colorSchemes from "../../../constants/colorSchemes";
import PostBidLotListTable from "./PostBidLotListTable";
import getPostBidEventLotsAPI from "../../../api/getPostBidEventLots";
import { ChevronLeft } from "@mui/icons-material";
import { toast } from "react-toastify";
import requestPostBidAPI from "../../../api/requestPostBidAPI";

const PostBidAuction = () => {
  // extract auction id from url
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const queryParams = useParams();
  const [userAmount, setUserAmount] = useState({});
  const [auctionDetails, setAuctionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [lots, setLots] = useState([]);

  const auctionId = queryParams.auction_id;
  // check if user is authenticated or not?
  // if not authenticated -> show error message on screen with Home button to redirect to auction detail page

  useEffect(() => {
    if (getToken()) {
      setIsAuthenticated(true);
      // fetch event details
      fetchAuctionDetails();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const handleStatusChange = () => {
    setIsOnline(navigator.onLine);
  };

  const fetchAuctionDetails = async () => {
    try {
      const fetchedDetails = await getPostBidEventLotsAPI({ auctionId });
      const auctionDetails = fetchedDetails.data.event;
      const lotsDetails = fetchedDetails.data.lots;
      setAuctionDetails(auctionDetails);
      setLots(lotsDetails);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleUserAmountChange = (lotNumber, amount, amounts = {}) => {
    const current = lodash.cloneDeep(amounts || {});
    current[lotNumber] = Number(amount);
    setUserAmount(current || {});

    // panelDetailsReRender(0, props.allLots, current);
    // panelDetailsReRender(1, props.userLots, current);
  };

  const storeHandler = async (lotDetails, bidAmount) => {
    try {
      if (!bidAmount) {
        return toast.error(`Please enter the bidding amount.`);
      }
      if (bidAmount <= 0) {
        return toast.error(`Bidding amount must be greater than zero. `);
      }

      const postBidArguments = [
        // { amount: Number(Number(userAmount).toFixed(2)) },
        { amount: Number(Number(bidAmount).toFixed(2)) },
        lotDetails.eventId,
        lotDetails.eventItemId,
      ];

      const response = await requestPostBidAPI(...postBidArguments);
      if (response.data.status === "FAILURE") {
        toast.error(
          "Your Bid Has Been Recorded, but Unfortunately, It Was Not Accepted."
        );
      } else {
        toast.success("Your Bid Submitted Successfully!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    } finally {
      // await Promise.resolve(toggleState({ disabled: false, loading: false }));
    }

    // const eventDetails = props.eventsDetails;
    // // const lotNumber = lotDetails.lotNumber;
    // // const bidAmount = userAmount[`${lotNumber}`];
    // // let { lotNumber, startDateAndTime } = lotDetails;
    // // const { publishDateTime, bidStartDateTime } = eventDetails;
    // const timeZone =
    //   localStorage.getItem("currentTimeZone") || momentTimeZone.tz.guess();
    // const currentTime = moment().tz(timeZone).format();
    // const publishDateTime = moment(eventDetails.publishDateTime)
    //   .tz(timeZone)
    //   .format(); // event publish date
    // const bidStartDateTime = moment(eventDetails.bidStartDateTime)
    //   .tz(timeZone)
    //   .format(); // event start date
    // const startDateAndTime = moment(lotDetails.startDateAndTime)
    //   .tz(timeZone)
    //   .format(); // lot start date
    // const endDateAndTime = moment(lotDetails.endDateAndTime)
    //   .tz(timeZone)
    //   .format(); // lot end date
    // if (!bidAmount) {
    //   return toast.error(`Please enter the bidding amount.`);
    // }
    // if (bidAmount <= 0) {
    //   return toast.error(`Bidding amount must be greater than zero. `);
    // }
    // // if (
    // //   timerContext.currentTime >= publishDateTime &&
    // //   timerContext.currentTime <= bidStartDateTime
    // // ) {
    // //   return handleAuctionDialog({
    // //     eventDetails,
    // //     lotDetails,
    // //     userAmount: bidAmount,
    // //   });
    // // }
    // // if (startDateAndTime > timerContext.currentTime) {
    // //   return toast.error(`Bidding hasn't started yet.`);
    // // }
    // if (currentTime >= publishDateTime && currentTime <= bidStartDateTime) {
    //   return handleAuctionDialog({
    //     eventDetails,
    //     lotDetails,
    //     userAmount: bidAmount,
    //   });
    // }
    // if (startDateAndTime > currentTime) {
    //   return toast.error(`Bidding hasn't started yet.`);
    // }
    // const isForwardAuction = () => {
    //   return props.eventsDetails.eventType === "FORWARD_AUCTION";
    // };
    // const isReverseAuction = () => {
    //   return props.eventsDetails.eventType === "REVERSE_AUCTION";
    // };
    // if (isForwardAuction() && bidAmount < lotDetails.nextBidAmount) {
    //   return toast.error(
    //     `Please place a bid higher than the current bid. ${lotDetails.nextBidAmount}`
    //   );
    // } else if (isReverseAuction() && bidAmount > lotDetails.nextBidAmount) {
    //   return toast.error(
    //     `Please place a bid lower than the current bid. ${lotDetails.nextBidAmount}`
    //   );
    // }
    // return handleAuctionDialog({
    //   eventDetails,
    //   lotDetails,
    //   userAmount: bidAmount,
    // });
  };

  const lotBidEnded = async (data, index) => {
    // if (data.status !== "live") {
    //   props.refetchLotDetails();
    //   return;
    // }
    // finalizeLot(data.eventItemId);
  };

  if (!isAuthenticated) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "10rem 0 2rem 0",
          alignItems: "center",
        }}
      >
        <div>Please login to see your post bid auction lots</div>
        <Button
          onClick={() => {
            window.location.href = `/#/auctions/${auctionId}`;
          }}
          style={{ textTransform: "none" }}
          //   className={ }
        >
          Go back to auction page
        </Button>
      </div>
    );
  }

  return (
    <div>
      <div>
        <Typography
          // className={classes.textHead}
          sx={{
            textAlign: "center",
            padding: "1em",
            fontSize: "22px",
            fontWeight: "600",
            color: colorSchemes.primaryColor,
            marginTop: "5em",
          }}
        >
          Post bid Auction
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginRight: "6rem",
        }}
        onClick={() => {
          window.location.href = `/#/auctions/${auctionId}`;
        }}
      >
        <Button style={{ textTransform: "none" }}>
          <ChevronLeft style={{ color: "blue", marginRight: ".1rem" }} />
          Go back to auction page
        </Button>
      </div>
      <div>
        <div>
          {!loading && (
            <PostBidLotListTable
              lots={lots}
              userAmount={userAmount}
              eventDetails={auctionDetails}
              handleUserAmountChange={handleUserAmountChange}
              storeHandler={storeHandler}
              isOnline={isOnline}
              lotBidEnded={lotBidEnded}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PostBidAuction;
