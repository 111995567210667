import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import useStyles from "../BuyerTable/BuyerTable.style";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import getEventItemsListAPI from "../../../api/getEventItemsListAPI";
import buyerAddLots from "../../../api/buyerAddLots";
import buyerRemoveLots from "../../../api/buyerRmoveLots";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import moment from "moment";
import EditViewBuyer from "../../components/DashboardAuction/EditDashEvent/EditTabEvent/EditViewBuyerLots/EditViewBuyer";

export default function EditBuyerTable(props) {
  const classes = useStyles();
  const check = props.type == "Add";
  //   const location = useLocation();
  const eventId = props.eventId;

  const [tableEventsItemData, setTableEventsItemData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, updateClearSelectedRows] = useState(false);

  const eventBuyerIds = props.data;

  const [isLoading, setIsLoading] = useState(false);

  const [columnsEventBuyers, setColumnsEventBuyers] = useState([
    {
      name: "Sr",
      selector: "id",
      cell: (data) => String(data.id),
      sortable: true,
    },
    {
      name: "Lot No.",
      selector: "lotNumber",
      cell: (data) => String(data.lotNumber),
      sortable: true,
    },
    {
      name: "Short Description",
      selector: "shortDescription",
      cell: (data) => String(data.shortDescription),
      sortable: true,
    },
    {
      name: "Location",
      selector: "location",
      cell: (data) => String(data.location),
      sortable: true,
    },
    {
      name: "Qty & Unit",
      selector: "QtyUnit",
      cell: (data) => String(data.QtyUnit),
      sortable: true,
    },
    {
      name: "Start Date & Time",
      selector: "startDate",
      cell: (data) => String(data.startDate),
      sortable: true,
    },
    {
      name: "End Date & Time",
      selector: "EndDate",
      cell: (data) => String(data.EndDate),
      sortable: true,
    },
  ]);

  const eventItemsList = async () => {
    try {
      const payload = { itemId: eventId };

      const dataEventItems = await getEventItemsListAPI(payload);

      if (dataEventItems) {
        let id = 1;
        const eventsBuyerList = dataEventItems.data.data.map((data) => {
          return {
            id: id++,
            eventItemId: data.eventItemId,
            lotNumber: data.lotNumber,
            location: data.location,
            shortDescription: data.shortDescription,
            QtyUnit: `${data.quantity} ${data.unit}`,
            startDate: moment(data.startDateAndTime).format(
              "DD-MM-YYYY hh:mm:ss"
            ),
            EndDate: moment(data.endDateAndTime).format("DD-MM-YYYY hh:mm:ss"),
          };
        });

        setTableEventsItemData(eventsBuyerList);
      } else throw new Error(dataEventItems);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    eventItemsList();
    return () => {
      return false;
    };
  }, [eventId]);

  const handleSubmitSave = async (event) => {
    try {
      event.preventDefault();
      const paloadBuyerId = eventBuyerIds.map((ids, index) => {
        return ids.eventBuyerId;
      });

      const paloadItemId = selectedRows.map((ids, index) => {
        return ids.eventItemId;
      });

      const paylod = {
        eventBuyerIds: paloadBuyerId,
        eventItemIds: paloadItemId,
      };

      const { data } = await buyerAddLots(paylod, eventId);
      if (data) {
        setTimeout(() => {
          toast("Buyer Added To Lots Successfully!");
          props.modalHandleClose();
          eventItemsList();
          setSelectedRows([]);
          // props.eventBuyersList();
        }, 1500);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.errorMessage);
        error.response.data.errorMessage.map((errors, index) => {
          toast(errors.message);
          setIsLoading(false);
        });
      } else if (error.response.status === 500) {
        toast("Network Error!");
        setIsLoading(false);
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log("Error", error.message.code);
        setIsLoading(false);
      }
    }
  };

  const handleSubmitRemove = async (event) => {
    try {
      event.preventDefault();
      const paloadBuyerId = eventBuyerIds.map((ids, index) => {
        return ids.eventBuyerId;
      });

      const paloadItemId = selectedRows.map((ids, index) => {
        return ids.eventItemId;
      });

      const paylod = {
        eventBuyerIds: paloadBuyerId,
        eventItemIds: paloadItemId,
      };

      const { data } = await buyerRemoveLots(paylod, eventId);
      if (data) {
        setTimeout(() => {
          toast("Buyer Deleted To Lots Successfully!");
          props.modalHandleClose();
          eventItemsList();
          setSelectedRows([]);
          //props.eventBuyersList();
        }, 1500);
      }
    } catch (error) {
      if (error.response.status === 409) {
        toast("Some Buyers already Bidded on lots!");
        setIsLoading(false);
      } else if (error.response.status === 500) {
        toast("Network Error!");
        setIsLoading(false);
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log(error.response.data.errorMessage);
        error.response.data.errorMessage.map((errors, index) => {
          toast(errors.message);
          setIsLoading(false);
        });
      }
    }
  };
  return (
    <Box sx={{ width: "100%", padding: "0px !important" }}>
      <div
        className="email-container"
        style={{ background: "#2D55EB", paddingTop: "10px", height: "55px" }}
      >
        <p
          type="primary"
          sx={{ width: "fit-content !important" }}
          className="addlots"
        >
          {check ? "Add" : "Remove"}
        </p>
      </div>

      <DataTableExtensions
        print={false}
        export={false}
        exportHeaders={false}
        columns={columnsEventBuyers}
        data={tableEventsItemData}
      >
        <DataTable
          noHeader
          // name="checkbox"
          id="checkbox"
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          export={false}
          // disableMultipleRowSelection={true}
          exportHeaders={false}
          selectableRows={true}
          onSelectedRowsChange={(data) => {
            updateClearSelectedRows(false);
            setSelectedRows(data.selectedRows);
          }}
          clearSelectedRows={clearSelectedRows}
          className="DataTable"
        />
      </DataTableExtensions>
      {selectedRows.length > 0 && (
        <form onSubmit={check ? handleSubmitSave : handleSubmitRemove}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2D55EB",
              color: "#fff",
              borderRadius: "0px",
            }}
            type="submit"
          >
            {check ? "Save" : "Remove"}
          </Button>
        </form>
      )}
    </Box>
  );
}
