export default {
  home: "/",
  about: "/about",
  signin: "/sign-in",
  forgot: "/forgot",
  verification: "/verification",
  resetpassword: "/reset-password",
  register: "/register",
  createvent: "/create-event",
  event: "/event",
  sellerRegister: "/seller-register",
  postbidAuction: "/auction/:auction_id/post-bid",
  auction: "/auction",
  search: "/search/:searchQuery",
  dashboard: "/dashboard",
  profile: "/profile",
  dashboardAuction: "/mainAuction",
  dashBuyer: "/buyer",
  dashSeller: "/seller",
  privacy: "/privacy-policy",
  refundPolicy: "/refund-policy",
  termOfUse: "/term-of-use",
  contactUs: "/contact-us",
  liveAuctions: "/live-auctions",
};
