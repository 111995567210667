import React, { useState, useEffect } from "react";
import { Tabs, Tab, Typography, Box, Grid } from "@mui/material";
import { Carousel, Row, Col } from "react-bootstrap";
import useStyles from "./auctionModal.style";
import tempImg from "../../../../assets/temp-img.png";
import Image1 from "../../../../assets/common/product.png";
import Image2 from "../../../../assets/common/product.png";
import Image3 from "../../../../assets/common/product.png";
import saveBidAPI from "../../../../api/saveBidAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Countdown from "react-countdown";
import {
  getToken,
  getUserData,
  getTimeZone,
  localTimeZone,
  localSetTimeZone,
} from "../../../../common";

function AuctionModal(props) {
  const preview = props.eventItemDetail.setDetails;

  const [index, setIndex] = useState(0);
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const [submitted, setSubmitted] = useState(false);

  const itemDetails = props.eventItemDetail.eventItemObject ?? {};
  console.log("itemDetails amount", itemDetails.amount);

  const handleClose = () => props.eventItemDetail.onHide();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  let current_time = moment().tz(getZone).format("DD-MM-YYYY hh:mm:ss");

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    console.log(
      "bidStartDateTime",
      props.eventItemDetail.eventDetail.bidStartDateTime
    );
    console.log(
      "StartDateTime",
      props.eventItemDetail.eventItemObject.startDateAndTime
    );

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const handleSubmit = async () => {
    if (preview !== "NO") {
      if (
        moment(props.eventItemDetail.eventDetail.bidStartDateTime)
          .tz(getZone)
          .format("DD-MM-YYYY hh:mm:ss") < current_time &&
        moment(props.eventItemDetail.eventItemObject.startDateAndTime)
          .tz(getZone)
          .format("DD-MM-YYYY hh:mm:ss") > current_time
      ) {
        setSubmitted(true);
        toast("Bid Not Started !");
      } else {
        try {
          const payloadBid = {
            amount: Number(Number(itemDetails.amount).toFixed(2)),
          };
          const { data } = await saveBidAPI(
            payloadBid,
            itemDetails.eventId,
            itemDetails.eventItemId
          );
          // console.clear();
          if (data) {
            if (data.status === "FAILURE") {
              setSubmitted(true);
              toast("Please bid higher amount from High Bid !");
              // props.eventItemDetail.setAmount(0);
              // setSubmitted(false);
            }
            // else if(itemDetails.status === "upcoming"){
            //   toast("Bid not started");
            // }
            else if (data.status === "SUCCESS") {
              toast("Your Bid Submited!");
              props.eventItemDetail.setAmount(0);
            }
          }
          setTimeout(() => {
            handleClose();
          }, 1000);
        } catch (error) {
          console.log("Errorss", error.response.data);
          if (error.response.status === 403) {
            setSubmitted(true);
            toast("You are Not Authorized !");
          } else if (error.response.status === 201) {
            setSubmitted(true);
            console.log("Bid should be higher than last bid!");
            toast("Bid should be higher than last bid!");
            setIsLoading(false);
            setSubmitted(false);
            props.eventItemDetail.setAmount(0);
          } else if (error.response.status === 401) {
            toast("Please login and try again!");
          } else {
            toast("Something Went Wrong!");
          }
          setIsLoading(false);
          setSubmitted(false);
        }
      }
    } else if (itemDetails.status === "live") {
      try {
        const payloadBid = { amount: Number(itemDetails.amount).toFixed(2) };
        const { data } = await saveBidAPI(
          payloadBid,
          itemDetails.eventId,
          itemDetails.eventItemId
        );

        if (data) {
          if (data.status === "FAILURE") {
            setSubmitted(true);
            toast("Please bid higher amount from High Bid !");
            props.eventItemDetail.setAmount(0);
            setSubmitted(false);
          }
          // else if(itemDetails.status === "upcoming"){
          //   toast("Bid not started");
          // }
          else if (data.status === "SUCCESS") {
            toast("Your Bid Submited!");
            props.eventItemDetail.setAmount(0);
          }
        }
        setTimeout(() => {
          handleClose();
        }, 1000);
      } catch (error) {
        if (error.response.status === 401) {
          setSubmitted(true);
          toast("Please login and Try Again !");
          setIsLoading(false);
          setSubmitted(false);
          props.eventItemDetail.setAmount(0);
        } else if (error.response.status === 201) {
          console.log("first");
          setSubmitted(true);
          console.log("Bid should be higher than last bid!");
          toast("Bid should be higher than last bid!");
          setIsLoading(false);
          setSubmitted(false);
          props.eventItemDetail.setAmount(0);
        } else if (error.response.status === 400) {
          setSubmitted(true);
          toast("You are Not Authorized !");
          setIsLoading(false);
          setSubmitted(false);
          props.eventItemDetail.setAmount(0);
        } else if (error.response.status === 403) {
          setSubmitted(true);
          toast("You are not authorized");
          setIsLoading(false);
          setSubmitted(false);
          props.eventItemDetail.setAmount(0);
        } else if (error.response.status === 500) {
          setSubmitted(true);
          toast("Network Error!");
          setIsLoading(false);
          setSubmitted(false);
          props.eventItemDetail.setAmount(0);
        } else if (error.response.status === 401) {
          setSubmitted(true);
          toast("Please login and try again!");
          setIsLoading(false);
          setSubmitted(false);
          props.eventItemDetail.setAmount(0);
        } else if (error.request) {
          toast("Something went wrong!");
          setIsLoading(false);
          setSubmitted(false);
          props.eventItemDetail.setAmount(0);
        } else {
          console.log("Error", error.message.code);
          setIsLoading(false);
          setSubmitted(false);
          props.eventItemDetail.setAmount(0);
        }
      }
    }
    setSubmitted(false);
  };

  return (
    <div>
      <Box className={classes.auctionCard}>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Box className={classes.auctionImage}>
                {/* <Carousel activeIndex={index} onSelect={handleSelect}> */}
                {/* {itemDetails.eventItemImagesUrl.map(
                    (eventItemImages, index) => (
                      <Carousel.Item>
                        <div>
                          <img
                            className={classes.productimg}
                            src={tempImg}
                            alt="First slide"
                          />
                        </div>
                      </Carousel.Item>
                    )
                  )} */}
                {/* {console.log("eventItemImagesUrl", itemDetails.eventItemImagesUrl)}
                  <Carousel.Item>
                    <div>
                      <img
                        className={classes.productimg}
                        src={tempImg}
                        alt="First slide"
                      />
                    </div>
                  </Carousel.Item>
                </Carousel> */}
                {itemDetails.eventItemImagesUrl.length == 0 ? (
                  <div>
                    <img
                      className={classes.productimg}
                      src={tempImg}
                      alt="First slide"
                    />
                  </div>
                ) : (
                  <Carousel activeIndex={index} onSelect={handleSelect}>
                    {itemDetails.eventItemImagesUrl.map(
                      (eventItemImages, index) => (
                        <Carousel.Item key={index}>
                          <div>
                            <img
                              className={classes.productimg}
                              src={eventItemImages}
                              alt="First slide"
                            />
                          </div>
                        </Carousel.Item>
                      )
                    )}
                  </Carousel>
                )}
              </Box>
            </div>
            <div className="col-md-9">
              <Box className={classes.auctionCardContent}>
                <div className={classes.auctionDetails}>
                  <Col xs={2}>
                    <div className={classes.auctionNumber}>
                      <span className={classes.lot}>LOT</span>
                      <p className="eventId">{itemDetails.lotNumber}</p>
                    </div>
                  </Col>
                  <Col xs={10}>
                    <div className={classes.auctionName}>
                      <span className={classes.eventName}>
                        {itemDetails.shortDescription}
                      </span>
                      <p className={classes.eventDetail}>
                        {itemDetails.longDescription}
                      </p>
                    </div>
                  </Col>
                </div>

                <div className={classes.bidders}>
                  <div className="row">
                    <div className="col-md-7">
                      <div className={classes.bidAmount}>
                        <div className={classes.highBid}>
                          <p className={classes.bidderText}>
                            Lead Bid{" "}
                            {/* {console.log("itemDetails modal", itemDetails)} */}
                            {preview !== "NO" &&
                            moment(itemDetails.startDateAndTime)
                              .tz(getZone)
                              .format("DD-MM-YYYY hh:mm:ss") > current_time ? (
                              <span className={classes.amount}>{"XXX"}</span>
                            ) : (
                              <span className={classes.amount}>
                                {itemDetails.showH1L1Bid === "YES" && (
                                  <>
                                    {itemDetails.currency}{" "}
                                    {itemDetails.highestBid}{" "}
                                  </>
                                )}
                              </span>
                            )}
                            {/* {itemDetails.showH1L1Bid === "YES" &&
                              <span className={classes.amount}>
                                {itemDetails.currency} {itemDetails.highestBid} 
                              </span>
                            } */}
                          </p>
                          <p className={classes.bidderText}>
                            per {itemDetails.unit}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-0.5"></div> */}
                    <div className="col-md-5">
                      <div className={classes.bidRank}>
                        <div
                          className={classes.rankColumn}
                          style={{
                            border:
                              preview !== "NO" &&
                              moment(itemDetails.startDateAndTime)
                                .tz(getZone)
                                .format() > moment().tz(getZone).format()
                                ? "1px solid #db1c1c"
                                : itemDetails.myBidStatus.myBidRank == 0 ||
                                  itemDetails.myBidStatus.myBidRank > 1
                                ? "1px solid #db1c1c"
                                : "1px solid #329E0C",

                            backgroundColor:
                              preview !== "NO" &&
                              moment(itemDetails.startDateAndTime)
                                .tz(getZone)
                                .format() > moment().tz(getZone).format()
                                ? "#fabcbc"
                                : itemDetails.myBidStatus.myBidRank == 0 ||
                                  itemDetails.myBidStatus.myBidRank > 1
                                ? "#fabcbc"
                                : "#E3FFD9",
                          }}
                        >
                          {preview !== "NO" &&
                          moment(itemDetails.startDateAndTime)
                            .tz(getZone)
                            .format("DD-MM-YYYY hh:mm:ss") > current_time ? (
                            <p className={classes.bidderText}>
                              Your Rank{" "}
                              <span className={`${classes.amount} colorBlack`}>
                                N/A
                              </span>
                            </p>
                          ) : (
                            <p className={classes.bidderText}>
                              Your Rank{" "}
                              {itemDetails.myBidStatus.myBidRank === "0" ? (
                                <span className={classes.amount}>N/A</span>
                              ) : (
                                <span className={classes.amount}>
                                  H{itemDetails.myBidStatus.myBidRank}
                                </span>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classes.biddingOptions}>
                  <div className={classes.biddingColumn}>
                    <div className="row">
                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-md-6">
                            <Box sx={{ textAlign: "left" }}>
                              {itemDetails.status === "live" ? (
                                <p className={classes.biddingEndTime}>
                                  Bid Ends (D:H:M:S):
                                </p>
                              ) : (
                                <p className={classes.biddingEndTime}>
                                  Bid Starts (D:H:M:S):
                                </p>
                              )}

                              <span className={classes.biddingEndTime}>
                                Quantity:
                              </span>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box sx={{ textAlign: "left" }}>
                              <p style={{ marginBottom: "0" }}>
                                {itemDetails.status === "live" ? (
                                  <Countdown
                                    className={classes.bidLive}
                                    isPlaying={true}
                                    date={moment(itemDetails.endDateAndTime)
                                      .tz(getZone)
                                      .format()}
                                  />
                                ) : (
                                  <Countdown
                                    className={classes.bidUpcoming}
                                    isPlaying={true}
                                    date={moment(itemDetails.startDateAndTime)
                                      .tz(getZone)
                                      .format()}
                                  />
                                )}
                              </p>
                              {itemDetails.status === "live" ? (
                                <span className={classes.bidLive}>
                                  {itemDetails.quantity} {itemDetails.unit}
                                </span>
                              ) : (
                                <span className={classes.bidUpcoming}>
                                  {itemDetails.quantity} {itemDetails.unit}
                                </span>
                              )}
                            </Box>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <Box sx={{ textAlign: "right" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <span className={classes.biddingDate}>
                                {itemDetails.status === "live"
                                  ? moment(itemDetails.endDateAndTime)
                                      .tz(getZone)
                                      .format("DD/MM/YYYY HH:mm:ss")
                                  : moment(itemDetails.startDateAndTime)
                                      .tz(getZone)
                                      .format("DD/MM/YYYY HH:mm:ss")}
                              </span>
                              <span className={classes.biddingDate}>
                                Amount(Qty * Bid Value) :{" "}
                                {itemDetails.quantity * itemDetails.amount}
                              </span>
                            </div>
                          </div>
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </div>

          <div className={classes.otherPhase}>
            <div className="row">
              <div className="col-md-3">
                <div className={classes.priceSection}>
                  <div className={classes.priceOptions}>
                    <div className={classes.priceType}>
                      <span className={classes.priceName}>Start Price</span>
                      <span className={classes.priceName}>
                        {itemDetails.currency} {itemDetails.startPrice}{" "}
                      </span>
                    </div>
                    <div className={classes.priceType}>
                      <span className={classes.priceName}>Increment</span>
                      <span className={classes.priceName}>
                        {itemDetails.currency} {itemDetails.increment}{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Col xs={9} className={classes.otherThreeButtons}>
                {console.log("AMOUNTSSS", itemDetails.amount)}
                <Col className={classes.bidButton}>
                  <div className={classes.firstButton}>
                    <span
                      className={
                        submitted === true
                          ? classes.cadAmountRed
                          : classes.cadAmount
                      }
                    >
                      {itemDetails.currency} {itemDetails.amount}
                    </span>
                  </div>
                  <div className={classes.secondButton}>
                    {isOnline ? (
                      <span
                        className={classes.bid}
                        onClick={() => {
                          handleSubmit();
                          handleClose();
                        }}
                      >
                        Confirm
                      </span>
                    ) : (
                      <span
                        className={classes.bid}
                        style={{ backgroundColor: "#D22B2B" }}
                      >
                        Please check your Internet!
                      </span>
                    )}
                    {/* <MyVerticallyCenteredModal
                    show={modalShow}
                  /> */}
                  </div>
                  <div className={classes.thirdButton}>
                    <span
                      className={classes.lastBidText}
                      onClick={() => {
                        handleClose();
                        props.eventItemDetail.setAmount(0);
                      }}
                    >
                      Cancel
                    </span>
                  </div>
                </Col>
              </Col>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default AuctionModal;
