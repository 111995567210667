import * as React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useStyles from "./Register.styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
// import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import colorSchemes from "../../../constants/colorSchemes";
import { useState } from "react";
import routes from "../../../constants/routes";
import saveUserApi from "../../../api/saveUserAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty, omit } from "lodash";
import config from "../../../config/config";

export default function Register() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    password: "",
    confirmpassword: "",
    alternateContactNumber: "",
    username: "",
  });

  // Validation Form

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validate = (event, name, value) => {
    //A function to validate each input values
    const re = /^[A-Za-z ]+$/;
    const number_val = /^[0-9]+$/;
    switch (name) {
      case "firstName":
        if (!re.test(value)) {
          // we will set the error firstName

          setErrors({
            ...errors,
            firstName: "First Name should contains only Letters",
          });
        } else {
          // set the error firstName empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "firstName");
          setErrors(newObj);
        }
        break;

      case "contactNumber":
        if (!number_val.test(value)) {
          // we will set the error firstName

          setErrors({
            ...errors,
            contactNumber: "Contact Number should contains only Numbers",
          });
        } else {
          // set the error contactNumber empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "contactNumber");
          setErrors(newObj);
        }
        break;

      case "alternateContactNumber":
        if (!number_val.test(value)) {
          // we will set the error firstName

          setErrors({
            ...errors,
            alternateContactNumber:
              "ALternate Contact Number should contains only Numbers",
          });
        } else {
          // set the error alternateContactNumber empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "alternateContactNumber");
          setErrors(newObj);
        }
        break;

      case "lastName":
        if (!re.test(value)) {
          // we will set the error lastName

          setErrors({
            ...errors,
            lastName: "Last Name should contains only Letters",
          });
        } else {
          // set the error lastName empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "lastName");
          setErrors(newObj);
        }
        break;

      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter a valid email address",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  const handleChange = (event) => {
    event.persist();
    validate(event, event.target.name, event.target.value);
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isEmpty(errors)) {
      toast("Please Provide Correct Values!");
    } else {
      try {
        userInfo.contactNumber = Number(userInfo.contactNumber);

        if (userInfo.password != userInfo.confirmpassword) {
          toast("Password Does Not Match!!");
        } else {
          userInfo.alternateContactNumber = Number(
            userInfo.alternateContactNumber
          );
          const { privacy_policy, confirmpassword, ...payload } = userInfo;
          payload.email = String(payload.email).toLowerCase();
          const { data } = await saveUserApi(payload);
          toast("Registration Successfully!");
          setTimeout(() => {
            navigate(routes.signin);
          }, 1500);
        }
      } catch (error) {
        if (error.response) {
          //console.log(error.response.data.error);
          toast(error.response.data.error);
          setIsLoading(false);
        } else if (error.response.status === 500) {
          toast("Network Error!");
          setIsLoading(false);
        } else if (error.request) {
          toast("Something went wrong!");
          setIsLoading(false);
        } else {
          console.log("Error", error.message.code);
          setIsLoading(false);
        }
        console.log(error);
      }
    }

    //  setUserInfo({ email: "", firstname: "", lastname: "", phonenumber: "" , password: "", confirmpassword: ""});
  };

  const classes = useStyles();
  return (
    <div className="container">
      <Box className={classes.signin}>
        <Card
          sx={{ maxWidth: 800, marginTop: 5, padding: "1em 3em" }}
          className={classes.signcard}
        >
          <form onSubmit={handleSubmit}>
            <div className="row">
              <Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "600",
                    color: colorSchemes.primaryColor,
                  }}
                  variant="h5"
                >
                  CREATE AN ACCOUNT
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  margin: "30px 0px 20px 0px",
                  justifyContent: "space-evenly",
                }}
              >
                <a
                  style={{ width: "45%" }}
                  href={config.socialLogin.google}
                  className="px-4 py-2 border flex gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400  transition duration-150 google-login"
                >
                  <img
                    className="w-6 h-6"
                    src="https://www.svgrepo.com/show/475656/google-color.svg"
                    loading="lazy"
                    alt="google logo"
                  />
                  <span>Continue with Google</span>
                </a>
                <a
                  style={{ width: "45%" }}
                  href={config.socialLogin.facebook}
                  className="px-4 py-2 border flex gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400  transition duration-150 google-login"
                >
                  <img
                    className="w-6 h-6"
                    src="https://www.svgrepo.com/show/448224/facebook.svg"
                    loading="lazy"
                    alt="facebook logo"
                  />
                  <span>Continue with Facebook</span>
                </a>
              </Box>
              <Box style={{ display: "flex", margin: "10px 0px 30px 0px" }}>
                <Box
                  style={{
                    position: "relative",
                    top: "0.70em",
                    height: "1.2px",
                    backgroundColor: "#DBDBDB",
                    width: "44%",
                  }}
                ></Box>
                <Box
                  style={{
                    height: "1px",
                    textAlign: "center",
                    color: "#7E8184",
                    width: "12%",
                  }}
                >
                  OR
                </Box>
                <Box
                  style={{
                    position: "relative",
                    top: "0.70em",
                    height: "1.2px",
                    backgroundColor: "#DBDBDB",
                    width: "44%",
                  }}
                ></Box>
              </Box>
              <div className="col-md-6">
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className={classes.label}>
                      First Name
                    </Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="text"
                      name="firstName"
                      placeholder="Enter your First Name"
                      value={userInfo.firstName}
                      onChange={handleChange}
                      required
                    />
                    {errors.firstName && <span>{errors.firstName}</span>}
                  </Form.Group>
                </Box>
              </div>
              <div className="col-md-6">
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className={classes.label}>Last Name</Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="text"
                      name="lastName"
                      placeholder="Enter your Last Name"
                      value={userInfo.lastName}
                      onChange={handleChange}
                      required
                    />
                    {errors.lastName && <span>{errors.lastName}</span>}
                  </Form.Group>
                </Box>
              </div>
              <div className="col-md-6">
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.label}>E-mail</Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      value={userInfo.email}
                      onChange={handleChange}
                      required
                    />
                    {errors.email && <span>{errors.email}</span>}
                  </Form.Group>
                </Box>
              </div>
              <div className="col-md-6">
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.label}>Username</Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="text"
                      name="username"
                      placeholder="Enter username"
                      value={userInfo.username}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Box>
              </div>
              <div className="col-md-6">
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className={classes.label}>
                      Contact No.
                    </Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="text"
                      name="contactNumber"
                      placeholder="Number"
                      value={userInfo.contactNumber}
                      onChange={handleChange}
                      required
                    />
                    {errors.contactNumber && (
                      <span>{errors.contactNumber}</span>
                    )}
                  </Form.Group>
                </Box>
              </div>
              <div className="col-md-6">
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className={classes.label}>
                      Alternate Contact No.
                    </Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="text"
                      name="alternateContactNumber"
                      placeholder="Alternate Number"
                      value={userInfo.alternateContactNumber}
                      onChange={handleChange}
                      required
                    />
                    {errors.alternateContactNumber && (
                      <span>{errors.alternateContactNumber}</span>
                    )}
                  </Form.Group>
                </Box>
              </div>
              <div className="col-md-6">
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className={classes.label}>Password</Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="password"
                      placeholder="Enter your Password"
                      name="password"
                      value={userInfo.password}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Box>
              </div>
              <div className="col-md-6">
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className={classes.label}>
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="password"
                      name="confirmpassword"
                      placeholder="Enter your Confirm Password"
                      value={userInfo.confirmpassword}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Box>
              </div>
              <FormGroup>
                <Form.Label className={classes.label}></Form.Label>
                <Box sx={{ display: "flex" }}>
                  {["checkbox"].map((type) => (
                    <div key={`inline-${type}`} className="mb-4">
                      <Form.Check
                        inline
                        label=""
                        name="privacy_policy"
                        type={type}
                        id={`inline-${type}-Yes`}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  ))}
                  <span style={{ fontSize: "small" }}>
                    Agree That I Have Read The Site's{" "}
                    <Link>Privacy Policy</Link> & Accept Bidweb{" "}
                    <Link>Terms & Conditions</Link>
                  </span>
                </Box>
              </FormGroup>
              <Box>
                <Button
                  sx={{
                    backgroundColor: colorSchemes.primaryColor,
                    color: "#fff",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "#2D55EB !important",
                    },
                  }}
                  fullWidth
                  type="submit"
                >
                  Create My Account
                </Button>
              </Box>
            </div>
          </form>
        </Card>
      </Box>
    </div>
  );
}
