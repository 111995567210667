import React from "react";
import { Box, Button, Typography } from "@mui/material";
import useStyles from "../../../../Create/Event/Rules/rules.style";
import EditIndexView from "./EditIndexView";
import EditListView from "./EditListView";
import { useState } from "react";

function EditItem({ method, eventEditId, eventDetail }) {
  const classes = useStyles();
  const [hide, SetHide] = useState(false);
  const [edit, SetEdit] = useState(false);
  const [eventItemId, SetEventItemId] = useState('');

  return (
    <div>
      {hide ? (
        <EditIndexView eventEditId={eventEditId} SetHide={SetHide} edit={edit} eventItemId={eventItemId} SetEventItemId={SetEventItemId} />
      ) : (
        <EditListView eventDetail={eventDetail} method={method} eventEditId={eventEditId} SetHide={SetHide} SetEdit={SetEdit} SetEventItemId={SetEventItemId} />
      )}
    </div>
  );
}

export default EditItem;
