import axios from "axios";

import config from "../config/config";
import { getToken } from "../common";

export const getLogs = async (params) => {
  return await axios({
    method: "get",
    url: `${config.api.base_url}logs`,
    params: { ...params },
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: getToken(),
    },
  });
};

export default getLogs;
