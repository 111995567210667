import { orange } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  auction: { display: "flex" },
  auctionTab: { margin: "2.5em 0.9em" },
  auctionCard: {
    padding: "1em",
    backgroundColor: "#fff",
    marginRight: "0rem !important",
  },
  auctionImage: { padding: "0 !important" },
  productimg: {
    width: "100%",
    height: "8em !important",
    objectFit: "contain !important",
  },
  auctionCardContent: { width: "100% !important", padding: "0 !important" },
  auctionDetails: {
    borderBottom: "1px solid #ccc",
    paddingBottom: "0.5rem",
    display: "flex",
  },
  auctionNumber: { textAlign: "center" },
  auctionName: { borderLeft: "1px solid #b4b5ba", paddingLeft: "1rem" },
  lot: {
    display: "block",
    fontWeight: "bold",
    color: "#3f4043",
    fontSize: "14px",
  },
  bidLive: {
    color : "#db1c1c",
    fontWeight: 600,
    display: 'flex',
    justifyContent:'center',
    margin:'6px 0',
    fontSize:'15px'
  },
  bidUpcoming: {
    color: "#199e2b",
    fontWeight: 600,
    display: 'block',
    fontSize:'15px',
    textAlign:'end',
  },
  auctionNumber: { textAlign: "center" },
  auctionName: { borderLeft: "1px solid #b4b5ba", paddingLeft: "1rem" },
  lot: {
    display: "block",
    fontWeight: "bold",
    color: "#3f4043",
    fontSize: "14px",
  },
  bid :{
    color: "#FF4433"
  },
  biddingEndTime: { 
    marginBottom: "0",
    fontSize:"14px", 
    color: "#7E8184" ,
  },
  bidLive : {
    color : "#db1c1c",
    fontWeight: 600,
    display: 'block',
    fontSize:'15px',
    textAlign:'end',
  },
  bidUpcoming :{
    color: "#199e2b",
    fontWeight: 600,
    display: 'block',
    fontSize:'15px',
    textAlign:'end',
  },
  eventId: {
    fontSize: "24px !important",
    fontWeight: "600",
    marginBottom: "0em !important",
    height: "60px",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "16px !important",
    },
  },
  eventName: {
    display: "block",
    fontSize: "22px",
    fontWeight: "bold",
    color: "#3f4043",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "14px",
    },
  },
  eventDetail: {
    marginBottom: "0em",
    fontWeight: "500",
    fontSize: "small",
    color: "#7e8184",
    
    [theme.breakpoints.down("tablet")]: {
      fontSize: "11px",
    },
  },
  bidders: {
    textAlign: "center",
    alignitem: "center",
    marginTop: "0.5rem",
    marginBottom: "0.4rem",
  },
  bidAmount: {
    border: "1px solid #b4b5ba",
    backgroundColor: "#e4e4e4",
    display: "flex",
    justifyContent: "spaceAround",
    padding: "0.7rem 0.5rem",
    width: "100%",
  },
  bidderText: {
    fontWeight: "bold",
    marginBottom: "0",
    textAlign: "center",
    color: "#7e8184",
    fontSize: "small",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "10px",
    },
  },
  biddingEndTime: {
    fontSize: "15px",
    marginBottom:'0',
    // margin: '3px 0',
    display:'block',
    color:'#7E8184',
    [theme.breakpoints.down("tablet")]: {
      fontSize: "12px",
    },
  },
  amount: {
    fontWeight: "800",
    fontSize: "medium",
    color: "#000",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "10px",
    },
  },
  rankColumn: {
    border: "1px solid #db1c1c",
    backgroundColor: "#fabcbc",
    padding: "0.7rem",
  },
  biddingOptions: {
    display: "flex",
    marginTop: "0.2rem",
    height: "3em",
  },
  biddingColumn: { width: "100%" },
  biddingEnd: { fontWeight: "bold", color: "#7e8184", fontSize: "small" },
  biddingTime: {
    fontWeight: "900",
    color: "#db1c1c",
    fontSize: "16px",
    marginBottom: "0em !important",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "14px",
    },
  },
  biddingDate: {
    fontWeight: "bold",
    color: "#7e8184",
    fontSize: "15px",
    display:"flex",
    justifyContent:"end",
    // margin:"6px 0",
    alignitem:'baseline',
    marginLeft: "-9em",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "12px",
    },
  },
  otherPhase: {
    alignitem: "center",
    justifyContent: "spaceBetween",
    padding: "0",
  },
  //   priceSection: { padding: "0 0.8rem" },
  priceOptions: { marginTop: "1rem" },
  priceType: {
    display: "flex",
    justifyContent: "space-between !important",
    fontWeight: "bold",
    color: "#7e8184",
    fontSize: "small",
    marginTop: "0.3rem !important",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "8px",
    },
  },
  otherThreeButtons: { padding: "0 0.7rem" },
  bidButton: {
    display: "flex",
    alignitem: "center",
    justifyContent: "space-between",
    textAlign: "center",
    // marginTop: "0.5rem",
  },
  firstButton: {
    fontWeight: "bold",
    padding: "0.5rem",
    width: "100%",
    paddingleft: "0",
  },
  cadAmountRed: {
    padding: "0.7rem",
    display: "block",
    fontSize: "14px",
    backgroundColor: "#f2f2f24d",
    border: "1px solid #a0a0a0",
    [theme.breakpoints.down("tablet")]: {
      padding: "6px",
    },
    border:"1px solid red"
  },
  cadAmount: {
    padding: "0.7rem",
    display: "block",
    fontSize: "14px",
    backgroundColor: "#f2f2f24d",
    border: "1px solid #a0a0a0",
    [theme.breakpoints.down("tablet")]: {
      padding: "6px",
    },
  },
  secondButton: { fontWeight: "bold", padding: "0.5rem", width: "100%" },
  bid: {
    padding: "0.7rem",
    display: "block",
    color: "#fff",
    border: "1px solid #2d55eb",
    backgroundColor: "#2d55eb",
    fontSize: "14px",
    cursor:"pointer",
    [theme.breakpoints.down("tablet")]: {
      padding: "6px",
    },
  },
  thirdButton: {
    fontWeight: "bold",
    padding: "0.5rem",
    width: "100%",
    paddingRight: "0",
  },
  upperAuction: {
    padding: "1rem",
    boxShadow: "0px 1px 6px 0px rgb(221, 219, 219)",
    backgroundColor: "#fff",
    margin: "2.5em 1.6em",
  },
  firstText: {
    margin: "4px 0em 1em",
    color: "#7e8184",
    fontWeight: "bold",
    fontSize: "small !important",
  },
  liveButton: {
    border: "2px solid #db1c1c",
    padding: "0.1rem 1rem",
    fontWeight: "bold !important",
    color: "#db1c1c",
    backgroundColor: "#fabcbc",
  },
  auctionHeading: {
    fontSize: "24px !important",
    fontWeight: "bold !important",
    color: "#0d1d58",
    // margin: "0.5rem !important",
  },
  auctionLink: { fontWeight: "bold", fontSize: "small", color: "#2d55eb" },
  location: {
    fontWeight: "bold",
    color: "#7e8184",
    fontSize: "small !important",
    marginBottom: "0em",
  },
  lastBidText: {
    padding: "0.7rem",
    display: "block",
    backgroundColor: "#DB1C1C",
    border: "1px solid #DB1C1C",
    color: "#fff",
    fontSize: "14px",
    cursor: "pointer",
    [theme.breakpoints.down("tablet")]: {
      padding: "6px",
    },
  },
  highBid: { display: "flex", justifyContent: "space-between", width: "100%" },
}));
export default useStyles;
