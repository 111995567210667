import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import useStyles from "./auction.style";
import colorSchemes from "../../../../constants/colorSchemes";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import Form from "react-bootstrap/Form";
import Product from "../../../../assets/common/product.png";
import StarIcon from "@mui/icons-material/Star";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import getEventsListAPI from "../../../../api/getEventsListAPI";
import homeItemAPI from "../../../../api/homeItemAPI";
import saveBidAPI from "../../../../api/saveBidAPI";
import TimerIcon from "@mui/icons-material/Timer";
import tempImg from "../../../../assets/temp-img.png";
import {
  getToken,
  getTimeZone,
  localTimeZone,
  localSetTimeZone,
} from "../../../../common";
import ReactDOM from "react-dom";
import Countdown from "react-countdown";
import moment from "moment-timezone";
//import momentTimeZone from "moment-timezone";
import { useNavigate } from "react-router-dom";
import routes from "../../../../constants/routes";
import { isEmpty } from "lodash";

function AuctionCard() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [changeIcon, setChangeIcon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [Events, updateEvents] = useState({ apiState: "loading", list: [] });
  const [EventItems, updateEventItems] = useState({
    apiState: "loading",
    list: [],
  });

  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(8);
  const [pageLimit, setPageLimit] = React.useState(8);

  const getEventsList = async () => {
    try {
      const payload = { pageSize: pageSize, page: page };
      const { data } = await getEventsListAPI(payload);

      if (data) updateEvents({ apiState: "success", list: data.data });
      else throw new Error(data);
    } catch (error) {
      console.clear();
      console.log(error);
    }
  };
  React.useEffect(() => {
    getEventsList();
    return () => {
      return false;
    };
  }, []);

  const getEventItemsList = async () => {
    try {
      const { data } = await homeItemAPI();

      if (data) {
        updateEventItems({ apiState: "success", list: data });
        if (isEmpty(getTimeZone())) {
          localSetTimeZone();
        }
      } else throw new Error(data);
    } catch (error) {
      console.clear();
      console.log(error);
    }
  };
  React.useEffect(() => {
    getEventItemsList();
    return () => {
      return false;
    };
  }, []);

  const [bidInfo, setbidInfo] = useState({
    eventId: "",
    eventItemId: "",
    amount: "",
  });

  const handleChange = (event) => {
    setbidInfo({ ...bidInfo, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event, payload) => {
    try {
      event.preventDefault();
      if (isOnline) {
        bidInfo.amount = Number(bidInfo.amount);
        const payloadBid = {
          amount: Number(Number(bidInfo.amount).toFixed(2)),
        };
        const { data } = await saveBidAPI(
          payloadBid,
          payload.eventId,
          payload.eventItemId
        );

        if (data) {
          toast("Your Bid Submited!");
        }
      } else {
        toast("Please check Your Internet!");
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.errorMessage);
        setIsLoading(false);
        if (
          error.response.data.errorMessage === "Missing authorization header."
        ) {
          toast("Please Login and then try!");
          setIsLoading(false);
        } else {
          toast(error.response.data.errorMessage);
          setIsLoading(false);
        }
      } else if (error.response.status === 500) {
        toast("Network Error!");
        setIsLoading(false);
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log("Error", error.message.code);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className={classes.auction}>
        <div className="container">
          <div className={classes.auctions}>
            <Typography
              className={classes.textHead}
              sx={{
                textAlign: "center",
                padding: "1em",
                fontSize: "22px",
                fontWeight: "600",
                color: colorSchemes.primaryColor,
                marginTop: "1em",
              }}
            >
              Auctions
            </Typography>
          </div>
          <div className="row">
            {Events.list.map((event, index) => {
              return (
                <div
                  className="col-lg-3 col-md-4 col-sm-12 mob-card"
                  key={index}
                >
                  <div className={classes.AuctionCards}>
                    <div className={classes.AuctionCard}>
                      <p className={classes.firstText}>
                        {event.status === "live" && (
                          <span className={classes.liveButton}>Live Now</span>
                        )}
                        {event.status === "ended" && (
                          <span className={classes.ended}>Ended</span>
                        )}
                        {event.status === "upcoming" && (
                          <span className={classes.Upcoming}>Upcoming</span>
                        )}
                      </p>
                      <img
                        className={classes.productImg}
                        alt="product"
                        src={event.eventImageUrl}
                        onClick={() => {
                          // navigate(routes.auction, {
                          //   state: { eventId: event.eventId },
                          // });
                          navigate(`auctions/${event.eventId}`);
                        }}
                      />
                      <Box className={classes.auctionContent}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            height: "22px",
                            overflow: "hidden",
                            textOverflow: "ellispsis",
                          }}
                        >
                          Event Name : {event.auctionTitle}
                        </Typography>
                        <Typography
                          className={classes.fixedCard}
                          sx={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          Description : {event.description}
                        </Typography>
                        <Typography
                          className={classes.fixedCard}
                          sx={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          Reference number : {event.referenceNumber}
                        </Typography>
                        <Box>
                          <span className={classes.name}>
                            Auctioner Name {event.auctioneerName}
                          </span>
                        </Box>
                        <Box className={classes.loactionDetail}>
                          <span className={classes.location}>
                            Location:- {event.streetOne}, {event.streetTwo},{" "}
                            {event.city}, {event.province}, {event.postalCode},{" "}
                            {event.country}...
                          </span>
                        </Box>
                        <Box>
                          <span className={classes.lots}>
                            {event.totalLots} Lots
                          </span>
                        </Box>
                      </Box>
                    </div>
                    {/* <div className={classes.status}>
                    <Typography>Ended</Typography>
                  </div> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={classes.auction}>
        <div className="container">
          <div className={classes.auctions}>
            <Typography
              className={classes.textHead}
              sx={{
                textAlign: "center",
                padding: "1em",
                fontSize: "22px",
                fontWeight: "600",
                color: colorSchemes.primaryColor,
              }}
            >
              Lots
            </Typography>
          </div>
          <div className="row">
            {EventItems &&
              EventItems.list.map((event, index) => {
                return (
                  <div className="col-lg-3 col-md-4 col-sm-12" key={index}>
                    <div className={classes.AuctionCards}>
                      <div className={classes.AuctionCard}>
                        <img
                          className={classes.productImg}
                          alt="product"
                          src={
                            event.eventItemImagesUrl.length > 0
                              ? event.eventItemImagesUrl[0]
                              : tempImg
                          }
                        />
                        <Box
                          className={classes.auctionContent}
                          sx={{ paddingBottom: "5px" }}
                        >
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            Lot No. : {event.lotNumber}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            Quantity : {event.quantity}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            Start Price : {event.startPrice}
                          </Typography>
                          <Box>
                            <span className={classes.name}>
                              Auctioner Name {event.auctioneerName}
                            </span>
                          </Box>
                          <Box>
                            <span className={classes.location}>
                              Location:- {event.event.city},{" "}
                              {event.event.province}, {event.event.country}
                            </span>
                          </Box>
                          {/* <form
                            onSubmit={(form) => {
                              handleSubmit(form, event);
                            }}
                          >
                            <Box sx={{ padding: "8px 0px 0px 0px " }}>
                              <div className="row m-auto">
                                <div className="col-md-9 p-0">
                                  {moment(event.endDateAndTime)
                                    .tz(getZone)
                                    .format() >
                                    moment().tz(getZone).format() && (
                                    <Form.Control
                                      className={classes.input}
                                      type="text"
                                      name="amount"
                                      onChange={handleChange}
                                      required
                                    />
                                  )}
                                </div>
                                <div className="col-md-3 pr-0">
                                  {moment(event.endDateAndTime)
                                    .tz(getZone)
                                    .format() >
                                    moment().tz(getZone).format() && (
                                    <Button
                                      className={classes.next}
                                      type="submit"
                                    >
                                      Bid
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </Box>
                          </form> */}
                        </Box>
                      </div>

                      <div className={classes.status}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ marginRight: "0em", fontSize: "16px" }}
                          >
                            Ends in
                          </Typography>
                          <Box>
                            <div className={classes.time}>
                              {isOnline ? (
                                <>
                                  <TimerIcon />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      letterSpacing: "-1px",
                                      margin: "auto",
                                      color: "#000",
                                    }}
                                  >
                                    {moment(event.endDateAndTime)
                                      .tz(getZone)
                                      .format() >
                                    moment().tz(getZone).format() ? (
                                      <Countdown
                                        isPlaying={true}
                                        date={moment(event.endDateAndTime)
                                          .tz(getZone)
                                          .format()}
                                      />
                                    ) : (
                                      <p style={{ marginBottom: "0em" }}>
                                        Deal has been Expired
                                      </p>
                                    )}
                                  </Typography>
                                </>
                              ) : (
                                <p>Please check your Internet!</p>
                              )}
                            </div>
                          </Box>
                        </Box>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default AuctionCard;
