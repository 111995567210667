import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  detail: { marginTop: "2em" },
  bidweOnline: {
    padding: "2em 0em 3em 3em",
    [theme.breakpoints.down("mxs")]: {
      padding: "1em !important",
    },
  },
  rulesOnline: { padding: "2em 0em" },
  align: {
    borderLeft: "1px solid #ccc",
    padding: "0em 3em",
    [theme.breakpoints.down("mxs")]: {
      padding: "1em !important",
      borderLeft:"none !important"
    },
  },
  payLogo: { padding: "1em 0em !important" },
}));
export default useStyles;
