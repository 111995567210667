import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  amountSecondValueLive: {
    color: "#db1c1c",
    fontWeight: "bold !important",
    fontFamily: "Poppins,sans-serif!important",
  },
  amountSecondValueUpcoming: {
    color: "#199e2b",
    fontWeight: "bold !important",
    fontFamily: "Poppins,sans-serif!important",
  },
  auctionAmountField: {
    width: "100%",
    padding: "0.60rem !important",
    border: "1px solid #a0a0a0 !important",
  },
  auctionBidErrorBox: {
    color: "white",
    borderRadius: "0px",
    padding: "10px 2px 5px 8px !important",
    backgroundColor: "#D22B2B",
    padding: "0.55rem !important",
    textAlign: "center",
  },
  auctionBidButton: {
    width: "100%",
    color: "white !important",
    padding: "0.55rem !important",
    backgroundColor: "rgb(45, 85, 235) !important",
    borderRadius: "0px !important",
  },
  auctionAmountField: {
    width: "100%",
    padding: "0.60rem !important",
    border: "1px solid #a0a0a0 !important",
  },
}));

export default useStyles;
