import config from '../config/config'
import axios from 'axios'
import { getToken } from '../common'; 

export const homeItemAPI = async (params) => {

    return await axios({
        method  : 'get',
        url     : `${config.api.base_url}home-items`,
        // data    : params,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
            //"Authorization": getToken(),
        }
    })
}

export default homeItemAPI