import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const AuctionLotBidsDialog = React.memo((props) => {
    return (
        <Dialog open={props.activeDialog} onClose={(...props) => { console.log(props); }} fullWidth={true} maxWidth={'sm'}  >
            <DialogTitle id="alert-dialog-title">Bid Logs</DialogTitle>
            <DialogContent>
                <DataTableExtensions export={false} data={[]} print={false} exportHeaders={false} columns={[]}>
                    <DataTable className="DataTable" highlightOnHover noHeader defaultSortField="id" defaultSortAsc={false} pagination={true} />
                </DataTableExtensions>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.handleAuctionDialog({}) }}>Close Modal</Button>
            </DialogActions>
        </Dialog>
    );
})

export default AuctionLotBidsDialog