import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { BsUpload } from "react-icons/bs";
import "react-datetime/css/react-datetime.css";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import moment from "moment";
import momentTimezone from "moment-timezone";
import TextField from "@mui/material/TextField";
import "react-toastify/dist/ReactToastify.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ImageUploading from "react-images-uploading";

import uploadDocumentEvents from "../../../../../api/uploadDocumentEvents";
import getEventDetails from "../../../../../api/getEventDetail";
import Loader from "../../../../../common/Loader";
import currency from "../../Event/Rules/currency.json";
import useStyles from "./item.style";
import routes from "../../../../../constants/routes";
import { createEventItemAPI } from "../../../../../api/createEventItemAPI";

function IndexView({ stepper, setHide, hide }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const eventId = location.state.data.eventId;
  const auctionId = eventId;

  const [isLoading, setIsLoading] = useState(false);
  // const [hide, setHide] = useState(false);
  const [startDateNew, setStartDate] = useState();
  const [endDateNew, setEndDate] = useState();
  const [eventItemInfo, setEventItemInfo] = useState({
    lotNumber: "",
    shortDescription: "",
    longDescription: "",
    quantity: "",
    unit: "",
    startPrice: "",
    startDateAndTime: "",
    increment: "",
    endDateAndTime: "",
    currency: "",
  });
  const [images, setImages] = useState("");
  const [imagesUploaded, setImagesuploaded] = useState([]);
  const maxNumber = 5;
  const [eventUploadInfo, setEventUploadInfo] = useState({
    document: "",
  });
  const [currencyData, setCurrencyData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [details, setDetails] = useState({});
  const [eventsDetail, updateEventsDetails] = useState({
    apiState: "loading",
    list: [],
  });

  const handleCurrencySelect = (selectedOption) => {
    setSelectedCurrency(selectedOption.value);
  };

  useEffect(() => {
    const currencyArray = Object.entries(currency[0]).map(([key, value]) => ({
      value: key,
      label: value.code,
    }));
    setCurrencyData(currencyArray);
  }, []);

  useEffect(() => {
    EventDetails();
    return () => {
      return false;
    };
  }, [auctionId]);

  const EventDetails = async () => {
    try {
      const payload = { auctionId: auctionId, currency: selectedCurrency };
      const { data } = await getEventDetails(payload);
      setDetails(data);
      if (data) {
        if (data) updateEventsDetails({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setEventItemInfo({
      ...eventItemInfo,
      [event.target.name]: event.target.value,
    });
  };

  //Image Upload Functionality start

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    imageUpload(imageList);
  };
  const imageUpload = async (images) => {
    try {
      setIsLoading(true);
      images.map(async (image, index) => {
        eventUploadInfo.document = image["file"];
        const { data } = await uploadDocumentEvents(eventUploadInfo);
        setImagesuploaded([...imagesUploaded, data.documentId]);
      });
      setIsLoading(false);
      toast("Item Image Uploaded Successfully!");
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.error);
        error.response.data.error.map((errors, index) => {
          toast(errors);
          setIsLoading(false);
        });
      } else if (error.request) {
        toast("Something went wrong!");
      } else {
        console.log("Error", error.message.code);
      }
    }
  };

  const handleUnitChange = (event) => {
    if (event.target.value.match("^[a-zA-Z ]*$") != null) {
      setEventItemInfo({
        ...eventItemInfo,
        [event.target.name]: event.target.value,
      });
    }
  };

  //Image Upload Functionality End

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      eventItemInfo.quantity = Number(eventItemInfo.quantity);
      eventItemInfo.startPrice = Number(eventItemInfo.startPrice);
      eventItemInfo.increment = Number(eventItemInfo.increment);
      // eventItemInfo.eventId = eventId;

      eventItemInfo.lastMinuteBid = "123";
      if (startDateNew != null || startDateNew != undefined) {
        let startDateMoment = moment(startDateNew.$d, "dd/MM/YYYY HH:mm:ss ZZ");
        eventItemInfo.startDateAndTime = startDateMoment.format(
          "YYYY-MM-DD HH:mm:ss"
        );
      } else {
        eventItemInfo.startDateAndTime = moment().format("YYYY-MM-DD HH:mm:ss");
      }
      if (endDateNew != null || endDateNew != undefined) {
        let endDateMoment = moment(endDateNew.$d, "dd/MM/YYYY HH:mm:ss ZZ");
        eventItemInfo.endDateAndTime = endDateMoment.format(
          "YYYY-MM-DD HH:mm:ss"
        );
      } else {
        eventItemInfo.endDateAndTime = moment().format("YYYY-MM-DD HH:mm:ss");
      }

      if (eventItemInfo.currency !== "") {
        eventItemInfo.currency = eventItemInfo.currency;
      } else {
        eventItemInfo.currency = eventsDetail.list.currency;
      }

      eventItemInfo.eventItemImages =
        imagesUploaded.length > 0 ? imagesUploaded : [];
      // console.clear()
      // console.log(eventItemInfo)
      // return true;
      eventItemInfo.startDateAndTime = momentTimezone(
        eventItemInfo.startDateAndTime
      ).utc();
      eventItemInfo.endDateAndTime = momentTimezone(
        eventItemInfo.endDateAndTime
      ).utc();
      setIsLoading(true);
      const { data } = await createEventItemAPI(eventItemInfo, eventId);
      if (data) {
        toast("Event Item Create Successfully!");
        setTimeout(() => {
          //stepper.a11yProps(2);
          setIsLoading(false);
          setHide(false);
        }, 1500);
      }
    } catch (error) {
      if (error.response) {
        toast(error.response.data.error);
      } else if (error.request) {
        toast("Something went wrong!");
      } else {
        console.log("Error", error.message.code);
      }
      setIsLoading(false);
    }

    //  setUserInfo({ email: "", firstname: "", lastname: "", phonenumber: "" , password: "", confirmpassword: ""});
  };

  const label =
    details.eventType === "REVERSE_AUCTION" ? "Decrement" : "Increment";

  return (
    <>
      {isLoading === true && <Loader isLoading={isLoading} />}
      <Box className={classes.accordanHeader}>
        <form onSubmit={handleSubmit}>
          <Box className={classes.accordanContent}>
            <div className={classes.formHead}>
              <h5 className="head">Item Detail</h5>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.label}>
                      Lot Number
                    </Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="text"
                      // placeholder="Auction Title"
                      name="lotNumber"
                      onChange={handleChange}
                      value={eventItemInfo.lotNumber}
                      required
                      isInvalid={eventItemInfo.lotNumber === "" && true}
                    />
                  </Form.Group>
                </Box>
                <Box className={classes.forms}>
                  <Form.Group className="mb-3">
                    <Form.Label className={classes.label}>
                      Short Description(Limit 40 Text)
                    </Form.Label>
                    <Form.Control
                      className={classes.input}
                      as="textarea"
                      name="shortDescription"
                      onChange={handleChange}
                      value={eventItemInfo.shortDescription}
                      style={{ height: "24px" }}
                      maxLength={40}
                      isInvalid={eventItemInfo.shortDescription === "" && true}
                    />
                  </Form.Group>
                </Box>
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.label}>
                      Remark (if any)
                    </Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="text"
                      name="remarks"
                      onChange={handleChange}
                      value={eventItemInfo.remarks}
                    />
                  </Form.Group>
                </Box>
              </div>
              <div className="col-md-6">
                <Box className={classes.forms}>
                  <Form.Label className={classes.label}>
                    Long Description (Limit 250 characters)
                  </Form.Label>
                  <Form.Control
                    className={classes.input}
                    as="textarea"
                    name="longDescription"
                    onChange={handleChange}
                    value={eventItemInfo.longDescription}
                    style={{ height: "130px" }}
                    maxLength={250}
                    isInvalid={eventItemInfo.longDescription === "" && true}
                  />
                </Box>
              </div>
            </div>
            <div className={classes.formHead}>
              <h5 className="heading">Rate and Amount</h5>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.label}>Qty</Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="number"
                      name="quantity"
                      min="1"
                      onChange={handleChange}
                      value={eventItemInfo.quantity}
                      isInvalid={eventItemInfo.quantity === "" && true}
                    />
                  </Form.Group>
                </Box>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Label className={classes.label}>Currency</Form.Label>
                    <Form.Group className="mb-3">
                      <Form.Control
                        className={classes.input}
                        value={details.currency}
                        readOnly
                      />
                    </Form.Group>
                    {/* <Select 
                    name="currency"
                    id="currencySelect"
                    // type="text" 
                    placeholder="Currency"
                    isSearchable
                    options={currencyData}
                    className={classes.input}
                    value={currencyData.find((option) => option.value === selectedCurrency)}
                    onChange={handleCurrencySelect}
                  >
                  {currencyData.map((currency) => (
                    <option key={currency.code} value={currency.code}>
                      {currency.code}
                    </option>
                  ))}
                  </Select> */}
                    {/* <Form.Control
                    className={classes.input}
                    type="text"
                    name="currency"
                    onChange={handleChange}
                    value={eventItemInfo.currency ? eventItemInfo.currency : eventsDetail.list.currency}
                    placeholder="currency"
                    isInvalid={eventItemInfo.currency === '' && eventsDetail.list.currency === '' && true}                    
                  /> */}
                  </div>
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Label className={classes.label}>
                        Start Price
                      </Form.Label>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          className={classes.input}
                          type="number"
                          name="startPrice"
                          min="0"
                          onChange={handleChange}
                          value={eventItemInfo.startPrice}
                          isInvalid={eventItemInfo.startPrice === "" && true}
                        />
                      </Form.Group>
                    </Box>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>Unit</Form.Label>
                  <Form.Control
                    className={classes.input}
                    type="text"
                    name="unit"
                    placeholder="Unit"
                    onChange={handleUnitChange}
                    value={eventItemInfo.unit}
                    isInvalid={eventItemInfo.unit === "" && true}
                  />
                </Form.Group>
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.label}>{label}</Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="number"
                      name="increment"
                      onChange={handleChange}
                      value={eventItemInfo.increment}
                      isInvalid={eventItemInfo.increment === "" && true}
                      inputMode="decimal"
                      step={"0.01"}
                      min={0.0}
                    />
                  </Form.Group>
                </Box>
              </div>
            </div>
            <div className={classes.formHead}>
              <h5 className="time">Timing</h5>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Label className={classes.label}>
                  Start Date & Time{" "}
                  <span style={{ color: "red", fontWeight: "800" }}>{`>`}</span>{" "}
                  {moment(details.bidStartDateTime).format(
                    "MMM-DD-YYYY HH:mm:ss"
                  )}
                </Form.Label>
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="datePicker"
                    renderInput={(props) => <TextField {...props} />}
                    value={startDateNew}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    minDate={moment(new Date()).format("MMM-DD-YYYY HH:mm:ss")}
                    isInvalid={startDateNew === "" && true}
                  />
                </LocalizationProvider>
              </div>

              <div className="col-md-6 mob-margin">
                <Form.Label className={classes.label}>
                  End Date & Time{" "}
                  <span style={{ color: "red", fontWeight: "800" }}>{`<`}</span>{" "}
                  {moment(details.bidEndDateTime).format(
                    "MMM-DD-YYYY HH:mm:ss"
                  )}
                </Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="datePicker"
                    renderInput={(props) => <TextField {...props} />}
                    value={endDateNew}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    minDate={new Date()}
                    isInvalid={endDateNew === "" && true}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className={classes.formHead}>
              <h5 className="headings">Image and Document</h5>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className={classes.uploader}>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg", "jpeg", "png"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      // write your building UI
                      <>
                        <div className="upload__image-wrapper row">
                          <div className="col-md-4">
                            <Button
                              variant="contained"
                              component="label"
                              className={classes.upload}
                              sx={{
                                display: "block",
                                textAlign: "center",
                                padding: "1.5em",
                              }}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <BsUpload className={classes.uploadIcon} />
                              <br />
                              <Box>
                                <Typography
                                  sx={{
                                    color: "#000",
                                    fontSize: "12px",
                                    marginTop: "10px",
                                  }}
                                >
                                  Choose Image to upload
                                </Typography>
                              </Box>

                              {/* <input hidden accept="image/*" multiple type="file" /> */}
                            </Button>
                          </div>
                          {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                          <div className="col-md-8">
                            <div className="row">
                              {imageList.map((image, index) => (
                                <div className="col-md-2">
                                  <div key={index} className="image-item">
                                    <img
                                      src={image["data_url"]}
                                      alt=""
                                      width="100"
                                      className={classes.imagesStyle}
                                    />
                                    {/* <div className="image-item__btn-wrapper">
                                    <button onClick={() => onImageUpdate(index)}>
                                      Update
                                    </button>
                                    <button onClick={() => onImageRemove(index)}>
                                      Remove
                                    </button>
                                  </div> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        {errors && (
                          <div>
                            {errors.maxNumber && (
                              <span>
                                Number of selected images exceed maxNumber
                              </span>
                            )}
                            {errors.acceptType && (
                              <span>Your selected file type is not allow</span>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </ImageUploading>
                </div>
              </div>
            </div>
          </Box>
          <Box className={classes.saveNextBtn}>
            <Button
              className={classes.next}
              onClick={() => {
                setHide(false);
              }}
            >
              Cancel
            </Button>
            {hide === true && (
              <>
                <Button
                  className={classes.next}
                  type="submit"
                  // onClick={() => {
                  //   stepper.a11yProps(2);
                  // }}
                  sx={{ marginLeft: "1em" }}
                >
                  Save
                </Button>
                <Button
                  className={classes.next}
                  sx={{ marginLeft: "1em" }}
                  onClick={() => {
                    navigate(routes.dashboard);
                  }}
                >
                  Go To Dashboard
                </Button>
              </>
            )}
          </Box>
        </form>
      </Box>
      {/* {console.log("details.eventType", details.eventType)} */}
      {/* <ListView eventType={details.eventType} /> */}
    </>
  );
}

export default IndexView;
