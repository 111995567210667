import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  signin: { justifyContent: "center", alignItems: "center",marginTop:'10em' },
  signcard: { margin: "4em auto" ,boxShadow:'0px 0px 66px -15px #ccc !important'},
  form: { width: "100% !important" },
  forms: { marginTop: "1em" },
  input: { borderRadius: "0px" ,color:'#3F4043',backgroundColor:'#F2F2F24D'},
  label:{color:'#7E8184'},
  forgot: { margin: "1em 0em !important", fontSize: "14px !important" },
  twitter: {
    backgroundColor: "#2ABFF4 !important",
    color: "#fff !important",
    borderRadius: "1px !important",
    width: "90% !important",
  },
  facebook: {
    backgroundColor: "#3E75C9 !important",
    color: "#fff !important",
    borderRadius: "1px !important",
    width: "90% !important",
  },
}));

export default useStyles;
