import React, { useEffect, useState } from "react";
import { Avatar, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import routes from "../../../constants/routes";
import { Form } from "react-bootstrap";
import {
  getToken,
  removeToken,
  getUserData,
  timeZones,
  localTimeZone,
  getTimeZone,
  removeLocalTimeZone,
} from "../../../common";
import useStyles from "./dashboardMenu.style";
import momentTimezone from "moment-timezone";

function DashboardMenu() {
  const yy = window.location.href;

  const classes = useStyles();
  const navigate = useNavigate();
  const userData = getUserData() ?? {};
  const timeZonesList = timeZones();
  const [menutype, SetMenuType] = useState(localStorage.getItem("menutype"));
  const [currentTimeZones, SetCurrentTimeZone] = useState(
    localStorage.getItem("currentTimeZone")
      ? localStorage.getItem("currentTimeZone")
      : localTimeZone
  );

  const location = useLocation();

  const [active, SetActive] = useState("");
  // useEffect(() => {
  //   const value = localStorage.getItem('menutype')
  //  SetMenuType(value)
  // }, [])

  const colorChange = [
    { name: "Dashboard", navigate: routes.dashboard },
    {
      name: "Auctions",
      navigate: routes.dashboardAuction,
    },
    userData.role != "BUYER" && { name: "Buyers", navigate: routes.dashBuyer },
    userData.role === "ADMIN" && {
      name: "Sellers",
      navigate: routes.dashSeller,
    },

    (userData.role === "ADMIN" || userData.role === "SELLER") && {
      name: "Live Auctions",
      navigate: routes.liveAuctions,
    },

    { name: "Profile", navigate: routes.profile },
  ];
  return (
    <div>
      <div className={classes.dashboards}>
        <div className="row">
          <div className="col-md-12">
            <Box className={classes.menu}>
              <div className="row dashMenu align-items-center justify-content-start">
                {colorChange
                  .filter((x) => x !== false)
                  .map((d, i) => {
                    return (
                      <div className="col-lg mobMenu" key={i}>
                        <Typography
                          className={classes.menuHeading}
                          onClick={() => {
                            navigate(d.navigate);
                            SetActive(i);
                          }}
                          style={{
                            color: yy.includes(d.navigate) ? "#2D55EB" : "#000",
                          }}
                          sx={{ fontSize: "13px" }}
                        >
                          {d.name}
                        </Typography>
                      </div>
                    );
                  })}
                {userData.role === "SELLER" && (
                  <div className="col-lg mobMenu">
                    <div className="">
                      {" "}
                      {/* mobSideMenu */}
                      <Box
                        className="Buttons mobTopBar"
                        sx={{ marginRight: "2em" }}
                      >
                        <Button
                          onClick={() => {
                            navigate(routes.createvent);
                          }}
                          className="btn btn-group view ml-3"
                        >
                          Create Auction
                        </Button>
                      </Box>
                    </div>
                  </div>
                )}
                {location.pathname === "/dashboard" && (
                  <div className="col-lg mobMenu">
                    {" "}
                    {/* mobSideMenu */}
                    <div className="Buttons mobTopBar">
                      <Box
                        sx={{ marginRight: "2em", sm: { marginRight: "0" } }}
                        className="mobTopBar"
                      >
                        <Form.Select
                          style={{ width: "10em", fontSize: "13px" }}
                          aria-label="Default select example"
                          className={classes.select}
                          name="eventType"
                          onChange={(event) => {
                            SetCurrentTimeZone(event.target.value);

                            localStorage.setItem(
                              "currentTimeZone",
                              event.target.value
                            );
                            removeLocalTimeZone();
                          }}
                          value={currentTimeZones}
                        >
                          <option>Select Zones</option>
                          {timeZonesList.map((zones, index) => {
                            return <option value={zones}>{zones}</option>;
                          })}
                        </Form.Select>
                      </Box>
                    </div>
                  </div>
                )}
                <div className="col-lg mobMenu">
                  {" "}
                  {/* mobSideMenu */}
                  {userData.role === "BOTH" && (
                    <div className="Buttons mobTopBar">
                      <Form.Select
                        aria-label="Default select example"
                        className={classes.select}
                        name="eventType"
                        onChange={(event) => {
                          SetMenuType(event.target.value);
                          localStorage.setItem("menutype", event.target.value);
                        }}
                        value={menutype}
                      >
                        <option value="SELLER">Seller</option>
                        <option value="BUYER">Buyer</option>
                      </Form.Select>
                    </div>
                  )}
                  {userData.role === "SELLER" && (
                    <Box
                      className="Buttons mobTopBar"
                      sx={{ marginRight: "2em" }}
                    >
                      <Button className="btn btn-group view ml-3">
                        SELLER
                      </Button>
                    </Box>
                  )}
                  {userData.role === "BUYER" && (
                    <Box
                      className="Buttons mobTopBar"
                      sx={{ marginRight: "2em" }}
                    >
                      <Button className="btn btn-group view ml-3">BUYER</Button>
                    </Box>
                  )}
                  {userData.role === "ADMIN" && (
                    <Box
                      className="Buttons mobTopBar"
                      sx={{ marginRight: "2em", fontSize: "13px" }}
                    >
                      <Button
                        className="btn btn-group view ml-3"
                        style={{ fontSize: "13px !important" }}
                        sx={{ fontSize: "13px !important" }}
                      >
                        ADMIN
                      </Button>
                    </Box>
                  )}
                </div>
              </div>
            </Box>
          </div>
          {/* <div className="col-md-3 m-auto"></div> */}
        </div>
      </div>
    </div>
  );
}

export default DashboardMenu;
