import "react-data-table-component-extensions/dist/index.css";

import React from "react";
import moment from "moment";
import lodash from "lodash";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WebStoriesIcon from "@mui/icons-material/WebStories";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as XLSX from "xlsx";
import { FaRegFileExcel } from "react-icons/fa";
import {
  getToken,
  getUserData,
  getTimeZone,
  localTimeZone,
  localSetTimeZone,
} from "../../../../common";
import useStyles from "../dashAuction.style";
import eventDetailsAPI from "../../../../api/adminAPI/eventDetailsAPI";

function AuctionViewBuyer({
  Bidlog,
  BidlogSecond,
  eventId,
  AuctionTable,
  changeView,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [tableEventItemsData, setTableEventItemsData] = useState([]);
  const [eventData, setEventData] = useState([]);

  const eventsDetails = async (eventId) => {
    try {
      const { data } = await eventDetailsAPI(eventId);

      if (data) {
        let id = 1;
        const eventsData = data.auctionLotsDetails.map((eventItem, index) => {
          return {
            id: id++,
            lotNumber: eventItem.lotNumber,
            quantity: eventItem.quantity,
            unit: eventItem.unit,
            startPrice: eventItem.startPrice,
            rank: eventItem.rank,
            increment: eventItem.increment,
            amount: eventItem.amount,
            startDateAndTime: moment(eventItem.startDateAndTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
            endDateAndTime: moment(eventItem.endDateAndTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
            eventItemId: eventItem.eventItemId,
          };
        });

        setTableEventItemsData(eventsData);
        setEventData(data.auctionDetails);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    eventsDetails(eventId);
    return () => {
      return false;
    };
  }, [eventId]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const columns = [
    {
      name: "Auction Title",
      selector: "auctionTitle",
      width: "250px",
      cell: (data) => {
        return (
          <Tooltip
            title={data.auctionTitle}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{data.auctionTitle}</span>
          </Tooltip>
        );
      },
      sortable: false,
    },
    {
      name: "event Number",
      selector: "eventNumber",
      cell: (data) => data.eventNumber,
      sortable: false,
    },
    {
      name: "Description",
      width: "250px",
      cell: (data) => {
        return (
          <Tooltip
            title={data.description}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{data.description}</span>
          </Tooltip>
        );
      },
      sortable: false,
    },
    {
      name: "Start Date & Time",
      selector: "startDT",
      cell: (data) => data.startDT,
      sortable: false,
    },
    {
      name: "End Date & Time",
      selector: "endDT",
      cell: (data) => data.endDT,
      sortable: false,
    },
    {
      name: "Action",
      cell: (data) => (
        <div>
          <Button
            aria-describedby={data.eventId}
            variant="contained"
            onClick={(event) => {
              handleClick(event, data.eventId);
            }}
          >
            <MoreVertIcon />
          </Button>
          <Popover
            id={data.eventId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 1, display: "grid", px: 2 }}>
              <div
                className={classes.viewBtn}
                onClick={() => changeView("AuctionDetailLogs")}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <WebStoriesIcon
                  style={{
                    color: "#2D55EB",
                    paddingRight: "5px",
                    marginRight: ".4rem",
                  }}
                />
                Logs
              </div>
            </Typography>
          </Popover>
        </div>
      ),
    },
  ];
  const nextColumns = [
    {
      name: "Sr.",
      cell: (data) => String(data.id),
      sortable: true,
    },
    {
      name: "Lot No",
      cell: (data) => String(data.lotNumber),
      sortable: true,
    },

    {
      name: "Qty",
      cell: (data) => String(data.quantity),
      sortable: true,
    },
    {
      name: "Unit",
      cell: (data) => String(data.unit),
      sortable: true,
    },
    {
      name: `Start Price ${eventData.currency}`,
      cell: (data) => String(data.startPrice),
      sortable: true,
      width: "150px",
    },
    {
      name: `${
        eventData.eventType === "FORWARD_AUCTION" ? "Increment" : "Decrement"
      } ${eventData.currency}`,
      cell: (data) => String(data.increment),
      sortable: true,
      width: "150px",
    },
    {
      name: "Start Date & Time",
      cell: (data) => String(data.startDateAndTime),
      sortable: true,
      width: "180px",
    },
    {
      name: "End Date & Time",
      cell: (data) => String(data.endDateAndTime),
      sortable: true,
      width: "180px",
    },
    {
      name: "Amount",
      cell: (data) => String(data.amount || "-"),
      sortable: true,
      width: "120px",
    },
    {
      name: "Rank",
      cell: (data) => String(data.rank),
      sortable: true,
      width: "120px",
    },
    {
      name: "Action",
      sortable: true,
      cell: (data) => (
        <div>
          <Button
            className="btn btn-group"
            onClick={() => {
              BidlogSecond(data.eventItemId);
            }}
          >
            Bidlog
          </Button>
        </div>
      ),
    },
  ];

  const data = [
    {
      auctionTitle: eventData.auctionTitle,
      description: eventData.description,
      startDT: moment(eventData.bidStartDateTime)
        .tz(getZone)
        .format("DD/MM/YYYY hh:mm:ss"),
      endDT: moment(eventData.bidEndDateTime)
        .tz(getZone)
        .format("DD/MM/YYYY hh:mm:ss"),
      numLot: eventData.totalLots,
      eventNumber: eventData.eventNumber,
    },
  ];

  const auctionDetailsExportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.auctionTitle,
      columns.eventNumber,
      columns.description,
      columns.startDT,
      columns.endDT,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "auctions-name.xlsx");
  };

  const auctionLotsListingExportToXLSX = (
    tableData,
    tableHeader,
    auctionData,
    auctionColumns
  ) => {
    // =========== auction Data Columns
    const auctionDeatilsHeaderColumns = lodash.map(
      auctionColumns,
      ({ name }) => {
        if (name !== "Action") {
          return name;
        }
      }
    );
    const auctionDetailsDataColumns = lodash.map(auctionData, (columns) => [
      eventData.eventNumber,
      eventData.description,
      moment(eventData.bidStartDateTime)
        .tz(getZone)
        .format("DD/MM/YYYY hh:mm:ss"),
      moment(eventData.bidEndDateTime)
        .tz(getZone)
        .format("DD/MM/YYYY hh:mm:ss"),
      eventData.totalLots,
      eventData.participants,
      eventData.totalBuyers,
      eventData.totalUnBidedLots,
    ]);

    // ===================== Auction Lots Listing Export to XLSX =====================
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => {
      return [
        columns.id,
        columns.lotNumber,
        columns.quantity,
        columns.unit,
        columns.startPrice,
        columns.increment,
        columns.startDateAndTime,
        columns.endDateAndTime,
        columns.amount,
        columns.rank,
      ];
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([
      auctionDeatilsHeaderColumns,
      ...auctionDetailsDataColumns,
      "",
      "",
      headerColumns,
      ...dataColumns,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "lots-listing.xlsx");
  };

  const AuctionDetailsExportButton = () => (
    <Button onClick={() => auctionDetailsExportToXLSX(data, columns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  const AuctionLotsListingExportButton = () => (
    <Button
      onClick={() =>
        auctionLotsListingExportToXLSX(
          tableEventItemsData,
          nextColumns,
          data,
          columns
        )
      }
    >
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  return (
    <>
      <div className="AuctionTable ">
        <div className="AuctionView">
          <h3>
            <Button sx={{ color: "#000" }} onClick={AuctionTable}>
              <ArrowBackIcon />
            </Button>
            Auctions Name - {eventData.auctionTitle}
          </h3>
          <DataTableExtensions
            print={false}
            export={false}
            exportHeaders={false}
            columns={columns}
            data={data}
          >
            <DataTable
              noHeader={false}
              defaultSortField="id"
              defaultSortAsc={false}
              pagination={false}
              highlightOnHover
              className="DataTable"
              actions={<AuctionDetailsExportButton />}
            />
          </DataTableExtensions>
        </div>
      </div>
      <div className="AuctionTable mt-5">
        <h3>Lots Listing</h3>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={nextColumns}
          data={tableEventItemsData}
        >
          <DataTable
            noHeader={false}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={false}
            highlightOnHover
            className="DataTable"
            actions={<AuctionLotsListingExportButton />}
          />
        </DataTableExtensions>
      </div>
    </>
  );
}

export default AuctionViewBuyer;
