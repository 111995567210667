import React from "react";

function TermOfUse() {
  return (
    <div className="container" style={{ marginTop: "9em" }}>
      <div className="termHead">
        <h2>Bidder Terms and Conditions</h2>
      </div>
      <div className="termsContent">
        <p>
          Please read these Bidder Terms and Conditions ("Bidder Terms")
          carefully. By accepting these Bidder Terms or using the Services
          (defined below) to participate in any third-party auction made
          available via the Services ("Auction"), you agree to be legally bound
          by these Bidder Terms and all terms incorporated by reference. If you
          do not agree to these Bidder Terms, you may not use the Services to
          participate in any Auction.
        </p>
        <p>
          V3Bid.com, make Auctions available to Bidder Users ("you") via our web
          portal (the "Services"). Your use of the Services to participate in
          any Auction provided by a third-party auctioneer ("Auctioneer") is
          governed by these Bidder Terms in addition to our Website Terms and
          Conditions, which are incorporated herein by reference. Please note
          that additional terms and conditions established by an Auctioneer
          ("Auctioneer Terms") may apply to your use of the Auctioneer's website
          or your purchase of any items in connection with an Auction.
        </p>
        <div className="termhead">
          <h4>Summary of Our Services</h4>
          <ul>
            <li>
              Our Services provide a venue that allows Auctioneers to provide
              online auction content. The Services allow you to view such online
              auction content and/or participate in auction sales, but we are
              not an Auctioneer. Therefore, we are not a party to the contract
              for sale and purchase entered into between you and Auctioneer.
            </li>
            <li>
              We are not in control of the information made available by
              Auctioneers, which you access via the Services. We cannot,
              therefore, make any representations, warranties, or guarantees in
              connection with what the Auctioneer may offer for sale through the
              Services.
            </li>
            <li>
              We do not charge you for your use of the Services (however, some
              services that require a subscription fee may be subject to
              additional terms and conditions). We are paid by Auctioneers to
              provide the Services to potential bidders.
            </li>
            <li>
              If you have any questions regarding these Bidder Terms, please
              contact us at V3Bid.com.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <h4>Entities; Amendments; Privacy Policy</h4>
          <ul>
            <li>
              If you are using the Services on behalf of an Entity, you
              represent and warrant that you have the authority to (i) act on
              behalf of the Entity; (ii) bind the Entity to these Bidder Terms;
              and (iii) bind the Entity to any contract or arrangement entered
              into as a result of your use of the Services (including any
              agreement entered into with an Auctioneer). You acknowledge and
              agree that such Entity will be responsible for your violation of
              these Bidder Terms. You further agree that where you do not have
              the authority to act on behalf of the Entity and bind the Entity
              in accordance with (i) to (iii) above, you will remain personally
              liable to V3Bid.com for any claims arising from or relating to
              these Bidder Terms and any agreement you enter into with an
              Auctioneer via the Services.
            </li>
            <li>
              V3Bid.com reserves the right to amend these Bidder Terms at any
              time, in its sole discretion, by providing notice to you of any
              amended Bidder Terms. Notification of any amendments shall be
              effected by posting the amended Bidder Terms via the Services and
              updating the "Effective Date" above. In some cases, we may provide
              you with additional notice of any such amendments, such as by
              notifying you via e-mail. The amended Bidder Terms will apply
              prospectively to any access or use of the Services following the
              posting of such amended Bidder Terms via the Services. Your
              continued use of the Services following the posting of any amended
              Bidder Terms will confirm your acceptance of such terms.
            </li>
            <li>
              Please read our Privacy Policy for more information about how we
              collect, use, and share your information.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <h4>Services Provided by V3Bid.com to Bidder Users</h4>
          <ul>
            <li>
              Subject to the Bidder User's compliance with its obligations under
              these Bidder Terms, V3Bid.com may provide some or all of the
              following services from time to time to the Bidder User:
            </li>
            <li>
              Access to and participation in “Auctions” (including Pre Pid ,
              Tender Auctions). A “Auctions” is an automated Auction, which is
              administered automatically via the Services in accordance with
              parameters established by an Auctioneer and without the Auctioneer
              calling the bids. Auctions can take place via the Services and the
              Auctioneer will organize the sale (such organization includes (but
              is not limited to) the Auctioneer describing and cataloging the
              lots, posting the lots on the Services and dealing with post-sale
              matters). Auctions bids are placed over a defined time period
              (“Bidding Period”). Bidder Users can view the highest current bid.
              The highest bid, (being the greatest bid that meets or is in
              excess of any reserve bid), at the end of the Bidding Period is
              successful at the Auctioneer’s discretion.
            </li>
            <li>
              A Auctions:
              <ul>
                <li>
                  Will either be open to all registered Bidder Users who have
                  registered and been approved by V3Bid.com and/or the
                  Auctioneer in accordance with above or restricted to a defined
                  group of Bidder Users according to the Auctioneer's chosen
                  criteria
                </li>
                <li>
                  May also be a ‘private treaty sale’ or ‘tender sale’ or Pre
                  Bid Sale, where Bidder Users cannot see the value of the bids
                  placed by other Bidder Users.
                </li>
              </ul>
            </li>
            <li>
              Access to and participation in "Live Webcast Auctions”. Live
              webcast auctions are traditional auction sales conducted by an
              auctioneer that take place at a physical location. A “Live Webcast
              Auction” is an Auction that allows the Bidder User to participate
              in such Auction either by placing Max Bids (defined below) in
              advance of the Auction or, when available, during the Auction.
              Access to Live Webcast Auctions is only open to Bidder Users who
              have registered and been approved in accordance with various
              Sections.
            </li>
            <li>
              Communication of "Max Bids" to the Auctioneer.
              <ul>
                <li>
                  Max Bids are the highest amount the Bidder User is willing to
                  bid on a specific lot. Max Bids provide a method for Bidder
                  Users to submit automatic bids via the Services to the
                  applicable Auctioneer. Max Bids allow Bidder Users to
                  automatically increase their bid amount in order to exceed a
                  competing bid up to a fixed maximum amount set by such Max
                  Bid.
                </li>
                <li>
                  In Live webcast auctions, V3Bid.com automatically communicates
                  the Bidder User’s bid up to the Max Bid to the Auctioneer on
                  behalf of the Bidder User. Some Auctioneers may have access to
                  Max Bids in advance of the Live webcast auction and may choose
                  to print out the Max Bid in advance of the auction and
                  administer the Max Bid him/herself.
                </li>
                <li>
                  In Auctions, Max Bids are administered automatically by the
                  Services. Please note that some Auctioneers have access to Max
                  Bids.
                </li>
                <li>
                  The following terms apply to Max Bids:
                  <ul>
                    <li>
                      Max Bids are at the risk of the Bidder User. Subject
                      always to relevant Section/s, V3Bid.com accepts no
                      responsibility for and shall not be liable for the
                      communication and placement of Max Bids including (but not
                      limited to) any direct or indirect loss that may occur as
                      a result of a failure to communicate the Max Bid to the
                      Auctioneer due to any disruption or disturbance to the
                      wider internet; or for any other reason.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              V3Bid.com will at no time be an agent for the Bidder User and/or
              the Auctioneer
            </li>
            <li>
              Communication of the Max Bid through the Services does not
              constitute or guarantee the Auctioneer's acceptance of the Max
              Bid.
            </li>
            <li>
              V3Bid.com reserves the right to change, modify, or remove all or a
              portion of the Services at any time, with or without notice to the
              Bidder User, and without providing any reason. Additionally,
              V3Bid.com and/or any Auctioneer may terminate, restrict, and/or
              refuse the Bidder User's use of the Services, as well as remove or
              alter any content, at any time and for any reason.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <h4>Registration and user credentials</h4>
          <ul>
            <li>
              The Bidder User acknowledges and agrees to the following terms and
              conditions regarding their use of V3Bid.com services ("Services").
              V3Bid.com reserves the right to modify, change, or remove all or a
              portion of the Services at any time with or without notice to the
              Bidder User and without reason.
            </li>
            <li>
              Access to Services
              <ul>
                <li>
                  The Bidder User must be 18 years of age or older to access the
                  Services. V3Bid.com and/or any Auctioneer may terminate,
                  restrict, and/or refuse the Bidder User's use of the Services
                  and/or remove or alter any content at any time and for any
                  reason.
                </li>
              </ul>
            </li>
            <li>
              Registration and User Credentials
              <ul>
                <li>
                  To participate in any Auction using the Services, the Bidder
                  User must register with and be approved by V3Bid.com and the
                  Auctioneer. The Bidder User's registration with V3Bid.com will
                  require the Bidder User to agree to abide by these Bidder
                  Terms. The Bidder User's agreement to these Bidder Terms does
                  not constitute V3Bid.com approval of the Bidder User, and
                  V3Bid.com may terminate, restrict, and/or refuse the Bidder
                  User's use of the Services without notice.
                </li>
                <li>
                  The Bidder User will supply all information requested by
                  V3Bid.com and/or the Auctioneer to register to use the
                  Services and represents, warrants, and covenants to V3Bid.com
                  and the relevant Auctioneer that all such information is
                  complete, truthful, and accurate. It is the responsibility of
                  the Bidder User to update and maintain changes to such
                  information. V3Bid.com and the relevant Auctioneer are
                  entitled to rely on any information provided by the Bidder
                  User.
                </li>
                <li>
                  The Auctioneer reserves the right to refuse registration
                  and/or approval to any person for any reason, including if a
                  Bidder's conduct poses a risk to the integrity or reputation
                  of V3Bid.com and/or an Auctioneer's Services. Such refusal
                  shall be in the absolute discretion of VeBid.com and/or the
                  Auctioneer.
                </li>
                <li>
                  Upon registration via the Services, a Bidder User will be
                  allocated a unique user ID and password, known as "User
                  Credentials." The Bidder User's access to the Services must
                  only be achieved using these User Credentials. The Bidder User
                  must ensure that User Credentials are not disclosed to any
                  other person. If the Bidder User believes that there has been
                  any breach of security such as the disclosure, theft, or
                  unauthorized access or use of the User Credentials, the Bidder
                  User must immediately notify V3Bid.com by email.
                </li>
                <li>
                  The Bidder User shall be liable for any authorized or
                  unauthorized access or use of the Services via the User
                  Credentials and for any misuse of the User Credentials.
                </li>
              </ul>
            </li>
            <li>Responsibilities of the Bidder User</li>
            <li>
              The Bidder User represents, warrants, and covenants that their
              access to and use of the Services will not cause V3Bid.com any
              loss, damage, or any liability whatsoever, or cause any
              interference, interruption, or disruption to V3Bid.com, or cause
              the Services to become less efficient, less effective, or less
              functional.
            </li>
            <li>
              The Bidder User shall not impose unreasonable stress, burden, or
              demand on the Services, bypass measures to prevent or limit access
              to or use of the Services, or copy, reproduce, modify, communicate
              to the public, or make derivative product from or publicly display
              any material available on or through the Services other than in
              order to bid for goods using the Services.
            </li>
            <li>
              The Bidder User shall not create a database in electronic or
              structured manual form by systematically and/or regularly
              downloading, caching, printing, and/or storing the material
              available on or through the Services (by spidering or otherwise)
              or enable or permit others to do so.
            </li>
            <li>
              The Bidder User is strictly prohibited from presenting, using or
              communicating any information or language that is offensive,
              harmful, inaccurate, defamatory, deceptive, infringing or
              otherwise unlawful or illegal. Moreover, the Bidder User is
              obligated to use the Services in full compliance with all
              applicable laws.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <h4>Prohibited Activities</h4>
          <ul>
            <li>
              The Bidder User is strictly prohibited from engaging in bid
              sniping, whether through manual or automated means, during
              Auctions. V3Bid.com and/or the Auctioneer may suspend or revoke
              the Bidder User's access to the Services and extend the relevant
              Auction time in response to any attempted bid sniping.
            </li>
            <li>
              Similarly, shill bidding, which involves artificially inflating
              the price of a product being sold through collusion with the
              seller, is strictly prohibited on the Services, whether through
              manual or automated means. V3Bid.com and/or the Auctioneer may
              suspend or revoke the Bidder User's access to the Services and
              extend the relevant Auction time in response to any attempted
              shill bidding. However, reserve-maintenance bids made by the
              Auctioneer, the seller, or an agent of either during a reserve
              auction will not be considered shill bidding.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <h4>Duration of Services</h4>
          <ul>
            <li>
              The Bidder User's registration, approval, and right to use the
              Services apply on an auction-by-auction basis, although an
              Auctioneer may grant approval for participation in multiple
              auctions at once, in their absolute discretion.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <h4>Services as a Venue</h4>
          <ul>
            <li>
              V3Bid.com serves as a venue through which Bidder Users may
              participate in Auction sales conducted by Auctioneers. However,
              V3Bid.com is not an online auctioneer itself, and does not act as
              an agent for any party involved in the sale and purchase of items
              through the Services. V3Bid.com shall not be joined as a party to
              the contract for sale and purchase entered into between the
              Auctioneer (and/or its principal) and the Bidder User.
            </li>
            <li>
              No joint venture, franchise, or partnership exists between
              V3Bid.com and any Bidder User or Auctioneer.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <h4>Charges</h4>
          <ul>
            <li>
              V3Bid.com does not charge Bidder Users for accessing or using the
              Services, except for certain additional service offerings that may
              be subject to additional terms and conditions.
            </li>
            <li>
              Auctioneers may charge Bidder Users for Auction bids or other
              related services, and may require a specified deposit amount from
              Bidder Users. It is the responsibility of the Bidder User to
              ensure that they are aware of any charges, deposits, or commission
              arrangements established by an Auctioneer before submitting a bid
              or incurring any liability.
            </li>
            <li>
              Payments, refunds, and deposits are solely the responsibility of
              the Auctioneer, and V3Bid.com will not be involved in or
              responsible for such transactions.
            </li>
            <li>
              The Auctioneer Terms govern the bidding process for each auction,
              and the Bidder User is bound to comply with them. By placing a
              bid, the Bidder User is entering into a legally binding contract
              with the Auctioneer to purchase the item. It is the Bidder User's
              responsibility to understand and abide by the Auctioneer Terms, as
              well as any implied terms required by law. The Bidder User should
              not participate in an auction where they are the Auctioneer or
              encourage others to do so on their behalf. Additionally, the
              highest bidder who meets the reserve price is bound by law to
              complete the transaction. If the Bidder User uses the Services to
              submit commissions or Max Bids, they agree to these Bidder Terms
              in addition to any Auctioneer Terms.
            </li>
            <li>
              From time to time, the Auctioneer may use different auction
              methods, including Piecemeal Selling, Options, and Grouping. In
              Piecemeal Selling, the Bidder User bids on a lot of multiple
              items, and if they win the bid, they must pay the per-unit price
              multiplied by the number of items in the lot. In Options, the
              Auctioneer may offer the Bidder User the option to purchase
              additional lots at the same price as the successful bid or offer
              other purchase options. In Grouping, the Auctioneer offers a group
              of lots to the Bidder User as one lot, and if they win the bid,
              they must buy the entire group of lots. The purchase price does
              not include the buyer's premium or taxes, which may be charged by
              the Auctioneer.
            </li>
            <li>
              V3Bid.com makes no representation or warranty for any lot offered
              for sale through the Services or otherwise. The Auctioneer is
              solely responsible for any Representation made regarding the
              quality, description, fitness for purpose, truth or accuracy of
              statements, authenticity or provenance, legality, title, or
              liability to complete the transaction. V3Bid.com is not
              responsible for the safety or legality of any lot or for any harm
              that may result from the Bidder User's use of the Services.
              V3Bid.com does not warrant that the Services are free from viruses
              or other harmful materials that could infect the Bidder User's
              Computer System.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <h4>Force Majeure</h4>
          <ul>
            <li>
              V3Bid.com shall not be held liable for any delays or failures
              attributable to any cause beyond its reasonable control.
            </li>
            <li>
              Failures of telecommunications networks, the Internet, and/or
              V3Bid.com or its subcontractor's computer network and systems, and
              any website operated by an Auctioneer shall be deemed to be events
              beyond V3Bid.com reasonable control.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <h4>Third Party Rights</h4>
          <ul>
            <li>
              This agreement is intended for the benefit of V3Bid.com and the
              Bidder User, and where expressly provided, the Auctioneer and
              V3Bid.com affiliates and subcontractors.
            </li>
            <li>
              Upon acceptance of these Bidder Terms, Auctioneers and affiliates
              will have the right to enforce these Bidder Terms against the
              Bidder User as a third party beneficiary thereof.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <h4>Entire Agreement</h4>
          <ul>
            <li>
              These Bidder Terms, together with any terms incorporated herein by
              reference, constitute the entire agreement and understanding of
              the parties and supersede any previous agreement between the
              parties relating to its subject matter.
            </li>
            <li>
              All terms and conditions which may be implied by law are hereby
              excluded to the fullest extent permitted by law.
            </li>
            <li>
              Each party acknowledges and agrees that it does not rely on any
              statement not contained within these Bidder Terms.
            </li>
            <li>
              The agreement shall operate effectively without any provision
              struck out by law.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <h4>Assignment</h4>
          <ul>
            <li>
              V3Bid.com may assign, transfer, charge, or deal in any other
              manner with this agreement or any of its rights under it.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <h4>Applicable Law; Arbitration</h4>
          <ul>
            <li>
              Any dispute arising from these Bidder Terms or the use of the
              Services shall be arbitrated, except for disputes in which either
              party seeks equitable and other relief for the alleged unlawful
              use of intellectual property.
            </li>
            <li>
              The parties agree to notify each other in writing of any dispute
              within thirty (30) days of when it arises.
            </li>
            <li>
              The arbitration will occur in Udaipur, Rajasthan - India and be
              conducted confidentially by a single arbitrator in accordance with
              the rules of Indian Arbitration Act.
            </li>
            <li>
              The state or federal courts in Udaipur, Rajasthan - India will
              have exclusive jurisdiction over any appeals of an arbitration
              award and over any suit between the parties not subject to
              arbitration.
            </li>
            <li>
              The arbitrator has the authority to grant any remedy that would
              otherwise be available in court.
            </li>
          </ul>
        </div>
        <div className="termhead">
          <p>
            Any dispute between the parties will be governed by these Bidder
            Terms and the laws of India and applicable Indian law, without
            giving effect to any conflict of laws principles that may provide
            for the application of the law of another jurisdiction.
          </p>
        </div>
        <div className="termhead">
          <p>
            The parties agree not to commence against each other a class action,
            class arbitration, or representative action or proceeding, whether
            heard in arbitration or in court.
          </p>
        </div>
      </div>
      <div className="termHead">
        <h2>Website Terms and Conditions </h2>
        <p>
          The following are the Website Terms and Conditions that establish a
          binding agreement between the user and V3Bid.com, Inc. and its
          affiliated companies. These terms apply to the user's access and use
          of the website and the auction-related services provided via the
          website. By checking the box indicating acceptance of the terms or by
          accessing or using the services, the user agrees to be legally bound
          by the terms and all terms incorporated by reference. If the user does
          not agree to these terms, the user must stop using the services. The
          auctioneers may have their own terms, which the user may need to
          comply with, depending on the user's use of the services. In
          particular, the user may need to agree to the auctioneer's or seller's
          terms when the user bids at an auction. V3Bid.com is not responsible
          for any disputes with any auctioneer or seller.
        </p>
        <p>
          The services provided by V3Bid.com offer a venue for third-party
          auctioneers and sellers to provide online auction content. The
          services allow the user to view such online auction content and/or
          participate in auction sales, but V3Bid.com is not an online
          auctioneer or seller. Therefore, V3Bid.com is not a party to the
          contract for sale and purchase entered into between the user and any
          bidder, auctioneer, or seller.
        </p>
        <p>
          The content available through the services is not controlled by
          V3Bid.com. Therefore, V3Bid.com cannot make any representations,
          warranties, or guarantees in connection with what the auctioneer or
          seller may offer for sale through the services.
        </p>
        <p>
          The user is not charged for general use of the services. However, some
          services that the user may wish to sign up for require a subscription
          fee and may be subject to additional terms and conditions.
        </p>
        <p>
          V3Bid.com reserves the right to amend these terms at any time, in its
          sole discretion, by providing notice to the user of any amended terms.
          Notification of any amended terms shall be effected by posting the
          amended terms on the website and updating the "Last Updated" date at
          the top of these terms. In some cases, V3Bid.com may provide the user
          with additional notice of any amendments, such as by sending an email
          or providing notice on the homepage of the website. The user's
          continued use of the services after the revised terms have been posted
          to the website will confirm acceptance of the revised terms. Any
          amended terms will apply prospectively to any access or use of the
          services following the posting of such amended terms via the services.
        </p>
        <p>
          The user must be at least 18 years of age to use the services. By
          using the services, the user represents and warrants that they have
          not been previously suspended or removed from the services, have full
          power and authority to enter into this agreement, and will not violate
          any other agreement to which they are a party. The user will use the
          services only for their own personal use and not for commercial
          purposes.
        </p>
        <p>
          In order to participate in certain features of the services, the user
          will need to register for an online account. The user must provide
          accurate, current, and complete information when creating an account,
          maintain and promptly update their account information, maintain the
          security of their password, and accept all risks of authorized and
          unauthorized access to their account and the information they provide
          to V3Bid.com. The user must promptly notify V3Bid.com if they discover
          or otherwise suspect any security breaches related to the services.
        </p>
        <h4>Limitation of Liability Notice:</h4>
        <p>
          This document sets out the limitations of liability of the website
          operator, its affiliates, and its and their directors, employees, or
          agents. Unless caused by the website operator's negligence or fraud,
          the website operator, its affiliates, and its and their directors,
          employees, or agents will not be liable for any direct, special,
          indirect or consequential damages, or any other damages of any kind
          arising directly or indirectly in connection with the use of or
          inability to use the services or the content. This includes damages
          arising from loss of use, loss of profits, loss of data, or any
          defects, errors, imperfections, faults, mistakes, or inaccuracies with
          the services or the content, or due to any unavailability of part or
          all of the services or any content.
        </p>
        <p>
          The website operator's liability for loss of profits, economic loss,
          wasted management time, special, incidental, indirect, and/or
          consequential loss or damage is expressly excluded to the fullest
          extent permitted by law. The website operator accepts no liability or
          responsibility for the use made of the services by the user or those
          under its control and, in particular but without limitation, the
          content of any information.
        </p>
        <p>
          These exclusions and limitations of liability also apply to the
          website operator’s affiliates (if any). The aggregate liability of
          website operator, whether in contract, warranty, tort (including
          negligence, whether active, passive, or imputed), product liability,
          strict liability or other theory, arising out of or relating to the
          use of or inability to use the services, will not exceed one hundred
          dollars (INR 10000).
        </p>
        <p>
          Please note that some jurisdictions may not allow the limitation of
          liability in contracts with consumers, so some or all of these
          limitations of liability may not apply to you.
        </p>
        <h4>Third-Party Content; Hyperlinks:</h4>
        <p>
          The website operator may provide third-party content on the services,
          which may include links to other internet websites and other content
          that is not owned or controlled by the website operator, including but
          not limited to any content provided by sellers or auctioneers
          (collectively the “third-party content”). The website operator does
          not control, endorse or adopt any third-party content, and makes no
          representations or warranties of any kind regarding the third-party
          content, including, without limitation, regarding its accuracy or
          completeness.
        </p>
        <p>
          You acknowledge and agree that the website operator is not responsible
          or liable in any manner for any third-party content and undertakes no
          responsibility to update or review any third-party content. Users use
          such third-party content at their own risk. Your business dealings or
          correspondence with, or participation in promotions of, any third
          parties, including without limitation any sellers or auctioneers, and
          any terms, conditions, warranties or representations associated with
          such dealings or promotions, are solely between you and such third
          parties.
        </p>
        <p>
          The website operator is not responsible or liable for any loss or
          damage of any sort incurred as the result of any such dealings or
          promotions or as the result of the presence of such third-party
          content on the services.
        </p>
        <h4>Linking Policy:</h4>
        <p>
          The user may not link directly or indirectly, or facilitate the
          linking of, the services to any other website without the website
          operator's prior written consent provided that no consent is required
          for links made solely for the purposes of operating and providing a
          bona fide search engine although the website operator reserves the
          right at any time to require any such linking to cease.
        </p>
        <h4>Repeat Infringer Policy; Copyright Complaints:</h4>
        <p>
          In accordance with the Digital Millennium Copyright Act ("DMCA") and
          other applicable laws, we have adopted a policy of terminating, in
          appropriate circumstances and in our sole discretion, the account of
          users who are deemed to be repeat infringers. We may also, in our sole
          discretion, limit access to the services and/or terminate the accounts
          of any users who infringe any intellectual property rights of others,
          whether or not there is any
        </p>
      </div>
    </div>
  );
}

export default TermOfUse;
