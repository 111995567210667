import config from "../config/config";
import axios from "axios";
import { getToken } from "../common";

export const uploadDocumentEvents = async (params) => {
  return await axios({
    method: "post",
    url: `${config.api.base_url}documents`,
    data: params,
    headers: {
      "Content-type": "application/json;multipart/form-data; charset=UTF-8",
      Authorization: getToken(),
    },
  });
};

export default uploadDocumentEvents;
