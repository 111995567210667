import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import useStyles from "../../dashAuction.style";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import buyerDetailsAPI from "../../../../../api/adminAPI/buyerDetailsAPI";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {
  getToken,
  getUserData,
  getTimeZone,
  localTimeZone,
  localSetTimeZone,
} from "../../../../../common";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";

function DashBuyerDetail({ DashEventDetail, BidlogFirst, buyerId }) {
  const classes = useStyles();

  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const [tableBuyersData, setTableBuyersData] = useState([]);
  const [BuyerDetails, setBuyerDetails] = useState([]);

  const buyersDetails = async (buyerId) => {
    try {
      const { data } = await buyerDetailsAPI(buyerId);

      if (data) {
        let id = 1;
        const buyersData = data.buyerWiseEvents.map((data, index) => {
          return {
            id: id++,
            seller: data.seller,
            auctionId: data.auctionId,
            eventName: data.eventName,
            sDateNtime: moment(data.startDateTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
            eDateNtime: moment(data.endDateTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
            numLots: data.totalLots,
            lotOwner: data.ownerOfLots,
            allotedLot: data.allotedLots,
            bidLot: data.bidedLots,
            location: data.location,
            eventId: data.eventId,
          };
        });

        setTableBuyersData(buyersData);
        setBuyerDetails(data.buyerDetails);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    buyersDetails(buyerId);
    return () => {
      return false;
    };
  }, [buyerId]);

  const nextColumns = [
    {
      name: "Sr.",
      selector: "id",
      cell: (data) => String(data.id),
      sortable: true,
      width: "70px",
    },
    {
      name: "Seller",
      cell: ({ seller }) => String(seller),
      sortable: true,
    },
    {
      name: "Auction ID",
      selector: "auctionId",
      cell: (data) => String(data.auctionId),
      sortable: true,
    },
    {
      name: "Event Name",
      cell: (data) => {
        return (
          <Tooltip
            title={data.eventName}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{data.eventName}</span>
          </Tooltip>
        );
      },
      sortable: true,
    },
    {
      name: "Start Date & Time",
      cell: ({ sDateNtime }) => String(sDateNtime),
      sortable: true,
    },
    {
      name: "End Date & Time",
      cell: ({ eDateNtime }) => String(eDateNtime),
      sortable: true,
    },
    {
      name: "No. of Lots",
      selector: "numLots",
      cell: (data) => Number(data.numLots),
      sortable: true,
    },
    {
      name: "Lot Owner",
      cell: (data) => {
        return (
          <Tooltip
            title={data.lotOwner}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{data.lotOwner}</span>
          </Tooltip>
        );
      },
      sortable: true,
    },
    {
      name: "Alloted Lots",
      selector: "allotedLot",
      cell: (data) => Number(data.allotedLot),
      sortable: true,
    },
    {
      name: "Bidded Lots",
      selector: "bidLot",
      cell: (data) => Number(data.bidLot),
      sortable: true,
    },
    {
      name: "location",
      cell: ({ location }) => String(location),
      sortable: true,
    },
    {
      name: "Action",
      sortable: true,
      cell: (data) => (
        <div>
          <Button
            className="btn btn-group"
            onClick={() => {
              DashEventDetail(buyerId, data.eventId);
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  const exportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.seller,
      columns.auctionId,
      columns.eventName,
      columns.sDateNtime,
      columns.eDateNtime,
      columns.numLots,
      columns.lotOwner,
      columns.allotedLot,
      columns.bidLot,
      columns.location,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "buyer-wise-event.xlsx");
  };

  const ExportButton = () => (
    <Button onClick={() => exportToXLSX(tableBuyersData, nextColumns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  return (
    <>
      <div className="topDetail">
        <div className="row">
          <div className="col-md-6 m-auto">
            <h3>
              <Button sx={{ color: "#000" }} onClick={BidlogFirst}>
                <ArrowBackIcon />
              </Button>
              Buyer Details
            </h3>
          </div>
          <div className="col-md-6">
            <div className={classes.detailCard}>
              <div className="row">
                <div className="col-md-6">
                  <div className={classes.Detail}>
                    <p className={classes.SideLine}>
                      Name &nbsp; : &nbsp;{" "}
                      <span>
                        {BuyerDetails.firstName} {BuyerDetails.lastName}
                      </span>
                    </p>
                    <p
                      className={`${classes.SideLine} ${classes.marginremove}`}
                    >
                      email &nbsp; : &nbsp; <span>{BuyerDetails.email}</span>
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <div className={classes.Detail}>
                    <p>
                      Mobile No.&nbsp;: &nbsp;
                      <span>{BuyerDetails.contactNumber}</span>
                    </p>
                    <p className={classes.marginremove}>
                      alternate Contact Number&nbsp;:&nbsp;{" "}
                      <span>{BuyerDetails.alternateContactNumber}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="AuctionTable">
        <h3>Buyer Wise Event</h3>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={nextColumns}
          data={tableBuyersData}
        >
          <DataTable
            noHeader={false}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={false}
            highlightOnHover
            className="DataTable"
            actions={<ExportButton />}
          />
        </DataTableExtensions>
      </div>
    </>
  );
}

export default DashBuyerDetail;
