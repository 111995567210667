import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import AuctionLotCard from "../../../../packages/mui-component";

const renderLots = (
  lots,
  view,
  columns,
  listLots,
  eventsDetails,
  handleAuctionLotBidsDialog,
  handleAuctionDialog,
  value
) => {
  if (view === "list" || value === "proxy-bid") {
    // show list view

    return (
      <div className="AuctionTable tableHead liveAuction">
        <DataTableExtensions
          print={false}
          columns={columns}
          export={false}
          exportHeaders={false}
          data={listLots}
        >
          <DataTable
            pagination
            noHeader
            highlightOnHover
            data={listLots}
            className="DataTable"
            defaultSortField="id"
            defaultSortAsc={false}
          />
        </DataTableExtensions>
      </div>
    );
  }

  return (
    <>
      {lots.map((data, index) => (
        <AuctionLotCard
          handleAuctionLotBidsDialog={handleAuctionLotBidsDialog}
          handleAuctionDialog={handleAuctionDialog}
          key={index}
          lotDetails={data}
          eventDetails={eventsDetails}
        ></AuctionLotCard>
      ))}
    </>
  );
};

export default renderLots;
