import React from "react";

function Privacy() {
  return (
    <div className="container" style={{ marginTop: "10em" }}>
      <div className="privacySection">
        <div className="container">
          <div className="privacy">
            <div className="privacyHead">
              <h2>Privacy Policy</h2>
            </div>
            <div className="SectionPrivacy">
              <p className="topContent">
                In accordance with this policy, please be advised that it does
                not extend to any information collected by auctioneers or
                sellers directly from any user of our Services. Prior to
                submitting any information directly to any auctioneers or
                sellers, we strongly urge you to review their privacy policy.
              </p>
              <p className="topContent">
                It is important to note that we may revise this Privacy Policy
                periodically. In the event of any changes, we will inform you by
                updating the date indicated at the top of this policy.
                Additionally, we may provide you with additional notification in
                some instances, such as by posting a statement to our homepage
                or sending you an email notification. We suggest that you review
                this policy whenever you access the Services to stay informed
                about our information practices and the ways you can protect
                your privacy.
              </p>

              <div className="innerHead">
                <h3>
                  Collection of Information: Information You Provide to Us
                </h3>
              </div>
              <p>
                We gather information that you directly submit to us. This
                includes, but is not limited to, information collected when you
                create an account, register for an auction, participate in an
                auction or any other interactive features of the Services,
                communicate with us through messaging or forms, participate in a
                survey or promotion, request customer support, or otherwise
                contact us. The data we may collect includes your name, email
                address, phone number, fax number, postal address, company name,
                and any other information you choose to disclose.
              </p>
              <h5>
                In accordance with our information collection practices, we
                automatically collect certain information about you when you use
                our Services. This information includes{" "}
              </h5>
              <p>
                <span>Auction Information</span>: We gather information about
                your use of the Services, including participation in auctions,
                such as auction sign-ups, bidding history, outbids, defaults,
                purchases, Sales, items on your wish list and other relevant
                auction participation information. Log File Information: We
                collect log files when you use our Services, which includes
                details about the type of browser you are using, access times,
                pages viewed, your IP address, and the page you visited before
                accessing our Services.
              </p>
              <p>
                <span>
                  Information Collected by Cookies and Other Tracking
                  Technologies
                </span>
                : To improve the quality of our Services and enhance your
                experience, we utilize various technologies to gather
                information from your computer or mobile device, such as
                cookies. Cookies are small data files that are stored on your
                hard drive or in device memory and help us to determine popular
                areas and features of our Services and count visits. We may also
                collect information using web beacons, which are electronic
                images that help deliver cookies, count visits, understand usage
                and campaign effectiveness and determine whether an email has
                been opened and acted upon. For additional information about
                cookies and how to disable them, please refer to the "Your
                Choices" section below.
              </p>
              <p>
                <span>Information We Collect From Other Sources</span>: We may
                obtain information about you from other sources, which we may
                combine or link with the information we have collected about
                you. This may include information from auctioneers, such as
                feedback about your auction participation, and from third-party
                identification verification services.
              </p>
              <div className="innerLevel">
                <ul>
                  <li>
                    V3Bid.com uses collected information for various purposes,
                    including but not limited to
                    <ul>
                      <li>
                        V3Bid.com uses collected information for various
                        purposes, including but not limited to
                      </li>
                      <li>
                        Conducting auctions and sending related information such
                        as notices and confirmations.
                      </li>
                      <li>
                        Facilitating transactions processing and receiving
                        payments for services provided.
                      </li>
                      <li>Helping users communicate with each other.</li>
                      <li>
                        Sending technical notices, security alerts, support, and
                        administrative messages.
                      </li>
                      <li>
                        Sending technical notices, security alerts, support, and
                        administrative messages.
                      </li>
                      <li>
                        Communicating with users about products, services,
                        offers, promotions, rewards, and events offered by
                        V3Bid.com and others.
                      </li>
                      <li>
                        Monitoring and analyzing trends, usage, and activities
                        related to the Services.
                      </li>
                      <li>
                        Personalizing and improving the Services and providing
                        advertisements, content, or features that match user
                        profiles or interests.
                      </li>
                      <li>
                        Processing and delivering contest entries and rewards.
                      </li>
                      <li>
                        Linking or combining information collected from others
                        to understand users' needs and provide better service.
                      </li>
                      <li>
                        Carrying out any other purpose for which the information
                        was collected or as otherwise indicated via the Services
                        at the time of collection.
                      </li>
                    </ul>
                  </li>
                  <li>
                    V3Bid.com is based in the India/ Canada, and collected
                    information is governed by Indian/Canadian law.
                  </li>
                  <li>
                    V3Bid.com is based in the India/ Canada, and collected
                    information is governed by Indian/Canadian law.
                  </li>
                  <li>
                    V3Bid.com will take appropriate measures to protect user
                    information in accordance with the Privacy Policy.
                  </li>
                </ul>
              </div>
              <div className="heads">
                <h4>Sharing of Information:</h4>
                <ul>
                  <li>
                    We may share information about you with auctioneers, our
                    affiliated companies, vendors, consultants, and other
                    service providers to facilitate the processing of your
                    transactions, improve our services, and for other purposes
                    outlined in this privacy policy.
                  </li>
                  <li>
                    We may disclose information about you in response to a
                    request for information if we believe disclosure is in
                    accordance with any applicable law, regulation or legal
                    process, or as otherwise required by any applicable law,
                    rule, or regulation.
                  </li>
                  <li>
                    We may share your information in connection with a merger,
                    sale of company assets, financing, or acquisition of all or
                    a portion of our business to another company.
                  </li>
                  <li>
                    We may share aggregated or de-identified information, which
                    cannot reasonably be used to identify you.
                  </li>
                  <li>
                    We may also allow others to serve advertisements on our
                    behalf and provide analytics services, which may collect
                    information about your use of the Services and other
                    websites and online services.
                  </li>
                </ul>
              </div>
              <div className="heads">
                <h4>Advertising and Analytics Services Provided by Others:</h4>
                <ul>
                  <li>
                    Third-party entities may use cookies, web beacons, device
                    identifiers, and other technologies to collect information
                    about your use of the Services and other websites and online
                    services, including your IP address, web browser, mobile
                    network information, pages viewed, time spent on pages or in
                    apps, links clicked, and conversion information.
                  </li>
                  <li>
                    This information may be used to analyze and track data,
                    deliver advertising and content targeted to your interests
                    on our Services and other websites and online services, and
                    better understand your online activity.
                  </li>
                </ul>
              </div>
              <div className="heads">
                <h4>Security:</h4>
                <ul>
                  <li>
                    We take reasonable measures to help protect information
                    about you from loss, theft, misuse, and unauthorized access,
                    disclosure, alteration, and destruction.
                  </li>
                </ul>
              </div>
              <div className="heads">
                <h4>Your Choices:</h4>
                <ul>
                  <li>
                    You may update, correct, or delete your online account
                    information by logging into your account.
                  </li>
                  <li>
                    You may choose to set your browser to remove or reject
                    browser cookies, but note that this could affect the
                    availability and functionality of our Services.
                  </li>
                  <li>
                    You may opt-out of receiving promotional communications from
                    V3Bid.com by following the instructions in those messages.
                  </li>
                </ul>
              </div>
              <div className="heads">
                <h4>Contact Us:</h4>
                <ul>
                  <li>
                    If you have any questions about this Privacy Policy, please
                    contact us.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
