import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dashSearch: { position: "relative" },
  searchIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    color: "#999999",
  },
  input: {
    borderRadius: "0",
    backgroundColor: "#F2F2F24D",
    marginBottom: "1em",
  },
  tableBuyer: {
    minWidth: "100% !important",
    borderRadius: "0px !important",
    border: "1px solid #989898 !important",
    boxShadow: "none !important",
  },
  buyerRow: { backgroundColor: "#2D55EB", color: "#fff !important" },
  eye: { fontSize: "20px" },
  DataTable: { backgroundColor: "red" },
  pre: {
    width: "14em !important",
    color: "#2D55EB !important",
    backgroundColor: "#fff !important",
    borderRadius: "0em !important",
    border: "1px solid #2D55EB !important",
    [theme.breakpoints.down("sm")]: {
      width:"100% !important"
    },
  },
  next: {
    width: "14em !important",
    borderRadius: "0em !important",
    // marginLeft: "1em !important",
    color: "#fff !important",
    backgroundColor: "#2D55EB !important",
    [theme.breakpoints.down("sm")]: {
      width:"100% !important"
    },
  },
}));
export default useStyles;
