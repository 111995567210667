import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import useStyles from "../../dashAuction.style";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DashBuyerDetail from "../DashBuyerDetail";
import buyerEventDetailsAPI from "../../../../../api/adminAPI/buyerEventDetailsAPI";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {
  getToken,
  getUserData,
  getTimeZone,
  localTimeZone,
  localSetTimeZone,
} from "../../../../../common";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";

function DashEventDetail({
  BuyerBidLog,
  DashBuyerDetail,
  buyerId,
  buyerEventId,
}) {
  const classes = useStyles();

  const [tableBuyerEventsData, setTableBuyerEventsData] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [BuyerDataDetails, setBuyerDetails] = useState([]);
  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const buyerEventsDetails = async (buyerEventId, buyerId) => {
    try {
      const { data } = await buyerEventDetailsAPI(buyerId, buyerEventId);

      if (data) {
        let id = 1;
        const buyersData = data.buyerWiseEventLots.map((dataLots, index) => {
          return {
            id: id++,
            lotNum: dataLots.lotNumber,
            eventName: dataLots.eventName,
            qty: dataLots.quantity,
            unit: dataLots.unit,
            startPrice: dataLots.startPrice,
            increment: dataLots.increment,
            startDT: moment(dataLots.startDateAndTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
            endDT: moment(dataLots.endDateAndTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
            buyerSbid: dataLots.maxAmount,
            buyerLbid: dataLots.minAmount,
            countBid: dataLots.totalBids,
            auctionTitle: data.eventDetails.auctionTitle,
            eventItemId: dataLots.eventItemId,
          };
        });

        setTableBuyerEventsData(buyersData);
        setBuyerDetails(data);
        setEventDetails(data.eventDetails);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    buyerEventsDetails(buyerEventId, buyerId);
    return () => {
      return false;
    };
  }, [buyerEventId, buyerId]);

  const nextColumns = [
    {
      name: "Sr.",
      selector: "id",
      cell: (data) => String(data.id),
      sortable: true,
    },
    {
      name: "Lot No.",
      selector: "lotNum",
      cell: (data) => String(data.lotNum),
      sortable: true,
    },
    {
      name: "Event Name",
      cell: (data) => {
        return (
          <Tooltip
            title={data.auctionTitle}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{data.auctionTitle}</span>
          </Tooltip>
        );
      },
      width: "10%",
      sortable: true,
    },
    {
      name: "Qty",
      selector: "qty",
      cell: (data) => Number(data.qty),
      sortable: true,
    },
    {
      name: "Unit",
      selector: "unit",
      cell: (data) => Number(data.unit),
      sortable: true,
    },
    {
      name: `Start Price ${eventDetails.currency}`,
      selector: "startPrice",
      cell: (data) => Number(data.startPrice),
      sortable: true,
      width: "140px",
    },
    {
      name: `${
        eventDetails.eventType === "FORWARD_AUCTION" ? "Increment" : "Decrement"
      } ${eventDetails.currency}`,
      selector: "increment",
      cell: (data) => Number(data.increment),
      sortable: true,
      width: "140px",
    },
    {
      name: "Start Date & Time",
      cell: ({ startDT }) => String(startDT),
      sortable: true,
      width: "160px",
    },
    {
      name: "End Date Time",
      cell: ({ endDT }) => String(endDT),
      sortable: true,
      width: "150px",
    },
    {
      name: `Buyer Start Bid ${eventDetails.currency}`,
      cell: (data) => Number(data.buyerSbid),
      selector: "buyerSbid",
      sortable: true,
      width: "160px",
    },
    {
      name: `Buyer Last Bid ${eventDetails.currency}`,
      cell: (data) => Number(data.buyerLbid),
      selector: "buyerLbid",
      sortable: true,
      width: "160px",
    },
    {
      name: `Count Bids ${eventDetails.currency}`,
      cell: (data) => Number(data.countBid),
      selector: "countBid",
      sortable: true,
      width: "160px",
    },
    {
      name: "Action",
      sortable: true,
      cell: (data) => (
        <div>
          <Button
            className="btn btn-group"
            onClick={() => {
              BuyerBidLog(data.eventItemId);
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  const exportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.lotNum,
      columns.auctionTitle,
      columns.qty,
      columns.unit,
      columns.startPrice,
      columns.increment,
      columns.startDT,
      columns.endDT,
      columns.buyerSbid,
      columns.buyerLbid,
      columns.countBid,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "lots-listing.xlsx");
  };

  const ExportButton = () => (
    <Button onClick={() => exportToXLSX(tableBuyerEventsData, nextColumns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  return (
    <>
      <div className="topDetail">
        <div className="row">
          <div className="col-md-12 m-auto">
            <div className={classes.BuyEventHeading}>
              <h3>
                <Button
                  sx={{ color: "#000" }}
                  onClick={() => {
                    DashBuyerDetail(buyerId);
                  }}
                >
                  <ArrowBackIcon />
                </Button>
                Buyer Event Lots
              </h3>
              <h3 className="mob">Event Details</h3>
            </div>
          </div>
          <div className="col-md-12">
            <div className={classes.detailCard}>
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className={classes.Detail}>
                        <p className={classes.SideLine}>
                          Name &nbsp; : &nbsp;{" "}
                          <span>
                            {" "}
                            {BuyerDataDetails.buyerDetails?.firstName}{" "}
                            {BuyerDataDetails.buyerDetails?.lastName}
                          </span>
                        </p>
                        <p
                          className={`${classes.SideLine} ${classes.marginremove}`}
                        >
                          Company &nbsp; : &nbsp;{" "}
                          <span>
                            {BuyerDataDetails.buyerDetails?.firstName}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={classes.Detail}>
                        <p>
                          Mobile No.&nbsp;: &nbsp;
                          <span>
                            {BuyerDataDetails.buyerDetails?.contactNumber}
                          </span>
                        </p>
                        <p className={classes.marginremove}>
                          Alt.Mobile No.&nbsp;:&nbsp;{" "}
                          {
                            BuyerDataDetails.buyerDetails
                              ?.alternateContactNumber
                          }
                          <span></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className={classes.Detail}>
                        <p className={classes.SideLine}>
                          Event Id &nbsp; : &nbsp;{" "}
                          <span>
                            {BuyerDataDetails.eventDetails?.eventNumber}
                          </span>
                        </p>
                        <p
                          className={`${classes.SideLine} ${classes.marginremove}`}
                        >
                          Event Name&nbsp;: &nbsp;
                          <span>
                            {BuyerDataDetails.eventDetails?.auctionTitle}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={classes.Detail}>
                        <p>
                          Location &nbsp; : &nbsp;{" "}
                          <span>
                            {BuyerDataDetails.eventDetails?.country}{" "}
                            {BuyerDataDetails.eventDetails?.province}
                          </span>
                        </p>
                        <p className={classes.marginremove}>
                          Lot Owner&nbsp;:&nbsp;{" "}
                          <span>
                            {BuyerDataDetails.eventDetails?.ownerOfLots}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="AuctionTable" source="BUYER JOURNEY">
        <h3>Lots Listing</h3>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={nextColumns}
          data={tableBuyerEventsData}
        >
          <DataTable
            noHeader={false}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={false}
            highlightOnHover
            className="DataTable"
            actions={<ExportButton />}
          />
        </DataTableExtensions>
      </div>
    </>
  );
}

export default DashEventDetail;
