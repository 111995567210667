import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Button from "@mui/material/Button";
// import useStyles from "../dashAuction.style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import eventItemBidlogAPI from "../../../../api/adminAPI/eventItemBidlogAPI";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";

function AuctionViewFirst({ AuctionView, eventId, eventItemId, BidlogFirst }) {
  //   const classes = useStyles();

  // const [tableEventItemBidData, setTableEventItemBidData] = useState([]);

  // const eventItemsBidDetails = async (eventId,eventItemId) => {
  //   try {

  //     const {data} = await eventItemBidlogAPI(eventId,eventItemId);

  //     if (data) {
  //       let id = 1;
  //       const eventsData = data.map((eventItem,index) => {
  //         return {
  //           id: id++,
  //           lotNumber : eventItem.lotNumber,
  //           buyerName : eventItem.buyerName,
  //           buyerPosition : eventItem.buyerPosition,
  //           bidPrice : eventItem.bidPrice,
  //           bidderBidCount : eventItem.bidderBidCount,
  //           totalBidsCount : eventItem.totalBidsCount,
  //           bidDateTime: moment(eventItem.bidDateTime).format("DD/MM/YYYY hh:mm:ss"),
  //         };
  //       });

  //       setTableEventItemBidData(eventsData);

  //     } else throw new Error(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // React.useEffect(() => {
  //   eventItemsBidDetails(eventId,eventItemId);
  //   return () => {
  //     return false;
  //   };
  // }, [eventId,eventItemId]);

  const nextColumns = [
    {
      name: "Sr.",
      selector: "id",
      cell: (data) => String(data.id),
      sortable: true,
    },
    {
      name: "Buyer",
      selector: "buyer",
      cell: (data) => String(data.buyer),
      sortable: true,
    },
    {
      name: "Alloted Lots",
      selector: "allotedLots",
      cell: (data) => Number(data.allotedLots),
      sortable: true,
    },
    {
      name: "Bidded Lots",
      selector: "biddedLots",
      cell: (data) => Number(data.biddedLots),
      sortable: true,
    },
    {
      name: "H1",
      selector: "h1",
      cell: (data) => String(data.h1),
      sortable: true,
    },
    {
      name: "H2",
      selector: "h2",
      cell: (data) => String(data.h2),
      sortable: true,
    },
    {
      name: "H3",
      selector: "h3",
      cell: (data) => String(data.h3),
      sortable: true,
    },
  ];

  const data = [
    {
      id: "1",
      buyer: "Rahul Sharma",
      allotedLots: "987",
      biddedLots: "987",
      h1: "343",
      h2: "325",
      h3: "878",
    },
  ];

  return (
    <>
      <div className="AuctionTable">
        <h3>
          <Button sx={{ color: "#000" }} onClick={BidlogFirst}>
            <ArrowBackIcon />
          </Button>
          Auction View
        </h3>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={nextColumns}
          data={data}
        >
          <DataTable
            noHeader
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={false}
            highlightOnHover
            className="DataTable"
          />
        </DataTableExtensions>
      </div>
    </>
  );
}

export default AuctionViewFirst;
