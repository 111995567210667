import React, { Children, memo } from "react";

const MountCondition = memo(({ condition, children }) => {
  const [isTrue, isFalse] = Children.toArray(children);
  return condition === true ? isTrue : isFalse;
});

MountCondition.True = ({ children }) => children;
MountCondition.False = ({ children }) => children;

export default MountCondition;
