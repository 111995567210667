import createBidListLots from "./createBidListLots";
import createProxyBidListLots from "./createProxyBidListLots";

const createListLots = (
  lots,
  styles,
  userAmount,
  eventDetails,
  handleUserAmountChange,
  storeHandler,
  isForwardAuction,
  isOnline,
  lotBidEnded,
  panel,
  handleProxyAmountChange,
  setProxyBid
) => {
  switch (panel) {
    case "proxy-bid": {
      return createProxyBidListLots(
        lots,
        styles,
        userAmount,
        eventDetails,
        handleProxyAmountChange,
        isForwardAuction,
        isOnline,
        lotBidEnded,
        setProxyBid
      );
    }
    default: {
      return createBidListLots(
        lots,
        styles,
        userAmount,
        eventDetails,
        handleUserAmountChange,
        storeHandler,
        isForwardAuction,
        isOnline,
        lotBidEnded
      );
    }
  }
};

export default createListLots;
