import React from "react";
import { Typography } from "@mui/material";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import { ImHammer2 } from "react-icons/im";
import { SiClockify } from "react-icons/si";
import { FaHandPointUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useStyles from "./dashboard.style";
import { getUserData } from "../../../common";
import DashboardMenu from "../../commonComponents/DashboardMenu";
import dashboardAPI from "../../../api/adminAPI/dashboardAPI";
import { useState } from "react";

function Dashboard() {
  const classes = useStyles();
  const navigate = useNavigate();
  //const loginDash = localStorage.getItem("BidWebToken");

  const userData = getUserData() ?? {};

  const [dashboard, updateDashboard] = useState({
    apiState: "loading",
    list: [],
  });

  const getDashboardDetails = async () => {
    try {
      const { data } = await dashboardAPI();

      if (data) {
        updateDashboard({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {}
    return true;
  };
  React.useEffect(() => {
    getDashboardDetails();
    return () => null;
  }, []);

  // console.clear()
  // console.log(dashboard)
  const fourCards = [
    {
      Icon: <LiveTvIcon className={classes.svg} />,
      text: "Live",
      class: classes.live,
      classes: classes.dot,
      num: `${dashboard.list.liveAuctions}`,
    },
    {
      Icon: <FaHandPointUp className={classes.svg} />,
      text: "Upcoming",
      class: classes.Upcoming,
      classes: classes.dot1,
      num: `${dashboard.list.upcomingAuctions}`,
    },
    {
      Icon: <SiClockify className={classes.svg} />,
      text: "Past",
      class: classes.Past,
      classes: classes.dot2,
      num: `${dashboard.list.endedAuctions}`,
    },
    {
      Icon: <ImHammer2 className={classes.svg} />,
      text: "My Auctions",
      class: classes.my,
      classes: classes.dot3,
      num: `${dashboard.list.allAuctions}`,
    },
  ];
  return (
    <div className={classes.section}>
      <div className="container">
        <div className={classes.Dashboard}>
          <div className={classes.dashboardCard}>
            <DashboardMenu />
            <div className={classes.dashboardCards}>
              <Typography className={classes.headText}>
                Auctions <span className={classes.viewAll}>(View All)</span>
              </Typography>
              <div className={classes.cards}>
                <div className="row">
                  {fourCards.map((d, i) => {
                    return (
                      <div className="col-md-3" key={i}>
                        <div
                          className={classes.auctionCard}
                          style={{
                            backgroundColor: "#D9E6FF",
                          }}
                        >
                          <Typography className={d.class}>
                            <div className={d.classes}></div>
                            {d.text}
                          </Typography>
                          <div className="row">
                            <div className="col-md-6 m-auto">
                              <div className={classes.icon}>{d.Icon}</div>
                            </div>
                            <div className="col-md-6">
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  textAlign: "right",
                                }}
                              >
                                {d.num}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
