import { makeStyles } from "@mui/styles";
import { textAlign } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  Dashboard: {
    padding: "2em 0em",
    [theme.breakpoints.down("md")]: {
      padding: "5em 0em",
    },
  },
  section: { backgroundColor: "#e7e7e9" },
  avatar: {
    width: "3em !important",
    height: "3em !important",
    borderRadius: "10px !important",
  },
  name: { fontWeight: "600 !important" },
  number: { color: "#7E8184" },
  pentagon: {
    position: "relative",
    width: "90px",
    height: "24px",
    background: "#FFC400",
    clipPath: "polygon( 50% 0, 60% 50%, 50% 100%, 0% 100%, 0 0%)",
    padding: "2px 5px",
    marginLeft: "10px",
  },
  dashboards: {
    padding: "2em",
    borderBottom: "1px solid #F6F7FB",
  },
  dashboardCard: { backgroundColor: "#fff", borderRadius: "12px" },
  dashboardCards: { 
    padding: "2em",
    [theme.breakpoints.down("sm")]: {
      padding: "1em",
    },
   },
  headText: {
    fontSize: "18px !important",
    fontWeight: "600 !important",
    marginBottom: "1em !important",
  },
  viewAll: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    color: "#7E8184",
  },
  form: { width: "100% !important" },
  textarea: {
    borderRadius: 2,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "5px 12px",
  },
  input: {
    borderRadius: "0px",
    color: "#3F4043",
    backgroundColor: "#F2F2F24D",
  },
  label: { color: "#7E8184" },
  textarea: {
    width: "99% !important",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontFamily: "Roboto",
    fontSize: 16,
  },
  textHead: { fontWeight: "600 !important" },
  pre: {
    width: "14em !important",
    color: "#2D55EB !important",
    backgroundColor: "#fff !important",
    borderRadius: "0em !important",
    border: "1px solid #2D55EB !important",
  },
  next: {
    width: "14em !important",
    borderRadius: "0em !important",
    color: "#fff !important",
    backgroundColor: "#2D55EB !important",
  },
  saveBtn: { 
    marginTop: "2em",
    [theme.breakpoints.down("sm")]: {
      textAlign:'center'
    },
 },
  profileHead: { textAlign: "center", width: "45%" },
  proHead: { margin: "auto", color: "#2D55EB", fontWeight: "600" },
  editIcon: {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    backgroundColor: "#2D55EB",
    borderRadius: "100px",
    padding: "3px",
    color: "#fff",
    border: "1px solid #fff",
    fontSize:'22px',
    
  },
  saveNextBtns:{
    marginTop:'2em',
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
    [theme.breakpoints.down("tablet")]: {
      display: "flex",
    },
  }
}));
export default useStyles;
