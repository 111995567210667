import React, { useState } from "react";
import { Button, Checkbox } from "@mui/material";
import { Avatar, Box } from "@mui/material";
import Table from "react-bootstrap/Table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import useStyles from "../../../../Create/Event/Items/item.style";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "react-toastify/dist/ReactToastify.css";
import getEventItemsListAPIAdmin from "../../../../../../api/getEventItemsListAPIAdmin";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import deleteEventItemAPI from "../../../../../../api/deleteEventItemAPI";
import excelImportLots from "../../../../../../api/adminAPI/excelImportLots";

import routes from "../../../../../../constants/routes";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../../../../../config/config";

import Loader from "../../../../../../common/Loader";
import lodash, { isEmpty, omit } from "lodash";
import axios from "axios";
import deleteBulkEventItemsAPI from "../../../../../../api/deleteBulkEventItemsAPI";

const MountCondition = React.memo(({ condition, children }) => {
  const [isTrue, isFalse] = React.Children.toArray(children);
  return condition === true ? isTrue : isFalse;
});

MountCondition.True = ({ children }) => children;
MountCondition.False = ({ children }) => children;

function EditListView({
  SetHide,
  method,
  eventEditId,
  SetEdit,
  SetEventItemId,
  eventDetail,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleCloseModal = () => {
    setShow(false);
    resetForm();
  };
  const handleShow = () => setShow(true);

  const eventId = eventEditId;
  const [eventItemDetails, updateEventItemDetails] = useState({
    apiState: "loading",
    list: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("EXCEL");
  const [selectedTimeInterval, setSelectedTimeInterval] = useState("");

  const [uploadRules, setUploadRules] = useState({
    manualStartDate: moment().utc().toISOString(),
    manualEndDate: moment().utc().toISOString(),
    timeInterval: null,
    importType: "EXCEL",
  });

  const EventItemsDetails = async () => {
    try {
      const itempayload = { itemId: eventId };
      const { data } = await getEventItemsListAPIAdmin(itempayload);

      if (data) updateEventItemDetails({ apiState: "success", list: data });
      else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    EventItemsDetails();
    return () => {
      return false;
    };
  }, [eventId]);

  const [open, setOpen] = React.useState(false);
  const [deleteEventId, setDeleteEventId] = React.useState("");
  const [deleteEventItemId, setDeleteEventItemId] = React.useState("");
  const [errors, setErrors] = useState({});

  const handleClickOpen = (eventItemId, eventId) => {
    setOpen(true);
    setDeleteEventId(eventId);
    setDeleteEventItemId(eventItemId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateDeleteEventItem = async () => {
    try {
      const payload = {
        eventItemId: deleteEventItemId,
        eventId: deleteEventId,
      };
      const { data } = await deleteEventItemAPI(payload);

      toast("Event Item Deleted Successfully!");
      setTimeout(() => {
        EventItemsDetails();
        handleClose();
      }, 1500);
    } catch (error) {
      console.log(error);
      toast.error("Live lot cannot be deleted");
      setTimeout(() => {
        handleClose();
      }, 500);
    }
  };

  const resetForm = () => {
    setSelectedOption("");
    setSelectedTimeInterval("");
    setUploadRules({
      manualStartDate: moment().utc().toISOString(),
      manualEndDate: moment().utc().toISOString(),
      timeInterval: "",
      importType: "",
    });
    setFile(null);
    setErrors({});
    // Add any other states you want to reset here.
  };

  const [file, setFile] = useState();

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const validation = {
        size: 5 * 1024 * 1024,
        extensions: ["xlsx", "xls", "csv"],
      };
      const extension = (fileName) => {
        return fileName.substr(fileName.lastIndexOf(".") + 1);
      };

      if (lodash.isEmpty(event.target.file.files)) {
        return toast.error("Please upload the file first.");
      }
      if (file && file.size > validation.size) {
        return toast.error("File size exceeds the maximum limit of 5MB.");
      }
      if (!validation.extensions.includes(extension(file.name))) {
        return toast.error("Invalid file type");
      }

      const payload = { file: file, importType: uploadRules.importType };
      setIsLoading(true);

      if (uploadRules.importType === "MANUAL") {
        payload.manualStartDate = uploadRules.manualStartDate;
        payload.manualEndDate = uploadRules.manualEndDate;

        if (uploadRules.timeInterval) {
          payload.timeInterval = uploadRules.timeInterval;
        }
      }

      const { data } = await excelImportLots(payload, eventId);

      if (data) {
        toast.success("Lots Imported Successfully!");
        EventItemsDetails();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong.");
      }
    } finally {
      setIsLoading(false);
      handleCloseModal();
      setUploadRules({
        manualStartDate: moment().utc().toISOString(),
        manualEndDate: moment().utc().toISOString(),
        timeInterval: null,
        importType: "EXCEL",
      });
      setFile(null);
    }

    // setIsLoading(true)
    // const file = event.target.file.files[0];
    // const fileSizeLimit = 5 * 1024 * 1024;
    // const allowedExtensions = ["xlsx", "xls", "csv"];

    // if (file && file.size > fileSizeLimit) {
    //   setIsLoading(true);

    //   setTimeout(() => {
    //     toast("File size exceeds the maximum limit of 5MB.");
    //     setIsLoading(false);
    //     resetForm();
    //   }, 1500)

    //   // setIsLoading(false)
    //   return;
    // }

    // const fileExtension = file.name.split(".").pop().toLowerCase();
    // if (!allowedExtensions.includes(fileExtension)) {
    //   // Invalid file extension
    //   toast("Invalid file type");
    //   return;
    // }
    // try {
    //   setIsLoading(true)
    //   const formData = new FormData();
    //   formData.append('file', file);
    //   formData.append('fileName', file.name);

    //   const payload = { file: file, importType: selectedOption }

    //   console.clear(); console.log('uploadRules', uploadRules);

    //   if (selectedOption === "MANUAL") {
    //     payload.manualStartDate = uploadRules.manualStartDate;
    //     payload.manualEndDate = uploadRules.manualEndDate;

    //     if (uploadRules.timeInterval) { payload.timeInterval = uploadRules.timeInterval }
    //   }

    //   // const payload = { ...uploadRules, importType: selectedOption !== "" ? selectedOption : "EXCEL", timeInterval: selectedTimeInterval, file:file, };

    //   // if(selectedTimeInterval === '') { delete payload.timeInterval }

    //   // {console.log("selectedOption", selectedOption)}
    //   // if(selectedOption === "EXCEL" || "") { delete payload.manualEndDate; delete payload.manualStartDate; delete payload.timeInterval}

    //   const { data } = await excelImportLots(payload, eventId);
    //   if (data) {
    //     setIsLoading(false)
    //     toast("Lots Imported Successfully!");
    //     setTimeout(() => {
    //       EventItemsDetails();
    //       handleCloseModal();
    //     }, 1500);
    //   } else {
    //     toast("Network error!");
    //   }

    // } catch (error) {
    //   console.log("Error new", error)
    //   setIsLoading(false)
    //   toast(error.response.data.error)
    //   // if(error.response.status == "The bid start date should not be before the event start date."){
    //   //   console.log("This is the error")
    //   //   toast("The bid start date should not be before the event start date.")
    //   // }
    // }
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    setUploadRules({ ...uploadRules, importType: event.target.value });
  };

  const handleTimeChange = (event) => {
    setSelectedTimeInterval(event.target.value);
    setUploadRules({ ...uploadRules, timeInterval: event.target.value });
  };

  const validate = (name, value) => {
    //A function to validate each input values
    switch (name) {
      case "manualStartDate":
        if (value === null) {
          // we will set the error publishDateTime

          setErrors({
            ...errors,
            manualStartDate: "Start Date Time Can Not Be Empty.",
          });
        } else {
          // set the error publishDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "manualStartDate");
          setErrors(newObj);
        }
        break;

      case "manualEndDate":
        if (value === null) {
          // we will set the error bidStartDateTime

          setErrors({
            ...errors,
            manualEndDate: "End Start Date Time Can Not Be Empty.",
          });
        } else {
          // set the error bidStartDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "manualEndDate");
          setErrors(newObj);
        }
        break;

      case "timeInterval":
        if (value === null) {
          // we will set the error bidStartDateTime

          setErrors({
            ...errors,
            manualEndDate: "Time Interval Can Not Be Empty.",
          });
        } else {
          // set the error bidStartDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "timeInterval");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  const dateHandler = (name, date) => {
    validate(name, date);
    if (lodash.isEmpty(date)) {
      return true;
    }

    if (date.$d == "Invalid Date") {
      return true;
    }

    const dateUTC = moment.utc(date.$d).toISOString();

    setUploadRules((prevUploadRules) => ({
      ...prevUploadRules,
      [name]: dateUTC,
    }));
    // handleChange({ target : { name, value : moment(date.$d).utc().toISOString() } });
  };

  const handleDownload = () => {
    window.location.href = `${config.api.base_url}events/${eventId}/download-item-file`;
  };

  const [selectAll, setSelectAll] = useState(false);
  const [bulkDeleteDialog, toggleBulkDeleteDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectAll = ({ target }) => {
    setSelectAll(target.checked);
    setSelectedItems(
      target.checked
        ? eventItemDetails.list.map((item) => item.eventItemId)
        : []
    );
  };

  const handleCheckboxChange = ({ target }, itemId) => {
    const response = target.checked
      ? [...selectedItems, itemId]
      : selectedItems.filter((id) => id !== itemId);
    setSelectAll(lodash.size(response) === lodash.size(eventItemDetails.list));
    setSelectedItems(response);
  };

  const deleteBulkEventItems = async () => {
    try {
      await deleteBulkEventItemsAPI({ data: [...selectedItems], eventId });

      toast.success("Event Item Deleted Successfully!");
      EventItemsDetails();
      setSelectedItems([]);
      setSelectAll(false);
    } catch (error) {
      toast.error(
        axios.isAxiosError(error)
          ? error.response.data.error
          : "Something went wrong."
      );
    }

    return toggleBulkDeleteDialog(false);
  };

  return (
    <>
      {isLoading === true && <Loader isLoading={isLoading} />}
      <div className="">
        <div className={classes.listView}>
          <div className={classes.btnSection}>
            <MountCondition condition={lodash.size(selectedItems) !== 0}>
              <MountCondition.True>
                <Button
                  className={classes.deleteButton}
                  onClick={() => {
                    toggleBulkDeleteDialog(true);
                  }}
                >
                  <DeleteIcon /> Bulk Delete
                </Button>
              </MountCondition.True>
            </MountCondition>
            <Button className={classes.AddBtn} onClick={handleShow}>
              <AddIcon /> Bulk Upload
            </Button>
            <Button
              className={classes.AddBtn}
              onClick={() => {
                SetHide(true);
              }}
              sx={{ marginLeft: "1em" }}
            >
              <AddIcon /> Add More Lots
            </Button>
          </div>

          <div
            className="modal show"
            style={{ display: "block", position: "initial" }}
          >
            <Modal
              show={show}
              onHide={handleCloseModal}
              className="editListModal"
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>Bulk Upload</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="ps-4 pe-4">
                  {/* className='editModalBox' */}
                  <div className="row">
                    <div className="col-md-3 bg-x">
                      <Form.Label>Select Time Type</Form.Label>
                      <Form.Select
                        value={selectedOption}
                        onChange={handleDropdownChange}
                        className="editDropdown"
                      >
                        <option value="EXCEL">Excel</option>
                        <option value="MANUAL">Manual</option>
                      </Form.Select>
                    </div>
                    <div className="col-md-9">
                      {selectedOption === "MANUAL" && (
                        // <form onSubmit={handleSubmit} className="modalInsideForm">
                        <div className="row">
                          <div className="col-md-4 dateSelector">
                            <Form.Label>Select Start Date and Time</Form.Label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                className="datePicker newDatePicker"
                                renderInput={(props) => (
                                  <TextField
                                    {...props}
                                    className="form-control form-control-sm p-0 m-0"
                                  />
                                )}
                                value={uploadRules.manualStartDate}
                                onChange={(date) => {
                                  dateHandler("manualStartDate", date);
                                }}
                                minDate={moment().toDate()}
                                style={{ padding: "7px 14px !important" }}
                              />
                            </LocalizationProvider>
                          </div>
                          <div className="col-md-4 dateSelector">
                            <Form.Label>Select End Date and Time</Form.Label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                className="datePicker newDatePicker"
                                renderInput={(props) => (
                                  <TextField
                                    {...props}
                                    className="form-control form-control-sm p-0 m-0"
                                  />
                                )}
                                value={uploadRules.manualEndDate}
                                onChange={(date) => {
                                  dateHandler("manualEndDate", date);
                                }}
                                minDate={moment().toDate()}
                                style={{ padding: "7px 14px !important" }}
                              />
                            </LocalizationProvider>
                          </div>
                          <div className="col-md-4">
                            <Form.Label>Select Interval</Form.Label>
                            <Form.Select
                              onChange={handleTimeChange}
                              className="editDropdown"
                            >
                              <option value="">Select</option>
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="15">15</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="60">60</option>
                            </Form.Select>
                          </div>
                        </div>
                        // </form>
                      )}
                    </div>
                  </div>

                  <form
                    onSubmit={handleSubmit}
                    className="modalInsideForm mt-4"
                  >
                    <div className="bg-secondary new-background">
                      <input
                        type="file"
                        name="file"
                        onChange={handleChange}
                        accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      />
                    </div>
                    <div className="modalUpload mt-3">
                      <button
                        type="button"
                        value="Download"
                        className="editUploadButton mx-3"
                        onClick={handleDownload}
                      >
                        Download
                      </button>
                      <button type="submit" className="editUploadButton">
                        Upload
                      </button>
                    </div>
                  </form>
                </div>
              </Modal.Body>
            </Modal>
          </div>

          <div className={classes.table}>
            <Table
              striped
              bordered
              hover
              responsive
              className="tableHead"
              style={{ textWrap: "nowrap" }}
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>
                    <MountCondition
                      condition={lodash.size(eventItemDetails.list) !== 0}
                    >
                      <MountCondition.True>
                        <Checkbox
                          size="small"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </MountCondition.True>
                    </MountCondition>
                    <MountCondition condition={true}>Action</MountCondition>
                  </th>
                  <th>Sr.</th>
                  <th>Status</th>
                  <th>Lot Number</th>
                  <th>Short Description</th>
                  <th>Location</th>
                  <th>Qty</th>
                  <th>unit</th>
                  <th>Start Price</th>
                  <th>
                    {" "}
                    {eventDetail.eventType === "FORWARD_AUCTION"
                      ? "Increment"
                      : "Decrement"}
                  </th>
                  <th>Start date & Time</th>
                  <th>End date & Time</th>
                  <th>Remark</th>
                  <th>Images</th>
                </tr>
              </thead>
              <tbody>
                {eventItemDetails.list.length > 0 ? (
                  eventItemDetails.list.map((eventItem, index) => (
                    <tr>
                      <td>
                        <div className={classes.editBtn}>
                          <Checkbox
                            size="small"
                            checked={selectedItems.includes(
                              eventItem.eventItemId
                            )}
                            onChange={(event) =>
                              handleCheckboxChange(event, eventItem.eventItemId)
                            }
                          />
                          <Button
                            size="small"
                            onClick={() => {
                              if (
                                moment().isAfter(
                                  moment(eventItem.endDateAndTime).add(
                                    48,
                                    "hours"
                                  )
                                )
                              ) {
                                // lot ended already, should not be editable now
                                return;
                              }
                              SetHide(true);
                              SetEdit(true);
                              SetEventItemId(eventItem.eventItemId);
                            }}
                            disabled={moment().isAfter(
                              moment(eventItem.endDateAndTime).add(48, "hours")
                            )}
                            sx={{
                              borderRadius: "50%",
                              minWidth: "40px !important",
                            }}
                          >
                            <EditIcon
                              className={classes.edit}
                              style={{
                                color: moment().isAfter(
                                  moment(eventItem.endDateAndTime).add(
                                    48,
                                    "hours"
                                  )
                                )
                                  ? "grey"
                                  : "",
                              }}
                            />
                          </Button>
                          <MountCondition
                            condition={
                              !selectedItems.includes(eventItem.eventItemId)
                            }
                          >
                            <MountCondition.True>
                              <Button
                                size="small"
                                onClick={() =>
                                  handleClickOpen(
                                    eventItem.eventItemId,
                                    eventItem.eventId
                                  )
                                }
                                sx={{
                                  borderRadius: "50%",
                                  minWidth: "40px !important",
                                }}
                              >
                                <DeleteIcon className={classes.trash} />
                              </Button>
                            </MountCondition.True>
                          </MountCondition>
                        </div>
                      </td>
                      <td>{++index}</td>
                      <td>
                        <MountCondition
                          condition={moment().isBefore(
                            moment(eventItem.startDateAndTime)
                          )}
                        >
                          <MountCondition.True>
                            <p className={classes.statusUpcoming}>Upcoming</p>
                          </MountCondition.True>
                          <MountCondition.False>
                            <MountCondition
                              condition={moment().isBetween(
                                moment(eventItem.startDateAndTime),
                                moment(eventItem.endDateAndTime),
                                null,
                                "[]"
                              )}
                            >
                              <MountCondition.True>
                                <p className={classes.statusLive}>Live</p>
                              </MountCondition.True>
                              <MountCondition.False>
                                <p className={classes.statusEnded}>Ended</p>
                              </MountCondition.False>
                            </MountCondition>
                          </MountCondition.False>
                        </MountCondition>
                      </td>
                      <td>{eventItem.lotNumber}</td>
                      <td>{eventItem.shortDescription}</td>
                      <td>Rajasthan</td>
                      <td>{eventItem.quantity}</td>
                      <td>{eventItem.unit}</td>
                      <td>
                        {eventItem.startPrice} ({eventItem.currency})
                      </td>
                      <td>
                        {eventItem.increment} ({eventItem.currency})
                      </td>
                      <td style={{ width: "200px !important" }}>
                        {moment(eventItem.startDateAndTime).format(
                          "DD-MM-YYYY hh:mm:ss"
                        )}
                      </td>
                      <td style={{ width: "200px !important" }}>
                        {moment(eventItem.endDateAndTime).format(
                          "DD-MM-YYYY hh:mm:ss"
                        )}
                      </td>
                      <td>Remarks</td>
                      <td>
                        <Avatar src={eventItem.eventItemImagesUrl[0]} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    {" "}
                    <td colspan="14">
                      <center>
                        <b>Lots Are Not Available</b>
                      </center>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">
              Delete Event Item?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete Item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={updateDeleteEventItem} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={bulkDeleteDialog}
            onClose={() => {
              toggleBulkDeleteDialog(false);
            }}
          >
            <DialogTitle id="alert-dialog-title">
              Delete Event Items{" "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete Items ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  toggleBulkDeleteDialog(false);
                }}
              >
                Cancel
              </Button>
              <Button onClick={deleteBulkEventItems} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Box className={classes.saveNextBtns}>
            <Button
              className={classes.pre}
              onClick={(event) => {
                method(event, 0);
              }}
            >
              Previous
            </Button>
            <Button
              className={classes.next}
              style={{ marginRight: "2em" }}
              onClick={(event) => {
                method(event, 2);
              }}
              sx={{ marginLeft: "1em" }}
            >
              Next
            </Button>
            <Button
              className={classes.next}
              onClick={() => {
                navigate(routes.dashboard);
              }}
            >
              Go To Dashboard
            </Button>
            {/* )} */}
          </Box>
        </div>
      </div>
    </>
  );
}

export default EditListView;
