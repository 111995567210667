import React, { useState } from "react";
import AuctionDetail from "./AuctionDetail";
import AuctionFilter from "./AuctionFilter";
import useStyles from "./auction.style";
import AuctionCards from "./AuctionCards";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
// import routes from "../../constants/routes";
import MenuIcon from "@mui/icons-material/Menu";
import colorSchemes from "../../../constants/colorSchemes";
import { useLocation } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

function Auction(props) {
  const location = useLocation();
  const state = location.state;

  const [eventIdFilter, setEventIdFilter] = useState("");
  const [viewCard, setViewCard] = useState(
    state !== null || eventIdFilter !== "" ? true : false
  );
  const [eventId, setEventId] = useState(state === null ? "" : state.eventId);
  const [auctionIds, setAuctionId] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { window, handleDetectRouteChange } = props;

  const [countryId, setCountryId] = React.useState("");
  const [stateId, setStateId] = React.useState("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <AuctionFilter filterChange={viewCard} setMobileOpen={setMobileOpen} />
    </Box>
  );
  const classes = useStyles();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const drawerWidth = 340;

  return (
    <div
      className={classes.auction}
      style={{
        ...(mobileOpen ? { minWidth: "25%" } : { width: "100%" }),
      }}
    >
      {mobileOpen ? (
        <Box className={classes.auctionFliter}>
          <AuctionFilter
            setCountryId={setCountryId}
            setStateId={setStateId}
            auctionIds={auctionIds}
            setAuctionId={setAuctionId}
            eventIdFilter={eventIdFilter}
            setEventIdFilter={setEventIdFilter}
            filterChange={viewCard}
            setMobileOpen={setMobileOpen}
          />
        </Box>
      ) : (
        <div
          style={{
            background: "#2D55EB",
            height: "3rem",
            marginTop: "2rem",
            zIndex: "2",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            padding: ".1rem",
            borderTopRightRadius: "2rem",
            borderBottomRightRadius: "2rem",
            transition: "all ease-in-out .3s",
            cursor: "pointer",
          }}
          onClick={() => setMobileOpen(true)}
        >
          <ChevronRight
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: "white",
            }}
          />
        </div>
      )}
      <div
        className={classes.auctionDetail}
        style={{
          ...(mobileOpen ? { width: "85%" } : { width: "100%" }),
        }}
      >
        <Button
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            marginLeft: "0.7em",
            display: { sm: "none" },
            position: "absolute",
            backgroundColor: colorSchemes.primaryColor,
            color: "#fff",
            borderRadius: "0px",
            width: "95%",
          }}
        >
          <span>Filter</span>
        </Button>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          aria-disabled={true}
          // onClose={() => setMobileOpen(true)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        {viewCard ? (
          <AuctionDetail
            eventId={eventId}
            stepper={props}
            setViewCard={setViewCard}
          />
        ) : (
          <AuctionCards
            countryId={countryId}
            stateId={stateId}
            auctionIds={auctionIds}
            setEventId={setEventId}
            setViewCard={setViewCard}
            stepper={props}
          />
        )}
      </div>
    </div>
  );
}

export default Auction;
