import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import colorSchemes from "../../../constants/colorSchemes";
import routes from "../../../constants/routes";
import useStyles from "./SignIn.styles";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loginApi from "../../../api/loginAPI";
import { setToken, removeToken, removeUserData, getToken, setUserData,getUserData } from "../../../common";
import GoogleLogin from "react-google-login";
import GoogleButton from "react-google-button";
import LogoutIcon from "@mui/icons-material/Logout";
import { isEmpty } from "lodash";
import config from "../../../config/config";

import { useContext } from 'react';
import { TimerContext } from "../../../app/App";

export default function SignIN() {
  const timerContext = useContext(TimerContext) 
  const navigate = useNavigate();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });
  const userData = getUserData() ?? {};
  //---------------------------- login-Process ----------------------------------

  const handleChange = (event) => {
    setLoginInfo({ ...loginInfo, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      const { data } = await loginApi(loginInfo);
      setToken(data.token); /* set user token */
      setUserData(data);
      timerContext.timerProps.activate();
      toast("Login Successfully!");
      setTimeout(() => {
        navigate(routes.home);
      }, 1500);
    } catch (error) {
      if (error.response) {
        //console.log(error.response.data.error);
        toast(error.response.data.error);
        setIsLoading(false);
      } else if (error.response.status === 500) {
        toast("Network Error!");
        setIsLoading(false);
      }else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log('Error', error.message.code);
        setIsLoading(false);
      }
      console.log(error);
    }

   //setUserInfo({ email: "", firstname: "", lastname: "", phonenumber: "" , password: "", confirmpassword: ""});
  };

  //---------------Google Login --------------------------------------
  // @google-login --------------------------------------------------------------------------------------

  const responseGoogle = (response) => {
    if (response.type === "error") {
      return false;
    }

    console.log(response);

    // return createCustomer({
    //     signup_method: "google",
    //     name: response.profileObj.name,
    //     email: response.profileObj.email,
    //     id_token: response.tokenObj.id_token,
    // });
  };

  return (
    <Box className={classes.signin}>
      {isEmpty(userData) ? 
        <Card
          sx={{ maxWidth: 500, marginTop: "9em", padding: "1em 2em" }}
          className={classes.signcard}
        >
        
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "600",
                color: colorSchemes.primaryColor,
              }}
              variant="h5"
            >
              LOGIN
            </Typography>
          </Box>
          <form
            layout="vertical"
            onSubmit={handleSubmit}
          >
            <Box className={classes.forms}>
              {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className={classes.label}>E-mail</Form.Label>
                <Form.Control
                  className={classes.input}
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={loginInfo.email}
                  onChange={handleChange}
                />
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className={classes.label}>E-mail</Form.Label>
                <Form.Control
                  className={classes.input}
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={loginInfo.email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Box>
            <Box className={classes.forms}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className={classes.label}>Password</Form.Label>
                <Form.Control
                  className={classes.input}
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  value={loginInfo.password}
                  onChange={handleChange}
                />
              </Form.Group>
            </Box>

            <Typography
              className={classes.forgot}
              sx={{ color: colorSchemes.primaryColor }}
            >
              <Link to="/forgot">Forgot Password ?</Link>
            </Typography>
            <Box>
              <Button
                sx={{
                  backgroundColor: colorSchemes.primaryColor,
                  color: "#fff",
                  borderRadius: "0em",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#2D55EB !important",
                  },
                }}
                // onClick={() => {
                //   setToken(loginInfo.email);
                //   navigate(routes.home);
                // }}
                fullWidth
                type="submit"
              >
                Login
              </Button>
              <a href={config.socialLogin.google} className="px-4 py-2 border flex gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400  transition duration-150 google-login">
                  <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                  <span>Continue with Google</span>
              </a>
              <a href={config.socialLogin.facebook} className="px-4 py-2 border flex gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400  transition duration-150 google-login">
                <img className="w-6 h-6" src="https://www.svgrepo.com/show/448224/facebook.svg" loading="lazy" alt="facebook logo" />
                <span>Continue with Facebook</span>
              </a>
            </Box>
            <Box>
              
            </Box>
          </form>
          <Typography className={classes.forgot}>
            Don't have an account? <Link to="/register">Create free account</Link>{" "}
          </Typography>
        </Card>
      : <Card
          sx={{ maxWidth: 500, marginTop: "9em", padding: "3.5em 2em" }}
          className={classes.signcard}
        >
          <center className="tabHead">
          <h3>Your Already Logged in!</h3>
          <Button
              sx={{
                backgroundColor: colorSchemes.primaryColor,
                color: "#fff",
                borderRadius: "0em",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#2D55EB !important",
                },
              }}
              onClick={() => {
                removeToken();
                removeUserData();
                navigate(routes.signin);
              }}
              fullWidth
              type="submit"
            >
              <LogoutIcon
                  sx={{
                    color: "white",
                    fontSize: "16px",
                    marginTop: "-2px",
                    marginRight: "5px",
                  }}
                />
              Log Out
            </Button>
          </center>
      </Card>}
    </Box>
  );
}
