import config from "../config/config";
import axios from "axios";

export const forgotPasswordAPI = async (params) => {
  return await axios({
    method: "post",
    url: `${config.api.base_url}auth/forgot-password`,
    data: params,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  });
};

export default forgotPasswordAPI;
