import config from '../config/config'
import axios from 'axios'
import { getToken } from '../common'; 
export const getViewByBuyerList = async (params) => {
   
    return await axios({
        method  : 'get',
        url     : `${config.api.base_url}events/${params.auctionId}/lots/view-by-buyers`,
        // params    : params,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": getToken(),
        }
    })
}

export default getViewByBuyerList