import config from '../config/config'
import axios from 'axios'
import { getToken } from '../common'; 

export const saveBuyerToEvent = async (params,eventId) => {

    return await axios({
        method  : 'post',
        url     : `${config.api.base_url}events/${eventId}/buyers`,
        data    : params,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": getToken(),
        }
    })
}

export default saveBuyerToEvent