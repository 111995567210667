import { Box } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import routes from "../../constants/routes";
import About from "../../views/components/About";
import Privacy from "../../views/components/Privacy";
import TermOfUse from "../../views/components/TermOfUse";
import ContactUs from "../../views/components/ContactUs";
import RefundPolicy from "../../views/components/RefundPolicy";
import Auction from "../../views/components/Auction";
import AuctionCardSearch from "../../views/components/AuctionCardSearch";
import CreateEvent from "../../views/components/Create/CreateEvent";
import Event from "../../views/components/Create/Event";
import Home from "../../views/components/Home";
import Register from "../../views/components/Register";
import SellerRegister from "../../views/components/SellerRegister";
import SignIN from "../../views/components/SignIn";
import Forgot from "../../views/components/Forgot";
import Verification from "../../views/components/Verification";
import ResetPassword from "../../views/components/ResetPassword";
import Dashboard from "../../views/components/Dashboard";
import useStyles from "./router.style";
import Profile from "../../views/components/Profile";
import DashboardAuction from "../../views/components/DashboardAuction";
import DashBuyer from "../../views/components/DashBuyer";
import DashSeller from "../../views/components/DashSeller";
import LiveAuctions from "../../views/components/LiveAuctions";
import Authmiddleware from "./middleware/Authmiddleware";
import AuctionLots from "../../views/auctionLots";
import PostbidAuction from "../../views/components/PostbidAuction";
import { HashRouter } from "react-router-dom";
import SocialLogin from "../../views/authentication/social-login-callback";

export default function Router() {
  const handleDetectRouteChange = (data) => {};
  const classes = useStyles();
  return (
    <React.Fragment>
      <HashRouter>
        <Header handleDetectRouteChange={handleDetectRouteChange} />
        <Box className={classes.homeContent}>
          <Routes>
            <Route exact path={routes.home} element={<Home />}></Route>
            <Route exact path={routes.about} element={<About />}></Route>
            <Route exact path={routes.privacy} element={<Privacy />}></Route>
            <Route
              exact
              path={routes.termOfUse}
              element={<TermOfUse />}
            ></Route>
            <Route
              exact
              path={routes.contactUs}
              element={<ContactUs />}
            ></Route>
            <Route
              exact
              path={routes.refundPolicy}
              element={<RefundPolicy />}
            ></Route>
            <Route exact path={routes.signin} element={<SignIN />}></Route>
            <Route exact path={routes.forgot} element={<Forgot />}></Route>
            <Route
              exact
              path={routes.verification}
              element={<Verification />}
            ></Route>
            <Route
              exact
              path={routes.resetpassword}
              element={<ResetPassword />}
            ></Route>

            <Route exact path={routes.register} element={<Register />}></Route>
            <Route
              exact
              path={routes.createvent}
              element={<CreateEvent />}
            ></Route>
            <Route exact path={routes.event} element={<Event />}></Route>
            <Route
              exact
              path={routes.sellerRegister}
              element={<SellerRegister />}
            ></Route>
            <Route
              exact
              path={routes.postbidAuction}
              element={<PostbidAuction />}
            ></Route>
            <Route exact path={routes.auction} element={<Auction />}></Route>
            <Route
              exact
              path="/auctions/:source"
              element={<AuctionLots />}
            ></Route>
            <Route
              exact
              path="/auth/:source/callback/:token"
              element={<SocialLogin />}
            ></Route>
            <Route
              exact
              path={routes.search}
              element={<AuctionCardSearch />}
            ></Route>
            {localStorage.getItem("userData") ? (
              <Route
                exact
                path={routes.dashboard}
                element={<Dashboard />}
              ></Route>
            ) : (
              <Route
                exact
                path={routes.dashboard}
                element={
                  <Authmiddleware>
                    <Dashboard />
                  </Authmiddleware>
                }
              ></Route>
            )}
            {localStorage.getItem("userData") ? (
              <Route exact path={routes.profile} element={<Profile />}></Route>
            ) : (
              <Route
                exact
                path={routes.profile}
                element={
                  <Authmiddleware>
                    <Profile />
                  </Authmiddleware>
                }
              ></Route>
            )}
            {localStorage.getItem("userData") ? (
              <Route
                exact
                path={routes.dashboardAuction}
                element={<DashboardAuction />}
              ></Route>
            ) : (
              <Route
                exact
                path={routes.dashboardAuction}
                element={
                  <Authmiddleware>
                    <DashboardAuction />
                  </Authmiddleware>
                }
              ></Route>
            )}
            {localStorage.getItem("userData") ? (
              <Route
                exact
                path={routes.dashBuyer}
                element={<DashBuyer />}
              ></Route>
            ) : (
              <Route
                exact
                path={routes.dashBuyer}
                element={
                  <Authmiddleware>
                    <DashBuyer />
                  </Authmiddleware>
                }
              ></Route>
            )}
            {localStorage.getItem("userData") ? (
              <Route
                exact
                path={routes.dashSeller}
                element={<DashSeller />}
              ></Route>
            ) : (
              <Route
                exact
                path={routes.dashSeller}
                element={
                  <Authmiddleware>
                    <DashSeller />
                  </Authmiddleware>
                }
              ></Route>
            )}
            {localStorage.getItem("userData") ? (
              <Route
                exact
                path={routes.liveAuctions}
                element={<LiveAuctions />}
              ></Route>
            ) : (
              <Route
                exact
                path={routes.liveAuctions}
                element={
                  <Authmiddleware>
                    <LiveAuctions />
                  </Authmiddleware>
                }
              ></Route>
            )}
            {/* <Route exact path={routes.profile} element={<Authmiddleware><Profile /></Authmiddleware>}></Route> */}
            {/* <Route exact path={routes.dashboardAuction} element={<Authmiddleware><DashboardAuction /></Authmiddleware>}></Route> */}
            {/* <Route exact path={routes.dashBuyer} element={<Authmiddleware><DashBuyer /></Authmiddleware>}></Route> */}
            {/* <Route exact path={routes.dashSeller} element={<Authmiddleware><DashSeller /></Authmiddleware>}></Route> */}
            {/* <Route exact path={routes.liveAuctions} element={<Authmiddleware><LiveAuctions></LiveAuctions></Authmiddleware>}></Route> */}
          </Routes>
        </Box>
        <Footer />
      </HashRouter>
    </React.Fragment>
  );
}
