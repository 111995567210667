import React from "react";

const ContactUs = () => {
  return (
    <div className="container" style={{ marginTop: "9em" }}>
      <div className="termHead">
        <h2>Contact US</h2>
      </div>
      <div
        className="termsContent"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "2rem 0",
        }}
      >
        <p>RIVRAM IT SOLUTIONS PRIVATE LIMITED (OPC)  </p>
        <p>Udaipur - 313001, Rajasthan</p>
        <p>
          <b>GST:</b> 08AAHCR3109H1ZG
        </p>
        <p>
          <b>Call Us &#128222; </b> +91 6377277504
        </p>
        <p>
          <b>Mail Us &#128231; </b>auction@V3Bid.com
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
