import React, { useState, useEffect } from "react";
import { omit } from "lodash";
import { Viewer } from "@react-pdf-viewer/core";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Worker } from "@react-pdf-viewer/core";
import ImageUploading from "react-images-uploading";
import { BsUpload } from "react-icons/bs";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "react-toastify/dist/ReactToastify.css";

// mui imports
import Box from "@mui/material/Box";
import { Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import DescriptionIcon from "@mui/icons-material/Description";
import CreateIcon from "@mui/icons-material/Create";

// APIs
import createEventAPI from "../../../../api/createEventAPI.js";
import uploadDocumentEvents from "../../../../api/uploadDocumentEvents";
import getCountries from "../../../../api/getContriesAPI";
import getStateAPI from "../../../../api/getStateAPI";

// component imports
import routes from "../../../../constants/routes";
import colorSchemes from "../../../../constants/colorSchemes";
import Loader from "../../../../common/Loader";
import useStyles from "./createEvent.styles";

import BulkUploadDialog from "../../Shared/BulkUploadDialog";

function CreateEvent() {
  const isLargeScreen = useMediaQuery("(min-width:768px)");

  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [createType, setCreateType] = useState(null);
  const [eventInfo, setEventInfo] = useState({
    auctionTitle: "",
    referenceNumber: "",
    description: "",
    eventType: "",
    bidType: "",
    testProject: "",
    streetOne: "",
    streetTwo: "",
    country: "",
    province: "",
    city: "",
    postalCode: "",
    eventImage: "",
    auctionCatalogue: "",
  });
  const [auctionBulkUploadDialog, toggleAuctionBulkUploadDialog] =
    useState(false);

  useEffect(() => {
    countryList();
    stateData();
  }, []);

  const handleChange = (event) => {
    event.persist();
    validate(event, event.target.name, event.target.value);
    if (event.target.name === "country") stateData(event.target.value);
    setEventInfo({ ...eventInfo, [event.target.name]: event.target.value });
  };

  //Image Upload Functionality start
  const [images, setImages] = React.useState("");
  const [imagesUploaded, setImagesuploaded] = React.useState([]);
  const maxNumber = 5;
  const [eventUploadInfo, setEventUploadInfo] = React.useState({
    document: "",
  });

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    imageUpload(imageList);
  };
  const imageUpload = async (images) => {
    try {
      setIsLoading(true);
      console.log(images);
      images.map(async (image, index) => {
        eventUploadInfo.document = image["file"];
        const { data } = await uploadDocumentEvents(eventUploadInfo);
        setImagesuploaded(data);
        eventInfo.eventImage = data.documentId;

        if (data) {
          setIsLoading(false);
          toast("Image Uploaded Successfully!");
        }
      });
    } catch (error) {
      if (error.response) {
        error.response.data.error.map((errors, index) => {
          toast(errors);
          setIsLoading(false);
        });
      } else if (error.request) {
        toast("Something went wrong!");
      } else {
        console.log("Error", error.message.code);
      }
    }
  };

  //Image Upload Functionality End

  // Catelogue PDF Upload Start

  const [imagesCatalogue, setImagesCatalogue] = React.useState("");
  const [imagesUploadedCatalogue, setImagesuploadedCatalogue] = React.useState(
    []
  );
  //const maxNumber = 5;
  const [eventUploadInfoCatalogue, setEventUploadInfoCatalogue] =
    React.useState({
      document: "",
    });

  //   console.log(images)
  const onChangeCatalogue = (catalogueImageList, addUpdateIndex) => {
    setImagesCatalogue(catalogueImageList);
    imageUploadCatalogue(catalogueImageList);
  };
  const imageUploadCatalogue = async (images) => {
    try {
      setIsLoading(true);
      images.map(async (image, index) => {
        eventUploadInfoCatalogue.document = image["file"];
        const { data } = await uploadDocumentEvents(eventUploadInfoCatalogue);
        setImagesuploadedCatalogue(data);
        eventInfo.auctionCatalogue = data.documentId;

        if (data) {
          setIsLoading(false);
          toast("Catalogue Uploaded Successfully!");
        }
      });
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.error);
        error.response.data.error.map((errors, index) => {
          toast(errors);
          setIsLoading(false);
        });
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log("Error", error);
        setIsLoading(false);
      }
    }
  };

  //catelogue PDF Upload End

  //Get Countries Nad States

  const [countriesList, updateCountriesList] = useState({
    apiState: "loading",
    list: [],
  });

  const [stateList, updateStateList] = useState({
    apiState: "loading",
    list: [],
  });

  const countryList = async () => {
    try {
      const { data } = await getCountries();

      if (data) {
        if (data) updateCountriesList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };

  const stateData = async (countryId) => {
    try {
      console.log(countryId);
      const { data } = await getStateAPI(countryId);

      if (data) {
        if (data) updateStateList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };

  // Validation Form

  const [errors, setErrors] = useState({});

  const validate = (event, name, value) => {
    //A function to validate each input values
    const re = /^[A-Za-z ]+$/;
    switch (name) {
      case "state":
        if (!re.test(value)) {
          // we will set the error state

          setErrors({
            ...errors,
            state: "state should contains only Letters",
          });
        } else {
          // set the error state empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "state");
          setErrors(newObj);
        }
        break;

      case "city":
        if (!re.test(value)) {
          // we will set the error city

          setErrors({
            ...errors,
            city: "City should contains only Letters",
          });
        } else {
          // set the error city empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "city");
          setErrors(newObj);
        }
        break;

      case "country":
        if (!re.test(value)) {
          // we will set the error country

          setErrors({
            ...errors,
            country: "country should contains only Letters",
          });
        } else {
          // set the error country empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "country");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      eventInfo.testProject = false;
      if (eventInfo.testProject == "true") {
        eventInfo.testProject = true;
      }

      eventInfo.auctionCatalogue = imagesUploadedCatalogue.documentId;
      eventInfo.eventImage = imagesUploaded.documentId;
      const { data } = await createEventAPI(eventInfo);
      if (data) {
        toast("Event Create Successfully!");
        setTimeout(() => {
          setIsLoading(false);
          navigate(routes.event, { state: { data: data } });
        }, 1500);
      }
    } catch (error) {
      console.log(error.response.data.error);
      setIsLoading(false);
      if (error.response) {
        toast(error.response.data.error);
      } else if (error.request) {
        toast("Something went wrong!");
      } else {
        console.log("Error", error.message.code);
      }
    }
  };

  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // for onchange event
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfFileError, setPdfFileError] = useState("");

  // for submit event
  const [viewPdf, setViewPdf] = useState(null);

  // onchange event
  const fileType = ["application/pdf"];
  const handlePdfFileChange = async (e) => {
    let selectedFile = e.target.files[0];
    setIsLoading(true);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = async (e) => {
          setPdfFile(e.target.result);
          setPdfFileError("");
          eventUploadInfoCatalogue.document = selectedFile;
          const { data } = await uploadDocumentEvents(eventUploadInfoCatalogue);
          setImagesuploadedCatalogue(data);
          eventInfo.auctionCatalogue = data.documentId;

          if (data) {
            setIsLoading(false);
            setViewPdf(e.target.result);
            toast("Catalogue Uploaded Successfully!");
          }
        };
      } else {
        setPdfFile(null);
        setPdfFileError("Please select valid pdf file");
        setIsLoading(false);
      }
    } else {
      console.log("select your file");
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading === true && <Loader isLoading={isLoading} />}
      <BulkUploadDialog
        show={auctionBulkUploadDialog}
        title="Create auction using excel file"
        toggleAuctionBulkUploadDialog={toggleAuctionBulkUploadDialog}
        setIsLoading={setIsLoading}
        setCreateType={setCreateType}
      />
      <div className={classes.event}>
        <Typography
          className={classes.textHead}
          sx={{
            textAlign: "center",
            padding: "1em",
            fontSize: "20px",
            fontWeight: "600",
            color: colorSchemes.primaryColor,
            marginTop: isLargeScreen ? "2em" : "8em",
          }}
        >
          Create Auction
        </Typography>
        {createType === null && (
          <div className="container">
            <div className="section-event mb-5">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  className={classes.upload}
                  sx={{
                    display: "block",
                    textAlign: "right",
                  }}
                  onClick={() => {
                    setCreateType("excel");
                    // open excel dialog
                    toggleAuctionBulkUploadDialog(true);
                  }}
                  size="small"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textTransform: "none",
                    width: "16rem",
                    marginBottom: ".6rem",
                  }}
                >
                  <DescriptionIcon
                    className={classes.uploadIcon}
                    style={{
                      marginRight: "8px",
                      fontSize: "22px",
                    }}
                  />
                  <span className="text-black normal-case">
                    Create auction using excel file
                  </span>
                </Button>
                <Button
                  variant="contained"
                  component="label"
                  className={classes.upload}
                  sx={{
                    display: "block",
                    textAlign: "right",
                  }}
                  size="small"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textTransform: "none",
                    width: "16rem",
                    marginBottom: ".6rem",
                  }}
                  onClick={() => setCreateType("manually")}
                >
                  <CreateIcon
                    className={classes.uploadIcon}
                    style={{
                      marginRight: "8px",
                      fontSize: "22px",
                    }}
                  />
                  <span className="text-black normal-case">
                    Create auction manually
                  </span>
                </Button>
              </div>
            </div>
          </div>
        )}
        {createType === "manually" && (
          <div className="container">
            <form onSubmit={handleSubmit}>
              <div className="section-event mb-5">
                <div className="row">
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Group className="mb-3" controlId="auctionTitle">
                        <Form.Label className={classes.label}>
                          Auction Title
                        </Form.Label>
                        <Form.Control
                          className={classes.input}
                          type="text"
                          placeholder="Auction Title"
                          name="auctionTitle"
                          onChange={handleChange}
                          value={eventInfo.username}
                          required
                        />
                      </Form.Group>
                    </Box>
                  </div>
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Group className="mb-3" controlId="refrenceNumber">
                        <Form.Label className={classes.label}>
                          Reference Number
                        </Form.Label>
                        <Form.Control
                          className={classes.input}
                          type="text"
                          name="referenceNumber"
                          onChange={handleChange}
                          value={eventInfo.username}
                          required
                          // placeholder="Refrence No."
                        />
                      </Form.Group>
                    </Box>
                  </div>
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Label className={classes.label}>
                        Description (Limit 250 characters)
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        as="textarea"
                        name="description"
                        onChange={handleChange}
                        value={eventInfo.username}
                        style={{ height: "124px" }}
                        maxLength={250}
                        required
                      />
                    </Box>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <Form.Label className={classes.label}>
                        Event Type
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className={classes.input}
                        name="eventType"
                        onChange={handleChange}
                        value={eventInfo.username}
                        required
                      >
                        <option>Select Event Type</option>
                        <option value="FORWARD_AUCTION">FORWARD AUCTION</option>
                        <option value="REVERSE_AUCTION">REVERSE AUCTION</option>
                      </Form.Select>
                    </div>
                    <Box className={classes.forms}>
                      <Form.Group controlId="ownerOfLots">
                        <Form.Label className={classes.label}>
                          Owner Of Lots
                        </Form.Label>
                        <Form.Control
                          className={classes.input}
                          type="text"
                          name="ownerOfLots"
                          onChange={handleChange}
                          value={eventInfo.ownerOfLots}
                          required
                          placeholder="Owner Of Lots"
                        />
                      </Form.Group>
                    </Box>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6">
                        <Box className={classes.forms}>
                          <Form.Group className="mt-3">
                            <Form.Label className={classes.label}>
                              Test Project
                            </Form.Label>
                            <Box sx={{ display: "flex" }}>
                              <Form.Group className="">
                                <Form.Check
                                  name="testProject"
                                  onChange={handleChange}
                                  value="true"
                                  type="radio"
                                  label="Yes"
                                />
                              </Form.Group>
                              <Form.Group className={classes.radio}>
                                <Form.Check
                                  name="testProject"
                                  onChange={handleChange}
                                  value="false"
                                  type="radio"
                                  label="No"
                                />
                              </Form.Group>
                            </Box>
                          </Form.Group>
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3" style={{ marginTop: ".8rem" }}>
                      <Form.Label className={classes.label}>
                        Bid Type
                      </Form.Label>
                      <Form.Select
                        className={classes.input}
                        name="bidType"
                        onChange={handleChange}
                        value={eventInfo.bidType}
                        required
                      >
                        <option>Select Bid Type</option>
                        <option value="RELATIVE_TO_LAST_BID_BY_ANY_USER">
                          Relative to last bid by any user
                        </option>
                        <option value="RELATIVE_TO_LAST_BID_BY_SELF">
                          Relative to user's own last bid
                        </option>
                      </Form.Select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Box className={classes.break} />
                  </div>
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Group className="" controlId="vanueAddress">
                        <Form.Label className={classes.label}>
                          Vanue Address
                        </Form.Label>
                        <Form.Control
                          className={classes.input}
                          type="text"
                          name="streetOne"
                          onChange={handleChange}
                          value={eventInfo.streetOne}
                          required
                          placeholder="Street Address"
                        />
                      </Form.Group>
                    </Box>
                  </div>
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Group className="" controlId="vanueAddress">
                        <Form.Label
                          className={classes.label}
                          style={{ visibility: "hidden" }}
                        >
                          Vanue Address
                        </Form.Label>
                        <Form.Control
                          className={classes.input}
                          type="text"
                          name="streetTwo"
                          onChange={handleChange}
                          value={eventInfo.streetTwo}
                          required
                          placeholder="Street Address Second"
                        />
                      </Form.Group>
                    </Box>
                  </div>
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Group className="" controlId="vanueAddress">
                        <Form.Label
                          className={classes.label}
                          style={{ visibility: "hidden" }}
                        >
                          Vanue Address
                        </Form.Label>
                        <Form.Control
                          className={classes.input}
                          type="text"
                          name="city"
                          onChange={handleChange}
                          value={eventInfo.city}
                          required
                          placeholder="City"
                        />
                        {errors.city && <span>{errors.city}</span>}
                      </Form.Group>
                    </Box>
                  </div>
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Group className="" controlId="vanueAddress">
                        <Form.Label
                          className={classes.label}
                          style={{ visibility: "hidden" }}
                        >
                          Vanue Address
                        </Form.Label>
                        <Form.Control
                          className={classes.input}
                          type="text"
                          name="postalCode"
                          onChange={handleChange}
                          value={eventInfo.postalCode}
                          required
                          placeholder="Postal Code  "
                        />
                      </Form.Group>
                    </Box>
                  </div>
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Group className="" controlId="vanueAddress">
                        <Form.Label
                          className={classes.label}
                          style={{ visibility: "hidden" }}
                        >
                          Vanue Address
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className={classes.input}
                          name="country"
                          onChange={handleChange}
                          required={true}
                        >
                          <option>Country</option>
                          {countriesList.list.map((country, index) => {
                            return (
                              <option value={country.id}>{country.name}</option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Box>
                  </div>
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Group className="" controlId="vanueAddress">
                        <Form.Label
                          className={classes.label}
                          style={{ visibility: "hidden" }}
                        >
                          Vanue Address
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className={classes.input}
                          name="province"
                          onChange={handleChange}
                          required={true}
                        >
                          <option>Province</option>
                          {stateList.list.map((state, index) => {
                            return (
                              <option value={state.id}>{state.name}</option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Box>
                  </div>
                  <div className="col-md-12">
                    <Box className={classes.break} />
                  </div>
                  <div className="col-md-12">
                    <div className={classes.uploader}>
                      {/* Event Images */}
                      <div className="row">
                        <div className="col-md-6">
                          Event Image (JPG,JPEG,PNG)
                          <p className={classes.imageSize}>
                            Dimension: 260px X 200px
                          </p>
                          <ImageUploading
                            value={images}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                            acceptType={["jpg", "jpeg", "png"]}
                          >
                            {({
                              imageList,
                              onImageUpload,
                              dragProps,
                              errors,
                            }) => (
                              <>
                                <div className="upload__image-wrapper">
                                  <div className="col-md-12">
                                    <Box sx={{ display: "flex" }}>
                                      <Box sx={{ width: "100%" }}>
                                        <Button
                                          variant="contained"
                                          component="label"
                                          className={classes.upload}
                                          sx={{
                                            display: "block",
                                            textAlign: "right",
                                          }}
                                          onClick={onImageUpload}
                                          {...dragProps}
                                        >
                                          <BsUpload
                                            className={classes.uploadIcon}
                                          />
                                        </Button>
                                      </Box>
                                    </Box>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="row">
                                      {imageList.map((image, index) => (
                                        <div className="col-md-4">
                                          <div
                                            key={index}
                                            className="image-item"
                                          >
                                            <img
                                              src={image["data_url"]}
                                              alt=""
                                              width="100"
                                              className={classes.imagesStyle}
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                {errors && (
                                  <div>
                                    {errors.maxNumber && (
                                      <span>
                                        Number of selected images exceed
                                        maxNumber
                                      </span>
                                    )}
                                    {errors.acceptType && (
                                      <span>
                                        Your selected file type is not allow
                                      </span>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </ImageUploading>
                        </div>

                        {/* catalogue Pdf */}
                        <div className="col-md-6">
                          Catalogue (PDF)
                          <p
                            className={classes.imageSize}
                            style={{ display: "none" }}
                          >
                            Dimension: 260px X 200px
                          </p>
                          <input
                            type="file"
                            accept=".pdf"
                            style={{ marginTop: "30px" }}
                            className="form-control"
                            required
                            onChange={handlePdfFileChange}
                          />
                          {pdfFileError && (
                            <div className="error-msg">{pdfFileError}</div>
                          )}
                          {/* <br></br>                     */}
                          <div
                            style={{ height: "30%" }}
                            className="pdf-container"
                          >
                            {viewPdf ? (
                              <>
                                <div
                                  style={{ display: "block", height: "30em" }}
                                >
                                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                                    <Viewer
                                      fileUrl={viewPdf}
                                      plugins={[defaultLayoutPluginInstance]}
                                    />
                                  </Worker>
                                </div>
                              </>
                            ) : (
                              <p style={{ height: "2em" }}>No Pdf Selected</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={classes.saveBtn}>
                      <Button className={classes.next} type="submit">
                        Save & Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default CreateEvent;
