import React from "react";
// import "../Css/banner.css";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import useStyles from "./banner.styles";
import bannerImage from "../../../../assets/common/banner.png";
import { useNavigate } from "react-router-dom";
import routes from "../../../../constants/routes";

function Banner() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <Box className="Banner-container">
        <Grid container>
          <div className="col-md-8">
            <div className={classes.BannerContent}>
              <Box>
                <Typography className={classes.BannerHeading}>
                  Where Every Bid Counts: V3bid Auctions
                </Typography>
                <Typography className={classes.listHead}>
                  "Your auction, your values, our integrity - Bid the way you
                  want"
                </Typography>
              </Box>
              <div className={classes.cardBox}>
                <div className="row alignCenter">
                  <div className="col-md-4 mob-card">
                    <Card
                      variant="outlined"
                      onClick={() => {
                        navigate(routes.register);
                      }}
                      className={classes.BannerCards}
                    >
                      <CardContent>
                        <Typography variant="h6" component="div">
                          <span className={classes.serialContent}>
                            <span className={classes.serialNumber}>1</span> Sign
                            Up
                          </span>
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.cardContent}
                        >
                          Unlock endless auction possibilities!
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>

                  <div className="col-md-4 mob-card">
                    <Card variant="outlined" className={classes.BannerCards}>
                      <CardContent>
                        <Typography variant="h6" component="div">
                          <span className={classes.serialContent}>
                            <span className={classes.serialNumber}>2</span>{" "}
                            Uncover
                          </span>
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.cardContent}
                        >
                          Reveal the possibilities!
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                  <div className="col-md-4 mob-card">
                    <Card
                      variant="outlined"
                      onClick={() => {
                        navigate(routes.auction);
                      }}
                      className={classes.BannerCards}
                    >
                      <CardContent>
                        <Typography variant="h6" component="div">
                          <span className={classes.serialContent}>
                            <span className={classes.serialNumber}>3</span>{" "}
                            V3bid
                          </span>
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.cardContent}
                        >
                          Make every bid count!
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {/* <div className="Banner-image">
              <img className={classes.banner} src={bannerImage} alt="Banner" />
            </div> */}
          </div>
        </Grid>
      </Box>
    </>
  );
}

export default Banner;
