import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import lodash from "lodash";
import momentTimeZone from "moment-timezone";

import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import { MdSignalWifiStatusbarConnectedNoInternet as WifiSVG } from "react-icons/md";
import { Mount, MountCondition } from "./mui-methods";
import useStyles from "./mui-component-style";

import Countdown from "react-countdown";
import { toast } from "react-toastify";
import Carousel from "react-bootstrap/Carousel";
import tempImg from "../assets/temp-img.png";
import { getUserData } from "../common";
import CountdownRenderer from "./../views/components/Auction/AuctionDetail/CountdownRenderer";
import { TimerContext } from "../app/App.js";

const ResponsiveCard = React.memo((props) => {
  const classes = useStyles();
  const eventDetails = props.eventDetails ?? {};
  const lotDetails = props.lotDetails ?? {};
  const timeZone =
    localStorage.getItem("currentTimeZone") || momentTimeZone.tz.guess();
  const userData = getUserData() ?? {};
  const timerContext = useContext(TimerContext);

  const currentTime = moment().tz(timeZone).format();
  const publishDateTime = moment(eventDetails.publishDateTime)
    .tz(timeZone)
    .format(); // event publish date
  const bidStartDateTime = moment(eventDetails.bidStartDateTime)
    .tz(timeZone)
    .format(); // event start date
  const startDateAndTime = moment(lotDetails.startDateAndTime)
    .tz(timeZone)
    .format(); // lot start date
  const endDateAndTime = moment(lotDetails.endDateAndTime)
    .tz(timeZone)
    .format(); // lot end date

  let timeDifferenceMs = moment(endDateAndTime).diff(currentTime);
  // let timeDifferenceMs = moment(endDateAndTime).diff(timerContext.currentTime);
  const [mountComponent, toggleMountComponent] = useState(
    // moment(timerContext.currentTime).isBefore(endDateAndTime)
    moment(currentTime).isBefore(endDateAndTime)
  );

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [userAmount, updateUserAmount] = useState(null);
  const [finalizing, setFinalizing] = useState(false);
  const [finalizeTOId, setFinalizeTOId] = useState(null);

  const finalizeLot = () => {
    setFinalizing(true);
    const finalizeTimeoutId = setTimeout(() => {
      toggleMountComponent(false);
    }, 5000);

    setFinalizeTOId(finalizeTimeoutId);
  };

  useEffect(() => {
    if (timeDifferenceMs <= 0) {
      return () => null;
    }

    const oneDayMilliseconds = 24 * 60 * 60 * 1000;
    if (timeDifferenceMs > oneDayMilliseconds) {
      timeDifferenceMs = oneDayMilliseconds;
    }

    const timeoutId = setTimeout(() => {
      finalizeLot();
    }, timeDifferenceMs);

    return () => {
      clearTimeout(timeoutId);
      if (finalizeTOId) clearTimeout(finalizeTOId);
    };
  }, [timeDifferenceMs]);

  useEffect(() => {
    // if (timerContext.currentTime >= startDateAndTime) {
    //   updateUserAmount(lotDetails.nextBidAmount ?? 0);
    // }
    if (currentTime >= startDateAndTime) {
      updateUserAmount(lotDetails.nextBidAmount ?? 0);
    }
    return () => null;
  }, [lotDetails.nextBidAmount]);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const storeHandler = () => {
    const isForwardAuction = () => {
      return eventDetails.eventType === "FORWARD_AUCTION";
    };
    const isReverseAuction = () => {
      return eventDetails.eventType === "REVERSE_AUCTION";
    };
    const isPreBiding = () => {
      return eventDetails.previewPeriod !== "NO";
    };

    if (!userAmount) {
      return toast.error(`Please enter the bidding amount.`);
    }
    if (userAmount <= 0) {
      return toast.error(`Bidding amount must be greater than zero. `);
    }

    // if (
    //   timerContext.currentTime >= publishDateTime &&
    //   timerContext.currentTime <= bidStartDateTime &&
    //   isPreBiding()
    // ) {
    //   return props.handleAuctionDialog({
    //     eventDetails,
    //     lotDetails,
    //     userAmount,
    //   });
    // }

    if (
      currentTime >= publishDateTime &&
      currentTime <= bidStartDateTime &&
      isPreBiding()
    ) {
      return props.handleAuctionDialog({
        eventDetails,
        lotDetails,
        userAmount,
      });
    }

    // if (moment(startDateAndTime).isAfter(timerContext.currentTime)) {
    //   return toast.error(`Bidding hasn\'t started yet.`);
    // }

    if (moment(startDateAndTime).isAfter(currentTime)) {
      return toast.error(`Bidding hasn\'t started yet.`);
    }

    if (isForwardAuction() && userAmount < lotDetails.nextBidAmount) {
      return toast.error(
        `Please place a bid higher than the current bid. ${lotDetails.nextBidAmount}`
      );
    } else if (isReverseAuction() && userAmount > lotDetails.nextBidAmount) {
      return toast.error(
        `Please place a bid lower than the current bid. ${lotDetails.nextBidAmount}`
      );
    }

    return props.handleAuctionDialog({ eventDetails, lotDetails, userAmount });
  };

  return (
    <Mount condition={mountComponent}>
      <Card className={classes.card}>
        <CardContent className="problem-maker">
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={3}
              style={{ backgroundColor: "transparent" }}
            >
              <MountCondition
                style={{ height: "12em" }}
                condition={lodash.isEmpty(lotDetails.eventItemImagesUrl)}
              >
                <MountCondition.True>
                  <Card elevation={1} style={{ height: "200px !important" }}>
                    <CardMedia
                      className={classes.carouselImage}
                      image={tempImg}
                      component="img"
                      alt="product"
                      height="100%"
                    />
                  </Card>
                </MountCondition.True>
                <MountCondition.False>
                  <Carousel>
                    {lotDetails.eventItemImagesUrl.map(
                      (eventItemImages, index) => (
                        <Carousel.Item key={index}>
                          <Card
                            elevation={1}
                            style={{ height: "200px !important" }}
                          >
                            <CardMedia
                              className={classes.carouselImage}
                              image={eventItemImages}
                              component="img"
                              alt="product"
                              height="100%"
                            />
                          </Card>
                        </Carousel.Item>
                      )
                    )}
                  </Carousel>
                </MountCondition.False>
              </MountCondition>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={0}>
                <Grid className={classes.auctionDetails}>
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={4}
                      md={3}
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "20px",
                        borderRight: "1px solid #ccc",
                        textAlign: "center",
                      }}
                    >
                      <Typography className={classes.lotNumber}>LOT</Typography>
                      <Typography className={classes.ellipsis}>
                        {lotDetails.lotNumber}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={8}
                      md={9}
                      style={{
                        backgroundColor: "transparent",
                        padding: "0px",
                        borderRight: "none !important",
                      }}
                    >
                      <Typography className={classes.shortDescription}>
                        {lotDetails.shortDescription}
                      </Typography>
                      <Typography className={classes.longDescription}>
                        {lotDetails.longDescription}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={7} className={classes.leadBidContainer}>
                  <Box className={classes.leadBidBox}>
                    <Typography className={classes.leadBidText}>
                      Lead Bid &nbsp;
                      <MountCondition
                        condition={eventDetails.previewPeriod !== "NO"}
                      >
                        <MountCondition.True>
                          {/* <Mount condition={startDateAndTime > timerContext.currentTime}>
                            <span>XXX</span>
                          </Mount> */}
                          <Mount condition={startDateAndTime > currentTime}>
                            <span>XXX</span>
                          </Mount>
                          {/* <Mount
                            condition={
                              timerContext.currentTime > startDateAndTime &&
                              eventDetails.showH1L1Bid === "YES"
                            }
                          >
                            {lotDetails.currency} {lotDetails.highestBid}{" "}
                          </Mount> */}
                          <Mount
                            condition={
                              currentTime > startDateAndTime &&
                              eventDetails.showH1L1Bid === "YES"
                            }
                          >
                            {lotDetails.currency} {lotDetails.highestBid}{" "}
                          </Mount>
                        </MountCondition.True>
                        <MountCondition.False>
                          <Mount condition={eventDetails.showH1L1Bid === "YES"}>
                            {lotDetails.currency} {lotDetails.highestBid}{" "}
                          </Mount>
                        </MountCondition.False>
                      </MountCondition>
                    </Typography>
                    <Typography className={classes.leadBidText}>
                      Per {lotDetails.unit}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={5} className={classes.userRankContainer}>
                  <Box
                    className={classes.userRankBox}
                    style={{
                      border:
                        eventDetails.previewPeriod !== "NO" &&
                        moment(lotDetails.startDateAndTime)
                          .tz(timeZone)
                          .format() > moment().tz(timeZone).format()
                          ? "1px solid #db1c1c"
                          : eventDetails.showH1L1Bid === "IN_TOP_RANKS_ONLY"
                          ? lotDetails.myBidStatus.myBidRank >= 1 &&
                            lotDetails.myBidStatus.myBidRank <= 3
                            ? "#329E0C"
                            : "#db1c1c"
                          : lotDetails.myBidStatus.myBidRank == 0 ||
                            lotDetails.myBidStatus.myBidRank > 1
                          ? "1px solid #db1c1c"
                          : "1px solid #329E0C",

                      backgroundColor:
                        eventDetails.previewPeriod !== "NO" &&
                        moment(lotDetails.startDateAndTime)
                          .tz(timeZone)
                          .format() > moment().tz(timeZone).format()
                          ? "#fabcbc"
                          : eventDetails.showH1L1Bid === "IN_TOP_RANKS_ONLY"
                          ? lotDetails.myBidStatus.myBidRank >= 1 &&
                            lotDetails.myBidStatus.myBidRank <= 3
                            ? "#e3ffd9"
                            : "#fabcbc"
                          : lotDetails.myBidStatus.myBidRank == 0 ||
                            lotDetails.myBidStatus.myBidRank > 1
                          ? "#fabcbc"
                          : "#E3FFD9",
                    }}
                  >
                    <MountCondition
                      condition={
                        eventDetails.previewPeriod !== "NO" &&
                        moment(lotDetails.startDateAndTime)
                          .tz(timeZone)
                          .format() > moment().tz(timeZone).format()
                      }
                    >
                      <MountCondition.True>
                        <Typography component="span">Your Rank </Typography>
                        <Typography component="span">N/A</Typography>
                      </MountCondition.True>
                      <MountCondition.False>
                        <MountCondition
                          condition={
                            eventDetails.showH1L1Bid === "IN_TOP_RANKS_ONLY"
                          }
                        >
                          <MountCondition.True>
                            {lotDetails.myBidStatus.myBidRank >= 1 &&
                            lotDetails.myBidStatus.myBidRank <= 3 ? (
                              <Typography component="span">
                                Your bid is among top 3 bids for this lot.
                              </Typography>
                            ) : (
                              <Typography component="span">
                                Your bid is not among top 3 bids for this lot.
                              </Typography>
                            )}
                          </MountCondition.True>
                          <MountCondition.False>
                            <MountCondition
                              condition={lotDetails.myBidStatus.myBidRank == 0}
                            >
                              <MountCondition.True>
                                <Typography component="span">
                                  Your Rank{" "}
                                </Typography>
                                <Typography component="span">N/A</Typography>
                              </MountCondition.True>
                              <MountCondition.False>
                                <Typography component="span">
                                  Your Rank{" "}
                                </Typography>
                                <Typography component="span">
                                  {eventDetails.eventType === "FORWARD_AUCTION"
                                    ? "H"
                                    : "L"}
                                  {lotDetails.myBidStatus.myBidRank}
                                </Typography>
                              </MountCondition.False>
                            </MountCondition>
                          </MountCondition.False>
                        </MountCondition>
                      </MountCondition.False>
                    </MountCondition>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={7}
                  className={classes.amountSecondContainer}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography className={classes.amountSecondHeading}>
                      {lotDetails.status === "live"
                        ? "Bid Ends (D, H:M:S):"
                        : "Bid Starts (D, H:M:S):"}
                    </Typography>
                    <Typography
                      className={
                        lotDetails.status === "live"
                          ? classes.amountSecondValueLive
                          : classes.amountSecondValueUpcoming
                      }
                    >
                      <Countdown
                        className={classes.bidLive}
                        isPlaying={true}
                        date={moment(
                          lotDetails.status === "live"
                            ? lotDetails.endDateAndTime
                            : lotDetails.startDateAndTime
                        )
                          .tz(timeZone)
                          .format()}
                        renderer={(...args) =>
                          CountdownRenderer(finalizing, ...args)
                        }
                      />
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography className={classes.amountSecondHeading}>
                      Quantity:
                    </Typography>
                    <Typography
                      className={
                        lotDetails.status === "live"
                          ? classes.amountSecondValueLive
                          : classes.amountSecondValueUpcoming
                      }
                    >
                      {lotDetails.quantity} {lotDetails.unit}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={5}
                  className={classes.amountThirdContainer}
                >
                  <Box className={classes.amountThirdContainerBox}>
                    <Typography className={classes.amountThirdTextStyle}>
                      {moment(
                        lotDetails.status === "live"
                          ? lotDetails.endDateAndTime
                          : lotDetails.startDateAndTime
                      )
                        .tz(timeZone)
                        .format("DD-MM-YYYY hh:mm:ss A")}
                    </Typography>
                    <Typography className={classes.amountThirdTextStyle}>
                      <Mount condition={eventDetails.previewPeriod !== "NO"}>
                        <Mount
                          condition={
                            moment(lotDetails.startDateAndTime)
                              .tz(timeZone)
                              .format() > moment().tz(timeZone).format()
                          }
                        >
                          <span className={classes.biddingDate}>
                            Amount : XXX
                          </span>
                        </Mount>
                        <Mount
                          condition={
                            moment().tz(timeZone).format() >
                            moment(lotDetails.startDateAndTime)
                              .tz(timeZone)
                              .format()
                          }
                        >
                          <Mount condition={eventDetails.showH1L1Bid === "YES"}>
                            <span className={classes.biddingDate}>
                              Amount :{" "}
                              {lotDetails.quantity * lotDetails.highestBid}
                            </span>
                          </Mount>
                        </Mount>
                      </Mount>

                      <Mount
                        condition={
                          eventDetails.showH1L1Bid === "YES" &&
                          eventDetails.previewPeriod === "NO"
                        }
                      >
                        <span className={classes.biddingDate}>
                          Amount : {lotDetails.quantity * lotDetails.highestBid}
                        </span>
                      </Mount>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={12} md={3} className={classes.amountFourthContainer}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className={classes.amountFourthTextStyle}>
                  Start Price
                </Typography>
                <Typography className={classes.amountFourthTextStyle}>
                  {eventDetails.currency} {lotDetails.startPrice}
                </Typography>
              </Box>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className={classes.amountFourthTextStyle}>
                  {eventDetails.eventType === "FORWARD_AUCTION"
                    ? "Increment"
                    : "Decrement"}
                </Typography>
                <Typography className={classes.amountFourthTextStyle}>
                  {eventDetails.currency} {lotDetails.increment}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={3}
              className={classes.auctionAmountContainer}
            >
              <TextField
                fullWidth={true}
                size="small"
                type="number"
                variant="outlined"
                inputMode="decimal"
                disabled={finalizing}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {lotDetails.currency}
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                    step:
                      eventDetails.variableIncrementDecrement === "NO"
                        ? parseFloat(lotDetails.increment)
                        : 0.1,
                    value: userAmount < 0 ? 0 : userAmount,
                    className: classes.auctionAmountField,
                    onChange: ({ target }) => {
                      updateUserAmount(target.value);
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={3} className={classes.auctionBidContainer}>
              <MountCondition condition={isOnline}>
                <MountCondition.True>
                  <MountCondition
                    condition={
                      finalizing ||
                      lotDetails.myBidStatus.authorized === false ||
                      eventDetails.auctioneerName == userData.username ||
                      userData.role == "ADMIN"
                    }
                  >
                    <MountCondition.True>
                      <Box className={classes.auctionBidErrorBox}>
                        {finalizing ? "finalizing bids..." : "Cannot Bid"}
                      </Box>
                    </MountCondition.True>
                    <MountCondition.False>
                      <MountCondition
                        condition={
                          lotDetails.nextBidAmount <= 0 &&
                          moment
                            .utc()
                            .isSameOrAfter(
                              moment.utc(lotDetails.startDateAndTime)
                            )
                        }
                      >
                        <MountCondition.True>
                          <Box className={classes.auctionBidErrorBox}>
                            Bidding Closed
                          </Box>
                        </MountCondition.True>
                        <MountCondition.False>
                          <Button
                            onClick={storeHandler}
                            className={classes.auctionBidButton}
                          >
                            Bid
                          </Button>
                        </MountCondition.False>
                      </MountCondition>
                    </MountCondition.False>
                  </MountCondition>
                </MountCondition.True>
                <MountCondition.False>
                  <Box className={classes.auctionBidErrorBox}>
                    Network Lost{" "}
                    <WifiSVG style={{ marginLeft: "5px", fontSize: "24px" }} />
                  </Box>
                </MountCondition.False>
              </MountCondition>
            </Grid>

            <Grid item xs={12} md={3} className={classes.auctionRankContainer}>
              <Box className={classes.auctionRankBox}>
                <MountCondition
                  condition={
                    lotDetails.myBidStatus.myLastBid === 0
                    // || currentTime < startDateAndTime // - commented to show prebids
                  }
                >
                  <MountCondition.True>
                    <Typography component="span">Your last Bid</Typography>
                    <Typography component="span"> N/A</Typography>
                  </MountCondition.True>
                  <MountCondition.False>
                    <Typography
                      onClick={() => {
                        props.handleAuctionLotBidsDialog({ ...lotDetails });
                      }}
                      component="span"
                    >
                      Your last Bid{" "}
                    </Typography>
                    <Typography component="span">
                      {lotDetails.currency} {lotDetails.myBidStatus.myLastBid}
                    </Typography>
                  </MountCondition.False>
                </MountCondition>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Mount>
  );
});

export default ResponsiveCard;
