import config from "../../config/config";
import axios from "axios";
import { getToken } from "../../common";

export const auctionImportAPI = async (params, eventId) => {
  return await axios({
    method: "post",
    url: `${config.api.base_url}events/import`,
    data: params,
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: getToken(),
    },
  });
};

export default auctionImportAPI;
