import React, { useState } from "react";
import { Avatar, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Form from "react-bootstrap/Form";
import useStyles from "./profile.style";
import { MdOutlineEdit } from "react-icons/md";
import DashboardMenu from "../../commonComponents/DashboardMenu";
import { getToken, removeToken, getUserData } from "../../../common";
import getUserProfileAPI from "../../../api/getUserProfileAPI";
import updateUserProfileAPI from "../../../api/updateUserProfileAPI";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty, omit } from "lodash";
import getCountries from "../../../api/getContriesAPI";
import getStateAPI from "../../../api/getStateAPI";

function Profile() {
  const classes = useStyles();
  const loginDash = localStorage.getItem("BidWebToken");
  const [editProfile, SeteditProfile] = useState(false);
  const [typeBuySel, SetTypeBuySel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userData = getUserData() ?? {};

  const [profileDetail, setProfileDetail] = useState({
    apiState: "loading",
    list: "",
  });

  const getProfileData = async () => {
    try {
      const { data } = await getUserProfileAPI();

      if (data) {
        setProfileDetail({ apiState: "success", list: data });
        stateData(data.country);
      } else throw new Error(data);
    } catch (error) {
      console.clear();
      console.log(error);
    }
  };
  React.useEffect(() => {
    getProfileData();
    return () => {
      return false;
    };
  }, []);

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      console.log("Password type", passwordType);
      return;
    }
    setPasswordType("password");
  };

  // Validation Form

  const [errors, setErrors] = useState({});

  const validate = (event, name, value) => {
    //A function to validate each input values
    const re = /^[A-Za-z ]+$/;
    switch (name) {
      case "firstName":
        if (!re.test(value)) {
          // we will set the error firstName

          setErrors({
            ...errors,
            firstName: "First Name should contains only Letters",
          });
        } else {
          // set the error firstName empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "firstName");
          setErrors(newObj);
        }
        break;

      case "lastName":
        if (!re.test(value)) {
          // we will set the error lastName

          setErrors({
            ...errors,
            lastName: "Last Name should contains only Letters",
          });
        } else {
          // set the error lastName empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "lastName");
          setErrors(newObj);
        }
        break;

      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter a valid email address",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "password":
        if (
          !new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            password: "Enter a valid password",
          });
        } else {
          let newObj = omit(errors, "password");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    password: "",
    alternateContactNumber: "",
  });

  const handleChange = (event) => {
    event.persist();
    validate(event, event.target.name, event.target.value);
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
    console.log("UserInfo", userInfo);
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      console.log("userInfo", userInfo);
      if (!isEmpty(errors)) {
        toast("Please fill correct value on all fields. !");
      } else {
        userInfo.firstName = userInfo.firstName
          ? userInfo.firstName
          : profileDetail.list.firstName;
        userInfo.lastName = userInfo.lastName
          ? userInfo.lastName
          : profileDetail.list.lastName;
        userInfo.alternateContactNumber = userInfo.alternateContactNumber
          ? userInfo.alternateContactNumber
          : profileDetail.list.alternateContactNumber;
        userInfo.contactNumber = userInfo.contactNumber
          ? userInfo.contactNumber
          : profileDetail.list.contactNumber;
        userInfo.email = userInfo.email
          ? userInfo.email
          : profileDetail.list.email;
        userInfo.password = userInfo.password
          ? userInfo.password
          : profileDetail.list.password;

        const { data } = await updateUserProfileAPI(userInfo);
        console.log("Datagg", data);
        if (data.updateUser[0] === 1) toast("Profile Updated Successfully. !");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 500) {
        toast("Network Error!");
        setIsLoading(false);
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log("Error", error.message.code);
        setIsLoading(false);
      }
    }
  };

  const [userInfoSeller, setUserInfoSeller] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    alternateContactNumber: "",
    bi_tin_ein: "",
    businessType: "",
    companyName: "",
    city: "",
    country: "",
    postalCode: "",
    state: "",
    streetOne: "",
    streetTwo: "",
    title: "",
    // password:""
  });

  const handleChangeSeller = (event) => {
    event.persist();
    validate(event, event.target.name, event.target.value);
    if (event.target.name === "country") stateData(event.target.value);
    setUserInfoSeller({
      ...userInfoSeller,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitSeller = async (event) => {
    try {
      event.preventDefault();
      console.log(userInfoSeller);
      if (!isEmpty(errors)) {
        toast("Please fill correct value on all fields. !");
      } else {
        userInfoSeller.firstName = userInfoSeller.firstName
          ? userInfoSeller.firstName
          : profileDetail.list.firstName;
        userInfoSeller.lastName = userInfoSeller.lastName
          ? userInfoSeller.lastName
          : profileDetail.list.lastName;
        userInfoSeller.alternateContactNumber =
          userInfoSeller.alternateContactNumber
            ? userInfoSeller.alternateContactNumber
            : profileDetail.list.alternateContactNumber;
        userInfoSeller.contactNumber = userInfoSeller.contactNumber
          ? userInfoSeller.contactNumber
          : profileDetail.list.contactNumber;
        userInfoSeller.email = userInfoSeller.email
          ? userInfoSeller.email
          : profileDetail.list.email;
        // userInfoSeller.password = userInfoSeller.password
        // ? userInfoSeller.password
        // : profileDetail.list.password;

        userInfoSeller.companyName = userInfoSeller.companyName
          ? userInfoSeller.companyName
          : profileDetail.list.companyName;
        userInfoSeller.bi_tin_ein = userInfoSeller.bi_tin_ein
          ? userInfoSeller.bi_tin_ein
          : profileDetail.list.bi_tin_ein;
        userInfoSeller.businessType = userInfoSeller.businessType
          ? userInfoSeller.businessType
          : profileDetail.list.businessType;
        userInfoSeller.city = userInfoSeller.city
          ? userInfoSeller.city
          : profileDetail.list.city;
        userInfoSeller.country = userInfoSeller.country
          ? userInfoSeller.country
          : profileDetail.list.country;
        userInfoSeller.postalCode = userInfoSeller.postalCode
          ? userInfoSeller.postalCode
          : profileDetail.list.postalCode;
        userInfoSeller.state = userInfoSeller.state
          ? userInfoSeller.state
          : profileDetail.list.state;
        userInfoSeller.streetOne = userInfoSeller.streetOne
          ? userInfoSeller.streetOne
          : profileDetail.list.streetOne;
        userInfoSeller.streetTwo = userInfoSeller.streetTwo
          ? userInfoSeller.streetTwo
          : profileDetail.list.streetTwo;
        userInfoSeller.title = userInfoSeller.title
          ? userInfoSeller.title
          : profileDetail.list.title;

        const { data } = await updateUserProfileAPI(userInfoSeller);

        if (data.updatePartner[0] === 1)
          toast("Profile Updated Successfully. !");
      }
    } catch (error) {
      console.log(error.response.data.error);
      toast(error.response.data.error);
      if (error.response.status === 500) {
        toast("Network Error!");
        setIsLoading(false);
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log("Error", error.message.code);
        setIsLoading(false);
      }
    }
  };

  //Get Countries Nad States

  const [countriesList, updateCountriesList] = useState({
    apiState: "loading",
    list: [],
  });

  const [stateList, updateStateList] = useState({
    apiState: "loading",
    list: [],
  });

  const countryList = async () => {
    try {
      const { data } = await getCountries();

      if (data) {
        if (data) updateCountriesList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    countryList();
    return () => {
      return false;
    };
  }, []);

  const stateData = async (countryId) => {
    try {
      console.log(countryId);
      const { data } = await getStateAPI(countryId);

      if (data) {
        if (data) updateStateList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    stateData();
    return () => {
      return false;
    };
  }, []);

  console.clear();
  console.log("profileDetail.list", profileDetail.list);

  return (
    <div className={classes.section}>
      <div className="container">
        {/* <p>{JSON.stringify(errors)}</p> */}
        <div className={classes.Dashboard}>
          <div className={classes.dashboardCard}>
            <DashboardMenu />
            {userData.role === "BUYER" ? (
              <form onSubmit={handleSubmit}>
                <div className={classes.dashboardCards}>
                  <Typography className={classes.headText}>
                    Personal Details
                  </Typography>
                  <div className={classes.forms}>
                    <div className="row">
                      <div className="col-md-6">
                        <Box className={classes.forms}>
                          <Form.Group className="mb-3">
                            <Form.Label className={classes.label}>
                              Name
                            </Form.Label>
                            <Form.Control
                              className={classes.input}
                              type="text"
                              name="firstName"
                              placeholder="First Name"
                              required
                              value={
                                userInfo.firstName
                                  ? userInfo.firstName
                                  : profileDetail.list.firstName
                              }
                              onChange={handleChange}
                              disabled={!editProfile}
                            />
                            {errors.firstName && (
                              <span>{errors.firstName}</span>
                            )}
                          </Form.Group>
                        </Box>
                      </div>
                      <div className="col-md-6">
                        <Box className={classes.forms}>
                          <Form.Group className="mb-3">
                            <Form.Label className={classes.label}>
                              Last Name
                            </Form.Label>
                            <Form.Control
                              className={classes.input}
                              type="text"
                              name="lastName"
                              placeholder="Last Name"
                              required
                              value={
                                userInfo.lastName
                                  ? userInfo.lastName
                                  : profileDetail.list.lastName
                              }
                              onChange={handleChange}
                              disabled={!editProfile}
                            />
                            {errors.lastName && <span>{errors.lastName}</span>}
                          </Form.Group>
                        </Box>
                      </div>
                      <div className="col-md-6">
                        <Form.Label className={classes.label}>
                          Contact Number
                        </Form.Label>
                        <Box sx={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "20%" }}
                            className={classes.input}
                            type="number"
                            placeholder="+91"
                            required
                            disabled={true}
                          />
                          <Form.Control
                            className={classes.input}
                            type="number"
                            name="contactNumber"
                            placeholder="Contact Number"
                            required
                            value={
                              userInfo.contactNumber
                                ? userInfo.contactNumber
                                : profileDetail.list.contactNumber
                            }
                            onChange={handleChange}
                            disabled={!editProfile}
                          />
                        </Box>
                      </div>
                      <div className="col-md-6">
                        <Form.Label className={classes.label}>
                          Alternate Contact Number
                        </Form.Label>
                        <Box sx={{ display: "flex" }}>
                          <Form.Control
                            style={{ width: "20%" }}
                            className={classes.input}
                            type="number"
                            placeholder="+91"
                            required
                            disabled={true}
                          />
                          <Form.Control
                            className={classes.input}
                            type="number"
                            name="alternateContactNumber"
                            placeholder="Alternate Contact Number"
                            required
                            value={
                              userInfo.alternateContactNumber
                                ? userInfo.alternateContactNumber
                                : profileDetail.list.alternateContactNumber
                            }
                            onChange={handleChange}
                            disabled={!editProfile}
                          />
                        </Box>
                      </div>
                      <div className="col-md-6">
                        <Box className={classes.forms}>
                          <Form.Group className="mt-3">
                            <Form.Label className={classes.label}>
                              Email
                            </Form.Label>
                            <Form.Control
                              className={classes.input}
                              type="email"
                              name="email"
                              placeholder="Email"
                              required
                              value={
                                userInfo.email
                                  ? userInfo.email
                                  : profileDetail.list.email
                              }
                              onChange={handleChange}
                              disabled={!editProfile}
                            />
                            {errors.email && <span>{errors.email}</span>}
                          </Form.Group>
                        </Box>
                      </div>
                      <div className="col-md-6">
                        <Box className={classes.forms}>
                          <Form.Group className="mt-3">
                            <Form.Label className={classes.label}>
                              Password
                            </Form.Label>

                            <div className="row">
                              <div className="col-md-11">
                                <Form.Control
                                  className={classes.input}
                                  type={passwordType}
                                  name="password"
                                  disabled={!editProfile}
                                  value={
                                    userInfo.password
                                      ? userInfo.password
                                      : profileDetail.list.password
                                  }
                                  onChange={handleChange}
                                  autoComplete="new-password"
                                />
                                <p>
                                  {errors.password && (
                                    <span>{errors.password}</span>
                                  )}
                                </p>
                              </div>
                              <div className="col-md-1">
                                <Box className={classes.forms}>
                                  <button
                                    className="btn btn-outline-primary"
                                    onClick={togglePassword}
                                  >
                                    <RemoveRedEyeIcon
                                      style={{ color: "#2D55EB" }}
                                    />
                                  </button>
                                </Box>
                              </div>
                            </div>
                          </Form.Group>
                        </Box>
                      </div>
                      <div className="col-md-6">
                        {editProfile ? (
                          <Box className={classes.saveNextBtns}>
                            <Button
                              onClick={() => SeteditProfile(false)}
                              className={classes.pre}
                            >
                              Cancel
                            </Button>
                            <Button
                              className={classes.next}
                              sx={{ marginLeft: "1em" }}
                              type="submit"
                            >
                              Save
                            </Button>
                          </Box>
                        ) : (
                          <div className={classes.saveBtn}>
                            <Button
                              onClick={() => SeteditProfile(true)}
                              className={classes.next}
                              type="submit"
                            >
                              Edit Profile
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div className={classes.dashboardCards}>
                <Typography className={classes.headText}>
                  Company Information
                </Typography>
                <form onSubmit={handleSubmitSeller}>
                  <div className={classes.forms}>
                    <div className="row">
                      <div className="col-md-6">
                        <Box className={classes.forms}>
                          <Form.Group className="mb-3">
                            <Form.Label className={classes.label}>
                              Company Name
                            </Form.Label>
                            <Form.Control
                              className={classes.input}
                              type="text"
                              name="companyName"
                              placeholder="Company Name"
                              required
                              disabled={!editProfile}
                              value={
                                userInfoSeller.companyName
                                  ? userInfoSeller.companyName
                                  : profileDetail.list.companyName
                              }
                              onChange={handleChangeSeller}
                            />
                          </Form.Group>
                        </Box>
                      </div>
                      <div className="col-md-6">
                        <Box className={classes.forms}>
                          <Form.Group className="mb-3">
                            <Form.Label className={classes.label}>
                              Bussiness Type
                            </Form.Label>
                            <Form.Control
                              className={classes.input}
                              type="text"
                              name="businessType"
                              placeholder="Business Type"
                              required
                              disabled={!editProfile}
                              value={
                                userInfoSeller.businessType
                                  ? userInfoSeller.businessType
                                  : profileDetail.list.businessType
                              }
                              onChange={handleChangeSeller}
                            />
                          </Form.Group>
                        </Box>
                      </div>
                      <div className="col-md-6">
                        <Box className={classes.forms}>
                          <Form.Group className="mb-3">
                            <Form.Label className={classes.label}>
                              Enter your Business Identification Number(BI, TIN,
                              EIN) ?
                            </Form.Label>
                            <Form.Control
                              className={classes.input}
                              type="text"
                              name="bi_tin_ein"
                              required
                              disabled={!editProfile}
                              value={
                                userInfoSeller.bi_tin_ein
                                  ? userInfoSeller.bi_tin_ein
                                  : profileDetail.list.bi_tin_ein
                              }
                              onChange={handleChangeSeller}
                            />
                          </Form.Group>
                        </Box>
                      </div>
                      <div className="col-md-6"></div>

                      <div className="col-md-12">
                        <div className="row">
                          <Form.Label className={classes.label}>
                            Address
                          </Form.Label>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  required
                                  name="streetOne"
                                  placeholder="Street Address"
                                  disabled={!editProfile}
                                  value={
                                    userInfoSeller.streetOne
                                      ? userInfoSeller.streetOne
                                      : profileDetail.list.streetOne
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  required
                                  name="streetTwo"
                                  placeholder="Street Address Line 2"
                                  disabled={!editProfile}
                                  value={
                                    userInfoSeller.streetTwo
                                      ? userInfoSeller.streetTwo
                                      : profileDetail.list.streetTwo
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  required
                                  placeholder="City"
                                  disabled={!editProfile}
                                  name="city"
                                  value={
                                    userInfoSeller.city
                                      ? userInfoSeller.city
                                      : profileDetail.list.city
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  required
                                  placeholder="Postal/Zip Code"
                                  disabled={!editProfile}
                                  name="postalCode"
                                  value={
                                    userInfoSeller.postalCode
                                      ? userInfoSeller.postalCode
                                      : profileDetail.list.postalCode
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Select
                                  aria-label="Default select example"
                                  className={classes.input}
                                  name="country"
                                  onChange={handleChangeSeller}
                                  required={true}
                                  value={
                                    userInfoSeller.country
                                      ? userInfoSeller.country
                                      : profileDetail.list.country
                                  }
                                >
                                  <option>Country</option>
                                  {countriesList.list.map((country, index) => {
                                    return (
                                      <option value={country.id}>
                                        {country.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Select
                                  aria-label="Default select example"
                                  className={classes.input}
                                  name="state"
                                  onChange={handleChangeSeller}
                                  required={true}
                                  value={
                                    userInfoSeller.state
                                      ? userInfoSeller.state
                                      : profileDetail.list.state
                                  }
                                >
                                  <option>States</option>
                                  {stateList.list.map((state, index) => {
                                    return (
                                      <option value={state.id}>
                                        {state.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Box>
                          </div>
                        </div>
                      </div>
                      <Typography className={classes.headText}>
                        Company Representative Information:
                      </Typography>
                      <div className="comapnyRe">
                        <div className="row">
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Label className={classes.label}>
                                  Name
                                </Form.Label>
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  name="firstName"
                                  placeholder="First Name"
                                  required
                                  disabled={!editProfile}
                                  value={
                                    userInfoSeller.firstName
                                      ? userInfoSeller.firstName
                                      : profileDetail.list.firstName
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Label className={classes.label}>
                                  Last Name
                                </Form.Label>
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  name="lastName"
                                  placeholder="Last Name"
                                  required
                                  disabled={!editProfile}
                                  value={
                                    userInfoSeller.lastName
                                      ? userInfoSeller.lastName
                                      : profileDetail.list.lastName
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Label className={classes.label}>
                                  Title
                                </Form.Label>
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  name="title"
                                  placeholder="title"
                                  required
                                  disabled={!editProfile}
                                  value={
                                    userInfoSeller.title
                                      ? userInfoSeller.title
                                      : profileDetail.list.title
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Form.Label className={classes.label}>
                              Contact Number
                            </Form.Label>
                            <Box sx={{ display: "flex" }}>
                              <Form.Control
                                style={{ width: "20%" }}
                                className={classes.input}
                                type="number"
                                placeholder="+91"
                                required
                                disabled={true}
                              />
                              <Form.Control
                                className={classes.input}
                                type="number"
                                name="contactNumber"
                                placeholder="Contact Number"
                                required
                                disabled={!editProfile}
                                value={
                                  userInfoSeller.contactNumber
                                    ? userInfoSeller.contactNumber
                                    : profileDetail.list.contactNumber
                                }
                                onChange={handleChangeSeller}
                              />
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Form.Label className={classes.label}>
                              Alternate Contact Number
                            </Form.Label>
                            <Box sx={{ display: "flex" }}>
                              <Form.Control
                                style={{ width: "20%" }}
                                className={classes.input}
                                type="number"
                                placeholder="+91"
                                required
                                disabled={true}
                              />
                              <Form.Control
                                className={classes.input}
                                type="number"
                                name="alternateContactNumber"
                                placeholder="Alternate Contact Number"
                                required
                                disabled={!editProfile}
                                value={
                                  userInfoSeller.alternateContactNumber
                                    ? userInfoSeller.alternateContactNumber
                                    : profileDetail.list.alternateContactNumber
                                }
                                onChange={handleChangeSeller}
                              />
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="">
                                <Form.Label className={classes.label}>
                                  Email
                                </Form.Label>
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  name="email"
                                  placeholder="email"
                                  required
                                  disabled={!editProfile}
                                  value={
                                    userInfoSeller.email
                                      ? userInfoSeller.email
                                      : profileDetail.list.email
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {editProfile ? (
                          <Box className={classes.saveNextBtns}>
                            <Button
                              onClick={() => SeteditProfile(false)}
                              className={classes.pre}
                            >
                              Cancel
                            </Button>
                            <Button
                              className={classes.next}
                              sx={{ marginLeft: "1em" }}
                              type="submit"
                            >
                              Save
                            </Button>
                          </Box>
                        ) : (
                          <div className={classes.saveBtn}>
                            <Button
                              onClick={() => SeteditProfile(true)}
                              className={classes.next}
                              type="submit"
                            >
                              Edit Profile
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
