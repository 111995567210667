import config from '../config/config'
import axios from 'axios'
import { getToken } from '../common'; 

export default async (payload) => {
    return await axios({
        method  : 'delete',
        url     : `${config.api.base_url}events/${payload.eventId}/item`,
        data    : payload.data,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": getToken(),
        }
}); };
  