import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Button from "@mui/material/Button";
import useStyles from "../dashAuction.style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import eventBuyerListAPI from "../../../../api/adminAPI/eventBuyerListAPI";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Box } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AuctionViewFirst from "./AuctionViewFirst";
import AuctionSummary from "./AuctionSummary";

import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";
import eventDetailsAPI from "../../../../api/adminAPI/eventDetailsAPI";

function BidlogFirst({
  DashBuyerDetail,
  AuctionView,
  eventId,
  AuctionViewFirst,
  AuctionSummary,
}) {
  const classes = useStyles();

  const [tableEventBuyersData, setTableEventBuyersData] = useState([]);
  const [eventType, setEventType] = useState("");

  const getEventBuyers = async (eventId) => {
    try {
      const { data: getEventsDetails } = await eventDetailsAPI(eventId);
      const { data } = await eventBuyerListAPI(eventId);

      if (data) {
        let id = 1;
        const buyersData = data.map((data, index) => {
          return {
            id: id++,
            userName: `${data.user.firstName} ${data.user.lastName}`,
            allotedLots: data.allotedLots,
            bidedLots: data.bidedLots,
            h1RanksCount: data.h1RanksCount,
            BuyerId: data.user.userId,
            h2RanksCount: data.h2RanksCount,
            h3RanksCount: data.h3RanksCount,
          };
        });

        setEventType(getEventsDetails.eventType);
        setTableEventBuyersData(buyersData);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getEventBuyers(eventId);
    return () => false;
  }, [eventId]);

  const nextColumns = [
    {
      name: "Sr.",
      selector: "id",
      cell: (data) => String(data.id),
      sortable: true,
    },
    {
      name: "Buyer",
      cell: (data) => String(data.userName),
      sortable: true,
    },
    {
      name: "Alloted Lots",
      cell: (data) => String(data.allotedLots),
      sortable: true,
    },
    {
      name: "Bidded Lots",
      cell: (data) => String(data.bidedLots),
      sortable: true,
    },
    {
      name: eventType === "FORWARD_AUCTION" ? "H1" : "L1",
      cell: (data) => String(data.h1RanksCount),
      sortable: true,
    },
    {
      name: eventType === "FORWARD_AUCTION" ? "H2" : "L2",
      cell: (data) => String(data.h2RanksCount),
      sortable: true,
    },
    {
      name: "Others",
      cell: (data) => String(data.h3RanksCount),
      sortable: true,
    },
    {
      name: "Action",
      sortable: true,
      width: "14%",
      cell: (data) => (
        <Box sx={{ display: "flex" }}>
          <Button
            className="btn btn-group"
            // onClick={() => {
            //   DashBuyerDetail(data.BuyerId);
            // }}
            onClick={() => {
              AuctionSummary(data.BuyerId);
            }}
          >
            <VisibilityIcon />
          </Button>
          {/* <Button
            className="btn btn-group"
            onClick={() => {
              AuctionSummary();
            }}
          >
            <ArticleIcon />
          </Button> */}
        </Box>
      ),
    },
  ];

  const exportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.userName,
      columns.allotedLots,
      columns.bidedLots,
      columns.h1RanksCount,
      columns.h2RanksCount,
      columns.h3RanksCount,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "buyer-event-summary.xlsx");
  };

  const ExportButton = () => (
    <Button onClick={() => exportToXLSX(tableEventBuyersData, nextColumns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  return (
    <>
      <div className="AuctionTable">
        <h3>
          <Button sx={{ color: "#000" }} onClick={AuctionView}>
            <ArrowBackIcon />
          </Button>
          Buyer Event Summary
        </h3>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={nextColumns}
          data={tableEventBuyersData}
        >
          <DataTable
            noHeader={false}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={false}
            highlightOnHover
            className="DataTable"
            actions={<ExportButton />}
          />
        </DataTableExtensions>
      </div>
    </>
  );
}

export default BidlogFirst;
