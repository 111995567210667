import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  BannerContent: {
    padding: "3em 0em 3em 6em",
    margin: "auto !important",
    [theme.breakpoints.down("mxs")]: {
      padding: "2em",
      marginTop: "2em !important",
    },
    [theme.breakpoints.down("tablet")]: {
      padding: "2em",
    },
  },
  BannerHeading: {
    color: "#ffc400",
    fontSize: "30px !important",
    fontWeight: "600 !important",
    [theme.breakpoints.down("tablet")]: {
      fontSize: "15px !important",
      // marginTop:'2em !important'
    },
  },
  cardBox: { marginTop: "1.5rem", justifyContent: "space-between !important" },
  BannerCards: {
    backgroundColor: " #1441A8 !important",
    borderRadius: "13px !important",
    color: "#fff !important",
    opacity: "1",
    boxShadow: "0px 0px 16px #0000001a",
    border: "none !important",
    [theme.breakpoints.down("mxs")]: {
      marginBottom: "2em !important",
    },
    [theme.breakpoints.down("tablet")]: {
      marginBottom: "1em",
    },
  },
  cardContent: { fontSize: "12px !important", marginTop: "1em !important" },
  serialContent: { fontWeight: "bold", fontSize: "18px" },
  serialNumber: {
    backgroundColor: "#ffc400",
    padding: "0.1rem 0.4rem",
    borderRadius: "1rem",
    fontWeight: "bold",
    color: "#000",
  },
  banner: {
    position: "relative",
    right: "0 !important",
    objectFit: "contain",
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("tablet")]: {
      top: "5em",
    },
    [theme.breakpoints.down("mxs")]: {
      top: "0em",
    },
  },
  listHead: {
    display: "block",
    fontWeight: "600",
    fontSize: "15px !important",
    color: "#fff",
  },
}));

export default useStyles;
