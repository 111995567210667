import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  auction: {
    display: "flex",
    transition: "all ease-in-out .3s",
    width: "100%",
    [theme.breakpoints.down("mxs")]: {
      marginTop: "9em",
    },
  },
  auctionFliter: {
    width: "25%",
    transition: "all ease-in-out .3s",
    [theme.breakpoints.down("mxs")]: {
      display: "none",
    },
  },
  auctionDetail: {
    width: "100%",
    transition: "all ease-in-out .3s",
    background: "#f8f9ff",
    boxShadow: "0px -7px 13px 0px #ccc",
    [theme.breakpoints.down("mxs")]: {
      width: "100%",
      paddingTop: "2em",
    },
  },
  auctionDetailSearch: {
    width: "100%",
    background: "#f8f9ff",
    transition: "all ease-in-out .3s",
    boxShadow: "0px -7px 13px 0px #ccc",
    [theme.breakpoints.down("mxs")]: {
      width: "100%",
      paddingTop: "2em",
    },
  },
}));
export default useStyles;
