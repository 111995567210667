import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainHeadStyling: {
    backgroundColor: "#C4D1FF !important",
    color: "#3A4A81 !important",
    marginRight: "1em !important",
    width: "8em",
    borderRadius: "0px !important",
    [theme.breakpoints.down("md")]: {
      width: "6em !important",
    },
  },
  logoText: {
    fontSize: ".8rem",
    cursor: "default",
    color: "white",
    marginTop: "1rem",
  },
  navbar: {
    marginBottom: "6.4em",
    [theme.breakpoints.down("tablet")]: {
      marginTop: "6.6em",
    },
  },
  input: { borderRadius: "0" },
  signButtonStyle: {
    backgroundColor: "#FFC400 !important",
    color: "#000 !important",
    width: "8em",
    borderRadius: "0px !important",
    [theme.breakpoints.down("md")]: {
      width: "6em !important",
    },
  },
  dashboard: {
    [theme.breakpoints.down("tablet")]: {
      marginRight: "10px !important",
      float: "none !important",
    },
  },
  nameDash: {
    [theme.breakpoints.down("tablet")]: {
      paddingLeft: "5px !important",
      margin: "auto 0em !important",
      fontSize: "14px !important",
    },
  },
  mainSearchStyling: {
    width: "85% !important",
    color: "#fff !important",
    backgroundColor: "#fff !important",
    height: "2.3em !important",
    border: "none !important",
  },
  mainSearchBtn: {
    backgroundColor: "#0D1D58 !important",
    borderRadius: "0px !important",
  },
  searchIcon: { color: "#fff !important" },
  bottomMenu: {
    textAlign: "center !important",
    justifyContent: "center !important",
  },
  logo: { TextDecoder: "none", color: "#fff" },
  logoimg: { width: "4em" },
}));

export default useStyles;
