import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import "react-datetime/css/react-datetime.css";
import useStyles from "./item.style";
import IndexView from "./IndexView";
import ListView from "./ListView";

function Items(props) {
  const classes = useStyles();
  const [hide, setHide] = useState(false);

  return (
    <React.Fragment>
      <div>
        {hide ? (
          <IndexView hide={hide} setHide={setHide} stepper={props} />
        ) : (
          <ListView
            eventDetail={props.eventDetail}
            setHide={setHide}
            stepper={props}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default Items;
