const BASE_URI = process.env.REACT_APP_BACKEND_URI;

const config = {
  api: {
    base_url: BASE_URI,
    socket_url: BASE_URI,
  },
  socialLogin: {
    google: `${BASE_URI}auth/google`,
    facebook: `${BASE_URI}auth/facebook`,
  },
};

export default config;
