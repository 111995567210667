import React from "react";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ListIcon from "@mui/icons-material/List";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const ToggleViewButtons = (props) => {
  const { view, handleViewChange } = props;

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: ".6rem",
      }}
    >
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        aria-label="view toggler"
      >
        <ToggleButton value="list" aria-label="list view">
          <ListIcon />
        </ToggleButton>
        <ToggleButton value="card" aria-label="card view">
          <ViewModuleIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default ToggleViewButtons;
