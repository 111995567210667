import config from "../config/config";
import axios from "axios";
import { getToken } from "../common";

export const saveBidAPI = async (params, eventId, eventItemId) => {
  return await axios({
    method: "post",
    url: `${config.api.base_url}events/${eventId}/item/${eventItemId}/request-bid`,
    data: params,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: getToken(),
    },
  });
};

export default saveBidAPI;
