import { makeStyles } from "@mui/styles";
import colorSchemes from "../../constants/colorSchemes";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: "0em 3em",
    [theme.breakpoints.down("mxs")]: {
      padding: "1em",
    },
  },
  call: { display: "flex", color: "#fff", cursor: "pointer" },
  mail: { display: "flex", color: "#fff" },
  whatsappIcon: {
    width: "46px",
    height: "46px",
    marginTop: ".1rem",
    [theme.breakpoints.down("tablet")]: {
      width: "38px",
      height: "38px",
      marginTop: ".2rem",
    },
  },
  footerBg: { backgroundColor: colorSchemes.primaryColor, padding: "1em" },
  callText: { fontSize: "12px" },
  callNo: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
    [theme.breakpoints.down("mxs")]: {
      fontSize: "10px !important",
    },
  },
  icon: {
    margin: "auto 0",
    backgroundColor: "#fff !important",
    color: "#2d55eb !important",
    [theme.breakpoints.down("mxs")]: {
      width: "30px !important",
      height: "30px !important",
    },
  },
  socialIcon: {
    textAlign: "right",
    [theme.breakpoints.down("mxs")]: {
      textAlign: "center",
      marginTop: "1em",
    },
    [theme.breakpoints.down("tablet")]: {
      textAlign: "left !important",
      marginTop: "1em",
    },
  },
  facebook: {
    marginLeft: "1em",
    width: "20%",
    [theme.breakpoints.down("mxs")]: {
      width: "14%",
    },
  },
  insta: {
    width: "20%",
    [theme.breakpoints.down("mxs")]: {
      width: "14%",
    },
  },
  footerBgSecond: {
    backgroundColor: "#0D1D58",
    padding: "0em 7em",
    [theme.breakpoints.down("mxs")]: {
      padding: "1em",
    },
    [theme.breakpoints.down("tablet")]: {
      padding: "0em 1em",
    },
  },
  footerLink: {
    color: "#fff !important",
    padding: "2em 0em",
    borderBottom: "1px solid #fff",
  },
  link: { paddingRight: "1em !important", color: "#fff !important" },
  copyWrite: { textAlign: "center", color: "#fff", padding: "0.6em" },
}));

export default useStyles;
