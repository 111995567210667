import React from "react";
import { useState } from "react";
import useStyles from "./auctionFilter.style";
import Form from "react-bootstrap/Form";
import { Button, Box, Typography, Select } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import getPartnersListAPI from "../../../../api/getPartnersListAPI";
import { Pagination } from "@mui/material";
import getEventsListAPI from "../../../../api/getEventsListAPI";
import getCountries from "../../../../api/getContriesAPI";
import getStateAPI from "../../../../api/getStateAPI";

function AuctionFilter({
  filterChange,
  setAuctionId,
  auctionIds,
  setEventIdFilter,
  setCountryId,
  setStateId,
  setMobileOpen,
}) {
  const classes = useStyles();

  const [partnersList, updatepartnersList] = useState({
    apiState: "loading",
    list: [],
  });

  const [page, setPage] = React.useState();
  const [pageSize, setPageSize] = React.useState();
  const [pageCount, setPageCount] = React.useState();

  const [countryDropdown, setCountryDropdown] = React.useState("");
  const [provinceDropdown, setProvinceDropdown] = React.useState(null);

  const getPartnersList = async () => {
    try {
      const payload = { pageSize: pageSize, page: page };

      const { data } = await getPartnersListAPI(payload);

      if (data) {
        updatepartnersList({
          apiState: "success",
          list: data.data,
          meta: data.meta,
        });
        setPageCount(data.meta.totalPages);
        setPageSize(data.meta.pageSize);
      } else throw new Error(data);
    } catch (error) {
      console.clear();
      console.log(error);
    }
  };
  React.useEffect(() => {
    getPartnersList();
    return () => {
      return false;
    };
  }, []);

  const [Events, updateEvents] = useState({ apiState: "loading", list: [] });

  const [pageEvents, setPageEvents] = React.useState();
  const [pageSizeEvents, setPageSizeEvents] = React.useState();
  const [pageCountEvents, setPageCountEvents] = React.useState();

  const getEventsList = async () => {
    try {
      const payload = { pageSize: pageSizeEvents, page: pageEvents };

      const { data } = await getEventsListAPI(payload);

      if (data) {
        updateEvents({ apiState: "success", list: data.data, meta: data.meta });
        setPageCountEvents(data.meta.totalPages);
        setPageSizeEvents(data.meta.pageSize);
      } else throw new Error(data);
    } catch (error) {
      console.clear();
      console.log(error);
    }
  };
  React.useEffect(() => {
    getEventsList();
    return () => {
      return false;
    };
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
    getPartnersList();
  };
  const handleChangeEvents = (event, value) => {
    setPageEvents(value);
    getEventsList();
  };

  const onChangeCompany = ({ target }) => {
    if (!target.checked) {
      const data = auctionIds.filter((id) => id !== target.value);
      setAuctionId([...data]);
    } else {
      setAuctionId([...auctionIds, target.value]);
    }
  };

  const onChangeEvent = (eventId) => {
    setEventIdFilter(eventId);
    //console.log(eventId)
  };

  //Get Countries Nad States
  const handleChangeSC = ({ target }) => {
    if (target.name === "country") {
      setStateId("");
      stateData(target.value);
      setCountryId(target.value);
      setCountryDropdown(target.value);
    } else {
      setStateId(target.value);
      setProvinceDropdown(target.value);
    }
  };

  const [countriesList, updateCountriesList] = useState({
    apiState: "loading",
    list: [],
  });

  const [stateList, updateStateList] = useState({
    apiState: "loading",
    list: [],
  });

  const countryList = async () => {
    try {
      const { data } = await getCountries();

      if (data) {
        if (data) updateCountriesList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    countryList();
    return () => {
      return false;
    };
  }, []);

  const stateData = async (countryId) => {
    try {
      const { data } = await getStateAPI(countryId);

      if (data) {
        if (data) updateStateList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    stateData();
    return () => {
      return false;
    };
  }, []);

  const resetFilters = () => {
    setAuctionId([]);
    setCountryId("");
    setStateId("");
    setCountryDropdown("");
    setProvinceDropdown("");
  };

  return (
    <div className={classes.filter}>
      <div className={classes.filterHead}>
        <Typography
          sx={{
            color: "#000",
            fontSize: "18px",
            fontWeight: "600",
            borderBottom: "4px solid #FFC400",
          }}
        >
          Filters
        </Typography>
        {!filterChange && (
          <Typography sx={{ color: "#2D55EB", margin: "auto 0" }}>
            <Button onClick={resetFilters}>Reset All</Button>
          </Typography>
        )}
        <Typography sx={{ margin: "auto 0" }}>
          <Button
            onClick={() => {
              setMobileOpen(false);
            }}
            sx={{ color: "#ff0000" }}
          >
            Close
          </Button>
        </Typography>
      </div>
      {filterChange ? (
        <Box className={classes.changeFilter}>
          {/* <div className={classes.filters}>
            <div className={classes.searchBar}>
              <Form.Group
                className="mb-3 filterSearch"
                controlId="formBasicEmail"
              >
                <Form.Label className={classes.label}>Search</Form.Label>
                <Form.Control
                  className={classes.input}
                  type="search"
                  placeholder="Search Lot"
                  name="search"
                />
                <SearchIcon
                  sx={{
                    position: "absolute",
                    right: "6px",
                    top: "1.7em",
                    color: "#7E8184",
                  }}
                />
              </Form.Group>
            </div>
          </div> */}
          <div className={classes.lotDetail}>
            <div className={classes.lotHead}>
              <Typography variant="h6">Events List</Typography>
            </div>
            {Events.list.map((event, index) => {
              return (
                <div className={classes.lotList} key={index}>
                  <Typography>
                    <span className={classes.number}>{++index}</span>
                    <span
                      onClick={() => {
                        onChangeEvent(event.eventId);
                      }}
                    >
                      {event.auctionTitle}
                    </span>
                  </Typography>
                </div>
              );
            })}
          </div>
          <Pagination
            count={pageCountEvents}
            onChange={handleChangeEvents}
            variant="outlined"
            shape="rounded"
            className="pagination"
            sx={{ justifyContent: "center" }}
          />
        </Box>
      ) : (
        <Box className={classes.filterSection}>
          <Box className={classes.filterselect}>
            <Box className={classes.selectfilter}>
              <Form.Label className={classes.label}>Country</Form.Label>
              <Form.Select
                aria-label="Default select example"
                className={classes.input}
                name="country"
                onChange={handleChangeSC}
                required={true}
                value={countryDropdown}
              >
                <option>Country</option>
                {countriesList.list.map((country, index) => {
                  return <option value={country.id}>{country.name}</option>;
                })}
              </Form.Select>
            </Box>
            <Box className={classes.selectfilter} sx={{ marginTop: "1em" }}>
              <Form.Label className={classes.label}>Province</Form.Label>
              <Form.Select
                aria-label="Default select example"
                className={classes.input}
                name="state"
                onChange={handleChangeSC}
                required={true}
                value={provinceDropdown}
              >
                <option>States</option>
                {stateList.list.map((state, index) => {
                  return <option value={state.id}>{state.name}</option>;
                })}
              </Form.Select>
            </Box>
          </Box>
          <Box className={classes.auctionCheck}>
            <Typography sx={{ color: "#3F4043", fontWeight: "600" }}>
              Auctioneer
            </Typography>
            {partnersList.list.map((partners, index) => {
              return (
                <div className={classes.checkboxes} key={index}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label={partners.companyName}
                      sx={{ fontSize: "14px" }}
                      value={partners.userId}
                      checked={
                        auctionIds === undefined
                          ? false
                          : auctionIds.includes(partners.userId)
                          ? true
                          : false
                      }
                      onClick={(event) => onChangeCompany(event)}
                      name="userId"
                    />
                  </FormGroup>
                </div>
              );
            })}
          </Box>
          <Pagination
            count={pageCount}
            onChange={handleChange}
            // variant="outlined"
            color="primary"
            // shape="rounded"
            className="pagination"
            sx={{ justifyContent: "center", flexWrap: "nowrap" }}
          />
        </Box>
      )}
    </div>
  );
}

export default React.memo(AuctionFilter);
