import React from "react";
import AuctionCard from "./AuctionCard";
import Banner from "./Banner";
import RulesAndsafety from "./RulesAndsafety";

function Home() {
  return (
    <React.Fragment>
      <Banner />
      <AuctionCard />
      <RulesAndsafety />
    </React.Fragment>
  );
}

export default Home;
