//import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../constants/routes";
import { Avatar, TextField } from "@mui/material";
import colorSchemes from "../../constants/colorSchemes";
import Form from "react-bootstrap/Form";
import SearchIcon from "@mui/icons-material/Search";
import useStyles from "./Header.styles";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/logo.png";
// import Logo from "../../assets/temp-img.jpg";
import DashboardIcon from "@mui/icons-material/Dashboard";
//import { getToken, removeToken, getUserData, removeUserData } from "../../common";
import { Dropdown } from "react-bootstrap";
import LogoutIcon from "@mui/icons-material/Logout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import momentTimezone from "moment-timezone";
import lodash from "lodash";

import {
  getToken,
  removeToken,
  getUserData,
  timeZones,
  localTimeZone,
  getTimeZone,
  removeLocalTimeZone,
  removeUserData,
} from "../../common";

const drawerWidth = 240;

let timeout;

const pages = [
  { name: "Auction", navigate: routes.auction },
  { name: "How to sell", navigate: routes.sellerRegister },
  { name: "Help" },
];
function Header(props) {
  const classes = useStyles();
  const { window, handleDetectRouteChange } = props;
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const HideNshow = getToken();

  const userData = getUserData() ? getUserData() : {};
  // console.log("userData", userData )

  const handleClickOnHeaderItems = (item) => {
    navigate(item.path);
  };

  React.useEffect(() => {
    handleDetectRouteChange(location);
  }, [location]);

  const navItems = [
    // { path: routes.home, label: "Home" },
    // { path: routes.about, label: "About" },
    {
      path: routes.register,
      label: "Register",
      isShowOnHeader:
        location.pathname === "/sign-in" || location.pathname === "/register"
          ? false
          : true,
    },
    {
      path: routes.signin,
      label: "Sign In",
      isShowOnHeader:
        location.pathname === "/sign-in" || location.pathname === "/register"
          ? false
          : true,
    },

    // { path: "/contact", label: "Contact" },
  ];

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        backgroundColor: colorSchemes.primaryColor,
        height: "100%",
      }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        <img alt="logo" src={Logo} className="drawerLogo" />
        <div className={classes.logoText}>
          RIVRAM IT SOLUTIONS PRIVATE LIMITED (OPC)
        </div>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <React.Fragment key={index}>
            {item.isShowOnHeader ? (
              <React.Fragment>
                <Button
                  onClick={() => handleClickOnHeaderItems(item)}
                  sx={{ color: "#fff" }}
                >
                  {item.label}
                </Button>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ))}
      </List>
      <Box
        sx={{
          display: {
            xs: "block",
          },
        }}
      >
        <Box
          sx={{
            float: { xs: "left" },
            display: "flex",
            padding: { xs: "0em 1em" },
            marginBottom: "1em",
          }}
        >
          <Avatar
            sx={{
              backgroundColor: "#fff",
              color: colorSchemes.primaryColor,
            }}
          ></Avatar>
          <Typography
            className="userName"
            sx={{
              textAlign: "left",
              margin: "auto",
              paddingLeft: "1em",
              color: colorSchemes.mainColor,
            }}
          >
            {userData.username}
          </Typography>
        </Box>
        <Box
          onClick={() => {
            // navigate(routes.createvent);
            navigate(routes.dashboard);
          }}
          sx={{
            float: { xs: "left" },
            display: "flex",
            padding: { xs: "0em 1em" },
            marginBottom: "5px",
          }}
        >
          <Avatar
            sx={{
              backgroundColor: "#fff",
              color: colorSchemes.primaryColor,
            }}
          >
            <DashboardIcon sx={{ color: colorSchemes.primaryColor }} />
          </Avatar>
          <Typography
            sx={{
              margin: "auto",
              paddingLeft: "1em",
              color: colorSchemes.mainColor,
            }}
          >
            Dashboard
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const defaultTimeZone = momentTimezone.tz.guess();

  const getZone = !lodash.isEmpty(getTimeZone())
    ? getTimeZone()
    : localTimeZone() ?? defaultTimeZone;
  const [liveTime, SetLiveTime] = useState(
    moment().tz(getZone).format("DD/MM/YYYY HH:mm:ss")
  );

  useEffect(() => {
    const interval = setInterval(() => {
      SetLiveTime(moment().tz(getZone).format("DD/MM/YYYY HH:mm:ss"));
    }, 1000);
    return () => clearInterval(interval);
  }, [getZone]);

  const [globalSearch, setGlobalSearch] = useState({
    searchQuery: "",
  });

  const [redirect, setRedirect] = useState([false, ""]);

  const handleChange = (event) => {
    setGlobalSearch({
      ...globalSearch,
      [event.target.name]: event.target.value,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search/${globalSearch.searchQuery}`, { replace: true });
  };

  const sessionTimeoutRef = useRef(null);

  const resetSessionTimeout = () => {
    clearTimeout(sessionTimeoutRef.current);

    sessionTimeoutRef.current = setTimeout(() => {
      // Perform session destroy actions here
      // For example, log the user out or clear session-related data

      // Redirect the user to the login page or show a session expired message
      // removeToken();
      // removeUserData();
      // toast("you have Logout Successfully!");
      setTimeout(() => {
        navigate(routes.home);
      }, 1000);
    }, 60 * 60 * 1000); // 1 hour in milliseconds
  };

  useEffect(() => {
    const handleUserActivity = () => {
      resetSessionTimeout();
    };

    resetSessionTimeout();

    if (typeof window !== "undefined") {
      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);
      // window.addEventListener('beforeunload', handleUserActivity);
      document.addEventListener("visibilitychange", function () {
        if (document.visibilityState === "hidden") {
          // Clear session data or perform other actions
          handleUserActivity();
        }
      });
    }

    return () => {
      clearTimeout(sessionTimeoutRef.current);
      if (typeof window !== "undefined") {
        window.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("keydown", handleUserActivity);
        // window.removeEventListener('beforeunload', handleUserActivity);
        document.addEventListener("visibilitychange", function () {
          if (document.visibilityState === "hidden") {
            // Clear session data or perform other actions
            handleUserActivity();
          }
        });
      }
    };
  }, []);

  return (
    <>
      {/* <Box sx={{ marginBottom: "2.2em" }}> */}
      <Box className={classes.navbar}>
        <ToastContainer autoClose={1000} />
        <AppBar component="nav">
          <div>
            <div className="container" style={{ padding: "1em" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { sm: "none" }, position: "absolute" }}
              >
                <MenuIcon />
              </IconButton>
              <div className="row">
                <div className="col-md-4">
                  <Box xs={"10"}>
                    <Link
                      to={"/"}
                      className={classes.logo}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        // onClick={() => handleClickOnHeaderItems(item)}
                        sx={{
                          flexGrow: 1,
                          display: {
                            xs: "block",
                            sm: "block",
                            height: "46px",
                            textAlign: "left",
                          },
                        }}
                        className="logo"
                      >
                        <img
                          // className={classes.logoimg}
                          alt="logo"
                          className="mainLogo"
                          src={Logo}
                        />
                        <div
                          className={classes.logoText}
                          style={{ marginTop: ".5rem" }}
                        >
                          RIVRAM IT SOLUTIONS PRIVATE LIMITED (OPC)
                        </div>
                      </Typography>
                    </Link>
                  </Box>
                </div>
                <div className="col-md-4">
                  <Box xs={12} md={4}>
                    {/* {getRouteToShowSearchInput() ? ( */}
                    <form
                      onSubmit={(e) => {
                        handleSearch(e);
                      }}
                    >
                      <React.Fragment>
                        <div className="searchbar">
                          <Box
                            sx={{
                              display: "inline-flex",
                              width: "100%",
                            }}
                          >
                            <Form.Control
                              className={classes.input}
                              type="text"
                              placeholder="Search"
                              onChange={handleChange}
                              name="searchQuery"
                              value={globalSearch.searchQuery}
                            />
                            <Button
                              className={classes.mainSearchBtn}
                              type="submit"
                            >
                              <SearchIcon className={classes.searchIcon} />
                            </Button>
                          </Box>
                        </div>
                      </React.Fragment>
                    </form>
                    {/* ) : null} */}
                  </Box>
                </div>
                <div className="col-md-4">
                  {HideNshow === null ? (
                    <Box
                      sx={{
                        display: { xs: "none", sm: "flex" },
                        float: "right",
                      }}
                    >
                      {navItems.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.isShowOnHeader ? (
                            <React.Fragment>
                              <Button
                                onClick={() => handleClickOnHeaderItems(item)}
                                className={
                                  item.label === "Sign In"
                                    ? classes.signButtonStyle
                                    : classes.mainHeadStyling
                                }
                                sx={{
                                  color: "#fff",
                                  textTransform: "inherit",
                                }}
                              >
                                {item.label}
                              </Button>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          sm: "block",
                        },
                      }}
                      className="display"
                    >
                      <Box
                        sx={{
                          float: "right",
                          display: "flex",
                          marginLeft: "2em",
                        }}
                        className={classes.dashboard}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: "#fff",
                            color: colorSchemes.primaryColor,
                          }}
                          className="avatar"
                        ></Avatar>
                        {/* <Typography
                          className={classes.nameDash}
                          sx={{ margin: "auto", paddingLeft: "1em" }}
                        >
                          Tanishq
                        </Typography> */}
                        <Dropdown
                          style={{
                            background: "#2D55EB",
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          <Dropdown.Toggle
                            style={{
                              background: "#2D55EB",
                              color: "#fff",
                              border: "none",
                            }}
                            variant="success"
                            id="dropdown-basic"
                          >
                            {userData.username}
                          </Dropdown.Toggle>

                          <Dropdown.Menu style={{ border: "none" }}>
                            <Dropdown.Item
                              onClick={() => {
                                navigate(routes.dashboard);
                              }}
                            >
                              <DashboardIcon
                                sx={{
                                  color: colorSchemes.primaryColor,
                                  fontSize: "16px",
                                  marginTop: "-2px",
                                  marginRight: "5px",
                                }}
                              />
                              DashBoard
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                removeToken();
                                removeUserData();
                                toast("Logout Successfully!");
                                setTimeout(() => {
                                  navigate(routes.home);
                                }, 1000);
                              }}
                            >
                              <LogoutIcon
                                sx={{
                                  color: colorSchemes.primaryColor,
                                  fontSize: "16px",
                                  marginTop: "-2px",
                                  marginRight: "5px",
                                }}
                              />
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Box>
                    </Box>
                  )}
                </div>
              </div>
            </div>
            <div
              className="col-md-12"
              style={{ backgroundColor: "#fff", width: "100vw" }}
            >
              <div className="container">
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "flex", md: "flex" },
                    backgroundColor: "#fff",
                    textAlign: "center",
                    position: "relative",
                  }}
                  className={classes.bottomMenu}
                >
                  {pages.map((page) => (
                    <Button
                      onClick={() => {
                        navigate(page.navigate);
                      }}
                      key={page.name}
                      sx={{
                        my: 1,
                        color: "#3F4043",
                        display: "block",
                        height: "2.4em",
                        margin: "0em 1em",
                        padding: "0",
                        textTransform: "inherit",
                      }}
                    >
                      {page.name}
                    </Button>
                  ))}
                  <Typography
                    className={classes.nameDash}
                    sx={{
                      display: { xs: "none", sm: "flex" },
                      float: "right",
                      color: "#000",
                      padding: "7px",
                      position: "absolute",
                      right: "0",
                      fontSize: "0.875rem",
                    }}
                  >
                    {liveTime}
                  </Typography>
                </Box>
              </div>
            </div>
          </div>

          <Box>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
        </AppBar>
      </Box>
    </>
  );
}

Header.propTypes = {
  window: PropTypes.func,
};

export default Header;
