import { makeStyles } from "@mui/styles";
import colorSchemes from "../../../constants/colorSchemes";

const useStyles = makeStyles((theme) => ({
  auctionHead: {
    justifyContent: "space-between",
    display: "flex",
    [theme.breakpoints.down("mxs")]: {
      marginTop: "1em",
    },
  },
  auctioncard: { padding: "2em" },
  textHead: { fontWeight: "600 !important" },
  name: { color: colorSchemes.primaryColor, fontSize: "13px" },
  compName: {
    color: colorSchemes.primaryColor,
    fontSize: "13px",
    height: "20px",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  AuctionCard: { padding: "10px" },
  AuctionCards: {
    boxShadow: "0px 0px 16px #00000014",
    position: "relative",
    marginBottom: "2em",
    background: "#fff",
  },
  productImg: { width: "100%", height: "11em", objectFit: "cover" },
  // auctionContent: { padding: "10px" },
  location: {
    fontSize: "14px",
    color: "#7E8184",
    height: "20px",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  lots: { fontSize: "14px", fontWeight: "500" },
  status: {
    color: colorSchemes.warningColor,
    boxShadow: "0px 0px 16px #00000014",
    padding: "8px 10px",
    textAlign: "left",
  },
  input: {
    borderRadius: "0px",
    color: "#3F4043",
    backgroundColor: "#fff",
    border: "none",
    boxShadow: "0px 0px 8px #0000000F",
  },
  label: { color: "#7E8184" },
  star: {
    position: "absolute",
    top: "18px",
    right: "18px",
    width: "30px",
    background: "#fff",
    borderRadius: "100%",
    height: "30px",
    textAlign: "center",
  },
  next: {
    color: "#fff !important",
    backgroundColor: "#2D55EB !important",
    minWidth: "100% !important",
    borderRadius: "0px !important",
  },
  fixedCard: {
    width: "100%",
    height: "22px",
    overflow: "hidden",
    textOverflow: "ellispsis",
  },
  time: { display: "flex" },
  lotsnumber: { fontWeight: "600", color: "#3F4043" },
  cardAuction: {
    marginTop: "3em",
    [theme.breakpoints.down("mxs")]: {
      marginTop: "1em",
    },
  },
  liveD: {
    color: "#07AD00",
  },
  UpcomingD: {
    color: "#DCD500",
  },
  endedD: {
    color: "#EB2D6F",
  },

  firstText: {
    margin: "4px 0em 1em",
    color: "#3F4043",
    fontWeight: "bold",
    fontSize: "small !important",
    position: "absolute"
  },
  liveButton: {
    border: "1px solid #07AD00",
    padding: "0.1rem 1rem",
    fontWeight: "bold !important",
    color: "#07AD00",
    backgroundColor: "#E3FFD9",
    marginRight: "1em",
  },
  Upcoming: {
    border: "1px solid #DCD500",
    padding: "0.1rem 1rem",
    fontWeight: "bold !important",
    color: "#DCD500",
    backgroundColor: "mintcream",
    marginRight: "1em",
  },
  ended: {
    border: "1px solid #EB2D6F",
    padding: "0.1rem 1rem",
    fontWeight: "bold !important",
    color: "#EB2D6F",
    backgroundColor: "antiquewhite",
    marginRight: "1em",
  },
  auctionDetail: {
    width: "100%",
    background: "#f8f9ff",
    boxShadow: "0px -7px 13px 0px #ccc",
    [theme.breakpoints.down("mxs")]: {
      width: "100%",
      paddingTop:'2em'
    },
  },
}));
export default useStyles;
