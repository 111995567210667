import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Form from "react-bootstrap/Form";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { Button } from "@mui/material";
import useStyles from "./auctionCardSearch.style";
import Product from "../../../assets/common/product.png";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AuctionCard from "../Home/AuctionCard";
import globalSearchAPI from '../../../api/globalSearchAPI'
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import lodash from "lodash";
import { useParams } from 'react-router-dom';
import routes from "../../../constants/routes";

function AuctionCardSearch(props) {
  const classes = useStyles();
  const [changeIcon, setChangeIcon] = useState(false);
  const { searchQuery } = useParams();
  const [Events, updateEvents] = useState({ apiState: "loading", list: [] });
  const navigate = useNavigate();

  const getEventsList = async (searchQuery) => {
    try {
      const payload = {
        searchQuery:searchQuery
      };    

      const { data } = await globalSearchAPI(payload);
      
      if (data) {
        updateEvents({ apiState: "success", list: data.data, meta: data.meta });
       
      } else throw new Error(data);
    } catch (error) {
      console.clear();
      console.log(error);
    }
    return true;
  };
  React.useEffect(() => {
    getEventsList(searchQuery);
    window.scrollTo(0,0)
    return () => null;
  }, [searchQuery]); 
 
  return (
    <div className={classes.auctioncard}>
      <div className="container">
      <div className={classes.auctionHead}>          
            <Box>            
              <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                Auction Search List
              </Typography>
              <Typography>We found {Events.meta?.totalEvents} item</Typography>              
            </Box>          
        </div>       
        <div className={classes.cardAuction}>
          <div className="row">           
            {Events.list.map((event, index) => {
              return (
                <div className="col-md-4 mob-card-responsive" key={index}>
                  <div className={classes.AuctionCards}>
                    <div className={classes.AuctionCard}>
                      <p className={classes.firstText}>
                        { event.status === 'live' &&  <span className={classes.liveButton}>Live Now</span>}
                        { event.status === 'ended' &&  <span className={classes.ended}>Ended</span>}
                        { event.status === 'upcoming' &&  <span className={classes.Upcoming}>Upcoming</span>}                       
                      </p>
                      <img
                        // onClick={() => {
                        //   navigate(routes.auction, {
                        //     state: { eventId: event.eventId },
                        //   });
                        // }}
                        onClick={() => { navigate(`/auctions/${event.eventId}`); }}
                        className={classes.productImg}
                        alt="product"
                        src={event.eventImageUrl}
                      />
                      <Box
                        className={classes.auctionContent}
                        onClick={() => {
                          props.setViewCard(true);
                          props.setEventId(event.eventId);
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            paddingTop: "10px",
                          }}
                        >
                          Event - {event.auctionTitle}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            paddingTop: "10px",
                          }}
                        >
                          Ref. No. : {event.referenceNumber}
                        </Typography>
                        <span className={classes.name}>
                          Auctioner Name {event.auctioneerName}
                        </span>
                        <br />
                        <span className={classes.compName}>
                          Company Name {event.companyName}...
                        </span>
                      </Box>
                      <Box className={classes.auctionContent}>
                        <span className={classes.location}>
                          Location:- {event.city}, {event.country}
                        </span>
                      </Box>
                      <Box className={classes.auctionContent}>
                        <span className={classes.lotsnumber}>
                          {event.totalLots} Lots
                        </span>
                      </Box>
                    </div>
                    {/* 
                    <div className={classes.status}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            marginRight: "1em",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Ends in
                        </Typography>
                      </Box>
                    </div> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>       
      </div>
    </div>
  );
}

export default React.memo(AuctionCardSearch);
