import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useStyles from "./event.style";
import Rules from "./Rules";
import { Button } from "@mui/material";
import Items from "./Items";
import colorSchemes from "../../../../constants/colorSchemes";
import { useLocation, useNavigate } from "react-router-dom";
import getEventDetail from "../../../../api/getEventDetail";
import Buyer from "./Buyer";
import ViewBuyerLots from "./ViewBuyerLots";
import routes from "../../../../constants/routes";

export default function Event() {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [eventDetail, setEventDetail] = useState({
    auctionTitle: "",
    EventNo: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const eventId = location.state.data.eventId;

  const getEventData = async () => {
    try {
      const payload = { auctionId: eventId };
      const data = await getEventDetail(payload);
      setEventDetail(data.data);
    } catch (error) {
      console.clear();
      console.log(error);
    }
  };
  React.useEffect(() => {
    getEventData();
    return () => {
      return false;
    };
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <React.Fragment>{children}</React.Fragment>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    // alert('hell')
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <div className={classes.event}>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <Typography
              className={`${classes.textHead},${classes.kuldeep}`}
              sx={{
                marginTop: "2em",
                fontSize: "20px",
                fontWeight: "600",
                marginBottom: "0.1em",
                color: colorSchemes.primaryColor,
              }}
            >
              Event No: {eventDetail.eventNumber}
            </Typography>
          </div>
          <div className="col-md-4">
            <Typography
              className={classes.textHead}
              sx={{
                textAlign: "center",
                padding: "1em 0em",
                fontSize: "20px",
                fontWeight: "600",
                color: colorSchemes.primaryColor,
              }}
            >
              {eventDetail.auctionTitle}
            </Typography>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="section-event p-0">
          <Box sx={{ width: "100%", marginBottom: "2em" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                // onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab className={classes.tab} label="Rules" />
                <Tab className={classes.tab} label="Items" />
                <Tab className={classes.tab} label="Buyer" />
                <Tab className={classes.tab} label="View Buyer/Lots" />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Rules a11yProps={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Items eventDetail={eventDetail} a11yProps={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Buyer />
              <Box className="saveNextBtn">
                <Button
                  className={classes.pre}
                  onClick={() => {
                    handleChange(1);
                  }}
                >
                  Previous
                </Button>
                <Button
                  className={classes.next}
                  onClick={() => {
                    handleChange(3);
                  }}
                >
                  Next
                </Button>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ViewBuyerLots />
              <Box className="saveNextBtn saveButtonDisplay">
                <Button
                  className={classes.pre}
                  onClick={() => {
                    handleChange(2);
                  }}
                >
                  Previous
                </Button>
                <Button
                  className={classes.next}
                  sx={{ marginLeft: "1em" }}
                  onClick={() => {
                    navigate(routes.dashboard);
                  }}
                >
                  Go To Dashboard
                </Button>
              </Box>
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}
