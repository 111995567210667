import React from 'react'

const Mount = React.memo(({ condition, children }) => {
    return condition ? children : null;
});

const MountCondition = React.memo(({ condition, children }) => {
    const [isTrue, isFalse] = React.Children.toArray(children);
    return condition === true ? isTrue : isFalse;
});

MountCondition.True = ({ children }) => children;
MountCondition.False = ({ children }) => children;

export { MountCondition, Mount }
