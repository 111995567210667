import config from "../../config/config";
import axios from "axios";
import { getToken } from "../../common";

export const eventBuyerListAPI = async (eventId) => {
  return await axios({
    method: "get",
    url: `${config.api.base_url}dashboard/events/${eventId}/buyers`,
    //params    : params,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: getToken(),
    },
  });
};

export default eventBuyerListAPI;
