import React from "react";
import { TabPanel } from "@mui/lab";
import renderLots from "./renderLots";

const MountCondition = React.memo(({ condition, children }) => {
  const [isTrue, isFalse] = React.Children.toArray(children);
  return condition === true ? isTrue : isFalse;
});

MountCondition.True = ({ children }) => children;
MountCondition.False = ({ children }) => children;

const RenderTabPanel = (props) => {
  const {
    view,
    value,
    didCatch,
    error,
    columns,
    listLots,
    errorBoundary: ErrorBoundary,
    eventsDetails,
    lots,
    handleAuctionLotBidsDialog,
    handleAuctionDialog,
  } = props;

  return (
    <TabPanel
      value={value}
      className="auctionTab"
      style={{ padding: "24px 0px 24px 0px" }}
    >
      <div className="container p-0">
        <MountCondition condition={didCatch}>
          <MountCondition.True>
            <p>An error has been caught: {JSON.stringify(error, 2, null)}</p>
          </MountCondition.True>
          <MountCondition.False>
            <ErrorBoundary fallback={<p>Something went wrong</p>}>
              {renderLots(
                lots,
                view,
                columns,
                listLots,
                eventsDetails,
                handleAuctionLotBidsDialog,
                handleAuctionDialog,
                value
              )}
            </ErrorBoundary>
          </MountCondition.False>
        </MountCondition>
      </div>
    </TabPanel>
  );
};

export default RenderTabPanel;
