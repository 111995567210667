import config from '../config/config'
import axios from 'axios'
import { getToken } from '../common'; 

export const getEventItemsListAPIAdmin = async (params) => {

    return await axios({
        method  : 'get',
        url     : `${config.api.base_url}dashboard/events/${params.itemId}/event-items`,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": getToken(),
        }
    })
}

export default getEventItemsListAPIAdmin