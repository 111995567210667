import axios from "axios";

import config from "../config/config";
import { getToken } from "../common";

export const getEventReportData = async (eventId) => {
  const token = getToken();

  return await axios({
    method: "get",
    url: `${config.api.base_url}events/${eventId}/report`,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: token,
    },
  });
};

export default getEventReportData;
