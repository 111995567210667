import React, { useEffect, useState, createContext, useContext } from "react";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import Router from "../core/router/Router";
import { toast } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";

import { Button, Typography, Box, Grid } from "@mui/material";
import { Dialog, DialogContent } from "@mui/material";
import { CiWarning } from "react-icons/ci";

import timeSyncApi from "../api/time-sync.api";

const SessionDialogCountdown = ({ timeout, timerProps }) => {
  const [remaining, setRemaining] = useState(timeout);
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(timerProps.getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return <>{remaining}</>;
};

const Mount = React.memo(({ condition, children }) => {
  return condition ? children : null;
});

const SessionInfoComponent = ({
  timeout,
  timerProps,
  state,
  promptBeforeIdle,
  render,
}) => {
  const [remaining, setRemaining] = useState(timeout);
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(timerProps.getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? "seconds" : "second";

  return (
    <Box>
      <h1>React Idle Timer</h1>
      <h2>Confirm Prompt</h2>
      <br />
      <p>Current State: {state}</p>
      {timeTillPrompt > 0 && (
        <p>
          {" "}
          {timeTillPrompt} {seconds} until prompt{" "}
        </p>
      )}
    </Box>
  );
};

export const TimerContext = createContext();

const TimeSyncComponent = React.memo(() => {
  const timeZone = localStorage.getItem("currentTimeZone") || momentTimeZone.tz.guess();
  const timerContext = useContext(TimerContext);

  const syncTime = async () => {
    await timeSyncApi().then(({ data }) => {
      const currentTime = moment(data.utcDateTime).tz(timeZone).format();
      timerContext.updateCurrentTime(currentTime)
    }).catch((...errors) => { console.clear(); console.log("errors", errors); });
  };

  useEffect(() => {
    const intervalId = setInterval(syncTime, 3000);
    return () => clearInterval(intervalId);
  }, []);
});

export default () => {
  const timeout = 3180_000;
  const throttle = 500;
  const promptBeforeIdle = 20_000;
  const timerArguments = {};
  const timeZone = localStorage.getItem("currentTimeZone") || momentTimeZone.tz.guess();

  const [state, setState] = useState("Active");
  const [sessionDialog, toggleSessionDialog] = useState(false);
  const [currentTime, updateCurrentTime] = useState(moment().tz(timeZone).format());

  timerArguments.timeout = timeout;
  timerArguments.throttle = throttle;
  timerArguments.promptBeforeIdle = promptBeforeIdle;

  timerArguments.onIdle = (message, props) => {
    setState("Idle");
    toggleSessionDialog(false);

    if (localStorage.getItem("BidWebToken")) {
      localStorage.removeItem("BidWebToken");
      localStorage.removeItem("userData");

      toast("Session Timeout: All good!");
    }

    return props.pause(true);
  };

  timerArguments.onActive = () => {
    setState("Active");
    return toggleSessionDialog(false) ?? true;
  };
  timerArguments.onPrompt = () => {
    setState("Prompted");
    if (localStorage.getItem("BidWebToken")) {
      return toggleSessionDialog(true) ?? true;
    }
  };

  const timerProps = useIdleTimer({ ...timerArguments });

  return (
    <React.Fragment>
      <Dialog open={sessionDialog} fullWidth={true} maxWidth="xs">
        <DialogContent
          sx={{ textAlign: "center", borderTop: "solid 5px orange" }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              lg={2}
              md={2}
              sm={2}
              xs={2}
              sx={{
                backgroundColor: "transparent",
                paddingTop: "15px !important",
                paddingLeft: "0px !important",
              }}
            >
              <Box
                sx={{
                  fontSize: "60px !important",
                  color: "orange",
                  background: "transparent",
                }}
              >
                <CiWarning style={{ verticalAlign: "top" }}></CiWarning>
              </Box>
            </Grid>
            <Grid
              item
              lg={10}
              md={10}
              sm={10}
              xs={10}
              sx={{
                backgroundColor: "transparent",
                paddingTop: "15px !important",
                paddingLeft: "15px !important",
              }}
            >
              <Box sx={{ textAlign: "left", background: "transparent" }}>
                <Typography
                  sx={{
                    marginTop: "0px !important",
                    fontWeight: "light !important",
                    fontSize: "20px !important",
                    color: "#3f4043 !important",
                  }}
                >
                  Are you still here ?
                </Typography>
                <Typography
                  sx={{
                    marginTop: "8px !important",
                    fontWeight: "light !important",
                    fontSize: "14px !important",
                    color: "#7e8184 !important",
                  }}
                >
                  Your session will automatically log out in{" "}
                  <SessionDialogCountdown
                    timeout={timeout}
                    timerProps={timerProps}
                  />{" "}
                  seconds for security reasons. Please take action if you wish
                  to continue using this session.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ textAlign: "right" }}>
                <Button
                  variant="small"
                  onClick={() => {
                    timerProps.activate();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Mount condition={false}>
        <SessionInfoComponent
          timeout={timeout}
          timerProps={timerProps}
          state={state}
          promptBeforeIdle={promptBeforeIdle}
        ></SessionInfoComponent>
      </Mount>

      <TimerContext.Provider value={{ timerProps, currentTime, updateCurrentTime }}>
        {/* <TimeSyncComponent></TimeSyncComponent> */}
        <Router></Router>
      </TimerContext.Provider>
    </React.Fragment>
  );
};
