import React, { useState } from "react";
import useStyles from "./dashAuction.style";
import DashboardMenu from "../../commonComponents/DashboardMenu";
import AuctionTable from "./AuctionTable";
import AuctionTableBuyer from "./AuctionTableBuyer";
import AuctionDetailLogs from "./AuctionDetailLogs";
import AuctionView from "./AuctionView";
import AuctionViewBuyer from "./AuctionViewBuyer";
import BidlogFirst from "./BidlogFirst";
import BidlogSecond from "./BidogSecond";
import BidlogSecondBuyer from "./BidogSecondBuyer";
import DashBuyerDetail from "./BidlogFirst/DashBuyerDetail";
import DashEventDetail from "./BidlogFirst/DashEventDetail";
import BuyerBidLog from "./BidlogFirst/BuyerBidLog";
import EditEvent from "./EditDashEvent/EditEvent";
import EditTabEvent from "./EditDashEvent/EditTabEvent";
import AuctionViewFirst from "./BidlogFirst/AuctionViewFirst";
import AuctionSummary from "./BidlogFirst/AuctionSummary";
import { getUserData } from "../../../common";

function DashboardAuction(props) {
  const classes = useStyles();
  const [changeView, SetChangeView] = useState(false);
  const [eventId, SetEventId] = useState("");
  const [buyerId, SetBuyerId] = useState("");
  const [buyerEventId, SetBuyerEventId] = useState("");
  const [buyerEventItemId, SetBuyerEvenItemtId] = useState("");
  const [eventItemId, SetEvenItemtId] = useState("");
  const userData = getUserData() ?? {};

  const renderComponent = () => {
    switch (changeView) {
      case "AuctionView":
        return userData.role === "BUYER" ? (
          <AuctionViewBuyer
            changeView={SetChangeView}
            Bidlog={() => {
              SetChangeView("AuctionBid");
            }}
            BidlogSecond={(eventItemId) => {
              SetChangeView("BidlogSecond");
              SetEvenItemtId(eventItemId);
            }}
            AuctionTable={() => {
              SetChangeView("AuctionTable");
            }}
            eventId={eventId}
          />
        ) : (
          <AuctionView
            changeView={SetChangeView}
            Bidlog={() => {
              SetChangeView("AuctionBid");
            }}
            BidlogSecond={(eventItemId) => {
              SetChangeView("BidlogSecond");
              SetEvenItemtId(eventItemId);
            }}
            AuctionTable={() => {
              SetChangeView("AuctionTable");
            }}
            eventId={eventId}
          />
        );
      case "AuctionDetailLogs":
        return (
          <AuctionDetailLogs
            AuctionView={() => SetChangeView("AuctionView")}
            eventId={eventId}
            buyerId={buyerId}
            buyerEventId={buyerEventId}
            buyerEventItemId={buyerEventItemId}
          />
        );
      case "AuctionBid":
        return (
          <BidlogFirst
            // DashBuyerDetail={(buyerId) => {
            //   SetChangeView("DashBuyerDetail");
            //   SetBuyerId(buyerId);
            // }}
            AuctionView={() => {
              SetChangeView("AuctionView");
            }}
            eventId={eventId}
            AuctionViewFirst={() => {
              SetChangeView("AuctionViewFirst");
            }}
            AuctionSummary={(BuyerId) => {
              SetChangeView("AuctionSummary");
              SetBuyerId(BuyerId);
            }}
          />
        );
      case "AuctionViewFirst":
        return (
          <AuctionViewFirst
            BidlogFirst={() => {
              SetChangeView("AuctionBid");
            }}
          />
        );
      case "AuctionSummary":
        return (
          <AuctionSummary
            BidlogFirst={() => {
              SetChangeView("AuctionBid");
            }}
            eventId={eventId}
            buyerId={buyerId}
          />
        );
      case "BidlogSecond":
        return userData.role === "BUYER" ? (
          <BidlogSecondBuyer
            AuctionView={() => {
              SetChangeView("AuctionView");
            }}
            eventItemId={eventItemId}
            eventId={eventId}
          />
        ) : (
          <BidlogSecond
            AuctionView={() => {
              SetChangeView("AuctionView");
            }}
            eventItemId={eventItemId}
            eventId={eventId}
          />
        );
      case "DashBuyerDetail":
        return (
          <DashBuyerDetail
            DashEventDetail={(buyerId, buyerEventId) => {
              SetChangeView("DashEventDetail");
              SetBuyerEventId(buyerEventId);
            }}
            BidlogFirst={() => {
              SetChangeView("AuctionBid");
            }}
            buyerId={buyerId}
          />
        );

      case "DashEventDetail":
        return (
          <DashEventDetail
            BuyerBidLog={(eventItemId) => {
              SetChangeView("BuyerBidLog");
              SetBuyerEvenItemtId(eventItemId);
            }}
            DashBuyerDetail={(buyerId) => {
              SetChangeView("DashBuyerDetail");
              SetBuyerId(buyerId);
            }}
            buyerId={buyerId}
            buyerEventId={buyerEventId}
          />
        );
      case "BuyerBidLog":
        return (
          <BuyerBidLog
            DashEventDetail={() => {
              SetChangeView("DashEventDetail");
            }}
            buyerId={buyerId}
            buyerEventId={buyerEventId}
            buyerEventItemId={buyerEventItemId}
          />
        );
      case "EditTabEvent":
        return (
          <EditTabEvent
            eventEditId={eventId}
            onBack={() => SetChangeView("EditEvent")}
          />
        );

      case "EditEvent":
        return (
          <EditEvent
            AuctionTable={() => {
              SetChangeView("AuctionTable");
              SetEventId("");
            }}
            EditTabEvent={(eventId) => {
              SetChangeView("EditTabEvent");
              SetEventId(eventId);
            }}
            eventId={eventId}
          />
        );
      case "AuctionTable":
        return userData.role === "BUYER" ? (
          <AuctionTableBuyer
            onView={(eventId) => {
              SetChangeView("AuctionView");
              SetEventId(eventId);
            }}
            onEdit={(eventId) => {
              SetChangeView("EditEvent");
              SetEventId(eventId);
            }}
          />
        ) : (
          <AuctionTable
            onView={(eventId) => {
              SetChangeView("AuctionView");
              SetEventId(eventId);
            }}
            onEdit={(eventId) => {
              SetChangeView("EditEvent");
              SetEventId(eventId);
            }}
          />
        );

      default:
        return userData.role === "BUYER" ? (
          <AuctionTableBuyer
            onView={(eventId) => {
              SetChangeView("AuctionView");
              SetEventId(eventId);
            }}
            onEdit={(eventId) => {
              SetChangeView("EditEvent");
              SetEventId(eventId);
            }}
          />
        ) : (
          <AuctionTable
            onView={(eventId) => {
              SetChangeView("AuctionView");
              SetEventId(eventId);
            }}
            onEdit={(eventId) => {
              SetChangeView("EditEvent");
              SetEventId(eventId);
            }}
          />
        );
    }
  };

  return (
    <div className={classes.section}>
      <div className="container">
        <div className={classes.Dashboard}>
          <div className={classes.dashboardCard}>
            <DashboardMenu />
            <div className={classes.dashboardCards}>
              <div className={classes.auctionDetail}>{renderComponent()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardAuction;
