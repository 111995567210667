import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import "bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";
import colorSchemes from "./constants/colorSchemes";
import { ToastContainer, toast } from "react-toastify";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({
  palette: {
    primary: {
      main: colorSchemes.primaryColor,
    },
    secondary: {
      main: colorSchemes.secondaryColor,
    },
  },
  overrides: {
    // MuiTooltip:{
    //   tooltip:{
    //     fontSize:"13px"
    //   }
    // } it just a example to override the MUI default color schemes and palette
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
      mxs: 768,
      tablet: 1024,
    },
  },
});

root.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// window.onbeforeunload = () => {
//     // event.preventDefault();
//     console.log("Are you sure")
// };

// window.addEventListener('beforeunload', function() {
//     console.log("Are you sure")
// });

// window.addEventListener('focus', function() {
//   // Tab has gained focus
//   console.log('Tab is now focused');
//   // Perform actions related to tab focus
// });

// window.addEventListener('blur', function() {
//   // Tab has lost focus
//   console.log('Tab is now blurred');
//   // Perform actions related to tab blur
// });

// const clearStorage = () => {
//     localStorage.clear();
// };

// document.addEventListener('visibilitychange', handleVisibilityChange);
// When screen is locked
// window.addEventListener("beforeunload", clearStorage);

// When browser is closed
// chrome.browserAction.onClicked.addListener(function() {
//   clearStorage();
// });

// When laptop is in sleep mode
// window.addEventListener("onbeforesleep", clearStorage);
// window.addEventListener("onbeforeunload", clearStorage);

// But not when tab is switched

// window.onbeforeunload = function(event) {
//if you return anything but null, it will warn the user.
//optionally you can return a string which most browsers show to the user as the warning message.
//   localStorage.clear();
// }

// window.onbeforesleep = function(event) {
//   //if you return anything but null, it will warn the user.
//   //optionally you can return a string which most browsers show to the user as the warning message.
//   localStorage.clear();
// }

// let preventClear = false;

// function handleVisibilityChange() {
//   console.log("visibilitychange", document.visibilityState)
//   if (document.visibilityState=="hidden" && !preventClear) {
//     // Tab is now hidden and not due to blur/focus event
//     // localStorage.clear();
//     // console.log('localStorage cleared.');
//     if(preventClear){
//       alert(!preventClear)
//       localStorage.clear();
//       alert('localStorage cleared.');
//     }
//   }
// }

// function handleBlur() {
//   preventClear = true;
// }

// function handleFocus() {
//   preventClear = true;
// }

// document.addEventListener('visibilitychange', handleVisibilityChange);
// window.addEventListener('blur', handleBlur);
// window.addEventListener('focus', handleFocus);
