import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import colorSchemes from "../../../constants/colorSchemes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./sellerRegister.style";
import { useNavigate } from "react-router-dom";
import routes from "../../../constants/routes";
import { useState } from "react";
import saveSellerAPI from "../../../api/saveSellerAPI";
import getCountries from "../../../api/getContriesAPI";
import getStateAPI from "../../../api/getStateAPI";
import { omit } from "lodash";

function SellerRegister() {
  const navigate = useNavigate();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [sellerInfo, setSellerInfo] = useState({
    companyName: "",
    businessType: "",
    bi_tin_ein: "",
    streetOne: "",
    streetTwo: "",
    state: "",
    city: "",
    country: "",
    postalCode: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    alternateContactNumber: "",
    title: "",
    password: "",
    confirmpassword: "",
  });

  const handleChange = (event) => {
    event.persist();
    validate(event, event.target.name, event.target.value);
    if (event.target.name === "country") stateData(event.target.value);
    setSellerInfo({ ...sellerInfo, [event.target.name]: event.target.value });
  };

  //Get Countries Nad States

  const [countriesList, updateCountriesList] = useState({
    apiState: "loading",
    list: [],
  });

  const [stateList, updateStateList] = useState({
    apiState: "loading",
    list: [],
  });

  const countryList = async () => {
    try {
      const { data } = await getCountries();

      if (data) {
        if (data) updateCountriesList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    countryList();
    return () => {
      return false;
    };
  }, []);

  const stateData = async (countryId) => {
    try {
      console.log(countryId);
      const { data } = await getStateAPI(countryId);

      if (data) {
        if (data) updateStateList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    stateData();
    return () => {
      return false;
    };
  }, []);

  // Validation Form

  const [errors, setErrors] = useState({});
  const [hide, setHide] = useState(false);

  const validate = (event, name, value) => {
    //A function to validate each input values
    const re = /^[A-Za-z ]+$/;
    switch (name) {
      case "companyName":
        if (!re.test(value)) {
          // we will set the error state

          setErrors({
            ...errors,
            companyName: "Comapany Name should contains only Letters",
          });
        } else {
          // set the error state empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "companyName");
          setErrors(newObj);
        }
        break;

      case "city":
        if (!re.test(value)) {
          // we will set the error city

          setErrors({
            ...errors,
            city: "City should contains only Letters",
          });
        } else {
          // set the error city empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "city");
          setErrors(newObj);
        }
        break;

      case "firstName":
        if (!re.test(value)) {
          // we will set the error firstName

          setErrors({
            ...errors,
            firstName: "First Name should contains only Letters",
          });
        } else {
          // set the error firstName empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "firstName");
          setErrors(newObj);
        }
        break;

      case "lastName":
        if (!re.test(value)) {
          // we will set the error lastName

          setErrors({
            ...errors,
            lastName: "Last Name should contains only Letters",
          });
        } else {
          // set the error lastName empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "lastName");
          setErrors(newObj);
        }
        break;

      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter a valid email address",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "businessType":
        if (value === "" || !re.test(value)) {
          console.log(errors);
          setErrors({
            ...errors,
            businessType: "businessType should contains only Letters",
          });
        } else {
          let newObj = omit(errors, "businessType");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      sellerInfo.contactNumber = Number(sellerInfo.contactNumber);
      sellerInfo.alternateContactNumber = Number(
        sellerInfo.alternateContactNumber
      );
      console.log(sellerInfo);
      delete sellerInfo.confirmpassword;
      const { data } = await saveSellerAPI(sellerInfo);

      if (data) {
        toast("Partner Registration Successfully!");
        //navigate(routes.home);
        setTimeout(() => {
          navigate(routes.signin);
        }, 1500);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.error);
        toast(error.response.data.error);
        setIsLoading(false);
      } else if (error.response.status === 500) {
        toast("Network Error!");
        setIsLoading(false);
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log("Error", error.message.code);
        setIsLoading(false);
      }
    }

    //  setsellerInfo({ email: "", firstname: "", lastname: "", phonenumber: "" , password: "", confirmpassword: ""});
  };

  return (
    <div className="container">
      {hide ? (
        <div className="sellerRegister">
          <div className={classes.event}>
            <Typography
              className={classes.textHead}
              sx={{
                textAlign: "center",
                padding: "0.3em",
                fontSize: "20px",
                fontWeight: "600",
                color: colorSchemes.primaryColor,
                marginTop: "8rem",
              }}
            >
              Seller Register
            </Typography>
            <div className="container mb-4">
              <form onSubmit={handleSubmit}>
                <div className="section-event">
                  <Typography className={classes.sellerFormHead}>
                    Company Information
                  </Typography>
                  <div className="row">
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className={classes.label}>
                            Company Name
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="text"
                            name="companyName"
                            value={sellerInfo.companyName}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                        {errors.companyName && (
                          <span>{errors.companyName}</span>
                        )}
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Form.Label className={classes.label}>
                        Business Type
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="text"
                        name="businessType"
                        value={sellerInfo.businessType}
                        onChange={handleChange}
                        required
                      />
                      {errors.businessType && (
                        <span>{errors.businessType}</span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className={classes.label}>
                            Enter your Business Identification Number(BI, TIN,
                            EIN) ?
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="text"
                            name="bi_tin_ein"
                            value={sellerInfo.bi_tin_ein}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </Box>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className={classes.label}>
                            Address
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="text"
                            name="streetOne"
                            placeholder="Street Address Line 1"
                            value={sellerInfo.streetOne}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label style={{ visibility: "hidden" }}>
                            Address
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="text"
                            name="streetTwo"
                            placeholder="Street Address Line 2"
                            value={sellerInfo.streetTwo}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label style={{ visibility: "hidden" }}>
                            Address
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="text"
                            name="city"
                            placeholder="City"
                            value={sellerInfo.city}
                            onChange={handleChange}
                            required
                          />
                          {errors.city && <span>{errors.city}</span>}
                        </Form.Group>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label style={{ visibility: "hidden" }}>
                            Address
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="text"
                            name="postalCode"
                            placeholder="Postal/Zip Code"
                            value={sellerInfo.postalCode}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label
                            className={classes.label}
                            style={{ visibility: "hidden" }}
                          >
                            Address
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className={classes.input}
                            name="country"
                            onChange={handleChange}
                            required={true}
                          >
                            <option>Country</option>
                            {countriesList.list.map((country, index) => {
                              return (
                                <option value={country.id}>
                                  {country.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label style={{ visibility: "hidden" }}>
                            Address
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className={classes.input}
                            name="state"
                            onChange={handleChange}
                            required={true}
                          >
                            <option>States</option>
                            {stateList.list.map((state, index) => {
                              return (
                                <option value={state.id}>{state.name}</option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Box>
                    </div>
                  </div>
                  <Typography
                    className={classes.sellerFormHead}
                    sx={{ marginTop: "1em" }}
                  >
                    Company Representative Information:
                  </Typography>
                  <div className="row">
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className={classes.label}>
                            First Name
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="text"
                            name="firstName"
                            placeholder="Enter your First Name"
                            value={sellerInfo.firstName}
                            onChange={handleChange}
                            required
                          />
                          {errors.firstName && <span>{errors.firstName}</span>}
                        </Form.Group>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className={classes.label}>
                            Last Name
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="text"
                            name="lastName"
                            placeholder="Enter your Last Name"
                            value={sellerInfo.lastName}
                            onChange={handleChange}
                            required
                          />
                          {errors.lastName && <span>{errors.lastName}</span>}
                        </Form.Group>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className={classes.label}>
                            Title
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="text"
                            name="title"
                            value={sellerInfo.title}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className={classes.label}>
                            Phone
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="number"
                            name="contactNumber"
                            placeholder="Number"
                            value={sellerInfo.contactNumber}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className={classes.label}>
                            Alternate Phone
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="number"
                            name="alternateContactNumber"
                            placeholder="Alternate Number"
                            value={sellerInfo.alternateContactNumber}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className={classes.label}>
                            Email
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            value={sellerInfo.email}
                            onChange={handleChange}
                            required
                          />
                          {errors.email && <span>{errors.email}</span>}
                        </Form.Group>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label className={classes.label}>
                            Password
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="password"
                            placeholder="Enter your Password"
                            name="password"
                            value={sellerInfo.password}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label className={classes.label}>
                            Confirm Password
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="password"
                            name="confirmpassword"
                            placeholder="Enter your Confirm Password"
                            value={sellerInfo.confirmpassword}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </Box>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={classes.saveBtn}>
                    <Button className={classes.next} type="submit">
                      Register
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="sellerContent">
          <h2> Welcome to V3bid.com - Where Auctioneers Soar!</h2>
          <p>
            {" "}
            Embark on an exhilarating journey through V3Bid.com, the ultimate
            online auction marketplace for machinery and industrial wonders.
            With over experience with auctioneers and impressive users, we bring
            you the widest range of industrial assets available at your
            fingertips.
          </p>
          <p>
            {" "}
            At V3bid.com, we take pride in handpicking only the most qualified
            and licensed auctioneers/ individuals to showcase their talents on
            our esteemed platform.{" "}
          </p>
          <p>
            {" "}
            Rest assured, every partner we collaborate with holds a valid
            auctioneer or auction firm license or individuals have worked in the
            auctioning area thus ensuring an exceptional auction experience.{" "}
          </p>
          <p>
            {" "}
            For a detailed list of our stringent listing policy, we invite you
            to delve into our comprehensive guidelines.
          </p>
          <p>
            {" "}
            We're thrilled to have the opportunity to get to know you and your
            business better. Our team is dedicated to finding the perfect
            synergy between V3bid.com and your extraordinary offerings. Let's
            take the first step towards a fruitful partnership by clicking here
            to fill out our below New Partner Form. Together, we'll unlock
            endless possibilities!
          </p>
          <p>
            {" "}
            If you're not an auction company but desire to share your remarkable
            items with our vibrant community, fret not! Explore our list of
            Auctioneers, individuals for their expertise and enthusiasm. They
            will be delighted to showcase your treasures to our discerning
            audience, ensuring maximum exposure and exceptional results.
          </p>
          <p>
            {" "}
            Join us at V3bid.com, where extraordinary auctions come to life.
            Unleash the power of our platform and connect with a global audience
            of passionate bidders.{" "}
          </p>
          <p>
            {" "}
            Your exceptional items deserve the spotlight, and we're here to make
            it shine. Step into the spotlight. Unleash your auction potential.
          </p>
          <h4> Embrace V3bid.com - Your Gateway to Auction Success!</h4>

          <div className="howSell">
            <button
              className="btn btn-primary"
              onClick={() => {
                setHide(true);
              }}
            >
              How To Sell
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SellerRegister;
