import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  filter: { height: "100%",paddingBottom:"10em", textAlign:'left' },
  filterHead: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2em 1em",
    backgroundColor: "#F7F8FC",
  },
  input: {
    borderRadius: "0px",
    color: "#7E8184",
    backgroundColor: "#F2F2F24D",
  },
  filters: { padding: "1em", borderBottom: "1px solid #00000029" },
  lotDetail: { padding: "1em" },
  number: {
    borderRight: "2px solid #FFC400",
    paddingRight: "5px",
    marginRight: "5px",
  },
  lotList: { padding: "10px 0px", borderBottom: "1px solid #00000029" },
  label: { color: "#3F4043", fontWeight: "600" },
  filterselect: { padding: "2em 1em", borderBottom: "1px solid #00000029", textAlign:'left' },
  auctionCheck:{padding:'1em',textAlign:'left'}
}));
export default useStyles;
