import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box, Grid } from "@mui/material";
import Form from "react-bootstrap/Form";
import { Carousel, Col, Modal, Button } from "react-bootstrap";
import tempImg from "../../../../assets/temp-img.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RefreshIcon from "@mui/icons-material/Refresh";
import useStyles from "./auctionDetail.style";
import AuctionModal from "../AuctionModal";
import getEventDetails from "../../../../api/getEventDetail";
import getEventItemsListAPI from "../../../../api/getEventItemsListAPI";
import getBidStatusAPI from "../../../../api/getBidStatusAPI";
import getMyLotsBuyerAPI from "../../../../api/getMyLotsBuyerAPI";
import getViewByLotsList from "../../../../api/getViewByLotsList";
// import PopUpContent from "./PopUpContent.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Countdown from "react-countdown";
import io from "socket.io-client";
import config from "../../../../config/config";
import {
  getToken,
  getUserData,
  getTimeZone,
  localTimeZone,
} from "../../../../common";
import lodash from "lodash";
import { BsFileEarmarkPdf } from "react-icons/bs";
import BidModal from "./BidModal";
import AuctionTable from "../../DashboardAuction/AuctionTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const userData = getUserData() ?? {};
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="xl"
      style={{ marginTop: "2em" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div closebutton="true">
        <Modal.Title id="contained-modal-title-vcenter">
          <AuctionModal eventItemDetail={props} />
        </Modal.Title>
      </div>
    </Modal>
  );
}

function AuctionTableModal() {
  return (
    <Modal
      // {...props}
      size="xl"
      style={{ marginTop: "2em" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div closebutton="true">
        <Modal.Title id="contained-modal-title-vcenter">
          <AuctionTable />
        </Modal.Title>
      </div>
    </Modal>
  );
}

export default function AuctionDetail(props) {
  const [index, setIndex] = useState(0);
  const [value, setValue] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [eventItemObject, setEventItemObject] = useState({});
  const [amount, setAmount] = useState(0);
  const [secondAmount, setSecondAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [lotDetails, setLotDetails] = useState({});
  const [count, setCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCodeContent, setShowCodeContent] = useState(false);
  const result = "";

  useEffect(() => {
    setCount(1);
  }, []);

  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();
  let now = moment();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [val, setVal] = useState();

  const handleInputChange = (event) => {
    const result = event.target.value.replace(/\D/g, "");
    setAmount(result);
  };

  const keyupFunction = (event) => {
    if (event.target.value.length <= 1 && event.which == 48) {
      event.target.value = "";
    }
  };

  const current_time = moment().tz(getZone).format("DD-MM-YYYY hh:mm:ss");

  const userData = getUserData() ?? {};

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const [EventsDetails, updateEventsDetails] = useState({
    apiState: "loading",
    list: [],
  });
  const [eventItemDetails, updateEventItemDetails] = useState({
    apiState: "loading",
    list: [],
  });

  // console.log("EventsDetails first", EventsDetails);
  // console.log("eventItemDetails first", eventItemDetails);

  const classes = useStyles();
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //console.log(props.eventId)
  const auctionId = props.eventId;

  const LotDetails = async () => {
    try {
      const payload = { auctionId: auctionId };
      const { data } = await getViewByLotsList(payload);

      if (data) {
        setLotDetails(data);
      } else {
        console.log("No data");
      }
      // console.log("Lot data", data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    LotDetails();
  }, []);

  const EventDetails = async () => {
    try {
      // console.log(auctionId)
      const payload = { auctionId: auctionId };
      const { data } = await getEventDetails(payload);

      if (data) {
        setDetails(data);
        updateEventsDetails({ apiState: "success", list: data });
        //console.log(EventsDetails.list.eventId)
        const itempayload = { itemId: data.eventId };
        // console.log("itempayload", itempayload);
        const ItemData = await getEventItemsListAPI(itempayload);
        // console.log("ItemData", ItemData.data.data[0].nextBidAmount)

        const finalItemData = await Promise.all(
          ItemData.data.data.map(async (object, index) => {
            object.myBidStatus = await getBidStatusAPI(
              object.eventId,
              object.eventItemId
            )
              .then(({ data }) => {
                return data;
              })
              .catch((error) => {
                return {
                  myLastBid: 0,
                  myBidRank: "0",
                  status: "error",
                };
              });
            return object;
          })
        );
        if (finalItemData)
          updateEventItemDetails({ apiState: "success", list: finalItemData });
      } else throw new Error(data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  React.useEffect(() => {
    EventDetails();
    return () => {
      return false;
    };
  }, [auctionId]);

  // Get My Lots Start

  const [myLotsLists, updateMyLotsLists] = useState({
    apiState: "loading",
    list: [],
    meta: [],
  });

  const myLotsList = async () => {
    try {
      const payload = { eventId: auctionId };
      const ItemData = await getMyLotsBuyerAPI(payload);

      const finalItemData = await Promise.all(
        ItemData.data.data.map(async (object, index) => {
          object.myBidStatus = await getBidStatusAPI(
            object.eventId,
            object.eventItemId
          )
            .then(({ data }) => {
              return data;
            })
            .catch((error) => {
              return {
                myLastBid: 0,
                myBidRank: "0",
                status: "error",
              };
            });
          return object;
        })
      );
      if (finalItemData)
        updateMyLotsLists({ apiState: "success", list: finalItemData });
      else throw new Error(finalItemData);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.errorMessage);
        error.response.data.errorMessage.map((errors, index) => {
          toast(errors.message);
          setIsLoading(false);
        });
      } else if (error.response.status === 500) {
        toast("Network Error!");
        setIsLoading(false);
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log("Error", error.message.code);
        setIsLoading(false);
      }
    }
  };
  React.useEffect(() => {
    myLotsList();
    return () => {
      return false;
    };
  }, [auctionId]);

  // Get My Lots End

  // Websocket Part Start

  const [socket, setSocket] = useState(null);
  const SOCKET_URL = config.api.socket_url;

  useEffect(() => {
    const newSocket = io(SOCKET_URL, {
      extraHeaders: { authorization: getToken() },
    });

    setSocket(newSocket);
    return () => newSocket.disconnect();
  }, []);

  useEffect(() => {
    if (!socket) {
      return () => null;
    }
    const data = getUserData() ?? {};

    socket.on(`${data.userId}`, (props) => {
      if (!lodash.isEmpty(eventItemDetails.list)) {
        let oldData = [...eventItemDetails.list];

        const newData = lodash.map(oldData, (lot) => {
          if (lot.eventItemId === props.eventItemId) {
            lot.myBidStatus = props;
            lot.highestBid = props.highestBid;
            lot.nextBidAmount = props.nextBidAmount;
            lot.endDateAndTime = props.updatedEndTime;

            // @coder-kabir --------------------------------------------------------------------------------------------------
            const currentTime = moment().tz(getZone).format();
            const startDateAndTime = moment(lot.startDateAndTime)
              .tz(getZone)
              .format(); // lot start date

            if (currentTime >= startDateAndTime) {
              setAmountStates({
                ...amountStates,
                [lot.eventItemId]: props.nextBidAmount,
              });
            }
            //----------------------------------------------------------------------------------------------------------------
          }

          return lot;
        });

        updateEventItemDetails({ ...eventItemDetails, list: newData });
      }

      if (!lodash.isEmpty(myLotsLists.list)) {
        let oldData = [...myLotsLists.list];

        const newData = lodash.map(oldData, (lot) => {
          if (lot.eventItemId === props.eventItemId) {
            lot.myBidStatus = props;
            lot.highestBid = props.highestBid;
            lot.nextBidAmount = props.nextBidAmount;
            lot.endDateAndTime = props.updatedEndTime;

            // @coder-kabir --------------------------------------------------------------------------------------------------
            const currentTime = moment().tz(getZone).format();
            const startDateAndTime = moment(lot.startDateAndTime)
              .tz(getZone)
              .format(); // lot start date

            if (currentTime >= startDateAndTime) {
              setAmountStates({
                ...amountStates,
                [lot.eventItemId]: props.nextBidAmount,
              });
            }
            //----------------------------------------------------------------------------------------------------------------
          }

          return lot;
        });

        updateMyLotsLists({ ...eventItemDetails, list: newData });
      }
    });
    return () => null;
  }, [socket, eventItemDetails.apiState]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // @coder-kabir 21-06-2023 ---------------------------------------------------------------------------------------------

  const Mount = React.memo(({ condition, children }) => {
    if (condition === true) {
      return children;
    }
    return <React.Fragment></React.Fragment>;
  });

  const MountCondition = React.memo(({ condition, children }) => {
    const [isTrue, isFalse] = React.Children.toArray(children);
    return condition === true ? isTrue : isFalse;
  });

  Mount.True = ({ children }) => children;
  Mount.False = ({ children }) => children;

  const statusStyle = { border: "", backgroundColor: "" };
  const status = (details, eventItem) => {
    if (
      details.previewPeriod !== "NO" &&
      moment(eventItem.startDateAndTime).tz(getZone).format() >
        moment().tz(getZone).format()
    ) {
      statusStyle.border = "1px solid #db1c1c";
      statusStyle.backgroundColor = "#fabcbc";
    } else if (
      eventItem.myBidStatus.myBidRank == 0 ||
      eventItem.myBidStatus.myBidRank > 1
    ) {
      statusStyle.border = "1px solid #db1c1c";
      statusStyle.backgroundColor = "#fabcbc";
    } else {
      statusStyle.border = "1px solid #329E0C";
      statusStyle.backgroundColor = "#E3FFD9";
    }

    return statusStyle;
  };

  const [amountStates, setAmountStates] = useState({});
  const storeHandler = (event, eventItem) => {
    const showFinalModal = () => {
      if (!amountStates[eventItem.eventItemId]) {
        return toast("Please enter amount.");
      }
      if (amountStates[eventItem.eventItemId] == 0) {
        return toast("Amount should be grater then zero.");
      }

      setModalShow(true);
      setEventItemObject({
        ...eventItem,
        amount:
          amountStates[eventItem.eventItemId] === 0
            ? eventItem.nextBidAmount
            : amountStates[eventItem.eventItemId],
        showH1L1Bid: EventsDetails.list.showH1L1Bid,
      });
    };

    const amountHandler = () => {
      if (userData.role == "ADMIN") {
        return toast("You are not authorized");
      }

      if (startDateAndTime > currentTime) {
        console.log("startDateAndTime", eventItemDetails.list);
        console.log("currentTime", currentTime);
        if (userData.role == "SELLER") {
          // return toast('You are not authorized')
          return toast("Bid Not started yet.");
        } else if (localStorage.getItem("userData")) {
          showFinalModal();
          console.log("showFinalModal issue");
          // return toast('Bid Not started yet.')
        }
      }

      if (!localStorage.getItem("userData")) {
        return toast("Please login and try again");
      }

      if (
        EventsDetails.list.eventType === "FORWARD_AUCTION" &&
        amountStates[eventItem.eventItemId] < eventItem.nextBidAmount
      ) {
        if (userData.role == "ADMIN") {
          return toast("You are not authorized");
        } else if (userData.role == "SELLER") {
          // return toast("Bid Submitted")
          showFinalModal();
        } else if (
          userData.role == "BIDDER" &&
          currentTime < startDateAndTime
        ) {
          // return toast(`Please Enter amount higher than ${eventItem.nextBidAmount}`)
          return toast("Bid Not started yet.");
        }

        // return toast(`Please Enter amount higher than ${eventItem.nextBidAmount}`)
      } else if (
        EventsDetails.list.eventType === "REVERSE_AUCTION" &&
        amountStates[eventItem.eventItemId] > eventItem.nextBidAmount
      ) {
        if (userData.role == "ADMIN" || userData.role == "SELLER") {
          return toast("You are not authorized");
        } else {
          return toast(
            `Please Enter amount Lesser than ${eventItem.nextBidAmount}`
          );
        }

        // return toast(`Please Enter amount higher than ${eventItem.nextBidAmount}`)
      }

      if (localStorage.getItem("userData")) {
        return showFinalModal();
      }
    };

    const currentTime = moment().tz(getZone).format();
    const publishDateTime = moment(EventsDetails.list.publishDateTime)
      .tz(getZone)
      .format(); // event publish date
    const bidStartDateTime = moment(EventsDetails.list.bidStartDateTime)
      .tz(getZone)
      .format(); // event start date

    const startDateAndTime = moment(eventItem.startDateAndTime)
      .tz(getZone)
      .format(); // lot start date

    if (EventsDetails.list.previewPeriod !== "NO") {
      if (currentTime >= publishDateTime && currentTime <= bidStartDateTime) {
        return showFinalModal(); /*preview period || active*/
      } else {
        return amountHandler(); /* preview period || over */
      }
    } else {
      return amountHandler(); /* none preview period */
    }
  };

  const handleModal = () => {
    setIsModalOpen(true);
    setShowCodeContent(true);
  };

  // Event handler to close the Modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const label =
    EventsDetails.list.eventType === "REVERSE_AUCTION"
      ? "Decrement"
      : "Increment";
  // ---------------------------------------------------------------------------------------------------------------------

  //Websocket Part End

  // console.clear();
  return (
    <div className="container">
      <Grid className={classes.upperAuction}>
        <div className="row">
          <div className="col-md-2">
            <div className={classes.imageSection}>
              <div className="imageText">
                <img
                  className={classes.productImg}
                  alt="product"
                  src={EventsDetails.list.eventImageUrl}
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className={classes.auctionContent}>
              <p className={classes.firstText}>
                <p className={classes.firstText}>
                  {EventsDetails.list.status === "live" && (
                    <span className={classes.liveButton}>
                      Live Now -
                      {moment(EventsDetails.list.bidStartDateTime)
                        .tz(getZone)
                        .format("DD-MM-YYYY hh:mm:ss")}
                    </span>
                  )}

                  {EventsDetails.list.status === "ended" && (
                    <span className={classes.ended}>Ended</span>
                  )}
                  {EventsDetails.list.status === "upcoming" && (
                    <span
                      className={classes.Upcoming}
                      style={{ color: "#f0b70e", border: "1px solid #f0b70e" }}
                    >
                      Upcoming -
                      {moment(EventsDetails.list.bidStartDateTime)
                        .tz(getZone)
                        .format("DD-MM-YYYY hh:mm:ss")}
                    </span>
                  )}

                  <br />
                  <br />

                  <span>Auction Type -{EventsDetails.list.eventType}</span>

                  {EventsDetails.list.previewPeriod !== "NO" && (
                    <>
                      <br />
                      <br />
                      <span className={classes.Upcoming}>
                        Prebid Period -{" "}
                        {moment(EventsDetails.list.publishDateTime)
                          .tz(getZone)
                          .format("DD-MM-YYYY hh:mm:ss")}
                      </span>
                    </>
                  )}
                </p>
              </p>
              <Typography className={classes.auctionHeading} component={"span"}>
                {EventsDetails.list.auctionTitle},{" "}
                {EventsDetails.list.referenceNumber}
              </Typography>
              <a href="/">
                <p className={classes.auctionLink}>
                  {EventsDetails.list.auctioneerName}
                </p>
              </a>
              <p className={classes.location}>
                <LocationOnIcon /> {EventsDetails.list.streetOne},{" "}
                {EventsDetails.list.streetTwo}, {EventsDetails.list.city},
                {EventsDetails.list.province}, {EventsDetails.list.postalCode},{" "}
                {EventsDetails.list.country}
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="downloadBTN ">
              <a
                href={EventsDetails.list.auctionCatalogueUrl}
                target="_Blank"
                color="inherit"
                className="btn btn-group catelog"
                style={{ padding: "0px" }}
                aria-label="open drawer"
              >
                <BsFileEarmarkPdf
                  style={{ fontSize: "30px", color: "red", paddingTop: "4px" }}
                />
                <h5>Catelog</h5>
              </a>
              <Button
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => {
                  props.setViewCard(false);
                }}
                sx={{
                  marginLeft: "3em",
                  display: { sm: "none" },
                  position: "absolute",
                  color: "#fff",
                  borderRadius: "0px",
                  width: "100%",
                }}
              >
                <span className="auctionListName">Auction List</span>
              </Button>

              <Button onClick={LotDetails}>
                <RefreshIcon />
              </Button>
            </div>
          </div>
          <div className="col-md-12">
            <Box
              sx={{
                backgroundColor: "#c4d1ff",
                color: "#0d1d58",
                margin: "1em 0",
                paddingTop: "5px",
              }}
            >
              <marquee>
                {" "}
                {EventsDetails.list.eventMessage !== ""
                  ? EventsDetails.list.eventMessage
                  : ""}
              </marquee>
            </Box>
          </div>
        </div>
      </Grid>

      <div className="container">
        <div className={classes.auctionTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{ fontSize: "19px", fontWeight: "bold" }}
                label={`All Lots (${eventItemDetails.list.length})`}
                {...a11yProps(0)}
              />
              <Tab
                sx={{ fontSize: "19px", fontWeight: "bold" }}
                label={`My Lots (${myLotsLists.list.length})`}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>

          <TabPanel className="auctionTab" value={value} index={0}>
            <div className="container p-0">
              {eventItemDetails.list.map((eventItem, dataIndex) => {
                const total =
                  Number(eventItem.startPrice) + Number(eventItem.increment);
                //  console.log(eventItem.myBidStatus.myBidRank);eventItemDetails

                // amountStates[eventItem.eventItemId] = eventItem.nextBidAmount;
                return (
                  <Box className={classes.auctionCard}>
                    <div className="row">
                      <div className="col-md-3">
                        <Box className={classes.auctionImage}>
                          {eventItem.eventItemImagesUrl.length == 0 ? (
                            <div>
                              <img
                                className={classes.productimg}
                                src={tempImg}
                                alt="First slide"
                              />
                            </div>
                          ) : (
                            <Carousel onSelect={handleSelect}>
                              {eventItem.eventItemImagesUrl.map(
                                (eventItemImages, index) => (
                                  <Carousel.Item key={index * dataIndex}>
                                    <div>
                                      <img
                                        className={classes.productimg}
                                        src={eventItemImages}
                                        alt="First slide"
                                        key={index * dataIndex}
                                      />
                                    </div>
                                  </Carousel.Item>
                                )
                              )}
                            </Carousel>
                          )}
                        </Box>
                      </div>
                      <div className="col-md-9">
                        <Box className={classes.auctionCardContent}>
                          <div className={classes.auctionDetails}>
                            <div className="row">
                              <div className="col-md-3 auctionCardLeftMobile">
                                <div className={classes.auctionNumber}>
                                  <span className={classes.lot}>LOT</span>
                                  <p className="eventId fontSetLotNumber">
                                    {eventItem.lotNumber}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-9 auctionCardRightMobile">
                                <div className={classes.auctionName}>
                                  <span className={classes.eventName}>
                                    {eventItem.shortDescription}
                                  </span>
                                  <p className={classes.eventDetail}>
                                    {eventItem.longDescription}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={classes.bidders}>
                            <div className="row">
                              <div className="col-md-7">
                                <div className={classes.bidAmount}>
                                  <div className={classes.highBid}>
                                    {/* @coder-kabir -------------------------------------------------------------- */}
                                    <p className={classes.bidderText}>
                                      <React.Fragment>
                                        Lead Bid &nbsp;
                                      </React.Fragment>
                                      <Mount
                                        condition={
                                          details.previewPeriod !== "NO"
                                        }
                                      >
                                        <Mount
                                          condition={
                                            moment(eventItem.startDateAndTime)
                                              .tz(getZone)
                                              .format() >
                                            moment().tz(getZone).format()
                                          }
                                        >
                                          <span className={classes.amount}>
                                            XXX
                                          </span>
                                        </Mount>
                                        <Mount
                                          condition={
                                            moment().tz(getZone).format() >
                                            moment(eventItem.startDateAndTime)
                                              .tz(getZone)
                                              .format()
                                          }
                                        >
                                          <Mount
                                            condition={
                                              EventsDetails.list.showH1L1Bid ===
                                              "YES"
                                            }
                                          >
                                            <span className={classes.amount}>
                                              <React.Fragment>
                                                {" "}
                                                {eventItem.currency}{" "}
                                                {eventItem.highestBid}{" "}
                                              </React.Fragment>
                                              {/* <React.Fragment>Pre bid period lot started</React.Fragment> */}
                                            </span>
                                          </Mount>
                                        </Mount>
                                      </Mount>

                                      <Mount
                                        condition={
                                          details.previewPeriod === "NO"
                                        }
                                      >
                                        <Mount
                                          condition={
                                            EventsDetails.list.showH1L1Bid ===
                                            "YES"
                                          }
                                        >
                                          <span className={classes.amount}>
                                            <React.Fragment>
                                              {" "}
                                              {eventItem.currency}{" "}
                                              {eventItem.highestBid}{" "}
                                            </React.Fragment>
                                            {/* <React.Fragment>Normal Auction</React.Fragment> */}
                                          </span>
                                        </Mount>
                                      </Mount>
                                    </p>
                                    {/* ----------------------------------------------------------------------------*/}

                                    {/* <p className={classes.bidderText}> */}
                                    {/* Lead Bid &nbsp; */}

                                    {/* {console.log("details.previewPeriod u", details.previewPeriod)}
                                      {console.log("eventItem.startDateAndTime u", eventItem.startDateAndTime)} */}

                                    {/* { details.previewPeriod === "YES" &&  
                                        moment(eventItem.startDateAndTime)
                                        .tz(getZone)
                                        .format("DD-MM-YYYY hh:mm:ss") > current_time ?                                         
                                          
                                              <span className={classes.amount}>{"XXX"}</span>
                                          
                                          : 
                                          <span className={classes.amount}>
                                          {EventsDetails.list.showH1L1Bid === "YES" && (
                                            
                                              <> {eventItem.currency}{" "}
                                              {eventItem.highestBid}{" "}</>
                                              
                                            )}    
                                          </span>
                                      } */}

                                    {/* </p> */}
                                    <p className={classes.bidderText}>
                                      per {eventItem.unit}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-md-0.5"></div> */}
                              {/* <div className="col-md-5">
                                <div className={classes.bidRank}>
                                  <div
                                    className={classes.rankColumn}
                                    style={{
                                      border:
                                        eventItem.myBidStatus.myBidRank == 1 && details.previewPeriod === "NO"
                                          ? "1px solid #329E0C"
                                          : "1px solid #db1c1c",
                                      backgroundColor:
                                        eventItem.myBidStatus.myBidRank == 1 && details.previewPeriod === "NO"
                                          ? "#E3FFD9"
                                          : "#fabcbc",
                                    }}
                                  >

                                  {details.previewPeriod === "YES" &&  
                                      moment(eventItem.startDateAndTime)
                                      .tz(getZone)
                                      .format("DD-MM-YYYY hh:mm:ss") > current_time
                                    ? (<p className={classes.bidderText}>
                                        Your Rank{" "}  
                                        <span
                                          className={`${classes.amount} colorBlack`}
                                        >
                                          N/A
                                        </span>
                                      </p>
                                    ) : (
                                      <p className={classes.bidderText}>
                                        Your Rank{" "}
                                        {eventItem.myBidStatus.myBidRank ===
                                        "0" ? (
                                          <span
                                            className={`${classes.amount} colorBlack`}
                                          >
                                            N/A
                                          </span>
                                        ) : (
                                          <span className={classes.amount}>
                                            H{eventItem.myBidStatus.myBidRank}
                                          </span>
                                        )}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div> */}
                              {/* @coder-kabir 25-07-2023--------------------------------------------------------------------------------*/}

                              <div className="col-md-5">
                                <div className={classes.bidRank}>
                                  <div
                                    className={classes.rankColumn}
                                    style={{
                                      border:
                                        details.previewPeriod !== "NO" &&
                                        moment(eventItem.startDateAndTime)
                                          .tz(getZone)
                                          .format() >
                                          moment().tz(getZone).format()
                                          ? "1px solid #db1c1c"
                                          : eventItem.myBidStatus.myBidRank ==
                                              0 ||
                                            eventItem.myBidStatus.myBidRank > 1
                                          ? "1px solid #db1c1c"
                                          : "1px solid #329E0C",

                                      backgroundColor:
                                        details.previewPeriod !== "NO" &&
                                        moment(eventItem.startDateAndTime)
                                          .tz(getZone)
                                          .format() >
                                          moment().tz(getZone).format()
                                          ? "#fabcbc"
                                          : eventItem.myBidStatus.myBidRank ==
                                              0 ||
                                            eventItem.myBidStatus.myBidRank > 1
                                          ? "#fabcbc"
                                          : "#E3FFD9",
                                    }}
                                  >
                                    <MountCondition
                                      condition={
                                        details.previewPeriod !== "NO" &&
                                        moment(eventItem.startDateAndTime)
                                          .tz(getZone)
                                          .format() >
                                          moment().tz(getZone).format()
                                      }
                                    >
                                      <Mount.True>
                                        <p className={classes.bidderText}>
                                          Your Rank{" "}
                                          <span
                                            className={`${classes.amount} colorBlack`}
                                          >
                                            N/A
                                          </span>
                                        </p>
                                      </Mount.True>
                                      <Mount.False>
                                        <MountCondition
                                          condition={
                                            eventItem.myBidStatus.myBidRank ==
                                            "0"
                                          }
                                        >
                                          <Mount.True>
                                            <p className={classes.bidderText}>
                                              Your Rank{" "}
                                              <span
                                                className={`${classes.amount} colorBlack`}
                                              >
                                                N/A
                                              </span>
                                            </p>
                                          </Mount.True>
                                          <Mount.False>
                                            <p className={classes.bidderText}>
                                              Your Rank{" "}
                                              <span className={classes.amount}>
                                                H
                                                {
                                                  eventItem.myBidStatus
                                                    .myBidRank
                                                }
                                              </span>
                                            </p>
                                          </Mount.False>
                                        </MountCondition>
                                      </Mount.False>
                                    </MountCondition>
                                  </div>
                                </div>
                              </div>

                              {/* --------------------------------------------------------------------------------------------------*/}
                            </div>
                          </div>

                          <div className={classes.biddingOptions}>
                            <div className={classes.biddingColumn}>
                              <div className="row">
                                <div className="col-md-7">
                                  <div
                                    className="row"
                                    style={{ alignItems: "baseline" }}
                                  >
                                    <div className="col-md-6 bidMobileLive">
                                      <Box sx={{ textAlign: "left" }}>
                                        {/* { now.isSameOrBefore(eventItem.biddingEndTime) ? <p className={classes.biddingEndTime}>
                                          Bidding Starts (D:H:M:S):
                                        </p> : <p className={classes.biddingEndTime}>
                                          Bidding Ends (D:H:M:S):
                                        </p> } */}

                                        {eventItem.status === "live" ? (
                                          <p className={classes.biddingEndTime}>
                                            Bid Ends (D:H:M:S):
                                          </p>
                                        ) : (
                                          <p className={classes.biddingEndTime}>
                                            Bid Starts (D:H:M:S):
                                          </p>
                                        )}

                                        <span
                                          className={classes.biddingEndTime}
                                        >
                                          Quantity:
                                        </span>
                                      </Box>
                                    </div>
                                    <div className="col-md-6 bidMobileLive">
                                      <Box sx={{ textAlign: "right" }}>
                                        <p style={{ marginBottom: 0 }}>
                                          {eventItem.status === "live" ? (
                                            <Countdown
                                              className={classes.bidLive}
                                              isPlaying={true}
                                              date={moment(
                                                eventItem.endDateAndTime
                                              )
                                                .tz(getZone)
                                                .format()}
                                            />
                                          ) : (
                                            <Countdown
                                              className={`${classes.bidUpcoming} letter`}
                                              isPlaying={true}
                                              date={moment(
                                                eventItem.startDateAndTime
                                              )
                                                .tz(getZone)
                                                .format()}
                                            />
                                          )}
                                        </p>
                                        {eventItem.status === "live" ? (
                                          <span className={classes.bidLive}>
                                            {eventItem.quantity}{" "}
                                            {eventItem.unit}
                                          </span>
                                        ) : (
                                          <span className={classes.bidUpcoming}>
                                            {eventItem.quantity}{" "}
                                            {eventItem.unit}
                                          </span>
                                        )}
                                      </Box>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="col-md-5 mobileAmountClass"
                                  style={{ textAlign: "right" }}
                                >
                                  <span className={classes.biddingDate}>
                                    {/* {eventItem.status === "live" ?  moment(eventItem.endDateAndTime)
                                      .tz(getZone)
                                      .format("DD/MM/YYYY HH:mm:ss") : moment(eventItem.startDateAndTime)
                                      .tz(getZone)
                                      .format("DD/MM/YYYY HH:mm:ss")}
                                    {/* (
                                      {console.log(moment())}
                                    {moment(eventItem.endDateAndTime)
                                      .tz(getZone)
                                      .format("DD/MM/YYYY HH:mm:ss")}
                                    ) */}
                                    {eventItem.status === "upcoming"
                                      ? moment(eventItem.startDateAndTime)
                                          .tz(getZone)
                                          .format("DD/MM/YYYY HH:mm:ss")
                                      : moment(eventItem.endDateAndTime)
                                          .tz(getZone)
                                          .format("DD/MM/YYYY HH:mm:ss")}
                                  </span>
                                  <br />

                                  {/* @coder-kabir -------------------------------------------------------------- */}
                                  <Mount
                                    condition={details.previewPeriod !== "NO"}
                                  >
                                    <Mount
                                      condition={
                                        moment(eventItem.startDateAndTime)
                                          .tz(getZone)
                                          .format() >
                                        moment().tz(getZone).format()
                                      }
                                    >
                                      <span className={classes.biddingDate}>
                                        Amount : XXX
                                      </span>
                                    </Mount>
                                    <Mount
                                      condition={
                                        moment().tz(getZone).format() >
                                        moment(eventItem.startDateAndTime)
                                          .tz(getZone)
                                          .format()
                                      }
                                    >
                                      <Mount
                                        condition={
                                          EventsDetails.list.showH1L1Bid ===
                                          "YES"
                                        }
                                      >
                                        <span className={classes.biddingDate}>
                                          Amount :{" "}
                                          {eventItem.quantity *
                                            eventItem.highestBid}
                                        </span>
                                      </Mount>
                                    </Mount>
                                  </Mount>

                                  <Mount
                                    condition={details.previewPeriod === "NO"}
                                  >
                                    <Mount
                                      condition={
                                        EventsDetails.list.showH1L1Bid === "YES"
                                      }
                                    >
                                      <span className={classes.biddingDate}>
                                        Amount :{" "}
                                        {eventItem.quantity *
                                          eventItem.highestBid}
                                      </span>
                                    </Mount>
                                  </Mount>
                                  {/* ----------------------------------------------------------------------------*/}

                                  {/* {EventsDetails.list.showH1L1Bid === "YES" && EventsDetails.list.previewPeriod === "NO" ? (
                                    <span className={classes.biddingDate}>
                                      Amount :{" "}
                                      {eventItem.quantity *
                                        eventItem.highestBid}
                                    </span>
                                  ) : <span className={classes.biddingDate}>
                                      Amount : XXX
                                    </span>} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </div>
                    </div>

                    <div className={classes.otherPhase}>
                      <div className="row">
                        <div className="col-md-3">
                          <div className={classes.priceSection}>
                            <div className={classes.priceOptions}>
                              <div className={classes.priceType}>
                                <span className={classes.priceName}>
                                  Start Price
                                </span>
                                <span className={classes.priceName}>
                                  {EventsDetails.list.currency}{" "}
                                  {eventItem.startPrice}
                                </span>
                              </div>
                              <div className={classes.priceType}>
                                <span className={classes.priceName}>
                                  {label}
                                </span>
                                <span className={classes.priceName}>
                                  {EventsDetails.list.currency}{" "}
                                  {eventItem.increment}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${
                            classes.otherThreeButtons
                          } ${"col-md-9"}`}
                        >
                          <Col className={classes.bidButton}>
                            <div className={classes.firstButton}>
                              <span
                                className={classes.cadAmount}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {EventsDetails.list.currency}
                              </span>
                              {details.previewPeriod !== "NO" &&
                              moment(eventItem.startDateAndTime)
                                .tz(getZone)
                                .format("DD-MM-YYYY hh:mm:ss") >
                                current_time ? (
                                <Form.Control
                                  className={classes.input}
                                  type="number"
                                  placeholder="Price"
                                  onChange={(event) => {
                                    console.log(event.target.value);
                                    const result = event.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    setAmountStates({
                                      ...amountStates,
                                      [eventItem.eventItemId]: result,
                                    });
                                  }}
                                  value={amountStates[eventItem.eventItemId]}
                                  // onChange={handleInputChange}
                                  // onKeyUp={keyupFunction}
                                  // defaultValue={" "}
                                  // key={eventItem.nextBidAmount}
                                  // min={eventItem.nextBidAmount}
                                  step={1}
                                />
                              ) : (
                                <Form.Control
                                  className={classes.input}
                                  type="number"
                                  placeholder="Price"
                                  onChange={(event) => {
                                    result = event.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    setAmountStates({
                                      ...amountStates,
                                      [eventItem.eventItemId]: result,
                                    });
                                  }}
                                  value={amountStates[eventItem.eventItemId]}
                                  // value={amountStates[eventItem.eventItemId] || eventItem.highestBid !== 0 ? eventItem.highestBid : (eventItem.startPrice + eventItem.increment)}
                                  // onChange={handleInputChange}
                                  // key={eventItem.nextBidAmount}
                                  // min={eventItem.nextBidAmount}
                                  step={1}
                                />
                              )}
                            </div>
                            <div className={classes.secondButton}>
                              <Mount condition={isOnline}>
                                {EventsDetails.list.auctioneerName ==
                                userData.username ? (
                                  <Mount
                                    condition={
                                      moment(eventItem.endDateAndTime)
                                        .tz(getZone)
                                        .format() >
                                      moment().tz(getZone).format()
                                    }
                                  >
                                    <span
                                      className={classes.bid}
                                      style={{ backgroundColor: "#2d55eb" }}
                                    >
                                      Cannot Bid
                                    </span>
                                  </Mount>
                                ) : (
                                  <Mount
                                    condition={
                                      moment(eventItem.endDateAndTime)
                                        .tz(getZone)
                                        .format() >
                                      moment().tz(getZone).format()
                                    }
                                  >
                                    <span
                                      onClick={(e) => {
                                        storeHandler(e, eventItem);
                                      }}
                                      className={classes.bid}
                                      style={{ backgroundColor: "#2d55eb" }}
                                    >
                                      Bid
                                    </span>
                                  </Mount>
                                )}
                                <Mount
                                  condition={
                                    !(
                                      moment(eventItem.endDateAndTime)
                                        .tz(getZone)
                                        .format() >
                                      moment().tz(getZone).format()
                                    )
                                  }
                                >
                                  <span
                                    className={classes.bid}
                                    style={{ backgroundColor: "#D22B2B" }}
                                  >
                                    {" "}
                                    Closed!{" "}
                                  </span>
                                </Mount>
                              </Mount>
                              <Mount condition={!isOnline}>
                                <span
                                  className={classes.bid}
                                  style={{ backgroundColor: "#D22B2B" }}
                                >
                                  Please check your Internet!
                                </span>
                              </Mount>
                              {/* <div>{JSON.stringify(amountStates)}</div> */}
                              {/* {console.log("nextBidAmount", eventItem)}
                              {console.log("amount", amount)} */}
                              {/* {isOnline ? (
                                moment(eventItem.endDateAndTime)
                                  .tz(getZone)
                                  .format() > moment().tz(getZone).format() ? (
                                  <span
                                    className={classes.bid}
                                    style={{ backgroundColor: "#2d55eb" }}
                                    // onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                    onClick={() => {
                                      if(count < 2 && amount < ((Number(eventItem.startPrice)) + (Number(eventItem.increment))))
                                      { 
                                        toast("Please Enter higher amount") 
                                      }
                                      else{
                                      if(
                                        eventItem.nextBidAmount === 0 ||
                                        amount === ""
                                        // amount === "0"
                                      ) {
                                        return toast("Please Enter Amount!");
                                      }
                                      //setAmount(amount === 0 ? eventItem.nextBidAmount:amount)
                                      if (eventItem.previewPeriod === false) {
                                        if (
                                          amount < eventItem.nextBidAmount &&
                                          amount !== 0
                                        ) {
                                          return toast(
                                            "Amount Should be Greater Then High Bid!"
                                          );
                                        } else {
                                          setModalShow(true);
                                          setEventItemObject({
                                            ...eventItem,
                                            amount:
                                              amount === 0
                                                ? eventItem.nextBidAmount
                                                : amount, 
                                                    // +
                                                  // eventItem.nextBidAmount,
                                            showH1L1Bid:
                                              EventsDetails.list.showH1L1Bid ===
                                              true
                                                ? true
                                                : false,
                                          });
                                        }
                                      } else {
                                        setModalShow(true);
                                        setEventItemObject({
                                          ...eventItem,
                                          amount:
                                            amount === 0
                                              ? eventItem.nextBidAmount
                                              : amount,
                                          showH1L1Bid:
                                            EventsDetails.list.showH1L1Bid ===
                                            true
                                              ? true
                                              : false,
                                        });
                                      }
                                    }
                                    }}
                                  >
                                    Bid
                                  </span>
                                ) : (
                                  <span
                                    className={classes.bid}
                                    style={{ backgroundColor: "#D22B2B" }}
                                  >
                                    Closed!
                                  </span>
                                )
                              ) : (
                                <span
                                  className={classes.bid}
                                  style={{ backgroundColor: "#D22B2B" }}
                                >
                                  Please check your Internet!
                                </span>
                              )} */}
                              <MyVerticallyCenteredModal
                                eventItemObject={eventItemObject}
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                // setAmount={setAmount}
                                setDetails={details.previewPeriod}
                                eventDetail={EventsDetails.list}
                              />
                            </div>
                            <div className={classes.thirdButton}>
                              <span className={classes.lastBidText}>
                                {/* <div>
                                  <p
                                    className={classes.bidderText}
                                    style={{ marginBottom: "0" }}
                                    onClick={handleModal}
                                  >
                                    Your last Bid{" "}
                                    <span className={classes.amount}>N/A</span>
                                  </p>
                                  </div> */}
                                {eventItem.myBidStatus.myLastBid === 0 ? (
                                  <div>
                                    <p
                                      className={classes.bidderText}
                                      style={{ marginBottom: "0" }}
                                      onClick={handleModal}
                                    >
                                      Your last Bid{" "}
                                      <span className={classes.amount}>
                                        N/A
                                      </span>
                                    </p>
                                  </div>
                                ) : (
                                  <>
                                    <p
                                      className={classes.bidderText}
                                      style={{ marginBottom: "0" }}
                                      onClick={handleModal}
                                    >
                                      Your last Bid{" "}
                                      <span className={classes.amount}>
                                        {eventItem.currency}{" "}
                                        {eventItem.myBidStatus.myLastBid}
                                      </span>
                                    </p>
                                  </>
                                )}
                              </span>
                              {moment(eventItem.endDateAndTime)
                                .tz(getZone)
                                .format() < moment().tz(getZone).format() ? (
                                console.log("You Cannot view last bid")
                              ) : (
                                <Modal
                                  show={isModalOpen}
                                  onHide={handleCloseModal}
                                  size="lg"
                                  style={{ marginTop: "2em" }}
                                  // aria-labelledby="contained-modal-title-vcenter"
                                  centered
                                >
                                  <Modal.Header>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                      {/* Conditionally render CodeContent or any other component here */}
                                      {/* : <AuctionModal eventItemDetail={props} /> */}
                                      {showCodeContent && (
                                        <BidModal
                                          eventId={eventItem.eventId}
                                          eventItemId={eventItem.eventItemId}
                                        />
                                      )}
                                      {/* {showCodeContent ? <p>Hello</p>: <p>Byee</p>} */}
                                    </Modal.Title>
                                  </Modal.Header>
                                </Modal>
                              )}
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </Box>
                );
              })}
            </div>
          </TabPanel>
          <TabPanel className="auctionTab" value={value} index={1}>
            {myLotsLists.list && (
              <div className="container p-0">
                {myLotsLists.list &&
                  myLotsLists.list.map((eventItem) => {
                    //  console.log(eventItem.myBidStatus.myBidRank);
                    return (
                      <Box className={classes.auctionCard}>
                        <div className="row">
                          <div className="col-md-3">
                            <Box className={classes.auctionImage}>
                              {eventItem.eventItemImagesUrl.length == 0 ? (
                                <div>
                                  <img
                                    className={classes.productimg}
                                    src={tempImg}
                                    alt="First slide"
                                  />
                                </div>
                              ) : (
                                <Carousel
                                  activeIndex={index}
                                  onSelect={handleSelect}
                                >
                                  {eventItem.eventItemImagesUrl.map(
                                    (eventItemImages, index) => (
                                      <Carousel.Item key={index}>
                                        <div>
                                          <img
                                            className={classes.productimg}
                                            src={eventItemImages}
                                            alt="First slide"
                                          />
                                        </div>
                                      </Carousel.Item>
                                    )
                                  )}
                                </Carousel>
                              )}
                            </Box>
                          </div>
                          <div className="col-md-9">
                            <Box className={classes.auctionCardContent}>
                              <div className={classes.auctionDetails}>
                                <div className="row">
                                  <div className="col-md-3 auctionCardLeftMobile">
                                    <div className={classes.auctionNumber}>
                                      <span className={classes.lot}>LOT</span>
                                      <p className="eventId">
                                        {eventItem.lotNumber}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-9 auctionCardRightMobile">
                                    <div className={classes.auctionName}>
                                      <span className={classes.eventName}>
                                        {eventItem.shortDescription}
                                      </span>
                                      <p className={classes.eventDetail}>
                                        {eventItem.longDescription}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className={classes.bidders}>
                                <div className="row">
                                  <div className="col-md-7">
                                    <div className={classes.bidAmount}>
                                      <div className={classes.highBid}>
                                        {/* @coder-kabir -------------------------------------------------------------- */}
                                        <p className={classes.bidderText}>
                                          <React.Fragment>
                                            Lead Bid &nbsp;
                                          </React.Fragment>
                                          <Mount
                                            condition={
                                              details.previewPeriod !== "NO"
                                            }
                                          >
                                            <Mount
                                              condition={
                                                moment(
                                                  eventItem.startDateAndTime
                                                )
                                                  .tz(getZone)
                                                  .format() >
                                                moment().tz(getZone).format()
                                              }
                                            >
                                              <span className={classes.amount}>
                                                XXX
                                              </span>
                                            </Mount>
                                            <Mount
                                              condition={
                                                moment().tz(getZone).format() >
                                                moment(
                                                  eventItem.startDateAndTime
                                                )
                                                  .tz(getZone)
                                                  .format()
                                              }
                                            >
                                              <Mount
                                                condition={
                                                  EventsDetails.list
                                                    .showH1L1Bid !== "NO"
                                                }
                                              >
                                                <span
                                                  className={classes.amount}
                                                >
                                                  <React.Fragment>
                                                    {" "}
                                                    {eventItem.currency}{" "}
                                                    {eventItem.highestBid}{" "}
                                                  </React.Fragment>
                                                  {/* <React.Fragment>Pre bid period lot started</React.Fragment> */}
                                                </span>
                                              </Mount>
                                            </Mount>
                                          </Mount>

                                          <Mount
                                            condition={
                                              details.previewPeriod === "NO"
                                            }
                                          >
                                            <Mount
                                              condition={
                                                EventsDetails.list
                                                  .showH1L1Bid !== "NO"
                                              }
                                            >
                                              <span className={classes.amount}>
                                                <React.Fragment>
                                                  {" "}
                                                  {eventItem.currency}{" "}
                                                  {eventItem.highestBid}{" "}
                                                </React.Fragment>
                                                {/* <React.Fragment>Normal Auction</React.Fragment> */}
                                              </span>
                                            </Mount>
                                          </Mount>
                                        </p>
                                        {/* ----------------------------------------------------------------------------*/}

                                        {/* <p className={classes.bidderText}>
                                          Lead Bid &nbsp;

                                          { details.previewPeriod === "YES" &&  
                                            moment(eventItem.startDateAndTime)
                                            .tz(getZone)
                                            .format("DD-MM-YYYY hh:mm:ss") > current_time ?
                                            
                                                  <span className={classes.amount}>
                                                    {"XXX"}
                                                  </span>
                                            
                                              : 
                                              <span className={classes.amount}>
                                              {EventsDetails.list.showH1L1Bid ===
                                                true && (
                                              <>
                                                  {eventItem.currency}{" "}
                                                  {eventItem.highestBid}{" "}
                                                </>
                                              )}
                                            </span>
                                          }
                                        </p> */}
                                        <p className={classes.bidderText}>
                                          per {eventItem.unit}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-md-0.5"></div> */}
                                  {/* <div className="col-md-5">
                                    <div className={classes.bidRank}>
                                      <div
                                        className={classes.rankColumn}
                                        style={{
                                          border:
                                            eventItem.myBidStatus.myBidRank == 1 && details.previewPeriod === "NO"
                                              ? "1px solid #329E0C"
                                              : "1px solid #db1c1c",
                                          backgroundColor:
                                            eventItem.myBidStatus.myBidRank == 1 && details.previewPeriod === "NO"
                                              ? "#E3FFD9"
                                              : "#fabcbc",
                                        }}
                                      >
                                        {details.previewPeriod === "YES" &&
                                        moment(eventItem.startDateAndTime)
                                          .tz(getZone)
                                          .format("DD-MM-YYYY hh:mm:ss") >
                                          current_time ? (
                                          <p className={classes.bidderText}>
                                            Your Rank{" "}
                                            <span
                                              className={`${classes.amount} colorBlack`}
                                            >
                                              N/A
                                            </span>
                                          </p>
                                        ) : (
                                          <p className={classes.bidderText}>
                                            Your Rank{" "}
                                            {eventItem.myBidStatus.myBidRank ===
                                            "0" ? (
                                              <span
                                                className={`${classes.amount} colorBlack`}
                                              >
                                                N/A
                                              </span>
                                            ) : (
                                              <span className={classes.amount}>
                                                H
                                                {
                                                  eventItem.myBidStatus
                                                    .myBidRank
                                                }
                                              </span>
                                            )}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* @coder-kabir 25-07-2023--------------------------------------------------------------------------------*/}

                                  <div className="col-md-5">
                                    <div className={classes.bidRank}>
                                      <div
                                        className={classes.rankColumn}
                                        style={{
                                          border:
                                            details.previewPeriod !== "NO" &&
                                            moment(eventItem.startDateAndTime)
                                              .tz(getZone)
                                              .format() >
                                              moment().tz(getZone).format()
                                              ? "1px solid #db1c1c"
                                              : eventItem.myBidStatus
                                                  .myBidRank == 0 ||
                                                eventItem.myBidStatus
                                                  .myBidRank > 1
                                              ? "1px solid #db1c1c"
                                              : "1px solid #329E0C",

                                          backgroundColor:
                                            details.previewPeriod !== "NO" &&
                                            moment(eventItem.startDateAndTime)
                                              .tz(getZone)
                                              .format() >
                                              moment().tz(getZone).format()
                                              ? "#fabcbc"
                                              : eventItem.myBidStatus
                                                  .myBidRank == 0 ||
                                                eventItem.myBidStatus
                                                  .myBidRank > 1
                                              ? "#fabcbc"
                                              : "#E3FFD9",
                                        }}
                                      >
                                        {" "}
                                        <MountCondition
                                          condition={
                                            details.previewPeriod !== "NO" &&
                                            moment(eventItem.startDateAndTime)
                                              .tz(getZone)
                                              .format() >
                                              moment().tz(getZone).format()
                                          }
                                        >
                                          <Mount.True>
                                            <p className={classes.bidderText}>
                                              Your Rank{" "}
                                              <span
                                                className={`${classes.amount} colorBlack`}
                                              >
                                                N/A
                                              </span>
                                            </p>
                                          </Mount.True>
                                          <Mount.False>
                                            <MountCondition
                                              condition={
                                                eventItem.myBidStatus
                                                  .myBidRank == "0"
                                              }
                                            >
                                              <Mount.True>
                                                <p
                                                  className={classes.bidderText}
                                                >
                                                  Your Rank{" "}
                                                  <span
                                                    className={`${classes.amount} colorBlack`}
                                                  >
                                                    N/A
                                                  </span>
                                                </p>
                                              </Mount.True>
                                              <Mount.False>
                                                <p
                                                  className={classes.bidderText}
                                                >
                                                  Your Rank{" "}
                                                  <span
                                                    className={classes.amount}
                                                  >
                                                    H
                                                    {
                                                      eventItem.myBidStatus
                                                        .myBidRank
                                                    }
                                                  </span>
                                                </p>
                                              </Mount.False>
                                            </MountCondition>
                                          </Mount.False>
                                        </MountCondition>
                                      </div>
                                    </div>
                                  </div>

                                  {/* --------------------------------------------------------------------------------------------------*/}
                                </div>
                              </div>

                              <div className={classes.biddingOptions}>
                                <div className={classes.biddingColumn}>
                                  <div className="row">
                                    <div className="col-md-7">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <Box sx={{ textAlign: "left" }}>
                                            {eventItem.status === "live" ? (
                                              <p
                                                className={
                                                  classes.biddingEndTime
                                                }
                                              >
                                                Bid Ends (D:H:M:S):
                                              </p>
                                            ) : (
                                              <p
                                                className={
                                                  classes.biddingEndTime
                                                }
                                              >
                                                Bid Starts (D:H:M:S):
                                              </p>
                                            )}

                                            <span
                                              className={classes.biddingEndTime}
                                            >
                                              Quantity:
                                            </span>
                                          </Box>
                                        </div>
                                        {/* {console.log(
                                          "eventItem",
                                          eventItem.endDateAndTime
                                        )} */}
                                        <div className="col-md-6 bidMobileLive">
                                          <Box sx={{ textAlign: "right" }}>
                                            {eventItem.status === "live" ? (
                                              <Countdown
                                                className={classes.bidLive}
                                                isPlaying={true}
                                                date={moment(
                                                  eventItem.endDateAndTime
                                                )
                                                  .tz(getZone)
                                                  .format()}
                                              />
                                            ) : (
                                              <Countdown
                                                className={classes.bidUpcoming}
                                                isPlaying={true}
                                                date={moment(
                                                  eventItem.startDateAndTime
                                                )
                                                  .tz(getZone)
                                                  .format()}
                                              />
                                            )}
                                            <br />
                                            {eventItem.status === "live" ? (
                                              <span className={classes.bidLive}>
                                                {eventItem.quantity}{" "}
                                                {eventItem.unit}
                                              </span>
                                            ) : (
                                              <span
                                                className={classes.bidUpcoming}
                                              >
                                                {eventItem.quantity}{" "}
                                                {eventItem.unit}
                                              </span>
                                            )}
                                          </Box>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-5">
                                      <div className={classes.amount}>
                                        {eventItem.status === "upcoming"
                                          ? moment(eventItem.startDateAndTime)
                                              .tz(getZone)
                                              .format("DD/MM/YYYY HH:mm:ss")
                                          : moment(eventItem.endDateAndTime)
                                              .tz(getZone)
                                              .format("DD/MM/YYYY HH:mm:ss")}
                                        <br />
                                        {/* @coder-kabir -------------------------------------------------------------- */}
                                        <Mount
                                          condition={
                                            details.previewPeriod !== "NO"
                                          }
                                        >
                                          <Mount
                                            condition={
                                              moment(eventItem.startDateAndTime)
                                                .tz(getZone)
                                                .format() >
                                              moment().tz(getZone).format()
                                            }
                                          >
                                            <span
                                              className={classes.biddingDate}
                                            >
                                              Amount : XXX
                                            </span>
                                          </Mount>
                                          <Mount
                                            condition={
                                              moment().tz(getZone).format() >
                                              moment(eventItem.startDateAndTime)
                                                .tz(getZone)
                                                .format()
                                            }
                                          >
                                            <Mount
                                              condition={
                                                EventsDetails.list
                                                  .showH1L1Bid !== "NO"
                                              }
                                            >
                                              <span
                                                className={classes.biddingDate}
                                              >
                                                Amount :{" "}
                                                {eventItem.quantity *
                                                  eventItem.highestBid}
                                              </span>
                                            </Mount>
                                          </Mount>
                                        </Mount>

                                        <Mount
                                          condition={
                                            details.previewPeriod === "NO"
                                          }
                                        >
                                          <Mount
                                            condition={
                                              EventsDetails.list.showH1L1Bid !==
                                              "NO"
                                            }
                                          >
                                            <span
                                              className={classes.biddingDate}
                                            >
                                              Amount :{" "}
                                              {eventItem.quantity *
                                                eventItem.highestBid}
                                            </span>
                                          </Mount>
                                        </Mount>
                                        {/* ----------------------------------------------------------------------------*/}
                                        {/*EventsDetails.list.showH1L1Bid === true && EventsDetails.list.previewPeriod === "NO" ? (
                                        <span className={classes.biddingDate}>
                                          Amount :{" "}
                                          {eventItem.quantity *
                                            eventItem.highestBid}
                                        </span>
                                        ) : 
                                        <span className={classes.biddingDate}>
                                          Amount : XXX
                                          {{eventItem.quantity *
                                            eventItem.highestBid} }
                                        </span> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Box>
                          </div>
                        </div>

                        <div className={classes.otherPhase}>
                          <div className="row">
                            <div className="col-md-3">
                              <div className={classes.priceSection}>
                                <div className={classes.priceOptions}>
                                  <div className={classes.priceType}>
                                    <span className={classes.priceName}>
                                      Start Price
                                    </span>
                                    <span className={classes.priceName}>
                                      {eventItem.currency}{" "}
                                      {eventItem.startPrice}
                                    </span>
                                  </div>
                                  <div className={classes.priceType}>
                                    <span className={classes.priceName}>
                                      {label}
                                    </span>
                                    <span className={classes.priceName}>
                                      {eventItem.currency} {eventItem.increment}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Col className={classes.bidButton}>
                              <div className={classes.firstButton}>
                                <span
                                  className={classes.cadAmount}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {eventItem.currency}
                                </span>

                                {details.previewPeriod !== "NO" &&
                                moment(eventItem.startDateAndTime)
                                  .tz(getZone)
                                  .format("DD-MM-YYYY hh:mm:ss") <
                                  current_time ? (
                                  <Form.Control
                                    className={classes.input}
                                    type="number"
                                    placeholder="Price"
                                    onChange={(event) => {
                                      const result = event.target.value.replace(
                                        /\D/g,
                                        ""
                                      );
                                      setAmountStates({
                                        ...amountStates,
                                        [eventItem.eventItemId]: result,
                                      });
                                    }}
                                    value={amountStates[eventItem.eventItemId]}
                                    step={1}
                                    // className={classes.input}
                                    // type="number"
                                    // onChange={handleInputChange}
                                    // onKeyUp={keyupFunction}
                                    // placeholder="Price"
                                    // defaultValue={eventItem.nextBidAmount}
                                    // key={eventItem.nextBidAmount}
                                    // min={eventItem.nextBidAmount}
                                  />
                                ) : (
                                  <Form.Control
                                    className={classes.input}
                                    type="number"
                                    placeholder="Price"
                                    onChange={(event) => {
                                      const result = event.target.value.replace(
                                        /\D/g,
                                        ""
                                      );
                                      setAmountStates({
                                        ...amountStates,
                                        [eventItem.eventItemId]: result,
                                      });
                                    }}
                                    value={amountStates[eventItem.eventItemId]}
                                    step={1}
                                    // className={classes.input}
                                    // type="number"
                                    // onChange={handleInputChange}
                                    // onKeyUp={keyupFunction}
                                    // placeholder="Price"
                                    // defaultValue={" "}
                                    // key={eventItem.nextBidAmount}
                                    // min={eventItem.nextBidAmount}
                                  />
                                )}
                              </div>
                              <div className={classes.secondButton}>
                                <Mount condition={isOnline}>
                                  <Mount
                                    condition={
                                      moment(eventItem.endDateAndTime)
                                        .tz(getZone)
                                        .format() >
                                      moment().tz(getZone).format()
                                    }
                                  >
                                    <span
                                      onClick={(e) => {
                                        storeHandler(e, eventItem);
                                      }}
                                      className={classes.bid}
                                      style={{ backgroundColor: "#2d55eb" }}
                                    >
                                      Bid
                                    </span>
                                  </Mount>
                                  <Mount
                                    condition={
                                      !(
                                        moment(eventItem.endDateAndTime)
                                          .tz(getZone)
                                          .format() >
                                        moment().tz(getZone).format()
                                      )
                                    }
                                  >
                                    <span
                                      className={classes.bid}
                                      style={{ backgroundColor: "#D22B2B" }}
                                    >
                                      {" "}
                                      Closed!{" "}
                                    </span>
                                  </Mount>
                                </Mount>
                                <Mount condition={!isOnline}>
                                  <span
                                    className={classes.bid}
                                    style={{ backgroundColor: "#D22B2B" }}
                                  >
                                    Please check your Internet!
                                  </span>
                                </Mount>
                                {/* {isOnline ? (
                                  moment(eventItem.endDateAndTime)
                                    .tz(getZone)
                                    .format() >
                                  moment().tz(getZone).format() ? (
                                    <span
                                      className={classes.bid}
                                      style={{ backgroundColor: "#2d55eb" }}
                                      onClick={() => {
                                        if(count < 2 && amount < ((Number(eventItem.startPrice)) + (Number(eventItem.increment))))
                                          { 
                                            toast("Please Enter higher amount") 
                                          }
                                        else{
                                        if (
                                          eventItem.nextBidAmount === 0 ||
                                          amount === ""
                                        ) {
                                          return toast("Please Enter Amount!");
                                        }
                                        //setAmount(amount === 0 ? eventItem.nextBidAmount:amount)
                                        if (eventItem.previewPeriod === false) {
                                          if (
                                            amount < eventItem.nextBidAmount &&
                                            amount !== 0
                                          ) {
                                            return toast(
                                              "Amount Should be Greater Then High Bid!"
                                            );
                                          } else {
                                            setModalShow(true);
                                            setEventItemObject({
                                              ...eventItem,
                                              amount:
                                                amount === 0
                                                  ? eventItem.nextBidAmount
                                                  : amount,
                                              showH1L1Bid:
                                                EventsDetails.list
                                                  .showH1L1Bid === true
                                                  ? true
                                                  : false,
                                            });
                                          }
                                        } else {
                                          setModalShow(true);
                                          setEventItemObject({
                                            ...eventItem,
                                            amount:
                                              amount === 0
                                                ? eventItem.nextBidAmount
                                                : amount,
                                            showH1L1Bid:
                                              EventsDetails.list.showH1L1Bid ===
                                              true
                                                ? true
                                                : false,
                                          });
                                        }
                                      }}}
                                    >
                                      Bid
                                    </span>
                                  ) : (
                                    <span
                                      className={classes.bid}
                                      style={{ backgroundColor: "#D22B2B" }}
                                    >
                                      Closed!
                                    </span>
                                  )
                                ) : (
                                  <span
                                    className={classes.bid}
                                    style={{ backgroundColor: "#D22B2B" }}
                                  >
                                    Please check your Internet!
                                  </span>
                                )} */}
                                <MyVerticallyCenteredModal
                                  eventItemObject={eventItemObject}
                                  show={modalShow}
                                  onHide={() => setModalShow(false)}
                                  setDetails={details.previewPeriod}
                                  eventDetail={EventsDetails.list}
                                />
                              </div>

                              <div className={classes.thirdButton}>
                                <span className={classes.lastBidText}>
                                  {eventItem.myBidStatus.myLastBid === 0 ? (
                                    <p
                                      className={`${classes.bidderText} fontLevel`}
                                      style={{ marginBottom: "0" }}
                                    >
                                      Your last Bid{" "}
                                      <span className="colorBlack">N/A</span>
                                    </p>
                                  ) : (
                                    <p
                                      className={classes.bidderText}
                                      style={{ marginBottom: "0" }}
                                    >
                                      Your last Bid{" "}
                                      <span className={classes.amount}>
                                        {" "}
                                        {eventItem.currency}{" "}
                                        {eventItem.myBidStatus.myLastBid}
                                      </span>
                                    </p>
                                  )}
                                </span>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Box>
                    );
                  })}
              </div>
            )}
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
