import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "react-data-table-component-extensions/dist/index.css";

import useStyles from "./Buyer.style";
import getAllBuyersAPI from "../../../../../api/getAllBuyersAPI";
import getEventsBuyer from "../../../../../api/getEventsBuyer";
import saveBuyerToEvent from "../../../../../api/saveBuyerToEvent";
import deleteBuyerFromEvent from "../../../../../api/deleteBuyerFromEvent";
import SearchBar from "../../../Shared/SearchBar";

function Buyer() {
  const classes = useStyles();
  const location = useLocation();
  const eventId = location.state.data.eventId;

  let debounceTimeout;

  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searching, setSearching] = useState(false);
  const [columns, setColumns] = useState([
    {
      name: "Buyer Name",
      selector: "name",
      cell: (data) => String(data.name),
      sortable: true,
    },
    {
      name: "Buyer Email",
      selector: "email",
      cell: (data) => String(data.email),
      sortable: true,
    },
    {
      name: "Contact No.",
      selector: "contactNumber",
      cell: (data) => String(data.contactNumber),
      sortable: true,
    },
    {
      name: "Alt. Contact No.",
      selector: "alternateContactNumber",
      cell: (data) => String(data.alternateContactNumber),
      sortable: true,
    },
    {
      name: "Action",
      cell: (data) => (
        <Button
          onClick={() => {
            addBuyerToEvent(data);
          }}
        >
          {"Add"}
        </Button>
      ),
      sortable: false,
      export: false,
    },
  ]);
  const [columnsEventBuyers, setColumnsEventBuyers] = useState([
    {
      name: "Buyer Name",
      selector: "name",
      cell: (data) => String(data.name),
      sortable: true,
    },
    {
      name: "Buyer Email",
      selector: "email",
      cell: (data) => String(data.email),
      sortable: true,
    },
    {
      name: "Contact No.",
      selector: "contactNumber",
      cell: (data) => String(data.contactNumber),
      sortable: true,
    },
    {
      name: "Alt. Contact No.",
      selector: "alternateContactNumber",
      cell: (data) => String(data.alternateContactNumber),
      sortable: true,
    },
    {
      name: "Action",
      cell: (data) => (
        <Button
          sx={{ color: "red" }}
          onClick={() => {
            removeBuyerToEvent(data);
          }}
        >
          {"Remove"}
        </Button>
      ),
      sortable: false,
      export: false,
    },
  ]);
  const [tableEventsData, setTableEventsData] = useState([]);

  const searchTextRef = useRef(searchText);
  const searchBarRef = useRef(null);

  searchTextRef.current = searchText;

  const addBuyerToEvent = (data) => {
    addBuyer(data);
  };

  const addBuyer = async (data) => {
    try {
      const payload = { userId: data.userId };

      const dataBuyer = await saveBuyerToEvent(payload, eventId);

      if (dataBuyer) {
        toast("Buyer Added!");
        setTimeout(() => {
          allBuyersList();
          eventBuyersList();
        }, 1500);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const allBuyersList = async (email) => {
    try {
      const payload = { auctionId: eventId, ...(email ? { email } : {}) };
      const dataBuyers = await getAllBuyersAPI(payload);

      if (dataBuyers) {
        const buyerList = dataBuyers.data.map((data) => {
          return {
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
            contactNumber: data.contactNumber,
            alternateContactNumber: data.alternateContactNumber,
            userId: data.userId,
          };
        });

        setTableData(buyerList);
      } else throw new Error(dataBuyers);
    } catch (error) {
      console.log(error);
    }
  };

  //Get Buyer Form Events
  const removeBuyerToEvent = (dataNew) => {
    removeBuyer(dataNew);
  };

  const removeBuyer = async (dataNew) => {
    try {
      const eventBuyerId = dataNew.eventBuyerId;
      const dataBuyer = await deleteBuyerFromEvent(eventBuyerId, eventId);

      if (dataBuyer) {
        toast("Buyer Removed!");
        setTimeout(() => {
          allBuyersList(searchTextRef.current);
          eventBuyersList();
        }, 1500);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const eventBuyersList = async () => {
    try {
      const payload = { auctionId: eventId };
      const dataEventBuyers = await getEventsBuyer(payload);
      //console.log(dataEventBuyers.data)
      if (dataEventBuyers) {
        const eventsBuyerList = dataEventBuyers.data.map((data) => {
          return {
            name: `${data.user.firstName} ${data.user.lastName}`,
            email: data.user.email,
            contactNumber: data.user.contactNumber,
            alternateContactNumber: data.user.alternateContactNumber,
            userId: data.userId,
            eventBuyerId: data.eventBuyerId,
            eventId: data.eventId,
          };
        });

        setTableEventsData(eventsBuyerList);
      } else throw new Error(dataEventBuyers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    eventBuyersList(searchText);
    allBuyersList();
    return () => {
      return false;
    };
  }, [eventId]);

  useEffect(() => {
    searchTextRef.current = searchText;
  }, [searchText]);

  const onSearchTextChange = (e) => {
    const email = e.target.value?.trim();
    setSearchText(email);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(() => {
      // Perform the search after a delay (e.g., 500 milliseconds)
      performSearch(email);
    }, 1000);
  };

  const performSearch = async (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (emailPattern.test(email)) {
      setSearching(true);
      await allBuyersList(email);
      // if (searchBarRef?.current) searchBarRef.current.focus();
      setSearching(false);
    } else if (tableData?.length) {
      allBuyersList();
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup: Clear the debounceTimeout on unmount
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, []);

  return (
    <div className="buyer">
      <div className="Buyerdetail">
        <Typography
          sx={{
            color: "#3F4043",
            fontSize: "13px",
            fontWeight: "600",
            marginBottom: "2em",
          }}
        >
          Enter a full email ID to find new buyers. Use keywords to search for
          buyers in your existing auctions.
        </Typography>
        <div>
          <SearchBar
            searchText={searchText}
            onChange={onSearchTextChange}
            disabled={searching}
            ref={searchBarRef}
          />
        </div>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={columns}
          data={tableData}
          filter={false}
        >
          <DataTable
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            export={false}
            exportHeaders={false}
            className="DataTable"
          />
        </DataTableExtensions>
        <Typography
          sx={{
            color: "#3F4043",
            fontSize: "13px",
            fontWeight: "600",
            marginBottom: "2em",
            marginTop: "2em",
          }}
        >
          The following buyers have been added to the auction. Click 'Remove' to
          exclude them before the auction or bidding begins.
        </Typography>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={columnsEventBuyers}
          data={tableEventsData}
        >
          <DataTable
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            export={false}
            exportHeaders={false}
            className="DataTable"
          />
        </DataTableExtensions>
      </div>
    </div>
  );
}

export default Buyer;
