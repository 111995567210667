import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Modal } from "@mui/material";
import useStyles from "../../../../../Create/Event/ViewBuyerLots/ViewBuyer/viewBuyer.style";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import EditBuyerTable from "../../../../../../commonComponents/EditBuyerTable";
import { RiInboxArchiveLine } from "react-icons/ri";
import getViewByBuyerList from "../../../../../../../api/getViewByBuyerList";
import { useNavigate } from "react-router-dom";
import routes from "../../../../../../../constants/routes";
import { ButtonGroup } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";

export default function EditViewBuyer(eventEditId) {
  // const eventId = location.state.data.eventId;
  const classes = useStyles();
  const eventId = eventEditId.eventEditId.eventEditId;
  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, updateClearSelectedRows] = useState([]);
  const [buyerLotsModal, toggleBuyerLotsModal] = useState(false);
  const [tableEventsData, setTableEventsData] = useState([]);
  const rows = null;

  const [loading, setLoading] = useState(true);

  // const [newState, setnewState] = useState(initialState);

  // const initialState = {
  //   name: "",
  //   email:"",
  //   contactNumber:"",
  //   alternateContactNumber:"",
  //   eventBuyerId:"",
  //   totalLots:""
  // };

  const [columnsEventBuyers, setColumnsEventBuyers] = useState([
    {
      name: "Buyer Name",
      selector: "name",
      cell: (data) => String(data.name),
      sortable: true,
    },
    {
      name: "Buyer Email",
      selector: "email",
      cell: (data) => String(data.email),
      sortable: true,
    },
    {
      name: "Contact No.",
      selector: "contactNumber",
      cell: (data) => String(data.contactNumber),
      sortable: true,
    },
    {
      name: "Alt. Contact No.",
      selector: "alternateContactNumber",
      cell: (data) => String(data.alternateContactNumber),
      sortable: true,
    },
    {
      name: "Lots",
      cell: (data) => (
        <div>
          <RiInboxArchiveLine className={classes.lots} />{" "}
          {String(data.totalLots)}
        </div>
      ),
      sortable: true,
      export: false,
    },
  ]);

  const eventBuyersList = async () => {
    console.log("eventBuyersList called");
    try {
      const payload = { auctionId: eventId };
      const dataEventBuyers = await getViewByBuyerList(payload);

      if (dataEventBuyers) {
        const eventsBuyerList = dataEventBuyers.data.map((data) => {
          return {
            name: `${data.user.firstName} ${data.user.lastName}`,
            email: data.user.email,
            contactNumber: data.user.contactNumber,
            alternateContactNumber: data.user.alternateContactNumber,
            eventBuyerId: data.eventBuyerId,
            totalLots: data.totalLots,
          };
        });
        setTableEventsData(eventsBuyerList);

        updateClearSelectedRows(true);
        console.log("selectedRowssdd", selectedRows);
        console.log("clearSelectedRows", clearSelectedRows);
        // updateClearSelectedRows([])
        // setnewState(initialState);
        // console.log("newState", newState)
      } else throw new Error(dataEventBuyers);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    setTimeout(() => {
      console.log("useEffect called");
      eventBuyersList();
    }, 1000);

    return () => {
      return false;
    };
  }, [eventId]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    //   border: "1px solid #989898",
    boxShadow: "none",
    p: 4,
  };

  const [modalOpen, setModalOpen] = useState({});
  const handleOpen = (text) => {
    setModalOpen(
      {
        visible: true,
        type: text,
      },
      console.log("handleOpen called")
    );
  };
  const handleClose = (text) => {
    setModalOpen(
      {
        visible: false,
        type: text,
      },
      setTimeout(() => {
        // setColumnsEventBuyers(false)
        setSelectedRows([]);
        console.log("setSelectedRows cc", selectedRows);
        eventBuyersList();
      }, 1000),
      console.log("handleClose called")
    );
  };

  return (
    <div className="editviewBuyer">
      <Box sx={{ width: "100%", padding: "0px !important" }}>
        {selectedRows.length > 0 && (
          <div
            className="email-container"
            style={{
              background: "#2D55EB",
              paddingTop: "10px",
              height: "55px",
            }}
          >
            <Button
              type="primary"
              className="addlots"
              onClick={() => {
                handleOpen("Add");
              }}
            >
              Add Lots
            </Button>
            <Button
              type="primary"
              className="addlots"
              sx={{ color: "red !important" }}
              onClick={() => {
                handleOpen("Remove");
              }}
            >
              Remove Lots
            </Button>
          </div>
        )}

        <div>{JSON.stringify()}</div>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={true}
          columns={columnsEventBuyers}
          data={tableEventsData}
          checkboxSelection
          // checkboxSelection={false}
        >
          <DataTable
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            export={false}
            exportHeaders={false}
            selectableRows={true}
            onSelectedRowsChange={(data) => {
              updateClearSelectedRows(false);
              // data.selectedCount = 0;
              // data.selectedRows = [];
              console.log("dataa", data);
              setSelectedRows(data.selectedRows);
              setTimeout(() => {
                console.log("selectedRows", selectedRows);
              }, 1000);
            }}
            //clearSelectedRows={clearSelectedRows}
            className="DataTable"
          />
        </DataTableExtensions>

        {/* <Button
        className={classes.next}
        onClick={() => {
          navigate(routes.dashboard);
        }}
      >
        Go To Dashboard
      </Button> */}
        <Button
          className={classes.next}
          onClick={() => {
            navigate(routes.dashboard);
          }}
        >
          Go To Dashboard
        </Button>
        <Modal
          open={modalOpen.visible}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ textAlign: "right" }}>
              <Button
                className="hoverClose"
                onClick={handleClose}
                sx={{
                  backgroundColor: "rgb(45, 85, 235)",
                  color: "#fff",
                  marginBottom: "1em",
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            <EditBuyerTable
              type={modalOpen.type}
              modalHandleClose={handleClose}
              eventBuyersList={eventBuyersList}
              data={[...selectedRows]}
              eventId={eventId}
            />
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
