import React from "react";
import momentTimezone from "moment-timezone";

export const getToken = () => {
  return localStorage.getItem("BidWebToken");
};

export const removeToken = () => {
  return localStorage.removeItem("BidWebToken");
};

export const setToken = (token) => {
  return localStorage.setItem("BidWebToken", token);
};

export const setUserData = (data) => {
  return localStorage.setItem("userData", JSON.stringify(data));
};

export const getUserData = () => {
  return JSON.parse(localStorage.getItem("userData"));
};
export const removeUserData = () => {
  return localStorage.removeItem("userData");
};

export const timeZones = () => {
  return momentTimezone.tz.names();
};

export const localSetTimeZone = () => {
  return localStorage.setItem("localTimeZone", momentTimezone.tz.guess());
};

export const localTimeZone = () => {
  return localStorage.getItem("localTimeZone");
};

export const removeLocalTimeZone = () => {
  return localStorage.removeItem("localTimeZone");
};

export const getTimeZone = () => {
  return localStorage.getItem("currentTimeZone");
};
