import React from "react";

function About() {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  return (
    <div className="container" style={{ marginTop: "10em" }}>
      <div className="mt-5 mb-5">
        <div className="aboutContent">
          <h2>About Us</h2>
          <h4>Welcome to V3Bid.com - Creating a Dynamic Auction Ecosystem</h4>
          <p>V3Bid.com is a pioneering platform that revolutionizes the auction industry by fostering a virtuous circle of success for both auctioneers and bidders. We bring simplicity and integration to the online auction process, offering comprehensive solutions that cover cataloguing, marketing, auction hosting, bidding, and secure payment options. Our aim is to empower auctioneers, enabling them to establish and grow their online businesses in a highly cost-efficient manner.</p>
          <h4>Our Focus: Industrial & Commercial Markets</h4>
          <p>At V3Bid.com, we cater to the diverse needs of the Industrial & Commercial market segment. This encompasses a wide range of used equipment, machinery, and commercial vehicles from various industries such as manufacturing, including sectors like plastic moulding, metalworking, woodworking, food and beverage production. Additionally, we also serve the needs of laboratories, pharmaceuticals, warehousing, construction, agriculture, and real estate. Our platform also accommodates surplus stock of consumer goods and retail returns, spanning multiple retail areas, including apparel, electronics, homewares, and furniture.</p>
          <h4>Seamless Connectivity and Unparalleled Reach</h4>
          <p>Through our leading secondary-goods marketplaces and proprietary auction platform technology, we seamlessly connect bidders from all corners of the globe to trusted auctioneers and sellers within the Industrial & Commercial and Art & Antiques sectors. The trust we've garnered from our extensive base of auctioneers, with remarkably high retention rates, highlights the value our network effect and multiple marketplaces provide. With over 172 million bidding sessions in FY22 alone, we drive a virtuous cycle of engagement, continually fueling the growth of the auction industry.</p>
          <h4>Cutting-Edge Technology Empowering Growth</h4>
          <p>Our advanced technology empowers auctioneers to scale their businesses with ease, enabling incremental volume at minimal additional cost. We are committed to continuously enhancing the online bidding experience, leveraging our ownership of multiple marketplaces to implement best practices and offer expanded opportunities for our auctioneers.</p>
          <h4>A Collaborative Partnership for Success</h4>
          <p>For over 50 years, we have fostered successful partnerships with auctioneers, embodying a shared success model. This model aligns the ambitions of V3Bid.com with those of our auctioneer partners, fostering united growth within the auction industry. Our purpose is to unlock the immense value of the secondary goods market while accelerating the growth of the circular economy.</p>
          <h4>Pioneering the Transformation of the Auction Industry</h4>
          <p>V3Bid.com is at the forefront of transforming the auction industry. As a trusted partner to both auctioneers and bidders, we are uniquely positioned to unlock the untapped potential of the secondary goods market. Our technology empowers expert auctioneers to digitize their businesses, enhance operational efficiency, and tap into a vast, diverse, and fragmented buyer base online.</p>
          <p>Join us on this exciting journey as we reshape the auction landscape, harnessing the power of technology, and championing the growth of the circular economy.</p>
          <p>Unlock the Value. Transform Auctions. Experience V3Bid.com.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
