import axios from "axios";

import config from "../config/config";

export const getStateAPI = async (country_id) => {
  country_id = country_id?.replace(/\//g, "")?.replace(/"/g, "");
  if (!country_id) return { data: [] };

  return await axios({
    method: "get",
    url: `${config.api.base_url}locations/countries/${country_id}/states`,
    // params    : params,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      // "Authorization": getToken(),
    },
  });
};

export default getStateAPI;
