import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Button from "@mui/material/Button";
import useStyles from "../dashAuction.style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import eventItemBidlogAPI from "../../../../api/adminAPI/eventItemBidlogAPI";
import getLotPostBidsAPI from "../../../../api/adminAPI/getLotPostBidsAPI";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {
  getToken,
  getUserData,
  getTimeZone,
  localTimeZone,
  localSetTimeZone,
} from "../../../../common";
import { Tooltip, Zoom } from "@mui/material";

import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";

function BidlogSecond({ AuctionView, eventId, eventItemId }) {
  const classes = useStyles();

  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const [tableEventItemBidData, setTableEventItemBidData] = useState([]);
  const [postBidsData, setPostBidData] = useState([]);

  const eventItemsBidDetails = async (eventId, eventItemId) => {
    try {
      const { data } = await eventItemBidlogAPI(eventId, eventItemId);

      if (data) {
        let id = 1;
        const eventsData = data.map((eventItem, index) => {
          return {
            id: id++,
            lotNumber: eventItem.lotNumber,
            buyerName: eventItem.buyerName,
            buyerPosition: eventItem.buyerPosition,
            bidPrice: eventItem.bidPrice,
            bidderBidCount: eventItem.bidderBidCount,
            totalBidsCount: eventItem.totalBidsCount,
            bidDateTime: moment(eventItem.bidDateTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
          };
        });

        setTableEventItemBidData(eventsData);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }

    try {
      const postBidsResponse = await getLotPostBidsAPI(eventId, eventItemId);
      if (postBidsResponse) {
        const postBidDataDetails = postBidsResponse.data?.map(
          (details, index) => {
            return {
              id: index + 1,
              lotNumber: details.eventItem.lotNumber,
              biddingTime: moment(details.biddingTime)
                .tz(getZone)
                .format("DD/MM/YYYY hh:mm:ss"),
              amount: details.amount,
              bidder: `${details.users.firstName} ${details.users.lastName}`,
            };
          }
        );

        setPostBidData(postBidDataDetails);
      } else throw new Error(postBidsResponse.data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    eventItemsBidDetails(eventId, eventItemId);
    return () => {
      return false;
    };
  }, [eventId, eventItemId]);

  const postBidsColumns = [
    {
      name: "Sr.",
      cell: (data) => String(data.id),
      width: "80px",
      sortable: true,
    },
    {
      name: "Lot No",
      cell: (data) => String(data.lotNumber),
      width: "140px",
      sortable: true,
    },
    {
      name: "Bid Date & Time",
      cell: ({ biddingTime }) => String(biddingTime),
      sortable: true,
      width: "180px",
    },
    {
      name: "Bid Price",
      cell: (data) => String(data.amount),
      width: "240px",
      sortable: true,
    },
    {
      name: "Bidder",
      cell: ({ bidder }) => {
        return (
          <Tooltip
            title={bidder}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{bidder}</span>
          </Tooltip>
        );
      },
      width: "200px",
      sortable: true,
    },
  ];

  const nextColumns = [
    {
      name: "Sr.",
      cell: (data) => String(data.id),
      sortable: true,
    },
    {
      name: "Lot No",
      cell: (data) => String(data.lotNumber),
      sortable: true,
    },
    {
      name: "Bid Date & Time",
      cell: ({ bidDateTime }) => String(bidDateTime),
      sortable: true,
      width: "180px",
    },
    {
      name: "Bid Price",
      cell: (data) => String(data.bidPrice),
      sortable: true,
    },
    {
      name: "Bidder",
      cell: ({ buyerName }) => {
        return (
          <Tooltip
            title={buyerName}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{buyerName}</span>
          </Tooltip>
        );
      },
      width: "200px",
      sortable: true,
    },
    {
      name: "Position",
      cell: (data) => String(data.buyerPosition),
      sortable: true,
    },
    {
      name: "Bidder Bid Count",
      cell: (data) => Number(data.bidderBidCount),
      sortable: true,
      width: "150px",
    },
    {
      name: "Total Bid Count",
      cell: (data) => Number(data.totalBidsCount),
      sortable: true,
      width: "150px",
    },
  ];

  const exportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.lotNumber,
      columns.bidDateTime,
      columns.bidPrice,
      columns.buyerName,
      columns.buyerPosition,
      columns.bidderBidCount,
      columns.totalBidsCount,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "bid-logs.xlsx");
  };

  const exportToXLSXPostBid = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.lotNumber,
      columns.biddingTime,
      columns.amount,
      columns.bidder,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "bid-logs.xlsx");
  };

  const ExportButton = () => (
    <Button onClick={() => exportToXLSX(tableEventItemBidData, nextColumns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  const PostBidExportButton = () => (
    <Button onClick={() => exportToXLSXPostBid(postBidsData, postBidsColumns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  return (
    <>
      <div className="AuctionTable" source="SELLER||ADMIN">
        <h3>
          <Button sx={{ color: "#000" }} onClick={AuctionView}>
            <ArrowBackIcon />
          </Button>
          Bid Log
        </h3>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={nextColumns}
          data={tableEventItemBidData}
        >
          <DataTable
            noHeader={false}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={false}
            highlightOnHover
            className="DataTable"
            actions={<ExportButton />}
          />
        </DataTableExtensions>
        <div
          style={{
            marginTop: "4rem",
          }}
        >
          <h3>Post bid logs</h3>
          <DataTableExtensions
            print={false}
            export={false}
            exportHeaders={false}
            columns={postBidsColumns}
            data={postBidsData}
          >
            <DataTable
              noHeader={false}
              defaultSortField="id"
              defaultSortAsc={false}
              pagination={false}
              highlightOnHover
              className="DataTable"
              actions={<PostBidExportButton />}
            />
          </DataTableExtensions>
        </div>
      </div>
    </>
  );
}

export default BidlogSecond;
