import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import lodash from "lodash";
import useErrorBoundary from "use-error-boundary";
import momentTimeZone from "moment-timezone";
import { Tab, Box } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { toast } from "react-toastify";

import useStyles from "../views/components/Auction/AuctionDetail/auctionDetail.style";
import muiComponentStyles from "./mui-component-style";
import AuctionLotDialog from "./mui-dialog-lot";
import AuctionLotBidsDialog from "./mui-dialog-lot-bids";
import createListLots from "../views/components/Auction/AuctionDetail/createListLots";
import ToggleViewButtons from "../views/components/Auction/AuctionDetail/ToggleViewButtons";
import RenderTabPanel from "../views/components/Auction/AuctionDetail/RenderTabPanel";
import { getToken } from "../common";
import setAutobidAPI from "../api/setAutobidAPI";
import { TimerContext } from "../app/App";
import { ChevronRight } from "@mui/icons-material";

const AuctionLotsContainer = ({ finalizeLot, auctionId, ...props }) => {
  const classes = useStyles();
  const styles = muiComponentStyles();
  const { ErrorBoundary, didCatch, error } = useErrorBoundary();
  const timerContext = useContext(TimerContext);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [activeDialog, toggleDialog] = useState(false);
  const [activeTab, switchTab] = useState("all-lots");
  const [view, setView] = useState("list");
  const [userAmount, setUserAmount] = useState({});
  const [proxyLotAmounts, setProxyLotAmounts] = useState({});
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [loadingProxyBidFor, setLoadingProxyBidFor] = useState(null);
  const [dialogProps, updateDialogProps] = useState({
    eventDetails: {},
    lotDetails: {},
  });
  const [tabPanelDetails, setTabPanelDetails] = useState([
    {
      value: "all-lots",
      lots: props.allLots,
      columns: [],
      listLots: [],
    },
    {
      value: "my-lots",
      lots: props.userLots,
      columns: [],
      listLots: [],
    },
    {
      value: "proxy-bid",
      lots: props.userLots,
      columns: [],
      listLots: [],
    },
  ]);

  useEffect(() => {
    if (getToken()) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    updateTabPanelDetails(0, props.allLots);
  }, [props.allLots, isOnline]);

  useEffect(() => {
    updateTabPanelDetails(1, props.userLots);

    // live lots for proxy bid
    const liveLots =
      props.userLots?.filter((lot) => lot.status === "live") || [];
    updateProxyLotAmounts(liveLots);
    updateTabPanelDetails(2, liveLots);
  }, [props.userLots, isOnline]);

  useEffect(() => {
    if (props.allLots) {
      // update user lot amounts
      const userLotAmounts = updateUserLotAmounts(props.allLots);
      panelDetailsReRender(0, props.allLots, userLotAmounts);
      panelDetailsReRender(1, props.userLots, userLotAmounts);
    }
  }, [props.allLots]);

  const updateUserLotAmounts = (lots) => {
    const updatedLotAmounts = lodash.cloneDeep(userAmount || {});
    lots.forEach(
      (lot) =>
        (updatedLotAmounts[`${lot.lotNumber}`] = Number(
          lot.nextBidAmount >= 0 ? lot.nextBidAmount : 0
        ))
    );
    setUserAmount(updatedLotAmounts || {});
    return updatedLotAmounts;
  };

  const setProxyBid = async (eventDetails, proxyBidAmount) => {
    const eventType = eventDetails.event.eventType;
    const nextBidAmount = eventDetails.nextBidAmount;

    if (eventType === "REVERSE_AUCTION" && proxyBidAmount >= nextBidAmount) {
      return toast.info(`Proxy bid amount must be less than ${nextBidAmount}`);
    }

    if (eventType === "FORWARD_AUCTION" && proxyBidAmount <= nextBidAmount) {
      return toast.info(
        `Proxy bid amount must be greater than ${nextBidAmount}`
      );
    }

    const eventId = eventDetails.eventId;
    const eventItemId = eventDetails.eventItemId;
    setLoadingProxyBidFor(eventItemId);

    try {
      const response = await setAutobidAPI(
        { amount: proxyBidAmount },
        eventId,
        eventItemId
      );
      setLoadingProxyBidFor(null);

      // update current proxy bid amount for live lots
      const liveLots =
        props.userLots
          ?.filter((lot) => lot.status === "live")
          ?.map((lot) => {
            if (lot.eventItemId == eventItemId)
              lot.proxyBidAmount = proxyBidAmount;
            return lot;
          }) || [];

      updateTabPanelDetails(2, liveLots);

      return toast.success(
        `Proxy bid on lot ${eventDetails.lotNumber} set successfully!`
      );
    } catch (error) {
      setLoadingProxyBidFor(null);
      return toast.error(error.response.data.error || "Something went wrong!");
    }
  };

  const updateProxyLotAmounts = (lots) => {
    const updatedProxyLotAmounts = lodash.cloneDeep(proxyLotAmounts || {});
    lots.forEach(
      (lot) =>
        (updatedProxyLotAmounts[`${lot.lotNumber}`] = Number(
          lot.proxyBidAmount || (lot.nextBidAmount >= 0 ? lot.nextBidAmount : 0)
        ))
    );
    setProxyLotAmounts(updatedProxyLotAmounts || {});
    return updatedProxyLotAmounts;
  };

  const panelDetailsReRender = (index, lots, amounts) => {
    const timeZone =
      localStorage.getItem("currentTimeZone") || momentTimeZone.tz.guess();
    const currentTime = moment().tz(timeZone).format();

    const isPreviewPeriodAllowed = props.eventsDetails?.previewPeriod !== "NO";

    lots =
      lots?.map((lot) => {
        const startDateAndTime = moment(lot.startDateAndTime)
          .tz(timeZone)
          .format(); // lot start date

        // if (
        //   isPreviewPeriodAllowed &&
        //   timerContext.currentTime < startDateAndTime
        // ) {
        //   lot.nextBidAmount = 0;
        //   lot.myBidStatus.myBidRank = null;
        // }

        if (isPreviewPeriodAllowed && currentTime < startDateAndTime) {
          lot.nextBidAmount = 0;
          lot.myBidStatus.myBidRank = null;
        }

        return lot;
      }) || [];

    const { columns, data } = createListLots(
      lots,
      styles,
      amounts,
      props.eventsDetails,
      handleUserAmountChange,
      storeHandler,
      isForwardAuction,
      isOnline,
      lotBidEnded,
      tabPanelDetails[index].value,
      handleProxyAmountChange,
      setProxyBid
    );

    setTabPanelDetails((currentDetails) => {
      const updatedDetails = currentDetails?.map((details) =>
        lodash.cloneDeep(details)
      );

      updatedDetails[index] = {
        ...updatedDetails[index],
        columns,
        lots,
        listLots: data,
      };

      return updatedDetails;
    });
  };

  const updateTabPanelDetails = (index, lots) => {
    let userLotAmounts = lodash.cloneDeep(userAmount);
    if (lots.length && !Object.keys(userLotAmounts).length && index == 0) {
      userLotAmounts = updateUserLotAmounts(lots);
    }

    panelDetailsReRender(index, lots, proxyLotAmounts);
  };

  const handleChange = (event, tabId) => {
    switchTab(tabId);
  };

  const handleAuctionDialog = useCallback(
    (props) => {
      toggleDialog((prevActiveDialog) => !prevActiveDialog);
      updateDialogProps(() => props);
    },
    [props.allLots, props.userLots, isOnline]
  );

  const [activeLotBidsDialog, toggleActiveLotBidsDialog] = useState(false);
  const handleAuctionLotBidsDialog = useCallback(
    (props) => {
      toggleActiveLotBidsDialog((prevActiveDialog) => !prevActiveDialog);
    },
    [props.allLots, props.userLots, isOnline]
  );

  const handleViewChange = (event, newView) => {
    setView(newView);
  };

  const handleUserAmountChange = (lotNumber, amount, amounts = {}) => {
    const current = lodash.cloneDeep(amounts || {});
    current[lotNumber] = Number(amount);
    setUserAmount(current || {});

    panelDetailsReRender(0, props.allLots, current);
    panelDetailsReRender(1, props.userLots, current);
  };

  const handleProxyAmountChange = (lotNumber, amount, amounts = {}) => {
    const current = lodash.cloneDeep(amounts || {});
    current[lotNumber] = Number(amount);
    setProxyLotAmounts(current || {});

    const liveLots =
      props.userLots?.filter((lot) => lot.status === "live") || [];

    panelDetailsReRender(2, liveLots, current);
  };

  const lotBidEnded = async (data, index) => {
    if (data.status !== "live") {
      props.refetchLotDetails();
      return;
    }
    finalizeLot(data.eventItemId);
  };

  const storeHandler = (lotDetails, bidAmount) => {
    const eventDetails = props.eventsDetails;

    // const lotNumber = lotDetails.lotNumber;
    // const bidAmount = userAmount[`${lotNumber}`];
    // let { lotNumber, startDateAndTime } = lotDetails;
    // const { publishDateTime, bidStartDateTime } = eventDetails;
    const timeZone =
      localStorage.getItem("currentTimeZone") || momentTimeZone.tz.guess();

    const currentTime = moment().tz(timeZone).format();
    const publishDateTime = moment(eventDetails.publishDateTime)
      .tz(timeZone)
      .format(); // event publish date
    const bidStartDateTime = moment(eventDetails.bidStartDateTime)
      .tz(timeZone)
      .format(); // event start date
    const startDateAndTime = moment(lotDetails.startDateAndTime)
      .tz(timeZone)
      .format(); // lot start date
    const endDateAndTime = moment(lotDetails.endDateAndTime)
      .tz(timeZone)
      .format(); // lot end date

    if (!bidAmount) {
      return toast.error(`Please enter the bidding amount.`);
    }
    if (bidAmount <= 0) {
      return toast.error(`Bidding amount must be greater than zero. `);
    }

    // if (
    //   timerContext.currentTime >= publishDateTime &&
    //   timerContext.currentTime <= bidStartDateTime
    // ) {
    //   return handleAuctionDialog({
    //     eventDetails,
    //     lotDetails,
    //     userAmount: bidAmount,
    //   });
    // }

    // if (startDateAndTime > timerContext.currentTime) {
    //   return toast.error(`Bidding hasn't started yet.`);
    // }

    if (currentTime >= publishDateTime && currentTime <= bidStartDateTime) {
      return handleAuctionDialog({
        eventDetails,
        lotDetails,
        userAmount: bidAmount,
      });
    }

    if (startDateAndTime > currentTime) {
      return toast.error(`Bidding hasn't started yet.`);
    }

    const isForwardAuction = () => {
      return props.eventsDetails.eventType === "FORWARD_AUCTION";
    };
    const isReverseAuction = () => {
      return props.eventsDetails.eventType === "REVERSE_AUCTION";
    };

    if (isForwardAuction() && bidAmount < lotDetails.nextBidAmount) {
      return toast.error(
        `Please place a bid higher than the current bid. ${lotDetails.nextBidAmount}`
      );
    } else if (isReverseAuction() && bidAmount > lotDetails.nextBidAmount) {
      return toast.error(
        `Please place a bid lower than the current bid. ${lotDetails.nextBidAmount}`
      );
    }

    return handleAuctionDialog({
      eventDetails,
      lotDetails,
      userAmount: bidAmount,
    });
  };

  const isForwardAuction = props.eventsDetails?.eventType === "FORWARD_AUCTION";

  const userDetails = (() =>
    JSON.parse(localStorage.getItem("userData") ?? `{}`))();

  return (
    <div className="container">
      <div className={classes.auctionTab}>
        <div
          style={{
            display: "flex",
            justifyItems: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <button
            style={{
              alignSelf: "end",
              marginLeft: "auto",
              color: userDetails?.role !== "BUYER" ? "grey" : "blue",
              display: "flex",
              alignItems: "center",
            }}
            disabled={userDetails?.role !== "BUYER"}
            onClick={() => {
              window.location.href = `/#/auction/${auctionId}/post-bid`;
            }}
          >
            Post bid lots <ChevronRight size="1.2rem" />
          </button>
        </div>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList value={activeTab} onChange={handleChange}>
              <Tab
                sx={{ fontSize: "19px", fontWeight: "bold" }}
                label={`All Lots (${lodash.size(props.allLots)})`}
                value="all-lots"
              />
              <Tab
                sx={{ fontSize: "19px", fontWeight: "bold" }}
                label={`My Lots (${lodash.size(props.userLots)})`}
                value="my-lots"
              />
              {isAuthenticated && (
                <Tab
                  sx={{ fontSize: "19px", fontWeight: "bold" }}
                  label={`Proxy Bid`}
                  value="proxy-bid"
                />
              )}
            </TabList>
          </Box>
          {activeTab != "proxy-bid" && (
            <ToggleViewButtons
              view={view}
              handleViewChange={handleViewChange}
            />
          )}
          {tabPanelDetails
            .filter((tabPanelDetails) =>
              isAuthenticated ? true : tabPanelDetails.value != "proxy-bid"
            )
            .map((tabPanelDetail, index) => (
              <RenderTabPanel
                {...tabPanelDetail}
                view={view}
                key={index}
                handleAuctionLotBidsDialog={handleAuctionLotBidsDialog}
                eventsDetails={props.eventsDetails}
                handleAuctionDialog={handleAuctionDialog}
                didCatch={didCatch}
                error={error}
                errorBoundary={ErrorBoundary}
              />
            ))}
        </TabContext>
        <AuctionLotBidsDialog
          activeDialog={activeLotBidsDialog}
          handleAuctionDialog={handleAuctionLotBidsDialog}
        ></AuctionLotBidsDialog>
        <AuctionLotDialog
          dialogProps={dialogProps}
          activeDialog={activeDialog}
          handleAuctionDialog={handleAuctionDialog}
        ></AuctionLotDialog>
      </div>
    </div>
  );
};

export default AuctionLotsContainer;
