import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  pre: {
    width: "14em !important",
    color: "#2D55EB !important",
    backgroundColor: "#fff !important",
    borderRadius: "0em !important",
    border: "1px solid #2D55EB !important",
    [theme.breakpoints.down("sm")]: {
      width:"100% !important"
    },
  },
  next: {
    width: "14em !important",
    borderRadius: "0em !important",
    // marginLeft: "1em !important",
    color: "#fff !important",
    backgroundColor: "#2D55EB !important",
    [theme.breakpoints.down("sm")]: {
      width:"100% !important"
    },
  },
  saveNextBtn: { 
    margin: "2em 0em",
    [theme.breakpoints.down("sm")]: {
      display:'flex !important',
    },
    [theme.breakpoints.down("sm")]: {
      width:"100% !important"
    },
 },
  accord: { padding: "2em 2em !important", position: "relative" },
  number: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "1.7em",
    height: "1.7em",
    background: "#000",
    textAlign: "center",
  },
  textNum: { color: "#fff !important" },
  headDetail: {
    textAlign: "center !important",
    justifyContent: "center",
    alignItem: "center",
    borderRight: "1px solid #ccc",
  },
  commonCard: { width: "100% !important" },
  headingFour: { marginBottom: "0em" },
  accorDetail: { display: "flex" },
  quantity: { padding: "0.7em 6em 0em 0em", fontSize: "14px" },
  price: { padding: "0.7em 6em 0em 0em", fontSize: "14px" },
  increment: { padding: "0.7em 6em 0em 0em", fontSize: "14px" },
  timer: { display: "flex", padding: "12px 0px" },
  accordanHeader: { border: "1px solid #989898", position: "relative" },
  accordanContent: { width: "100%" },
  remove: {
    position: "absolute !important",
    bottom: "-18px !important",
    left: "35px !important",
    border: "1px solid red !important",
    backgroundColor: "#FABCBC  !important",
    borderRadius: "0px",
    color: "red !important",
  },
  expand: {
    position: "absolute !important",
    bottom: "-18px !important",
    right: "35px !important",
    backgroundColor: "#2D55EB !important",
    borderRadius: "100% !important",
    minWidth: "2em !important",
    height: "2.8em",
  },
  form: { width: "100% !important" },
  input: {
    borderRadius: "0px",
    color: "#3F4043",
    backgroundColor: "#F2F2F24D",
  },
  label: {
    color: "#7E8184",
    [theme.breakpoints.down("tablet")]: {
      height:'0.9em'
    },
  },
  textarea: {
    width: "99% !important",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontFamily: "Roboto",
    fontSize: 16,
  },
  upload: {
    border: "1px solid #ced4da !important",
    backgroundColor: "#f2f2f24d !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
    marginRight: "1em !important",
  },
  uploadIcon: { color: "#7E8184 !important", fontSize: "18px" },
  Tablehead: {
    // width:'100% !important'
  },
  editBtn: { display: "flex" },
  trash: { color: "red", fontSize: "18px !important" },
  edit: { fontSize: "18px !important" },
  AddBtn: {
    border: "1px solid #2D55EB !important",
    backgroundColor: "#E8EDFF !important",
    marginBottom: "1em !important",
    borderRadius: "0px !important",
  },
  btnSection: { textAlign: "right" },
  imagesStyle: {
    height: "6em",
    objectFit: "cover",
    borderRadius: "10px",
    border: " 1px solid #ccc",
  },
  checkBoxes: { marginRight: "1em" },
}));

export default useStyles;
