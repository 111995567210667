import * as React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import colorSchemes from "../../../constants/colorSchemes";
import routes from "../../../constants/routes";
import useStyles from "./resetPassword.styles";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import resetPasswordAPI from "../../../api/resetPasswordAPI";
import { removeToken, removeUserData, getUserData } from "../../../common";
import LogoutIcon from "@mui/icons-material/Logout";
import { isEmpty } from "lodash";
import Loader from "../../../common/Loader";

export default function Forgot() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const email = location.state.email;
  const otp = location.state.otp;
  const [resetPassword, setResetPassword] = useState({
    password: "",
    confirmpassword: "",
    email: email,
    otp: otp,
  });
  const userData = getUserData() ?? {};
  //---------------------------- login-Process ----------------------------------

  const handleChange = (event) => {
    setResetPassword({
      ...resetPassword,
      [event.target.name]: event.target.value,
    });
  };
  console.clear();
  console.log(resetPassword);
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      if (resetPassword.password != resetPassword.confirmpassword) {
        setIsLoading(false);
        toast("Password Does Not Match!!");
      } else {
        const { confirmpassword, ...payload } = resetPassword;
        const { data } = await resetPasswordAPI(payload);

        if (data) {
          toast("Password Updated Sucessfully!");
          setIsLoading(false);
          setTimeout(() => {
            navigate(routes.signin);
          }, 1500);
        }
      }
    } catch (error) {
      if (error.response) {
        //console.log(error.response.data.error);
        toast(error.response.data.error);
        setIsLoading(false);
      } else if (error.request) {
        toast("Something Went Wrong!");
        setIsLoading(false);
      } else {
        //console.log('Error', error.message.code);
        setIsLoading(false);
      }
    }

    //setUserInfo({ email: "", firstname: "", lastname: "", phonenumber: "" , password: "", confirmpassword: ""});
  };

  return (
    <>
      {isLoading === true && <Loader isLoading={isLoading} />}
      <Box className={classes.signin}>
        {isEmpty(userData) ? (
          <Card
            sx={{ maxWidth: 500, marginTop: "9em", padding: "1em 2em" }}
            className={classes.signcard}
          >
            <Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "600",
                  color: colorSchemes.primaryColor,
                }}
                variant="h5"
              >
                SET NEW PASSWORD
              </Typography>
            </Box>
            <form layout="vertical" onSubmit={handleSubmit}>
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className={classes.label}>Password</Form.Label>
                  <Form.Control
                    className={classes.input}
                    type="password"
                    placeholder="Enter password"
                    name="password"
                    value={resetPassword.password}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Box>
              <Box className={classes.forms}>
                <Form.Group
                  className="mb-3"
                  controlId="formBasicConfirmPassword"
                >
                  <Form.Label className={classes.label}>
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    className={classes.input}
                    type="password"
                    placeholder="Enter Confirm Password"
                    name="confirmpassword"
                    value={resetPassword.confirmpassword}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Box>
              <Box>
                <Button
                  sx={{
                    backgroundColor: colorSchemes.primaryColor,
                    color: "#fff",
                    borderRadius: "0em",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "#2D55EB !important",
                    },
                  }}
                  // onClick={() => {
                  //   setToken(loginInfo.email);
                  //   navigate(routes.home);
                  // }}
                  fullWidth
                  type="submit"
                >
                  Update
                </Button>
              </Box>
            </form>
            <Typography
              className={classes.forgot}
              sx={{
                textAlign: "center",
              }}
            >
              <Link to="/register">Back To Login</Link>{" "}
            </Typography>
          </Card>
        ) : (
          <Card
            sx={{ maxWidth: 500, marginTop: "9em", padding: "1em 2em" }}
            className={classes.signcard}
          >
            <center>
              <h3>Your Already Logged in!</h3>
              <Button
                sx={{
                  backgroundColor: colorSchemes.primaryColor,
                  color: "#fff",
                  borderRadius: "0em",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#2D55EB !important",
                  },
                }}
                onClick={() => {
                  removeToken();
                  removeUserData();
                  navigate(routes.signin);
                }}
                fullWidth
                type="submit"
              >
                <LogoutIcon
                  sx={{
                    color: "white",
                    fontSize: "16px",
                    marginTop: "-2px",
                    marginRight: "5px",
                  }}
                />
                Log Out
              </Button>
            </center>
          </Card>
        )}
      </Box>
    </>
  );
}
