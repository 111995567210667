import config from "../config/config";
import axios from "axios";
import { getToken } from "../common";

export const getEventItemsListAPI = async (params, query = {}) => {
  return await axios({
    method: "get",
    url: `${config.api.base_url}events/${params.itemId}/item`,
    params: { ...query },
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: getToken(),
    },
  });
};

export default getEventItemsListAPI;
