import config from '../../config/config'
import axios from 'axios'
import { getToken } from '../../common'; 

export const updateEventItem = async (params,eventId,eventItemId) => {     
    return await axios({
        method  : 'patch',
        url     : `${config.api.base_url}events/${eventId}/item/${eventItemId}`,
        data    : params,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": getToken(),
        }
    })
}

export default updateEventItem