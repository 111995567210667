import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // homeContent: {
  //   marginTop: "6.4em",
  //   [theme.breakpoints.down("tablet")]: {
  //       marginTop: "4.4em",
  //   },
  // },
}));

export default useStyles;
