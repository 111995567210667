import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  form: { width: "100% !important" },
  textarea: {
    borderRadius: 2,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "5px 12px",
  },
  input: {
    borderRadius: "0px",
    color: "#3F4043",
    backgroundColor: "#F2F2F24D",
  },
  label: { color: "#7E8184" },
  textarea: {
    width: "99% !important",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontFamily: "Roboto",
    fontSize: 16,
  },
  textHead: { fontWeight: "600 !important" },
  pre: {
    width: "14em !important",
    color: "#2D55EB !important",
    backgroundColor: "#fff !important",
    borderRadius: "0em !important",
    border: "1px solid #2D55EB !important",
  },
  next: {
    width: "14em !important",
    borderRadius: "0em !important",
    color: "#fff !important",
    backgroundColor: "#2D55EB !important",
  },
  saveBtn: { 
    marginTop: "2em",
    [theme.breakpoints.down("sm")]: {
      textAlign:'center'
    },
  },
  sellerFormHead:{marginBottom:'1em !important',fontSize:'18px !important',fontWeight:'600 !important'}
}));

export default useStyles;
