import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  imageSize: {
    fontSize: "10px",
  },
  upload: {
    border: "1px solid #ced4da !important",
    backgroundColor: "#f2f2f24d !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
    lineHeight: "1.75",
    // marginRight: "1em !important",
  },
  uploadIcon: { color: "#7E8184 !important", fontSize: "18px" },
  errorText: { color: "red" },
  flexRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.2rem",
    justifyContent: "space-between",
  },
}));

export default useStyles;
