import React from "react";
import { Typography } from "@mui/material";
import colorSchemes from "../../../../constants/colorSchemes";
import useStyles from "./rulesAndSafety.style";
import NavigationIcon from "@mui/icons-material/Navigation";
import { Box } from "@mui/system";
import Pay from "../../../../assets/common/paypal.png";
import Secure from "../../../../assets/common/secure.png";

function RulesAndsafety() {
  const classes = useStyles();
  return (
    <div className="container">
      <div className={classes.rules}>
        <div className="row">
          <div className="col-md-6">
            <div className={classes.bidweOnline}>
              <div className={classes.rulesHead}>
                <Typography
                  sx={{
                    color: colorSchemes.primaryColor,
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  V3Bid Online Auction Rules
                </Typography>
              </div>
              <div className={classes.detail}>
                <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                  <NavigationIcon
                    className="arrow"
                    sx={{ marginRight: "0.6em" }}
                  />
                  Our Services provide a platform for third-party auctioneers
                  and Sellers to offer online auction content.
                </Typography>
                <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                  <NavigationIcon
                    className="arrow"
                    sx={{ marginRight: "0.6em" }}
                  />
                  Users can view and participate in auction sales through the
                  Services.
                </Typography>
                <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                  <NavigationIcon
                    className="arrow"
                    sx={{ marginRight: "0.6em" }}
                  />
                  We are not an online auctioneer or Seller and have no control
                  over the information made available by auctioneers or Sellers via the
                  Services.
                </Typography>
                <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                  <NavigationIcon
                    className="arrow"
                    sx={{ marginRight: "0.6em" }}
                  />
                  We do not provide any representations or warranties regarding
                  what the auctioneer or Seller may offer for sale through the
                  Services.
                </Typography>
                <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                  <NavigationIcon
                    className="arrow"
                    sx={{ marginRight: "0.6em" }}
                  />
                  General use of the Services is free, but some services may
                  require a subscription fee and additional terms and
                  conditions.
                </Typography>
                <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                  <NavigationIcon
                    className="arrow"
                    sx={{ marginRight: "0.6em" }}
                  />
                  We may be paid by auctioneers, Sellers and Buyers to provide
                  the Services to potential bidders.
                </Typography>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className={classes.rulesOnline}>
              <div className={classes.align}>
                <div className={classes.rulesHead}>
                  <Typography
                    sx={{
                      color: colorSchemes.primaryColor,
                      fontWeight: "600",
                      fontSize: "20px",
                    }}
                  >
                    Secure & Safe Online Bidding
                  </Typography>
                </div>
                <div className={classes.detail}>
                  <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                    <NavigationIcon
                      className="arrow"
                      sx={{ marginRight: "0.6em" }}
                    />
                    We do not require or accept any security deposit or other
                    deposits from our users for the sale of lots/auctioned
                    items.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                    <NavigationIcon
                      className="arrow"
                      sx={{ marginRight: "0.6em" }}
                    />
                    Our Services provide a platform for Auctioneers to offer
                    online auction content
                  </Typography>
                  <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                    <NavigationIcon
                      className="arrow"
                      sx={{ marginRight: "0.6em" }}
                    />
                    You can use our Services to view and participate in auction
                    sales
                  </Typography>
                  <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                    <NavigationIcon
                      className="arrow"
                      sx={{ marginRight: "0.6em" }}
                    />
                    We are not an Auctioneer and are not party to any contract
                    for sale and purchase between you and the Auctioneer
                  </Typography>
                  <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                    <NavigationIcon
                      className="arrow"
                      sx={{ marginRight: "0.6em" }}
                    />
                    We have no control over the information made available by
                    Auctioneers through the Services.
                  </Typography>
                  <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                    <NavigationIcon
                      className="arrow"
                      sx={{ marginRight: "0.6em" }}
                    />
                    Contact us if you have any questions regarding these Bidder
                    Terms.
                  </Typography>
                  <Box className={classes.payLogo}>
                    <img src={Secure} style={{ marginRight: "1em" }} />
                    <img src={Pay} />
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RulesAndsafety;
