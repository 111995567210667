import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Button from "@mui/material/Button";
// import useStyles from "../dashAuction.style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import eventItemBidlogAPI from "../../../../api/adminAPI/eventItemBidlogAPI";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {
  getToken,
  getUserData,
  getTimeZone,
  localTimeZone,
  localSetTimeZone,
} from "../../../../common";

function BidModal({ AuctionView, eventId, eventItemId }) {
  // const classes = useStyles();
  console.log("BidModal called");
  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const [tableEventItemBidData, setTableEventItemBidData] = useState([]);

  const eventItemsBidDetails = async (eventId, eventItemId) => {
    try {
      const { data } = await eventItemBidlogAPI(eventId, eventItemId);

      if (data) {
        let id = 1;
        const eventsData = data.map((eventItem, index) => {
          return {
            id: id++,
            lotNumber: eventItem.lotNumber,
            buyerName: eventItem.buyerName,
            bidPrice: eventItem.amount,
            bidDateTime: moment(eventItem.biddingTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
          };
        });

        setTableEventItemBidData(eventsData);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    eventItemsBidDetails(eventId, eventItemId);
    return () => {
      return false;
    };
  }, [eventId, eventItemId]);

  const nextColumns = [
    {
      name: "Sr.",
      selector: "id",
      cell: (data) => String(data.id),
      sortable: true,
    },
    {
      name: "Lot No",
      selector: "lotNumber",
      cell: (data) => String(data.lotNumber),
      sortable: true,
    },
    {
      name: "Amount",
      selector: "bidPrice",
      cell: (data) => Number(data.bidPrice),
      sortable: true,
    },
    {
      name: "Bid Date And Time",
      selector: "bidDateTime",
      cell: (data) => String(data.bidDateTime),
      sortable: true,
    },
  ];

  return (
    <>
      <div className="AuctionTable">
        <h3>
          <Button sx={{ color: "#000" }} onClick={AuctionView}>
            <ArrowBackIcon />
          </Button>
          Bid Log
        </h3>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={nextColumns}
          data={tableEventItemBidData}
        >
          <DataTable
            noHeader
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={true}
            highlightOnHover
            className="DataTable"
          />
        </DataTableExtensions>
      </div>
    </>
  );
}

export default BidModal;
