import { Tooltip } from "@mui/material";
import React from "react";

const MyBidRankComponent = ({ myBidStatus, eventType, showH1L1Bid }) => {
  const myBidRank = myBidStatus?.myBidRank;
  const notRanked = !myBidRank || myBidRank === "0";

  if (showH1L1Bid === "IN_TOP_RANKS_ONLY") {
    return (
      <div style={{ width: notRanked ? "25rem" : "23rem" }}>
        {notRanked ? (
          <Tooltip title={"Your bid is not among top 3 bids for this lot"}>
            <span style={{ color: "red", fontWeight: "bold" }}>
              Your bid is not among top 3 bids for this lot.
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={"Your bid is among top 3 bids for this lot"}>
            <span style={{ color: "green", fontWeight: "bold" }}>
              Your bid is among top 3 bids for this lot.
            </span>
          </Tooltip>
        )}
      </div>
    );
  }

  return (
    <div>
      {notRanked ? (
        <span style={{ color: "red", fontWeight: "bold" }}>N/A</span>
      ) : eventType === "FORWARD_AUCTION" ? (
        <span style={{ color: "green", fontWeight: "bold" }}>H{myBidRank}</span>
      ) : (
        <span style={{ color: "green", fontWeight: "bold" }}>L{myBidRank}</span>
      )}
    </div>
  );
};

export default MyBidRankComponent;
