import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // event: { position: "relative", zIndex: "999999", top: "-1em" },
  form: { width: "100% !important" },
  textarea: {
    borderRadius: 2,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "5px 12px",
  },
  noPdf: {
    display: "none",
    backgroundColor: "red",
  },
  pdf: {
    display: "block",
    backgroundColor: "blue",
  },
  imageSize: {
    fontSize: "10px",
  },
  input: {
    borderRadius: "0px",
    color: "#3F4043",
    backgroundColor: "#F2F2F24D",
  },
  label: { color: "#7E8184" },
  textarea: {
    width: "99% !important",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontFamily: "Roboto",
    fontSize: 16,
  },
  textHead: { fontWeight: "600 !important" },
  pre: {
    width: "14em !important",
    color: "#2D55EB !important",
    backgroundColor: "#fff !important",
    borderRadius: "0em !important",
    border: "1px solid #2D55EB !important",
  },
  next: {
    width: "14em !important",
    borderRadius: "0em !important",
    // marginLeft: "1em !important",
    color: "#fff !important",
    backgroundColor: "#2D55EB !important",
  },
  saveBtn: {
    marginTop: "2em",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  upload: {
    border: "1px solid #ced4da !important",
    backgroundColor: "#f2f2f24d !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
    lineHeight: "1.75",
    // marginRight: "1em !important",
  },
  uploadIcon: { color: "#7E8184 !important", fontSize: "18px" },
  Tablehead: {
    // width:'100% !important'
  },
  radio: { marginLeft: "1em" },
  break: {
    borderTop: "0.5px solid #AAAAAA",
    margin: "3em 0em !important",
  },
}));

export default useStyles;
