import React from "react";

const RefundPolicy = () => {
  return (
    <div className="container" style={{ marginTop: "9em" }}>
      <div className="termHead">
        <h2>Refund Policy</h2>
      </div>
      <div className="termsContent">
        <p>
          All services and subscriptions purchased on V3bid.com are eligible for
          a refund within seven days from the date of payment realization in the
          bank account. To request a refund, customers must provide proof of
          payment deposition and the purchase of the service. Charges related to
          payment gateways are non-refundable.
        </p>
        <p>
          Refunds may take up to fifteen days to process after the refund
          request is approved. Security deposits or Earnest Money Deposits (EMD)
          are subject to the respective terms and conditions outlined in each
          auction. Please refer to the specific auction's terms and conditions
          regarding the refund policy for security deposits or EMD.
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
