import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // event: { position: "relative", zIndex: "999999", top: "-1em" },

  textHead: { fontWeight: "600 !important" },
  pre: {
    width: "14em !important",
    color: "#2D55EB !important",
    backgroundColor: "#fff !important",
    borderRadius: "0em !important",
    border: "1px solid #2D55EB !important",
    [theme.breakpoints.down("mxs")]: {
      marginLeft: "0px !important",
      // marginTop: "1em !important",
      width: "100% !important",
      marginRight: "0em !important",
    },
  },
  next: {
    width: "14em !important",
    borderRadius: "0em !important",
    marginLeft: "1em !important",
    color: "#fff !important",
    backgroundColor: "#2D55EB !important",
    [theme.breakpoints.down("mxs")]: {
      marginLeft: "0px !important",
      marginTop: "1em !important",
      width: "100% !important",
      marginRight: "0em !important",
    },
  },
  tab: { width: "25% !important", lineHeight: "2.25" },
  kuldeep: { textAlign: "left" },
}));

export default useStyles;
