import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../../common/Loader";
import config from "../../config/config";
import axios from "axios";
import lodash from "lodash";
import routes from "../../constants/routes";

const SocialLogin = () => {
  const params = useParams();
  const navigate = useNavigate();

  const userDetails = async () => {
    try {
      const validateParams = lodash.some(["source", "token"], (key) =>
        lodash.isEmpty(lodash.get(params, key))
      );
      const validateSession = lodash.some(
        ["userData", "BidWebToken"],
        (key) => !lodash.isEmpty(localStorage.getItem(key))
      );

      if (validateSession === true) {
        throw new Error("Already logged in");
      }

      if (validateParams === true) {
        throw new Error("Invalid params");
      }

      const { data: userDetails } = await axios({
        method: "get",
        url: `${config.api.base_url}profile`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: params.token,
        },
      });

      localStorage.setItem("userData", JSON.stringify(userDetails));
      localStorage.setItem("BidWebToken", params.token);

      return navigate(routes.home);
    } catch (error) {
      return navigate("/sign-in");
    }
  };

  useEffect(() => {
    userDetails();
  }, []);

  return (
    <React.Fragment>
      <div style={{ padding: "9.63em", textAlign: "center" }}>
        <b>Please Wait</b>
      </div>
      <Loader isLoading={true} />
    </React.Fragment>
  );
};

export default SocialLogin;
