import config from '../config/config'
import axios from 'axios'
import { getToken } from '../common'; 

export const deleteBuyerFromEvent = async (eventBuyerId,eventId) => {

    return await axios({
        method  : 'delete',
        url     : `${config.api.base_url}events/${eventId}/buyers/${eventBuyerId}`,
        // data    : params,
        headers : {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": getToken(),
        }
    })
}

export default deleteBuyerFromEvent