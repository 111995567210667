import React from "react";
import DashboardMenu from "../../commonComponents/DashboardMenu";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import useStyles from "../DashboardAuction/dashAuction.style";
import buyerListAPI from "../../../api/adminAPI/buyerListAPI";
import { useState, useEffect } from "react";
import moment from "moment";
import DashBuyerDetail from "../DashboardAuction/BidlogFirst/DashBuyerDetail";
import DashEventDetail from "../DashboardAuction/BidlogFirst/DashEventDetail";
import BuyerBidLog from "../DashboardAuction/BidlogFirst/BuyerBidLog";
import { getUserData } from "../../../common";
import { Navigate } from "react-router-dom";

import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";

function DashBuyer() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [changeView, SetChangeView] = useState(false);
  const [buyerEventId, SetBuyerEventId] = useState("");
  const [buyerEventItemId, SetBuyerEvenItemtId] = useState("");
  const [buyerId, SetBuyerId] = useState("");

  const userData = getUserData() ?? {};

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [tableBuyers, setTableBuyers] = useState([]);

  const buyerList = async () => {
    try {
      const { data } = await buyerListAPI();

      if (data) {
        let id = 1;
        const buyersData = data.map((list, index) => {
          return {
            id: id++,
            userName: `${list.firstName} ${list.lastName}`,
            company: list.company,
            email: list.email,
            contactNumber: list.contactNumber,
            alternateContactNumber: list.alternateContactNumber,
            buyerId: list.userId,
            h1RanksCount: list.h1RanksCount,
            h2RanksCount: list.h2RanksCount,
            h3RanksCount: list.h3RanksCount,
            totalEvents: list.totalEvents,
            totalBidedItems: list.totalBidedItems,
            totalItems: list.totalItems,
            allotedItems: list.allotedItems,
          };
        });

        setTableBuyers(buyersData);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    buyerList();
    return () => {
      return false;
    };
  }, []);

  const columns = [
    {
      name: "Sr. No.",
      cell: ({ id }) => Number(id),
      sortable: true,
    },
    {
      name: "User Name",
      cell: ({ userName }) => String(userName),
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: "email",
      cell: (data) => String(data.email),
      sortable: true,
      width: "300px",
    },
    {
      name: "Mobile Number",
      selector: "contactNumber",
      cell: (data) => String(data.contactNumber),
      sortable: true,
      width: "150px",
    },
    {
      name: "Alt. Mobile Number",
      selector: "alternateContactNumber",
      cell: (data) => String(data.alternateContactNumber),
      sortable: true,
      width: "180px",
    },
    {
      name: "Events",
      cell: (data) => Number(data.totalEvents),
      sortable: true,
    },
    {
      name: "Lots",
      cell: (data) => Number(data.totalItems),
      sortable: true,
    },
    {
      name: "Alloeted Lots",
      cell: (data) => Number(data.allotedItems),
      sortable: true,
      width: "130px",
    },
    {
      name: "Bidded Lots",
      cell: (data) => Number(data.totalBidedItems),
      sortable: true,
      width: "130px",
    },
    {
      name: "Lead Bid",
      cell: (data) => Number(data.h1RanksCount),
      sortable: true,
    },
    {
      name: "L2/H2",
      cell: (data) => Number(data.h2RanksCount),
      sortable: true,
    },
    {
      name: "Others",
      cell: (data) => Number(data.h3RanksCount),
      sortable: true,
    },

    {
      name: "Action",
      //   selector: "action",
      sortable: true,
      cell: (data) => (
        <div>
          {" "}
          <Button
            className="btn btn-group"
            onClick={() => {
              SetChangeView("DashBuyerDetail");
              SetBuyerId(data.buyerId);
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  const auctionBuyersListExportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.userName,
      columns.email,
      columns.contactNumber,
      columns.alternateContactNumber,
      columns.totalEvents,
      columns.totalItems,
      columns.allotedItems,
      columns.totalBidedItems,
      columns.h1RanksCount,
      columns.h2RanksCount,
      columns.h3RanksCount,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "buyer-list.xlsx");
  };

  const AuctionBuyerListExportButton = () => (
    <Button onClick={() => auctionBuyersListExportToXLSX(tableBuyers, columns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  const renderComponent = () => {
    switch (changeView) {
      case "DashBuyerDetail":
        return (
          <DashBuyerDetail
            DashEventDetail={(buyerId, buyerEventId) => {
              SetChangeView("DashEventDetail");
              SetBuyerEventId(buyerEventId);
            }}
            BidlogFirst={() => {
              SetChangeView("AuctionBid");
            }}
            buyerId={buyerId}
          />
        );
      case "DashEventDetail":
        return (
          <DashEventDetail
            BuyerBidLog={(eventItemId) => {
              SetChangeView("BuyerBidLog");
              SetBuyerEvenItemtId(eventItemId);
            }}
            DashBuyerDetail={(buyerId) => {
              SetChangeView("DashBuyerDetail");
              SetBuyerId(buyerId);
            }}
            buyerId={buyerId}
            buyerEventId={buyerEventId}
          />
        );
      case "BuyerBidLog":
        return (
          <BuyerBidLog
            DashEventDetail={() => {
              SetChangeView("DashEventDetail");
            }}
            buyerId={buyerId}
            buyerEventId={buyerEventId}
            buyerEventItemId={buyerEventItemId}
          />
        );
      default:
        return (
          <div className="AuctionTable">
            {userData.role != "BUYER" ? (
              <>
                <h3>Buyer List.</h3>
                <DataTableExtensions
                  print={false}
                  export={false}
                  exportHeaders={false}
                  columns={columns}
                  data={tableBuyers}
                >
                  <DataTable
                    noHeader={false}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    className="DataTable"
                    actions={<AuctionBuyerListExportButton />}
                  />
                </DataTableExtensions>
              </>
            ) : (
              <Navigate to={{ pathname: "/dashboard" }} />
            )}
          </div>
        );
    }
  };

  return (
    <div className={classes.section}>
      <div className="container">
        <div className={classes.Dashboard}>
          <div className={classes.dashboardCard}>
            <DashboardMenu />
            <div className={classes.dashboardCards}>{renderComponent()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBuyer;
